import create from 'zustand'
import { currentYear } from '~/utils/constants'

export type Teacher = {
  email: string
  familyName: string
  familyNameHiragana: string
  firebaseId: string
  givenName: string
  givenNameHiragana: string
  role: string
  testYear: number
  _id: string
  username: string
  schoolClass: number
  schoolGrade: number
}
export type School = {
  _id: string
  attributes: {
    schoolCode: string
    schoolName: string
    schoolPostalCode: number
    prefectureCode: number
    schoolAddress: string
    cityName: string
    districtName: string
    schoolCategoryCode: string
    schoolTypeCode: number
    email: string
    schoolAdminName: string
    schoolAdminEmail: string
    onboardComplete: boolean
    telephone: string
    fax?: string
    website?: string
    isStudentInputActive?: boolean
    isEnduranceRunInputActive?: boolean
  }
}

interface ISchoolStore {
  kobe: boolean
  teacher?: Teacher
  teacherRole?: string
  school?: School
  schoolCategoryCode?: string
  testYear: number
  testYears: number[]
  useCustomizeQuestion: boolean
  setTeacher: (me: Teacher) => void
  setSchool: (school: School) => void
  setTestYear: (testYear: number) => void
  setTestYears: (testYears: number[]) => void
  setUseCustomizeQuestion: (useCustomizeQuestion: boolean) => void
}

const useAlphaStore = create<ISchoolStore>((set) => ({
  kobe: (process.env.REACT_APP_KOBE ?? 'FALSE') === 'TRUE',
  teacher: null,
  school: null,
  schoolCategoryCode: null,
  testYear: currentYear,
  testYears: [currentYear],
  useCustomizeQuestion: false,
  setTeacher: (teacher: Teacher) => set({ teacher, teacherRole: teacher.role }),
  setSchool: (school: School) =>
    set({ school, schoolCategoryCode: school?.attributes?.schoolCategoryCode }),
  setTestYear: (testYear: number) => set({ testYear }),
  setTestYears: (testYears: number[]) => set({ testYears }),
  setUseCustomizeQuestion: (useCustomizeQuestion: boolean) =>
    set({ useCustomizeQuestion }),
}))

export { useAlphaStore }
