import React, { useEffect, useState } from 'react'

import {
  useGradesLazyQuery,
  useStudentInvitationCodesLazyQuery,
} from '@alpha/core'

import { Button, Select, Table, Typography } from 'antd'
import { ColumnType } from 'antd/es/table'
import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import { useSchoolMeApiQuery } from '~/utils/hooks'

import { KobeSchoolYearInfoPage } from '../modal/KobeSchoolInfoModal'
import { WarningOutlined } from '@ant-design/icons'

import axios from 'axios'
import { useAlphaStore } from '~/context'

const { Option } = Select
const { Text } = Typography

const SchoolYearInfoPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()
  const { data: teacherData } = useSchoolMeApiQuery()
  const testYear = teacherData?.testYear

  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade

  const [grade, setGrade] = useState<any>(
    teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
      ? teacherSchoolGrade
      : null,
  )
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad && teacherRole) {
      setFirstLoad(false)
      setGrade(
        teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
          ? teacherSchoolGrade
          : null,
      )
    }
  }, [firstLoad, teacherRole])

  const [data, setData] = useState<any[]>([])
  const [count, setCount] = useState<any>({})
  const [step, setStep] = useState<number>(1)

  // Class QR Code Modal
  const [showQRModal, setShowQRModal] = useState(false)
  const [qrCodeData, setQRCodeData] = useState<any>(null)

  const [loading, setLoading] = useState(true)

  const [getGrades, { data: gradesData }] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [getInvitationCodes, { data: invitationCodesData }] =
    useStudentInvitationCodesLazyQuery({
      fetchPolicy: 'network-only',
    })

  const fetchCount = async (
    schoolId: string,
    testYear: number,
    schoolGrade: string,
  ) => {
    await axios
      .get(`${process.env.REACT_APP_REST_API_URL}/alpha/students`, {
        params: {
          schoolId,
          testYear,
          schoolGrade,
        },
      })
      .then((response) => {
        setCount(response.data.count)
      })
      .catch((response) => {
        console.log(response)
      })
  }

  // getGrades
  useEffect(() => {
    if (!school) {
      return
    }
    const schoolId = school._id
    if (testYear) {
      getGrades({
        variables: {
          input: {
            schoolId,
            testYear,
          },
        },
      })

      fetchCount(schoolId, testYear, grade)
    }
  }, [school, getGrades, grade, testYear])

  // getInvitationCodes
  useEffect(() => {
    if (!gradesData?.grades?.data) return

    let schoolId = school._id
    if (!schoolId) return

    let schoolGrades = gradesData.grades.data.map((g) => {
      return {
        grade: g.schoolGrade,
        classes: g.numberOfClasses,
      }
    })
    if (grade)
      schoolGrades = schoolGrades.filter(
        (schoolGrade) => schoolGrade.grade === grade,
      )

    getInvitationCodes({
      variables: {
        input: {
          schoolId: schoolId,
          testYear,
          schoolGrades,
        },
      },
    })
  }, [testYear, gradesData, school, getInvitationCodes, grade])

  // combineData
  useEffect(() => {
    if (!gradesData?.grades?.data) return
    if (!invitationCodesData?.studentInvitationCodes) return

    setLoading(true)
    const getNumberOfClasses = (_grade) =>
      Object.keys(count).filter((key) => key.startsWith(`${_grade}_`)).length
    const getClassCountKeys = (_grade) =>
      Object.keys(count)
        .filter((key) => key.startsWith(`${_grade}_`))
        .sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]))

    let _data = []
    gradesData.grades.data.forEach((gradeData) => {
      if (getNumberOfClasses(gradeData.schoolGrade) >= 0) {
        _data.push({
          ...gradeData,
          numberOfClasses: getNumberOfClasses(gradeData.schoolGrade),
          invitationCodes:
            invitationCodesData.studentInvitationCodes?.data?.filter(
              (c) => c.schoolGrade === gradeData.schoolGrade,
            ) ?? [],
          classes:
            getNumberOfClasses(gradeData.schoolGrade) > 0 &&
            getClassCountKeys(gradeData.schoolGrade).map((key) => {
              const schoolClass = key.split('_')[1]
              return {
                ...gradeData,
                class: schoolClass,
                numberOfStudentsPerClass: count[key],
                invitationCodes:
                  invitationCodesData.studentInvitationCodes?.data?.filter(
                    (c) =>
                      c.schoolGrade === gradeData.schoolGrade &&
                      c.schoolClass === parseInt(schoolClass),
                  ) ?? [],
              }
            }),
        })
      }
    })
    setData(_data)
    setLoading(false)
  }, [count, grade, gradesData, invitationCodesData])
  useEffect(() => {
    setStep(1)
  }, [])
  const handleShowCodes = (code) => {
    setStep(2)
    setQRCodeData({ code, data })
  }

  // useEffect(() => {
  //   if (teacherRole === 'SUPER_ADMIN' || teacherRole === 'SCHOOL_ADMIN' && data?.length) {
  //     setNewData(data)
  //   }
  //   else if (teacherRole === 'GRADE_ADMIN' && data?.length) {
  //     const newData = data?.filter(inf => inf.schoolGrade === teacherSchoolGrade)
  //     setNewData(newData)
  //   } else if (teacherRole === 'CLASS_ADMIN' && data?.length) {
  //     const classes = []
  //     const filterData = data?.filter(inf => inf.schoolGrade === teacherSchoolGrade)
  //     const filterClasses = filterData?.map((info => info.classes))
  //     const filterGetClass = filterClasses?.map(classes => classes.filter(classInfo => +classInfo.class === teacherSchoolClass));
  //     classes.push(filterGetClass)
  //     const dataFiltered = filterData?.map((item: any) => {
  //       return {
  //         ...item,
  //         classes: filterGetClass[0]
  //       }
  //     })
  //     setNewData(dataFiltered)
  //   }
  // }, [data])

  const tableColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'schoolGrade',
      className: 'text-center-f w-1/4',
      onCell: (_, index) => {
        if (index === 0) {
          return { rowSpan: 100 }
        } else {
          return { rowSpan: 0 }
        }
      },
    },
    {
      title: '組',
      dataIndex: 'class',
      className: 'text-center-f w-1/4',
    },
    {
      title: '生徒数',
      dataIndex: 'numberOfStudentsPerClass',
      className: 'text-center-f w-1/4',
    },
    {
      title: 'ID',
      dataIndex: 'invitationCodes',
      className: 'text-center-f w-1/4',
      render: (invitationCodes) => {
        return (
          <div>
            {invitationCodes.map((code, index) => (
              <div
                key={`${code.schoolGrade}-${index}`}
                className="flex space-x-2 justify-center"
              >
                <Button
                  type="primary"
                  className="ant-btn-xs"
                  onClick={() => {
                    handleShowCodes(code)
                  }}
                >
                  表示
                </Button>
              </div>
            ))}
          </div>
        )
      },
    },
  ]

  const qrCodeModalProps = {
    data: qrCodeData,
    showModal: showQRModal,
    setShowModal: setShowQRModal,
  }

  const tableProps = {
    columns: tableColumns,
    rowKey: 'grade',
    pagination: {
      hideOnSinglePage: true,
    },
  }

  const dataClasses = data?.filter((data) => data.classes !== false)

  return (
    <div>
      <Dashboard
        selectedMenu={graphItem.tabIndex}
        navbar={graphItem.title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className="flex justify-center">
          <div style={{ width: '600px' }} className="mt-16">
            {step === 1 && (
              <>
                <div className="space-x-4 mb-4">
                  <Select
                    className="w-30 rounded-5px"
                    value={grade}
                    dropdownAlign={{
                      offset: [0, -2], // align offset
                    }}
                    onChange={setGrade}
                  >
                    {(teacherRole === 'SUPER_ADMIN' ||
                      teacherRole === 'SCHOOL_ADMIN') && (
                      <Option value={null} key="all">
                        全学年
                      </Option>
                    )}
                    {gradesData?.grades?.data?.length > 0 ? (
                      gradesData?.grades?.data?.map((element) => (
                        <Option
                          value={element.schoolGrade}
                          key={element.schoolGrade}
                        >
                          {element.schoolGrade}学年
                        </Option>
                      ))
                    ) : gradesData?.grades?.data?.length === 0 ? (
                      <></>
                    ) : (
                      <Option
                        value={teacherSchoolGrade}
                        key={teacherSchoolGrade}
                      >
                        {teacherSchoolGrade}学年
                      </Option>
                    )}
                  </Select>
                </div>

                {loading ? (
                  <Table
                    {...tableProps}
                    loading={loading}
                    className="mb-3"
                    style={{ width: 520 }}
                    size="small"
                    bordered={true}
                  />
                ) : data?.length > 0 && dataClasses?.length ? (
                  data
                    ?.map((_data) => _data.classes)
                    .map(
                      (_class) =>
                        _class.length > 0 && (
                          <Table
                            {...tableProps}
                            dataSource={_class}
                            className="mb-3"
                            style={{ width: 520 }}
                            size="small"
                            rowKey={(record) =>
                              `${record.schoolGrade}_${record.class}`
                            }
                            rowClassName="font-bold text-black"
                            bordered={true}
                          />
                        ),
                    )
                ) : (
                  <div className="mt-52 flex text-center items-center justify-center">
                    <div className="h-14 w-64 border p-3 border-warn">
                      <WarningOutlined className="text-3xl warn-icon" />
                      <Text type="danger" className="font-black">
                        生徒登録をしてください。
                      </Text>
                    </div>
                  </div>
                )}
              </>
            )}

            {step === 2 && <KobeSchoolYearInfoPage info={qrCodeData} />}
          </div>
        </div>
      </Dashboard>
    </div>
  )
}

export default SchoolYearInfoPage
