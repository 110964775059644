import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import axios from 'axios'
import { Dashboard } from '../layout/Dashboard'
import { LoginOutlined } from '@ant-design/icons'
import { DashboardNavGraphItem } from '../navigation'
import { useAlphaStore } from '~/context'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../../alpha/core/auth/AuthProvider'
import { currentYear } from '~/utils/constants'

const LogoutPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const {
    setSchool,
    setTeacher,
    setTestYear,
    setTestYears,
    setUseCustomizeQuestion,
  } = useAlphaStore()

  const client = useApolloClient()
  const history = useHistory()

  const handleLogoutBtn = useCallback(async () => {
    window.localStorage.clear()
    delete axios.defaults.headers.common[LOCAL_STORAGE_AUTH_TOKEN_KEY]
    await client.clearStore()
    setTimeout(() => {
      history.push('/')
      window.open('/', '_self')
      setSchool(null)
      setTeacher(null)
      setTestYear(currentYear)
      setTestYears([currentYear])
      setUseCustomizeQuestion(false)
    }, 500)
  }, [
    client,
    history,
    setSchool,
    setTeacher,
    setTestYear,
    setTestYears,
    setUseCustomizeQuestion,
  ])

  const handleReturnBtn = useCallback(() => {
    history.push('/home')
  }, [history])

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={false}
      setIsOpen={setIsOpen}
    >
      <div className="mt-40 text-center">
        <div className="space-y-16">
          <div className="text-xl font-bold">ログアウトしますか？</div>
          <LoginOutlined className="text-6xl" />
          <div className="space-x-4">
            <Button
              className="w-60 rounded"
              type="primary"
              onClick={handleLogoutBtn}
            >
              はい
            </Button>
            <Button
              className="w-60 rounded"
              type="primary"
              onClick={handleReturnBtn}
            >
              いいえ
            </Button>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default LogoutPage
