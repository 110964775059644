import axios from 'axios'

const API_URL = process.env.REACT_APP_REST_API_URL
const defaultOptions = {}

function getApi(path: string, options: any = {}, apiURL: any) {
  return axios.get(`${apiURL || API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

function postApi(path: any, data: any, options: any = {}) {
  return axios.post(`${API_URL}/${path?.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

function putApi(path: any, data: any, options: any = {}) {
  return axios.put(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options?.headers,
    },
  })
}

function patchApi(path: any, data: any, options: any) {
  return axios.patch(`${API_URL}/${path.replace(/^\//, '')}`, data, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

function deleteApi(path: any, options: any = {}) {
  return axios.delete(`${API_URL}/${path.replace(/^\//, '')}`, {
    ...defaultOptions,
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

const Api: any = {
  get: getApi,
  post: postApi,
  put: putApi,
  delete: deleteApi,
  patch: patchApi,
}

export default Api
