import React, { useEffect, useState } from 'react'

import { Button, Col, Form, Input, message, Row, Radio, Typography } from 'antd'

import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'

import { UpdateSchoolInput, useUpdateSchoolMutation } from '@alpha/core'

import { NoData } from '../shared/NoData'
import CompletionLayout from '@alpha/components/layout/CompletionLayout'
import { schoolCodeHaveEnduranceRun } from '../../utils'
import { School, useAlphaStore } from '~/context'
import { useSchoolMeApiQuery } from '~/utils/hooks'
import { getSchoolInformation, updateSchoolInformation } from '../admin/api'

const { Text } = Typography

const SchoolInfoPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school, teacher, setTeacher, setSchool } = useAlphaStore()
  const [showSavedNotification, setShowSavedNotification] = useState(false)
  const [schoolUpdateData, setSchoolUpdateData] = useState<any>()
  const {
    data: teacherData,
    loading: teacherLoading,
    refresh: refreshTeacherData,
  } = useSchoolMeApiQuery()

  const [updateSchoolMutation, { loading }] = useUpdateSchoolMutation()

  const [form] = Form.useForm()

  useEffect(() => {
    getSchoolData()
  }, [])

  useEffect(() => {
    if (schoolUpdateData) {
      form.setFieldsValue({
        username: schoolUpdateData.schoolUser.length
          ? schoolUpdateData.schoolUser[0].username
          : null,
        telephone: schoolUpdateData.school.attributes.telephone,
      })
    }
  }, [schoolUpdateData])

  useEffect(() => {
    if (teacherLoading) return
    if (teacherData) {
      let _teacher = teacherData
      let _school = _teacher.school

      if (_teacher) {
        setTeacher(_teacher)

        if (_school) setSchool(_school as School)
      }
    }
  }, [teacherLoading, teacherData, setTeacher, setSchool])

  const getSchoolData = async () => {
    const { data } = await getSchoolInformation()
    setSchoolUpdateData(data.data)
  }

  if (!school || !teacher) {
    return (
      <Dashboard
        selectedMenu={graphItem.tabIndex}
        navbar={graphItem.title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <Card>
          <div>
            <NoData />
          </div>
        </Card>
      </Dashboard>
    )
  }

  const onFinish = async (values) => {
    const variables: { input: UpdateSchoolInput } = {
      input: {
        schoolId: school._id,
        attributes: {
          isStudentInputActive:
            values.isStudentInputActive === '1' ? true : false,
          isEnduranceRunInputActive:
            values.isEnduranceRunInputActive === '1' ? true : false,
        },
      },
    }
    const telephoneObj: any = {
      schoolTelephone: values.telephone,
    }
    try {
      await updateSchoolInformation(telephoneObj)
      const res = await updateSchoolMutation({ variables })
      if (res.errors) {
        message.error(`エラーが発生しました [${res.errors.join(',')}]`)
        return
      }
      const { updateSchool } = res.data!
      if (!updateSchool?.success) {
        message.error(`エラーが発生しました [${updateSchool?.error}]`)
        return
      }
      setShowSavedNotification(true)
      setTimeout(() => {
        setShowSavedNotification(false)
      }, 2000)

      refreshTeacherData()
    } catch (err) {
      message.error(err as string)
    }
  }

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="flex justify-center mt-4">
        {!showSavedNotification ? (
          <Form
            form={form}
            layout="vertical"
            className="max-w-400"
            initialValues={{
              fax: school.attributes.fax,
              website: school.attributes.website,
              isStudentInputActive: school.attributes.isStudentInputActive
                ? '1'
                : '0',
              isEnduranceRunInputActive: school.attributes
                .isEnduranceRunInputActive
                ? '1'
                : '0',
            }}
            onFinish={onFinish}
            hideRequiredMark
          >
            <Row gutter={10}>
              <Col span={24}>
                <div className="text-base font-bold text-center">
                  {school.attributes.schoolName}
                </div>
              </Col>
              <Col span={24}>
                <div className="my-1 text-xs font-bold text-center">
                  {school.attributes.schoolAddress}
                </div>
              </Col>
              <Col span={24}>
                <div className="mt-3 mb-7 text-sm font-bold text-center">
                  {`${teacher.familyName ?? ''} ${
                    teacher.givenName ?? ''
                  } 先生`}
                </div>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="telephone"
                  label={
                    <>
                      <Text className="text-xs font-bold">学校電話番号</Text>
                      <Button
                        type="primary"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        className="ant-btn-ti ml-2"
                        danger
                      >
                        必須
                      </Button>
                    </>
                  }
                  rules={[{ required: true, message: '入力して下さい' }]}
                >
                  <Input
                    className="bg-gray-150"
                    disabled={
                      teacher.role !== 'SUPER_ADMIN' &&
                      teacher.role !== 'SCHOOL_ADMIN'
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="username"
                  label={
                    <>
                      <Text className="text-xs font-bold">担当者ID</Text>
                      <Button
                        type="primary"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        className="ant-btn-ti ml-2"
                        danger
                      >
                        必須
                      </Button>
                    </>
                  }
                >
                  <Input className="bg-gray-150" disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="isStudentInputActive"
                  label={
                    <Text className="text-xs font-bold">
                      児童生徒用ALPHAでの測定結果の入力可否
                    </Text>
                  }
                >
                  <Radio.Group
                    disabled={
                      teacher.role !== 'SUPER_ADMIN' &&
                      teacher.role !== 'SCHOOL_ADMIN'
                    }
                  >
                    <Radio key="input-on" value="1" checked={true}>
                      可能
                    </Radio>
                    <Radio key="input-off" value="0">
                      不可能
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {schoolCodeHaveEnduranceRun(
                school.attributes.schoolCategoryCode,
              ) && (
                <Col span={24}>
                  <div className="custom-row">
                    <Form.Item
                      name="isEnduranceRunInputActive"
                      label={
                        <Text className="text-xs font-bold">
                          全身持久力を選択
                        </Text>
                      }
                    >
                      <Radio.Group
                        disabled={
                          teacher.role !== 'SUPER_ADMIN' &&
                          teacher.role !== 'SCHOOL_ADMIN'
                        }
                      >
                        <Radio key="input-on" value="1">
                          持久走
                        </Radio>
                        <Radio key="input-off" value="0" checked={true}>
                          20mシャトルラン
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Col>
              )}
              <Col span={24} className="text-center">
                <Form.Item>
                  <Button
                    className="w-60 rounded"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={
                      teacher.role !== 'SUPER_ADMIN' &&
                      teacher.role !== 'SCHOOL_ADMIN'
                    }
                  >
                    登録
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <CompletionLayout message="登録完了" />
        )}
      </div>
    </Dashboard>
  )
}

export default SchoolInfoPage
