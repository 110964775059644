import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Select, Table, Tag, Button } from 'antd'
import { ColumnType } from 'antd/es/table'

import {
  AuthContext,
  useGradesLazyQuery,
  useMeQuery,
  useStudentsLazyQuery,
  fetchSchoolClasses,
} from '@alpha/core'

import InputQuestionnaireModal from '../modal/InputQuestionnaireModal'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import { Link } from 'react-router-dom'
import { currentYear } from '~/utils/constants'

const { Option } = Select

const QuestionnaireInputOldPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const auth = useContext(AuthContext)

  const [testYears, setTestYears] = useState([2021])
  const [testYear, setTestYear] = useState(2021)
  const [schoolGrade, setSchoolGrade] = useState<any>(1)
  const [schoolClass, setSchoolClass] = useState<any>(1)
  const [schoolClasses, setSchoolClasses] = useState([1])
  const [school, setSchool] = useState<any>(null)
  const [data, setData] = useState<any[]>()
  const [selectedStudent, setSelectedStudent] = useState<any>(null)
  const [showQuestionnaireInputModal, setShowQuestionnaireInputModal] =
    useState<any>(false)

  const { data: meData } = useMeQuery({
    fetchPolicy: 'cache-first',
  })
  const [getGrades, { data: gradesData }] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [
    getStudents,
    { data: studentData, loading, refetch: refetchStudents },
  ] = useStudentsLazyQuery({
    fetchPolicy: 'network-only',
  })

  const { currentUser } = auth

  // testYears
  // useEffect(() => {
  //   if (!currentUser) {
  //     return
  //   }
  //   if (!meData?.me?.data?.relationships.schools) {
  //     return
  //   }
  //   const school = meData.me.data.relationships.schools[0]
  //   setSchool(school)

  //   let _testYears = school.attributes.testYears ?? [2022]
  //   setTestYears(_testYears)
  // }, [currentUser, meData])

  // getGrades
  useEffect(() => {
    if (!currentUser) {
      return
    }
    if (!meData?.me?.data?.relationships.schools) {
      return
    }
    const school = meData.me.data.relationships.schools[0]
    setSchool(school)
    const schoolId = meData.me.data.relationships.schools[0]._id
    getGrades({
      variables: {
        input: {
          schoolId,
          testYear,
        },
      },
    })
  }, [currentUser, getGrades, meData, testYear])

  // getStudents
  useEffect(() => {
    if (!gradesData?.grades?.data) return

    let schoolId = school._id
    if (!schoolId) return

    getStudents({
      variables: {
        input: {
          schoolId: schoolId,
          testYear,
          schoolClass,
          schoolGrade,
        },
      },
    })
  }, [gradesData, school, getStudents, schoolGrade, schoolClass, testYear])

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, testYear).then((res) => {
        if (res['schoolClasses'].length) {
          setSchoolClasses(res['schoolClasses'])
        }
      })
    }
  }, [testYear, school])

  // setData
  useEffect(() => {
    if (!studentData?.students?.data) return

    setData(studentData.students.data)
  }, [studentData])

  const reloadList = useCallback(() => {
    if (!gradesData?.grades?.data) return

    let schoolId = school._id
    if (!schoolId) return

    if (!refetchStudents) {
      return
    }

    refetchStudents({
      input: {
        schoolId: schoolId,
        testYear,
        schoolClass,
        schoolGrade,
      },
    })
  }, [
    refetchStudents,
    gradesData?.grades?.data,
    school?._id,
    schoolClass,
    schoolGrade,
    testYear,
  ])

  const useTableColumns: ColumnType<any>[] = [
    {
      title: '出席番号',
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      key: 'schoolAttendanceNumber',
    },
    {
      title: '姓名',
      dataIndex: ['attributes'],
      render: (v, _) => (
        <div>{`${v.familyName ?? ''}　${v.givenName ?? ''}`}</div>
      ),
    },
    {
      title: '状況',
      dataIndex: ['attributes', 'isQuestionnaireSubmitted'],
      key: 'questionnaire',
      render: (v, r) => {
        let testResult = r.attributes.testResults && r.attributes.testResults[0]
        if (!testResult) return <Tag color="red">未提出</Tag>

        let hasResponded =
          testResult.questionnaire || testResult.questionnaireV2

        return hasResponded ? (
          <Tag color="green">提出済み</Tag>
        ) : (
          <Tag color="red">未提出</Tag>
        )
      },
    },
    {
      title: '',
      render: (_, r) => {
        let testResult = r.attributes.testResults && r.attributes.testResults[0]
        let hasResponded =
          testResult?.questionnaire || testResult?.questionnaireV2
        return (
          <Button
            type="primary"
            onClick={() => {
              setSelectedStudent(r)
              setShowQuestionnaireInputModal(true)
            }}
          >
            {hasResponded ? '編集' : '入力'}
          </Button>
        )
      },
    },
  ]

  const modalProps = {
    student: selectedStudent,
    isShow: showQuestionnaireInputModal,
    setIsShow: setShowQuestionnaireInputModal,
    testYear: currentYear,
    refetch: () => {
      if (reloadList) return reloadList()
    },
  }

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="mt-3 flex flex-col justify-center items-center">
        <div style={{ width: 930 }}>
          <div className="w-full mb-3">
            <Link className="warn-link" to="/questionnaire">
              2022年度はこちら&gt;&gt;&gt;
            </Link>
          </div>

          <div className="space-x-4 mb-8">
            <Select
              className="w-36"
              value={testYear}
              onChange={(y) => setTestYear(y)}
            >
              <>
                {testYears?.map((year) => (
                  <Option value={year} key={year}>
                    {year}年度
                  </Option>
                ))}
              </>
            </Select>

            <Select
              className="w-36"
              value={schoolGrade}
              onChange={setSchoolGrade}
            >
              <>
                {gradesData?.grades?.data?.map((v) => (
                  <Option value={v.schoolGrade} key={v.schoolGrade}>
                    {`${v.schoolGrade}年生`}
                  </Option>
                ))}
              </>
            </Select>

            <Select
              className="w-36"
              value={schoolClass}
              onChange={setSchoolClass}
            >
              <Option key={`class-${0}`} value={0}>
                全組
              </Option>
              <>
                {schoolClasses.map((_class) => {
                  return (
                    <Option key={`class-${_class}`} value={_class}>
                      {_class}組
                    </Option>
                  )
                })}
              </>
            </Select>
          </div>
          <Table
            columns={useTableColumns}
            dataSource={data}
            loading={loading}
            style={{ minWidth: 800 }}
            pagination={{ pageSize: 100 }}
            // onChange={handleTableChange}
            // rowKey="schoolCode"
          />

          <InputQuestionnaireModal
            key={`q-${selectedStudent?._id ?? ''}`}
            {...modalProps}
          />
        </div>
      </div>
    </Dashboard>
  )
}

export default QuestionnaireInputOldPage
