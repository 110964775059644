import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  message,
  Select,
  Steps,
  Table,
  Typography,
  Upload,
} from 'antd'
import { ColumnType } from 'antd/es/table'
import { UploadOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import CompletionLayout from '@alpha/components/layout/CompletionLayout'
import { useSchoolMeApiQuery } from '~/utils/hooks'
import {
  getListTeachers,
  fileUpload,
  updateListTeachers,
} from '../../components/admin/api'
import { Dashboard } from '../layout/Dashboard'
import {
  getGradeSchool,
  getClassSchool,
  getRoleSchool,
  dataType,
} from '~/utils/constants'
import { statusKey, displayNumbers } from '~/utils/constants'

const { Option } = Select
const { Step } = Steps
const { Text } = Typography

const TeacherManagementPage = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen
}) => {
  const history = useHistory()
  const { data: teacherData, loading: teacherLoading } = useSchoolMeApiQuery()
  const [page, setPage] = useState<number>(1)
  const [listTeachers, setListTeachers] = useState<any[]>([])
  const [listImported, setListImported] = useState<any[]>([])
  const [listBeforeUpload, setListBeforeUpload] = useState<any[]>([])
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0)
  const [confirmed1, setConfirmed1] = useState<boolean>(false)
  const [confirmed2, setConfirmed2] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(50)
  const [pageSizeList, setPageSizeList] = useState<number>(50)
  const [currentList, setCurrentList] = useState<number>(1)
  const [uploadFile, setUploadFile] = useState<string | null>(null)
  const [current, setCurrent] = useState<number>(1)

  if (teacherData && teacherData.username !== 'admin') {
    history.push('/home')
  }

  useEffect(() => {
    const fetchListTeachers = async () => {
      const { data } = await getListTeachers()
      if (!data) return
      setListTeachers(data.data)
    }
    fetchListTeachers()
  }, [listImported, currentStepIdx, page])

  const tableColumns: ColumnType<any>[] = [
    {
      title: '氏名',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'しめい',
      dataIndex: 'fullNameHiragana',
      key: 'fullNameHiragana',
    },
    {
      title: 'ID',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '権限',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'パスワード',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: '学年',
      dataIndex: 'schoolGrade',
      key: 'schoolGrade',
    },
    {
      title: 'クラス',
      dataIndex: 'schoolClass',
      key: 'schoolClass',
    },
  ]
  const keyInfo = {
    氏名: 'fullName',
    しめい: 'fullNameHiragana',
    ID: 'username',
    権限: 'role',
    パスワード: 'password',
    学年: 'schoolGrade',
    クラス: 'schoolClass',
    ステータス: 'status',
  }

  const keySchoolRoles = {
    管理者: 'SCHOOL_ADMIN',
    学年管理者: 'GRADE_ADMIN',
    クラス管理者: 'CLASS_ADMIN',
  }

  const handleUpload = async () => {
    const teacherFormUpload = new FormData()
    teacherFormUpload.append('file', uploadFile)
    try {
      const { data } = await fileUpload(teacherFormUpload)

      const rawData = data.data
      const processData = rawData.map((obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            keyInfo[key] ?? key,
            keySchoolRoles[key] ?? value,
          ]),
        ),
      )
      processData.map((item) => {
        for (const key of Object.keys(keySchoolRoles)) {
          if (item['role'] === key) {
            item['role'] = keySchoolRoles[key]
          }
        }
      })
      let executeData: Array<any> = []
      processData.map((item) => {
        if (item['status'] === statusKey.DEACTIVE) {
          if (item['role'] === 'CLASS_ADMIN') {
            if (
              item['schoolGrade'] !== undefined &&
              item['schoolClass'] !== undefined &&
              typeof item['schoolGrade'] !== 'string' &&
              typeof item['schoolClass'] !== 'string'
            ) {
              executeData.push(item)
            }
          } else if (item['role'] === 'GRADE_ADMIN') {
            if (
              item['schoolGrade'] !== undefined &&
              typeof item['schoolGrade'] !== dataType.STRING
            ) {
              executeData.push(item)
            }
          }
        } else {
          if (item['role'] === 'CLASS_ADMIN') {
            if (
              item['schoolGrade'] !== undefined &&
              item['schoolClass'] !== undefined &&
              typeof item['schoolGrade'] !== 'string' &&
              typeof item['schoolClass'] !== 'string'
            ) {
              item['status'] = statusKey.ACTIVE
              executeData.push(item)
            }
          } else if (item['role'] === 'GRADE_ADMIN') {
            if (
              item['schoolGrade'] !== undefined &&
              typeof item['schoolGrade'] !== dataType.STRING
            ) {
              item['status'] = statusKey.ACTIVE

              executeData.push(item)
            }
          } else {
            item['status'] = statusKey.ACTIVE
            executeData.push(item)
          }
        }
      })
      setListImported(executeData)
      setListBeforeUpload(executeData)
      setCurrentStepIdx(1)
    } catch (e) {
      message.error('エラーが発生しました')
    }
  }

  const prev = () => {
    setCurrent(1)
    setCurrentList(1)
    setConfirmed1(false)
    setConfirmed2(false)
    setPageSizeList(50)
    if (currentStepIdx === 1) {
      setCurrentStepIdx(0)
    } else setPage(1)
  }

  const next = async () => {
    if (page === 2 && !uploadFile && currentStepIdx !== 1) {
      message.error('Excelファイルを選択してください')
      return
    }
    if (page === 2 && uploadFile && currentStepIdx !== 1) {
      await handleUpload()
      setUploadFile(null)
    }
    if (page === 2 && currentStepIdx === 1) {
      await updateListTeachers(listBeforeUpload)
      setPage(3)
    }
  }

  const changePageSize = (value) => {
    setCurrent(1)
    setPageSize(value)
  }

  const disabled = () => {
    return (currentStepIdx === 0 && !confirmed1) ||
      (currentStepIdx === 1 && !confirmed2)
      ? true
      : false
  }

  const backToTop = () => {
    setPage(1)
    setConfirmed1(false)
    setConfirmed2(false)
    setCurrentStepIdx(0)
    setUploadFile(null)
    setCurrent(1)
    setCurrentList(1)
    setPageSizeList(50)
  }

  const dataImported = listImported
    ?.filter(
      (inf) =>
        inf.fullName &&
        inf.fullNameHiragana &&
        inf.username &&
        inf.password &&
        ['SCHOOL_ADMIN', 'GRADE_ADMIN', 'CLASS_ADMIN'].includes(inf.role),
    )
    .map((info, idx) => {
      return {
        key: idx,
        fullName: info.fullName,
        fullNameHiragana: info.fullNameHiragana,
        username: !Number(info.username)
          ? info.username.toLowerCase()
          : info.username,
        role: getRoleSchool(info.role),
        password: info.password,
        schoolGrade: getGradeSchool(info.role, info.schoolGrade),
        schoolClass: getClassSchool(info.role, info.schoolClass),
      }
    })

  const data = listTeachers?.map((info, idx) => {
    return {
      key: idx,
      fullName: info?.fullName,
      fullNameHiragana: info?.fullNameHiragana,
      username: info.username,
      role: getRoleSchool(info.role),
      password: info.password,
      schoolGrade: getGradeSchool(info.role, info.schoolGrade),
      schoolClass: getClassSchool(info.role, info.schoolClass),
    }
  })

  const steps = [
    {
      title: '①先生情報をアップロード',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file: any) => {
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setListImported([])
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">先生情報をアップロード</Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-28">(XLSXファイル式)</Text>
          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            先生情報を正しく記入しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: ' ②確認',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="mb-5 w-full flex justify-start">
            <Select
              size="small"
              className="w-30 rounded-5px"
              placeholder="表示数"
              value={pageSize}
              dropdownAlign={{
                offset: [0, -2], // align offset
              }}
              onChange={changePageSize}
            >
              {displayNumbers?.map((d) => (
                <Option className="option-small" value={d.value} key={d.text}>
                  {d.text}
                </Option>
              ))}
            </Select>
          </div>
          <Table
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            columns={tableColumns}
            dataSource={dataImported}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              defaultPageSize: 50,
              pageSize: pageSize,
              position: ['bottomCenter'],
              current: current,
              onChange(current) {
                setCurrent(current)
              },
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
            disabled={dataImported?.length === 0}
          >
            記入したデータは正しいでしょうか？
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard
      selectedMenu={20}
      navbar="管理ページ"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isManagementPage={true}
    >
      <div className="flex justify-center mt-14">
        <div>
          {page === 1 && (
            <>
              <span>登録人数 {data.length}</span>
              <Table
                columns={tableColumns}
                dataSource={data}
                loading={false}
                size="small"
                style={{ minWidth: 850 }}
                bordered={true}
                pagination={{
                  showSizeChanger: true,
                  defaultPageSize: pageSizeList,
                  pageSize: pageSizeList,
                  position: ['bottomCenter'],
                  current: currentList,
                  onChange(current, size) {
                    setCurrentList(pageSizeList !== size ? 1 : current)
                    setPageSizeList(size)
                  },
                }}
              />
              <div className="flex justify-center mt-7">
                <Button
                  type="primary"
                  className="w-64"
                  onClick={() => setPage(2)}
                >
                  先生情報をアップロード
                </Button>
              </div>
            </>
          )}

          {page === 2 && (
            <>
              <Steps
                labelPlacement="vertical"
                size="default"
                current={currentStepIdx}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className="steps-content">
                {steps[currentStepIdx].content}
              </div>
              <div className="steps-action text-center">
                <Button type="primary" className="h-8 w-24 mx-2" onClick={prev}>
                  戻る
                </Button>
                <Button
                  type="primary"
                  className="h-8 w-24"
                  onClick={next}
                  disabled={disabled()}
                >
                  {currentStepIdx === 0 ? '次へ' : '登録'}
                </Button>
              </div>
            </>
          )}

          {page === 3 && (
            <div>
              <CompletionLayout
                message="登録完了"
                prevButton="管理ページ（先生）TOPに戻る"
                onClickButton={backToTop}
              />
            </div>
          )}
        </div>
      </div>
    </Dashboard>
  )
}

export default TeacherManagementPage
