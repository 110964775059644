import React, { useEffect, useState } from 'react'
import { read, utils, set_cptable } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import axios from 'axios'

import {
  Table,
  Button,
  Upload,
  message,
  Checkbox,
  Steps,
  Typography,
  Select,
} from 'antd'
import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import CompletionLayout from '@alpha/components/layout/CompletionLayout'
import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import { convertJapNumbers, getQuestionnairesArr } from '~/utils'
import { PreparingPage } from '../shared/PreparingPage'
import { useSchoolMeApiQuery } from '~/utils/hooks'
import { currentYear } from '~/utils/constants'

const { Option } = Select
const { Step } = Steps
const { Text } = Typography

set_cptable(cp_table)

const showPreparingPage = false
const isDev = window.location.href?.endsWith('?dev')

const QuestionnaireUpload = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()
  const { data: teacherData } = useSchoolMeApiQuery()

  const [disabled, setDisabled] = useState(true)
  const [uploadFile, setUploadFile] = useState<any>()
  const [uploading, setUploading] = useState(false)
  const [data, setData] = useState<any[]>()
  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [downloaded, setDownloaded] = useState(false)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)

  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade
  const teacherSchoolClass = teacherData?.schoolClass

  const testYears = [currentYear]
  const testYear = teacherData?.testYear

  const history = useHistory()

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setDisabled(!downloaded)
        break
      case 1:
        setDisabled(!confirmed1)
        break
      case 2:
        setDisabled(!confirmed2)
        break
      case 3:
        setDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, downloaded, confirmed1, confirmed2, confirmed3])

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const isSecondarySchool = school?.attributes?.schoolCategoryCode === 'C1'
  const totalQuestionnaires = getQuestionnairesArr(school, isElementarySchool)

  const onFinish = async (values = {}) => {
    if (!school) return

    setUploading(true)

    // upload backup file
    let schoolId = school._id
    let schoolName = school.attributes.schoolName
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `questionnaire/${schoolName}_${schoolId}_${fileId}`

    const questionnaireFormData = new FormData()
    questionnaireFormData.append('file', uploadFile)
    questionnaireFormData.append('filename', fileId)

    let fileName: string

    await axios
      .post(
        `${process.env.REACT_APP_REST_API_URL!}/alpha/file-upload`,
        questionnaireFormData,
      )
      .then((res) => {
        fileName = res.data.data
      })

    // upload questionnaire data

    const formData = new FormData()
    // Refine data
    for (let i = 0; i < data.length; i++) {
      const { schoolGrade, schoolClass, schoolAttendanceNumber } = data[i]
      data[i] = {
        schoolGrade,
        schoolClass,
        schoolAttendanceNumber,
        questionnaire: {
          ...Object.fromEntries(
            totalQuestionnaires.map((q, id) => [
              `q${id + 1}`,
              data[i][`q${q}`],
            ]),
          ),
        },
      }
    }

    formData.set('testYear', `${testYear}`)
    formData.set('students', JSON.stringify(data))
    formData.set('filename', fileName)
    formData.set('schoolId', school._id)

    await axios({
      method: 'POST',
      url: `${process.env
        .REACT_APP_REST_API_URL!}/alpha/upload-questionnaire-data`,
      data: formData,
    })
      .then((response) => {
        let { status, error } = response.data
        if (status === 200) {
          message.success('アップロードしました')
          // form.resetFields()
          setUploadFile(null)
          setTimeout(() => {
            setUploading(false)
          }, 1000)
        } else {
          if (error) {
            message.error(error)
          } else {
            message.error('エラーが発生しました')
          }
        }
        setUploading(false)
      })
      .catch((response) => {
        console.error(response)
        message.error('エラーが発生しました')
        setUploading(false)
      })
  }

  const register = async () => {
    await onFinish()
    setRegisterComplete(true)
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      message.error('Excelファイルを選んでください')
      return
    }

    setCurrentStepIdx(nextStepIdx)
  }

  const prev = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  const downloadTemplate = () => {
    const fileName = '2024_ALPHAアップロード用ファイル-アンケート-小学校.xlsx'
    window.open(`/upload_sample/${fileName}`, '_blank')
    setDownloaded(true)
  }

  const parseXlsx = async (file) => {
    let _data = []
    const data = read(await file.arrayBuffer())

    const firstSheetName = data.SheetNames[0]
    const workBook = data.Sheets

    if (firstSheetName && workBook) {
      const rows = utils.sheet_to_json(workBook[firstSheetName])
      for (let row of rows) {
        let _schoolGrade =
          typeof row['学年'] === 'number'
            ? row['学年']
            : parseInt(convertJapNumbers(row['学年']))
        let _schoolClass =
          typeof row['学年'] === 'number'
            ? row['組']
            : parseInt(convertJapNumbers(row['組']))

        if (isNaN(_schoolGrade) || isNaN(_schoolClass)) continue

        _data.push({
          schoolGrade: _schoolGrade,
          schoolClass: _schoolClass,
          schoolAttendanceNumber: row['出席番号'],
          ...Object.fromEntries(
            totalQuestionnaires.map((q) => [`q${q}`, row[`Q${q}`]]),
          ),
        })
      }
      if (teacherRole === 'SUPER_ADMIN' || teacherRole === 'SCHOOL_ADMIN') {
        setData(_data)
      } else if (teacherRole === 'GRADE_ADMIN') {
        const newData = _data?.filter(
          (inf) => inf.schoolGrade === teacherSchoolGrade,
        )
        setData(newData)
      } else if (teacherRole === 'CLASS_ADMIN') {
        const filterData = _data?.filter(
          (inf) =>
            inf.schoolGrade === teacherSchoolGrade &&
            inf.schoolClass === teacherSchoolClass,
        )
        setData(filterData)
      }
    }
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'schoolGrade',
      key: 'schoolGrade',
      className: 'text-center-f',
    },
    {
      title: '組',
      dataIndex: 'schoolClass',
      key: 'schoolClass',
      className: 'text-center-f',
    },
    {
      title: '出席番号',
      dataIndex: 'schoolAttendanceNumber',
      key: 'schoolAttendanceNumber',
      className: 'text-center-f',
    },
    ...totalQuestionnaires.map((q) => ({
      title: `Q${q}`,
      dataIndex: `q${q}`,
      key: `q${q}`,
      className: 'text-center-f',
    })),
  ]

  const tableProps = {
    columns: tableColumns,
    dataSource: data,
    rowKey: 'schoolGrade',
  }

  const steps = [
    {
      title: '①登録用エクセルファイルをダウンロード',
      content: (
        <div className="steps-content flex items-center justify-center">
          <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
            <DownloadOutlined
              className="text-2xl"
              onClick={() => downloadTemplate()}
            />
          </div>
          <Button type="primary" onClick={() => downloadTemplate()}>
            登録用エクセルファイルをダウンロード
          </Button>
        </div>
      ),
    },
    {
      title: '②記入例',
      content: (
        <div className="steps-content flex flex-col items-center justify-between pt-5">
          <img className="mb-5" src="/questionnaire_list_demo.png" alt="" />
          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            記入例を確認しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: '③測定結果をアップロード',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black">
              ①でダウンロードしたファイルをアップロードしてください。
            </Text>
          </div>
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file) => {
                parseXlsx(file)
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setUploadFile(null)
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">アンケート結果をアップロード</Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-36">(XLSXファイル式)</Text>
          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
          >
            アンケート結果を正しく記入しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: ' ④確認',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <Table
            {...tableProps}
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 50,
              position: ['bottomCenter'],
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
          >
            記入したデータは正しいでしょうか？
          </Checkbox>
        </div>
      ),
    },
  ]

  const preparingElement = (
    <>
      <label className="text-base font-black">
        Excelでの入力をご希望の方は
      </label>
      <label className="text-base font-black">
        こちらのメールアドレスまでご連絡をお願いいたします。
      </label>
      <a
        className="text-base font-black"
        style={{ color: 'rgb(255,200,51)' }}
        href="mailto: alpha@pestalozzi-tech.co"
      >
        alpha@pestalozzi-tech.com
      </a>
    </>
  )

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {showPreparingPage && !isDev ? (
        <PreparingPage notice={preparingElement} />
      ) : !registerComplete ? (
        <div className="flex justify-center">
          <div className="mt-16" style={{ minWidth: '900px' }}>
            {/* <div className="space-x-4 mb-12">
                  <Select
                    className="w-30"
                    value={testYear}
                    dropdownAlign={{
                      offset: [0, -2], // align offset
                    }}
                  >
                    <>
                      {testYears?.map((year) => (
                        <Option value={year} key={year}>
                          {year}年度
                        </Option>
                      ))}
                    </>
                  </Select>
                </div> */}

            <Steps
              labelPlacement="vertical"
              size="default"
              current={currentStepIdx}
              onChange={() => {}}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[currentStepIdx].content}</div>
            <div className="steps-action text-center">
              {currentStepIdx > 0 && (
                <Button
                  type="primary"
                  className="h-8 w-24 mx-2"
                  onClick={() => prev()}
                >
                  戻る
                </Button>
              )}
              {currentStepIdx < steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => next()}
                  disabled={disabled}
                >
                  次へ
                </Button>
              )}
              {currentStepIdx === steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => register()}
                  disabled={disabled}
                >
                  登録
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CompletionLayout
          message="登録完了"
          button={
            <Button
              type="primary"
              onClick={() => history.push('/questionnaire_result')}
            >
              アンケート結果を閲覧する
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default QuestionnaireUpload
