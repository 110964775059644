import { Redirect, Route } from 'react-router-dom'

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../auth/AuthProvider'

export const PrivateRoute = ({
  component: Component,
  graphItem,
  isOpen = true,
  setIsOpen = {},
  ...rest
}) => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authToken) {
          return (
            <Component
              graphItem={graphItem}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              {...props}
            />
          )
        } else {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      }}
    />
  )
}
