import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { SignInLayout } from '@alpha/components'
import { Button } from 'antd'
import { useAlphaStore } from '~/context'

const SignInPage = () => {
  const history = useHistory()
  const { kobe } = useAlphaStore()

  const handleSignInBtn = useCallback(async () => {
    history.push('/register')
  }, [])

  return (
    <SignInLayout>
      <>
        {kobe && (
          <div className="fix right-0 top-0 text-xs text-right">kobe</div>
        )}
        <div
          className="flex flex-col justify-center"
          style={{ height: 'calc(100vh - 200px)' }}
        >
          <SignInLayout.AlphaLogo />

          <div className="text-center mt-24">
            <Button
              className="w-60"
              type="primary"
              size="large"
              onClick={handleSignInBtn}
            >
              はじめる
            </Button>
          </div>
        </div>
      </>
    </SignInLayout>
  )
}

export default SignInPage
