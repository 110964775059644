import React, { useEffect, useState } from 'react'

import {
  useGradesLazyQuery,
  useStudentInvitationCodesLazyQuery,
} from '@alpha/core'

import { Button, Select, Table, Typography } from 'antd'
import { ColumnType } from 'antd/es/table'
import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'

import { QRCodeModal } from '../modal/QRCodeModal'
import { WarningOutlined } from '@ant-design/icons'

import axios from 'axios'
import { useAlphaStore } from '~/context'

const { Option } = Select
const { Text } = Typography

const SchoolYearInfoPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school, testYear, testYears, setTestYear } = useAlphaStore()

  const [grade, setGrade] = useState<any>(null)
  const [data, setData] = useState<any[]>([])
  const [count, setCount] = useState<any>({})

  // Class QR Code Modal
  const [showQRModal, setShowQRModal] = useState(false)
  const [qrCodeData, setQRCodeData] = useState<any>(null)

  const [loading, setLoading] = useState(true)

  const [getGrades, { data: gradesData }] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [getInvitationCodes, { data: invitationCodesData }] =
    useStudentInvitationCodesLazyQuery({
      fetchPolicy: 'network-only',
    })

  const fetchCount = async (
    schoolId: string,
    testYear: number,
    schoolGrade: string,
  ) => {
    await axios
      .get(`${process.env.REACT_APP_REST_API_URL}/alpha/students`, {
        params: {
          schoolId,
          testYear,
          schoolGrade,
        },
      })
      .then((response) => {
        setCount(response.data.count)
      })
      .catch((response) => {
        console.log(response)
      })
  }

  // getGrades
  useEffect(() => {
    if (!school) {
      return
    }
    const schoolId = school._id
    getGrades({
      variables: {
        input: {
          schoolId,
          testYear,
        },
      },
    })

    fetchCount(schoolId, testYear, grade)
  }, [school, getGrades, grade, testYear])

  // getInvitationCodes
  useEffect(() => {
    if (!gradesData?.grades?.data) return

    let schoolId = school._id
    if (!schoolId) return

    let schoolGrades = gradesData.grades.data.map((g) => {
      return {
        grade: g.schoolGrade,
        classes: g.numberOfClasses,
      }
    })
    if (grade)
      schoolGrades = schoolGrades.filter(
        (schoolGrade) => schoolGrade.grade === grade,
      )

    getInvitationCodes({
      variables: {
        input: {
          schoolId: schoolId,
          testYear,
          schoolGrades,
        },
      },
    })
  }, [testYear, gradesData, school, getInvitationCodes, grade])

  // combineData
  useEffect(() => {
    if (!gradesData?.grades?.data) return
    if (!invitationCodesData?.studentInvitationCodes) return

    setLoading(true)
    const getNumberOfClasses = (_grade) =>
      Object.keys(count).filter((key) => key.startsWith(`${_grade}_`)).length
    const getClassCountKeys = (_grade) =>
      Object.keys(count)
        .filter((key) => key.startsWith(`${_grade}_`))
        .sort((a, b) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]))

    let _data = []
    gradesData.grades.data.forEach((gradeData) => {
      if (getNumberOfClasses(gradeData.schoolGrade) > 0) {
        _data.push({
          ...gradeData,
          numberOfClasses: getNumberOfClasses(gradeData.schoolGrade),
          invitationCodes:
            invitationCodesData.studentInvitationCodes?.data?.filter(
              (c) => c.schoolGrade === gradeData.schoolGrade,
            ) ?? [],
          classes:
            getNumberOfClasses(gradeData.schoolGrade) > 0 &&
            getClassCountKeys(gradeData.schoolGrade).map((key) => {
              const schoolClass = key.split('_')[1]
              return {
                ...gradeData,
                class: schoolClass,
                numberOfStudentsPerClass: count[key],
                invitationCodes:
                  invitationCodesData.studentInvitationCodes?.data?.filter(
                    (c) =>
                      c.schoolGrade === gradeData.schoolGrade &&
                      c.schoolClass === parseInt(schoolClass),
                  ) ?? [],
              }
            }),
        })
      }
    })

    setData(_data)
    setLoading(false)
  }, [count, grade, gradesData, invitationCodesData])

  const handleShowCodes = (code) => {
    setShowQRModal(true)
    setQRCodeData({ code, data })
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'schoolGrade',
      className: 'text-center-f w-1/4',
      onCell: (_, index) => {
        if (index === 0) {
          return { rowSpan: 100 }
        } else {
          return { rowSpan: 0 }
        }
      },
    },
    {
      title: '組',
      dataIndex: 'class',
      className: 'text-center-f w-1/4',
    },
    {
      title: '生徒数',
      dataIndex: 'numberOfStudentsPerClass',
      className: 'text-center-f w-1/4',
    },
    {
      title: 'サインインコード',
      dataIndex: 'invitationCodes',
      className: 'text-center-f w-1/4',
      render: (invitationCodes) => {
        return (
          <div>
            {invitationCodes.map((code, index) => (
              <div
                key={`${code.schoolGrade}-${index}`}
                className="flex space-x-2 justify-center"
              >
                <Button
                  type="primary"
                  className="ant-btn-xs"
                  onClick={() => {
                    handleShowCodes(code)
                  }}
                >
                  表示
                </Button>
              </div>
            ))}
          </div>
        )
      },
    },
  ]

  const qrCodeModalProps = {
    data: qrCodeData,
    showModal: showQRModal,
    setShowModal: setShowQRModal,
  }

  const tableProps = {
    columns: tableColumns,
    rowKey: 'grade',
    pagination: {
      hideOnSinglePage: true,
    },
  }

  return (
    <div>
      <Dashboard
        selectedMenu={graphItem.tabIndex}
        navbar={graphItem.title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className="flex justify-center">
          <div style={{ width: '600px' }} className="mt-16">
            <div className="space-x-4 mb-4">
              <Select
                className="w-30 rounded-5px"
                value={testYear}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
                onChange={setTestYear}
              >
                <>
                  {testYears?.map((year) => (
                    <Option value={year} key={year}>
                      {year}年度
                    </Option>
                  ))}
                </>
              </Select>
              <Select
                className="w-30 rounded-5px"
                value={grade}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
                onChange={setGrade}
              >
                <Option value={null} key="all">
                  全学年
                </Option>
                {gradesData?.grades?.data &&
                  gradesData.grades.data.map((element) => (
                    <Option
                      value={element.schoolGrade}
                      key={element.schoolGrade}
                    >
                      {element.schoolGrade}学年
                    </Option>
                  ))}
              </Select>
            </div>

            {loading ? (
              <Table
                {...tableProps}
                loading={loading}
                className="mb-3"
                style={{ width: 520 }}
                size="small"
                bordered={true}
              />
            ) : data?.length ? (
              data
                ?.map((_data) => _data.classes)
                .map((_class) => (
                  <Table
                    {...tableProps}
                    dataSource={_class}
                    className="mb-3"
                    style={{ width: 520 }}
                    size="small"
                    rowKey={(record) => `${record.schoolGrade}_${record.class}`}
                    rowClassName="font-bold text-black"
                    bordered={true}
                  />
                ))
            ) : (
              <div className="mt-52 flex text-center items-center justify-center">
                <div className="h-14 w-64 border p-3 border-warn">
                  <WarningOutlined className="text-3xl warn-icon" />
                  <Text type="danger" className="font-black">
                    生徒登録をしてください。
                  </Text>
                </div>
              </div>
            )}

            <QRCodeModal {...qrCodeModalProps} />
          </div>
        </div>
      </Dashboard>
    </div>
  )
}

export default SchoolYearInfoPage
