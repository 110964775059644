import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Form, Input, message, Row, Divider } from 'antd'

import { OnboardingLayout, Card } from '@alpha/components'

import {
  AuthContext,
  UpdateSchoolInput,
  useMeQuery,
  useUpdateSchoolMutation,
} from '@alpha/core'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'

const RegisterSchoolPage = () => {
  const auth = useContext(AuthContext)
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
  })
  const [updateSchoolMutation, { loading }] = useUpdateSchoolMutation()

  const history = useHistory()
  const [form] = Form.useForm()

  if (!auth.currentUser) {
    return null
  }

  if (meLoading) {
    return (
      <OnboardingLayout>
        <Card>
          <Loading />
        </Card>
      </OnboardingLayout>
    )
  }

  if (!meLoading && !meData?.me?.data) {
    return (
      <OnboardingLayout>
        <Card>
          <div>
            <span>{meError}</span>
            <NoData />
          </div>
        </Card>
      </OnboardingLayout>
    )
  }

  let schools = meData?.me?.data?.relationships.schools

  if (!schools) {
    return (
      <OnboardingLayout>
        <Card>
          <div>
            <NoData />
          </div>
        </Card>
      </OnboardingLayout>
    )
  }

  const onFinish = async (values) => {
    const variables: { input: UpdateSchoolInput } = {
      input: {
        schoolId: school._id,
        attributes: {
          ...values,
        },
      },
    }

    try {
      const res = await updateSchoolMutation({ variables })
      if (res.errors) {
        message.error(`エラーが発生しました [${res.errors.join(',')}]`)
        return
      }
      const { updateSchool } = res.data!
      if (!updateSchool?.success) {
        message.error(`エラーが発生しました [${updateSchool?.error}]`)
        return
      }
      message.success('学校情報を登録しました')
      history.push('/school_year_setup')
    } catch (err) {
      message.error(err)
    }
  }

  let school = schools[0]

  return (
    <OnboardingLayout>
      <Card>
        <h1 className="theme-nav-title">学校設定</h1>
        <div className="text-md">学校情報を入力してください。</div>
        <Divider />
        <div className="theme-nav-title pb-4">学校情報を編集</div>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            email: school.attributes.email,
            telephone: school.attributes.telephone,
            fax: school.attributes.fax,
            website: school.attributes.website,
          }}
          onFinish={onFinish}
        >
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label={<label className="dark:text-white">学校名</label>}
              >
                <div className="text-xl">{school.attributes.schoolName}</div>
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label={<label className="dark:text-white">学校所在地</label>}
              >
                <div className="text-xl">{school.attributes.schoolAddress}</div>
              </Form.Item>
            </Col>
            {/*<Col span={6}>*/}
            {/*  <Form.Item*/}
            {/*    label={<label className="dark:text-white">郵便番号</label>}*/}
            {/*  >*/}
            {/*    <div className="text-xl">*/}
            {/*      {school.attributes.schoolPostalCode}*/}
            {/*    </div>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            <Col span={6}>
              <Form.Item
                name="email"
                label={
                  <label className="dark:text-white">メールアドレス</label>
                }
                rules={[{ required: true, message: '入力して下さい' }]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="telephone"
                label={<label className="dark:text-white">TEL</label>}
                rules={[{ required: true, message: '入力して下さい' }]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="fax"
                label={<label className="dark:text-white">FAX</label>}
                rules={[{ required: false, message: '入力して下さい' }]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="website"
                label={<label className="dark:text-white">ウェブサイト</label>}
                rules={[{ required: false, message: '入力して下さい' }]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  送信
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </OnboardingLayout>
  )
}

export default RegisterSchoolPage
