import { useContext, useEffect, useState } from 'react'

import { Form, Input, Button, Divider, Checkbox, message } from 'antd'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { Card } from '@alpha/components'
import { AuthContext, useMeQuery, useUpdateSchoolMutation } from '@alpha/core'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
}
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
}

const QuestionnaireEditPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const auth = useContext(AuthContext)

  const [updateSchool] = useUpdateSchoolMutation()

  const [school, setSchool] = useState<any>(null)
  const [useMextQuestionnaire, setUseMextQuestionnaire] = useState(true)
  const [questions, setQuestions] = useState<any>(null)
  const [answers, setAnswers] = useState<any>({})

  const { data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const { currentUser } = auth

  // testYears
  useEffect(() => {
    if (!currentUser) {
      return
    }
    if (!meData?.me?.data?.relationships.schools) {
      return
    }
    const school = meData.me.data.relationships.schools[0]
    setSchool(school)
  }, [currentUser, meData])

  const onQuestionsFinish = (values) => {
    setQuestions(values.questions)
  }

  const onAnswersFinish = (values) => {
    let _answers = { ...answers }
    _answers[values.index] = {
      question: values.question,
      answers: values.answers,
      index: values.index,
    }
    setAnswers(_answers)
  }

  const onFinish = async () => {
    if (!school) {
      message.error('エラーが発生しました')
      return
    }

    if (school.attributes.schoolName === '横浜市立中和田小学校') {
      setUseMextQuestionnaire(false)
    }

    let payload: any = []
    for (const [_, value] of Object.entries(answers)) {
      payload.push({
        question: (value as any).question,
        options: (value as any).answers,
      })
    }
    const res = await updateSchool({
      variables: {
        input: {
          attributes: {
            questions: payload,
          },
          schoolId: school._id,
        },
      },
    })

    if (!res.data) {
      message.error('エラーが発生しました')
      return
    }
    message.success('アンケートの送信が完了しました')
    setAnswers(payload)
  }

  let title = <div className="theme-nav-title">{graphItem.title}</div>

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card>
        <div style={{ minWidth: 900 }}>
          {school && school.attributes.schoolName === '横浜市立中和田小学校' ? (
            <>
              <Checkbox
                className="text-xl font-semibold m-8"
                checked={useMextQuestionnaire}
                onChange={(e) => {
                  setUseMextQuestionnaire(e.target.checked)
                  if (e.target.checked) {
                    setQuestions(null)
                    setAnswers({})
                  }
                }}
              >
                文部科学省指定の6項目でアンケートを行う。
              </Checkbox>
              {!useMextQuestionnaire && (
                <>
                  <Divider />
                  <Form
                    name="dynamic_questions"
                    {...formItemLayoutWithOutLabel}
                    onFinish={onQuestionsFinish}
                  >
                    <Form.List
                      name="questions"
                      rules={[
                        {
                          validator: async (_, names) => {
                            if (!names || names.length < 1) {
                              return Promise.reject(
                                new Error('質問を1つ以上追加してください。'),
                              )
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel)}
                              label={index === 0 ? '問' : ''}
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: false,
                                    message: '質問を入力してください。',
                                  },
                                ]}
                                noStyle
                              >
                                <Input
                                  placeholder="質問を入力してください。"
                                  style={{ width: '60%' }}
                                />
                              </Form.Item>
                              {fields.length > 0 ? (
                                <MinusCircleOutlined
                                  className="ml-4"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '60%' }}
                              icon={<PlusOutlined />}
                            >
                              質問を追加
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item>
                      <Button key="question" htmlType="submit">
                        確定
                      </Button>
                    </Form.Item>
                  </Form>
                  {questions?.map((question, index) => (
                    <>
                      <Divider />
                      <div className="text-lg font-semibold ml-4 my-8">
                        {question}
                      </div>
                      <Form
                        name={`dynamic_answers_${index}`}
                        {...formItemLayoutWithOutLabel}
                        onFinish={onAnswersFinish}
                      >
                        <Form.Item
                          className="hidden"
                          name="index"
                          initialValue={index}
                        />
                        <Form.Item
                          className="hidden"
                          name="question"
                          initialValue={question}
                        />
                        <Form.List
                          name="answers"
                          rules={[
                            {
                              validator: async (_, names) => {
                                if (!names || names.length < 1) {
                                  return Promise.reject(
                                    new Error(
                                      '答えを2つ以上追加してください。',
                                    ),
                                  )
                                }
                              },
                            },
                          ]}
                        >
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item
                                  {...(index === 0
                                    ? formItemLayout
                                    : formItemLayoutWithOutLabel)}
                                  label={index === 0 ? '答え' : ''}
                                  required={false}
                                  key={field.key}
                                >
                                  <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: false,
                                        message: '答えを入力してください。',
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Input
                                      placeholder="答えを入力してください。"
                                      style={{ width: '60%' }}
                                    />
                                  </Form.Item>
                                  {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                      className="ml-4"
                                      onClick={() => remove(field.name)}
                                    />
                                  ) : null}
                                </Form.Item>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  style={{ width: '60%' }}
                                  icon={<PlusOutlined />}
                                >
                                  答えを追加
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                        <Form.Item>
                          <Button htmlType="submit">確定</Button>
                        </Form.Item>
                      </Form>
                    </>
                  ))}
                  {answers && (
                    <>
                      <Button
                        type="primary"
                        size="large"
                        onClick={(e) => {
                          e.preventDefault()
                          onFinish()
                        }}
                        disabled={answers === null}
                      >
                        送信
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Checkbox className="text-xl font-semibold m-8" checked={true}>
                文部科学省指定の6項目でアンケートを行う。
              </Checkbox>
            </>
          )}
        </div>
      </Card>
    </Dashboard>
  )
}

export default QuestionnaireEditPage
