import { useState, createContext } from 'react'
import axios from 'axios'

type AuthValue = {
  currentUser: null
  isAuthChecked: boolean
  isTokenChecked: boolean
  isRegistered: boolean
  setAccessToken: (x: string) => void
}
const initialState: AuthValue = {
  currentUser: null,
  isAuthChecked: false,
  isTokenChecked: false,
  isRegistered: false,
  setAccessToken: (x: string) => {},
}
export const AuthContext = createContext<AuthValue>(initialState)

export const LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY = 'sign-in-email'
export const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'Authorization'

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [isAuthChecked, setIsAuthChecked] = useState(false)
  const [isTokenChecked, setIsTokenChecked] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)

  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  if (authToken) {
    axios.defaults.headers.common = {
      [LOCAL_STORAGE_AUTH_TOKEN_KEY]: authToken,
    }
  }

  const setAccessToken = (token: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, `Bearer ${token}`)
    axios.defaults.headers.common = {
      [LOCAL_STORAGE_AUTH_TOKEN_KEY]: `Bearer ${token}`,
    }
    if (token) {
      setCurrentUser(true)
      setIsAuthChecked(true)
      setIsTokenChecked(true)
      setIsRegistered(true)
    } else {
      setCurrentUser(false)
      setIsAuthChecked(false)
      setIsTokenChecked(false)
      setIsRegistered(false)
    }
  }

  const value: AuthValue = {
    currentUser,
    isAuthChecked,
    isTokenChecked,
    isRegistered,
    setAccessToken,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
