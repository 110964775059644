import { useState } from 'react'
import { Table, Button } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import _ from 'lodash'

export const KobeSchoolYearInfoPage = ({ info }) => {
  const tableColumns: ColumnType<any>[] = [
    {
      title: '出席番号',
      dataIndex: 'attendanceNumber',
      key: 'attendanceNumber',
      align: 'center',
    },
    {
      title: 'ID',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
  ]

  const data = info.code.students.map((info, idx) => {
    return {
      key: idx,
      attendanceNumber: info.attendanceNumber,
      username: info.username,
    }
  })

  const modalRef = useRef<HTMLDivElement>()
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
  })

  const printPage = (students, printing = false) => {
    return (
      <div>
        <div className="flex justify-center text-base font-black">
          {info.code.schoolGrade}年{info.code.schoolClass}組
        </div>
        <span>登録人数 {info.code.students.length}</span>
        <Table
          columns={tableColumns}
          dataSource={data}
          style={{ width: 850 }}
          size="small"
          rowClassName="font-bold text-black"
          bordered={true}
        />
      </div>
    )
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)

  return (
    <div>
      <div className="flex justify-center text-base font-black">
        {info.code.schoolGrade}年{info.code.schoolClass}組
      </div>
      <span>登録人数 {info.code.students.length}</span>
      <Table
        columns={tableColumns}
        dataSource={data}
        style={{ width: 850 }}
        size="small"
        rowClassName="font-bold text-black"
        bordered={true}
        pagination={{
          pageSize: pageSize,
          defaultPageSize: pageSize,
          position: ['bottomCenter'],
          showSizeChanger: true,
          total: data?.length,
          current: currentPage,
          onChange: (page: number, size: number) => {
            setCurrentPage(pageSize !== size ? 1 : page)
            setPageSize(size)
          },
        }}
      />
      <div className="w-full flex justify-center">
        <Button className="w-60 rounded" type="primary" onClick={handlePrint}>
          印刷
        </Button>
      </div>
      <div ref={modalRef} className="print-container m-0 p-0">
        {_.chunk(info.code.students || [], 9).map((chunk) => (
          <>
            {printPage(chunk, true)}
            <div className="page-break" />
          </>
        ))}
      </div>
    </div>
  )
}
