import { Card as ACard, Col, List, Row, Space } from 'antd'
import {
  ageFromSchool,
  getShowRanking,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '~/utils'
import ScoreRadarChart, { ScoreData } from '../graph/ScoreRadarChart'

let StarFill = () => {
  return (
    <svg className="w-2 h-2" fill="currentColor" viewBox="0 0 20 20">
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  )
}

let StarOutline = () => {
  return (
    <svg
      className="w-2 h-2"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
      />
    </svg>
  )
}

const tests = {
  grip: { title: '握力', suffix: 'kg' },
  sitUps: { title: '上体起こし', suffix: '回' },
  bending: { title: '長座体前屈', suffix: 'cm' },
  shuttleRun: { title: '20mシャトルラン', suffix: '回' },
  sprintRun: { title: '50m走', suffix: '秒' },
  sideJump: { title: '反復横跳び', suffix: '回' },
  standingJump: { title: '立ち幅跳び', suffix: 'cm' },
  handballThrow: { title: 'ボール投げ', suffix: 'm' },
  enduranceRun: { title: '持久走', suffix: '' },
}

const Statistic = ({
  keyName,
  title,
  value,
  suffix,
  points,
  summary = false,
}) => {
  return (
    <>
      <Row className="text-xs font-bold">{title}</Row>
      <Row className="ant-row gap-4">
        <div
          className={`w-30 h-8 border-none rounded-5px relative ${
            summary ? 'bg-warn text-xl font-black' : 'bg-gray-150'
          }`}
        >
          {keyName !== 'enduranceRun' ? (
            <>
              <div
                className={`h-full flex items-center justify-center ${
                  !summary && 'text-black'
                }`}
              >
                {value ?? '-'}
              </div>
              <div className="text-10px font-black text-10px font-black absolute right-1 bottom-0.5">
                {suffix}
              </div>
            </>
          ) : (
            <div className="h-full flex">
              <div className="w-1/2 h-full flex items-center justify-center relative">
                <div className={`${!summary && 'text-black'}`}>{value[0]}</div>
                <div className="text-10px font-black text-10px font-black absolute right-1 bottom-0.5">
                  分
                </div>
              </div>
              <div className="w-1/2 h-full flex items-center justify-center relative">
                <div className={`${!summary && 'text-black'}`}>{value[1]}</div>
                <div className="text-10px font-black text-10px font-black absolute right-1 bottom-0.5">
                  秒
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`w-30 h-8 rounded-5px ${
            summary ? 'bg-warn text-xl font-black' : 'border border-primary'
          }`}
        >
          <div
            className={`h-full flex items-center justify-center ${
              !summary && 'text-black'
            }`}
          >
            {points}
          </div>
        </div>
      </Row>
    </>
  )
}

type Props = {
  school: any
  testYear: number
  student: any
  isEnduranceRunInputActive: boolean
  forPrinting?: boolean
}

const StudentTestResult = ({
  school,
  testYear,
  student,
  isEnduranceRunInputActive,
  forPrinting,
}: Props) => {
  let prevTestResult =
    student?.attributes?.testResults &&
    student.attributes.testResults.find((d) => d.testYear === testYear - 1)
  let testResult =
    student?.attributes?.testResults &&
    student.attributes.testResults.find((d) => d.testYear === testYear)
  let age =
    student &&
    ageFromSchool(
      school.attributes.schoolCategoryCode,
      student.attributes.schoolGrade,
    )
  let gender = student?.attributes?.gender

  if (testResult && gender) {
    testResult = reCalcTestResult(testResult, age, gender)
  }

  const scoreData: ScoreData[] = isEnduranceRunInputActive
    ? [
        {
          subject: '握力',
          personal: testResult?.grip?.points,
          prevPersonal: prevTestResult?.grip?.points,
        },
        {
          subject: '上体起こし',
          personal: testResult?.sitUps?.points,
          prevPersonal: prevTestResult?.sitUps?.points,
        },
        {
          subject: '長座体前屈',
          personal: testResult?.bending?.points,
          prevPersonal: prevTestResult?.bending?.points,
        },
        {
          subject: '持久走',
          personal: testResult?.enduranceRun?.points,
          prevPersonal: prevTestResult?.enduranceRun?.points,
        },
        {
          subject: '50m走',
          personal: testResult?.sprintRun?.points,
          prevPersonal: prevTestResult?.sprintRun?.points,
        },
        {
          subject: '反復横とび',
          personal: testResult?.sideJump?.points,
          prevPersonal: prevTestResult?.sideJump?.points,
        },
        {
          subject: '立ち幅とび',
          personal: testResult?.standingJump?.points,
          prevPersonal: prevTestResult?.standingJump?.points,
        },
        {
          subject: 'ボール投げ',
          personal: testResult?.handballThrow?.points,
          prevPersonal: prevTestResult?.handballThrow?.points,
        },
      ]
    : [
        {
          subject: '握力',
          personal: testResult?.grip?.points,
          prevPersonal: prevTestResult?.grip?.points,
        },
        {
          subject: '上体起こし',
          personal: testResult?.sitUps?.points,
          prevPersonal: prevTestResult?.sitUps?.points,
        },
        {
          subject: '長座体前屈',
          personal: testResult?.bending?.points,
          prevPersonal: prevTestResult?.bending?.points,
        },
        {
          subject: '20mシャトルラン',
          personal: testResult?.shuttleRun?.points,
          prevPersonal: prevTestResult?.shuttleRun?.points,
        },
        {
          subject: '50m走',
          personal: testResult?.sprintRun?.points,
          prevPersonal: prevTestResult?.sprintRun?.points,
        },
        {
          subject: '反復横とび',
          personal: testResult?.sideJump?.points,
          prevPersonal: prevTestResult?.sideJump?.points,
        },
        {
          subject: '立ち幅とび',
          personal: testResult?.standingJump?.points,
          prevPersonal: prevTestResult?.standingJump?.points,
        },
        {
          subject: 'ボール投げ',
          personal: testResult?.handballThrow?.points,
          prevPersonal: prevTestResult?.handballThrow?.points,
        },
      ]

  const commentsTable2 = () => (
    <div className="mt-4 w-full">
      <div className="w-full text-center font-black">
        体力要素と評価 (5段階)
      </div>
      <div className="border border-primary rounded-5px shadow-lg">
        {Object.keys(currentTests).map((key) => {
          // Chỗ này cần điền thông tin test kiểu gì nhỉ?
          // TODO: @LongPT, chỗ này e thấy hiện tại đang fill OK rồi nhé, a lấy logic chỗ này đưa sang bên student.
          let _testResult: any = testResult
          let result = _testResult && _testResult[key]

          return result ? (
            <tr className="border border-white">
              <td className="p-2 pt-1">
                <span className="text-xxs text-black whitespace-nowrap">
                  {tests[key].title}
                </span>
                <br />
                <div className="flex">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <div
                        key={`grip-rating-fill-${i}`}
                        className="text-yellow-500"
                      >
                        {i < result.rating ? <StarFill /> : <StarOutline />}
                      </div>
                    ))}
                </div>
              </td>
              <td className="p-2 pt-1 text-tiny text-black">
                {result.comment}
              </td>
            </tr>
          ) : null
        })}
      </div>
    </div>
  )

  const currentTests = isEnduranceRunInputActive
    ? {
        grip: { title: '握力', suffix: 'kg' },
        sitUps: { title: '上体起こし', suffix: '回' },
        bending: { title: '長座体前屈', suffix: 'cm' },
        enduranceRun: { title: '持久走', suffix: '' },
        sprintRun: { title: '50m走', suffix: '秒' },
        sideJump: { title: '反復横跳び', suffix: '点' },
        standingJump: { title: '立ち幅跳び', suffix: 'cm' },
        handballThrow: { title: 'ボール投げ', suffix: 'm' },
      }
    : {
        grip: { title: '握力', suffix: 'kg' },
        sitUps: { title: '上体起こし', suffix: '回' },
        bending: { title: '長座体前屈', suffix: 'cm' },
        sideJump: { title: '反復横跳び', suffix: '点' },
        shuttleRun: { title: '20mシャトルラン', suffix: '回' },
        sprintRun: { title: '50m走', suffix: '秒' },
        standingJump: { title: '立ち幅跳び', suffix: 'cm' },
        handballThrow: { title: 'ボール投げ', suffix: 'm' },
      }

  const header = [
    {
      title: 'ID',
      content: student?.username,
    },
    {
      title: '性別',
      content: student?.attributes.gender
        ? student.attributes.gender === 'MALE'
          ? '男子'
          : '女子'
        : '-',
    },
    {
      title: '学年',
      content: student?.attributes.schoolGrade ?? '-',
    },
    {
      title: '組',
      content: student?.attributes.schoolClass ?? '-',
    },
    {
      title: '番号',
      content: student?.attributes.schoolAttendanceNumber ?? '-',
    },
    {
      title: '身長',
      content:
        testResult?.sizeTest?.height != null
          ? `${testResult?.sizeTest?.height.toFixed(1)}`
          : '-',
    },
    {
      title: '体重',
      content:
        testResult?.sizeTest?.weight != null
          ? `${testResult?.sizeTest?.weight.toFixed(1)}`
          : '-',
    },
  ]
  const isEnduranceRun = schoolHasEnduranceTest(school.attributes)
  const showRanking = getShowRanking(testResult, isEnduranceRun)

  return (
    <div className="p-4" style={{ pageBreakAfter: 'always' }}>
      <Row>
        {header.map((item, index) => (
          <Col {...(index > 0 ? { flex: 'auto' } : {})}>
            <ACard
              title={item.title}
              size="small"
              headStyle={{
                fontWeight: 900,
                borderBottom: '3px solid #036EB8',
              }}
              bodyStyle={{
                color: '#000',
                ...(forPrinting ? { whiteSpace: 'nowrap' } : {}),
              }}
              bordered={false}
            >
              {item.content}
            </ACard>
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={11}>
          <Row className="ant-row gap-4">
            <div className="w-30 text-center font-black border-b-2 border-primary h-6">
              種目
            </div>
            <div className="w-30 text-center font-black border-b-2 border-primary h-6">
              得点
            </div>
          </Row>
          {Object.keys(currentTests).map((key) => {
            let _testResult: any = testResult
            let result = _testResult && _testResult[key]
            let points = result?.points || '-'
            let resultValue = result?.value || '-'
            if (
              key === 'sitUps' &&
              result?.value !== null &&
              result?.value !== undefined &&
              result?.points === 0
            ) {
              points = 1
            }
            if (key === 'sitUps') resultValue = result?.value ?? '-'
            if (key === 'enduranceRun') {
              resultValue = [result?.runningTime, result?.runningTimeSeconds]
            }
            const test = tests[key]
            const { title, suffix } = test
            return (
              <Statistic
                keyName={key}
                title={title}
                value={resultValue}
                suffix={suffix}
                points={points}
              />
            )
          })}
          <Row className="ant-row gap-4 mt-4 mb-2">
            <div className="w-30 text-center font-black border-b-2 border-primary h-6">
              総合得点
            </div>
            <div className="w-30 text-center font-black border-b-2 border-primary h-6">
              総合評価
            </div>
          </Row>
          <Statistic
            keyName=""
            title=""
            value={testResult?.points ?? '-'}
            suffix=""
            points={showRanking ? testResult?.rank ?? '-' : '-'}
            summary={true}
          />
        </Col>
        <Col span={13}>
          <Row>
            <Col style={{ marginBottom: 10, minWidth: '100%' }}>
              <ScoreRadarChart
                title=""
                data={scoreData}
                forPrinting={!!forPrinting}
                border={false}
                padding={1}
                outerRadius={90}
                size={{
                  height: 280,
                  width: '100%',
                }}
              />
            </Col>
          </Row>
          {!forPrinting && <Row>{commentsTable2()}</Row>}
        </Col>
      </Row>
      {forPrinting && (
        <Row>
          <Col span={24}>
            <Row>{commentsTable2()}</Row>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default StudentTestResult
