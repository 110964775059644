import React, { useEffect, useState } from 'react'

import { Dashboard } from '../layout/Dashboard'
import { useHistory } from 'react-router-dom'
import { DashboardNavGraphItem } from '../navigation'
import { Button, Col, Row, Select, Table } from 'antd'
import { useSchoolGradesApiQuery } from '~/utils/hooks'
import { useAlphaStore } from '~/context'
import { fetchSchoolClasses } from '@alpha/core'
import { ColumnType } from 'antd/es/table'
import { getExerciseDiary, getExerciseDiaryFilter } from '../admin/api'
import { getGenderSchool, pageSize } from '~/utils/constants'
import { useSchoolMeApiQuery } from '~/utils/hooks'

const { Option } = Select

const ExerciseDiaryPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  // useEffect(() => {
  //     alert('未実装機能です')
  //     history.push('/home')
  // }, [])

  const { school } = useAlphaStore()
  const { data: teacherData } = useSchoolMeApiQuery()
  const testYear = teacherData?.testYear
  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade
  const teacherSchoolClass = teacherData?.schoolClass
  const [pageSizeList, setPageSizeList] = useState<number>(50)
  const [currentList, setCurrentList] = useState<number>(1)
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)

  const [schoolGrade, setSchoolGrade] = useState<any>(0)
  const [schoolClasses, setSchoolClasses] = useState([
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1,
  ])
  const [schoolClass, setSchoolClass] = useState<any>(
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0,
  )
  const [filterGender, setFilterGender] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad && teacherRole) {
      setFirstLoad(false)
      setSchoolGrade(
        teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
          ? teacherSchoolGrade
          : 0,
      )
      setSchoolClasses([teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1])
      setSchoolClass(teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0)
    }
  }, [firstLoad, teacherRole])

  const [newData, setData] = useState([])

  const sortTestResults = (a, b, key) => {
    let element1
    let element2

    switch (key) {
      case 'name': {
        element1 = `${a?.familyName ?? ''}${a?.givenName ?? ''}`
        element2 = `${b?.familyName ?? ''}${b?.givenName ?? ''}`
        break
      }
      default: {
        element1 = a?.[key]
        element2 = b?.[key]
      }
    }

    if (element1 == null && element2 == null) return 1
    if (element1 == null) return -1
    if (element2 == null) return 1
    return typeof element1 === 'number'
      ? element1 - element2
      : element1.localeCompare(element2)
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'schoolGrade',
      key: 'schoolGrade',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '組',
      dataIndex: 'schoolClass',
      key: 'schoolClass',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'ID',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'username')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '性別',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'gender')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '今週の運動ポイント',
      dataIndex: 'activityPointWeek',
      key: 'activityPointWeek',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'activityPointWeek')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '合計の運動ポイント',
      dataIndex: 'totalPoints',
      key: 'totalPoints',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'totalPoints')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '2022年度ポイント',
      dataIndex: 'activityPoints2022',
      key: 'activityPoints2022',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'activityPoints2022')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '2023年度ポイント',
      dataIndex: 'activityPoints2023',
      key: 'activityPoints2023',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'activityPoints2023')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '2024年度ポイント',
      dataIndex: 'activityPoints2024',
      key: 'activityPoints2024',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'activityPoints2024')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '連続ログイン回数',
      dataIndex: 'loginContinousCount',
      key: 'loginContinousCount',
      align: 'center',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'loginContinousCount')
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const getData = async () => {
    setLoading(true)
    const {
      data: { data, total },
    } = await getExerciseDiary(currentList, pageSizeList)
    if (data) {
      setData(data)
      setTotal(total)
      // if ( teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN') {
      //     setData(data)
      // } else {
      //     const newDataClass = data.filter(inf => inf.schoolGrade === schoolGrade && inf.schoolClass === schoolClass)
      //     setData(newDataClass)
      // }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (teacherData) {
      getData()
    }
  }, [teacherData])

  const onChangeSchoolGrade = async (schoolGrade) => {
    setSchoolGrade(schoolGrade)
  }

  const onChangeSchoolClass = async (schoolClass) => {
    setSchoolClass(schoolClass)
  }

  const onChangeGender = async (gender) => {
    setFilterGender(gender)
  }

  const parseData = newData.map((data) => {
    return {
      ...data,
      gender: getGenderSchool(data.gender),
    }
  })

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, testYear).then((res) => {
        const schoolClasses = res['schoolClasses']
        if (schoolClasses.length) {
          schoolClasses.sort()
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [testYear, school])

  const schoolCategoryCode = teacherData?.school?.attributes.schoolCategoryCode
  const grades =
    schoolCategoryCode === 'B1' ? [0, 1, 2, 3, 4, 5, 6] : [0, 1, 2, 3]

  useEffect(() => {
    new Promise(async () => {
      setLoading(true)
      if (!schoolClass && !schoolGrade && !filterGender) {
        const {
          data: { data, total },
        } = await getExerciseDiary(currentList, pageSizeList)
        if (data) {
          setData(data)
          setTotal(total)
        }
      } else {
        const {
          data: { data, total },
        } = await getExerciseDiaryFilter(
          schoolClass,
          schoolGrade,
          filterGender,
          currentList,
          pageSizeList,
        )
        if (data) {
          setData(data)
        }
        setCurrentList(1)
        setTotal(total)
      }
      setLoading(false)
    })
  }, [currentList, filterGender, pageSizeList, schoolClass, schoolGrade])

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="flex justify-center mt-5">
        <div>
          <Row className="w-full space-x-2 mb-5">
            <Col>
              <Select
                value={schoolGrade}
                onChange={onChangeSchoolGrade}
                className="w-30 rounded-5px"
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                {grades.map((value, idx) => (
                  <Option key={`grade-${idx}`} value={value}>
                    {value === 0 ? '全学年' : `${value}年生`}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={schoolClass}
                onChange={onChangeSchoolClass}
                className="w-30 rounded-5px"
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                {teacherRole !== 'CLASS_ADMIN' && (
                  <Option key={`class-${0}`} value={0}>
                    全組
                  </Option>
                )}
                <>
                  {teacherRole !== 'CLASS_ADMIN' ? (
                    schoolClasses
                      .sort((a, b) => a - b)
                      .map((_class) => (
                        <Option key={`class-${_class}`} value={_class}>
                          {_class}組
                        </Option>
                      ))
                  ) : (
                    <Option value={teacherSchoolClass} key={teacherSchoolClass}>
                      {`${teacherSchoolClass}組`}
                    </Option>
                  )}
                </>
              </Select>
            </Col>
            <Col>
              <Select
                className="w-30 rounded-5px"
                value={filterGender}
                onChange={onChangeGender}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                <Option key="gender-0" value="">
                  全性別
                </Option>
                <Option key="gender-male" value="MALE">
                  男性
                </Option>
                <Option key="gender-female" value="FEMALE">
                  女性
                </Option>
              </Select>
            </Col>
          </Row>
          <Table
            columns={tableColumns}
            dataSource={parseData}
            style={{ minWidth: '70vw' }}
            size="small"
            rowKey="_id"
            loading={loading}
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: pageSizeList,
              pageSize: pageSizeList,
              position: ['bottomCenter'],
              current: currentList,
              total,
              onChange(current, size) {
                setCurrentList(pageSizeList !== size ? 1 : current)
                setPageSizeList(size)
              },
            }}
          />
        </div>
      </div>
    </Dashboard>
  )
}

export default ExerciseDiaryPage
