import { DashboardNavGraphItem } from '../navigation'
import { useEffect, useState } from 'react'
import { Dashboard } from '../layout/Dashboard'
import { Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useSchoolMeApiQuery } from '~/utils/hooks'

const elementaryTest = [
  {
    score: '1',
    grip: '4kg以下',
    sitUps: '2回以下',
    bending: '14cm以下',
    sideJump: '17点以下',
    enduranceRun: '9’21”以上',
    shuttleRun: '7回以下',
    sprintRun: '13.1秒以上',
    standingJump: '92cm以下',
    handballThrow: '4m以下',
  },
  {
    score: '2',
    grip: '5~6',
    sitUps: '3~5',
    bending: '15~18',
    sideJump: '18~21',
    enduranceRun: '8’20”~9’20”',
    shuttleRun: '8~9',
    sprintRun: '12.3~13.0',
    standingJump: '93~104',
    handballThrow: '5~6',
  },
  {
    score: '3',
    grip: '7~8',
    sitUps: '6~8',
    bending: '19~22',
    sideJump: '22~25',
    enduranceRun: '7’31”~8’19”',
    shuttleRun: '10~14',
    sprintRun: '11.5~12.2',
    standingJump: '105~116',
    handballThrow: '7~9',
  },
  {
    score: '4',
    grip: '9~10',
    sitUps: '9~11',
    bending: '23~26',
    sideJump: '26~29',
    enduranceRun: '6’51”~7’30”',
    shuttleRun: '15~22',
    sprintRun: '10.711.4',
    standingJump: '117~129',
    handballThrow: '10~12',
  },
  {
    score: '5',
    grip: '11~13',
    sitUps: '12~14',
    bending: '27~29',
    sideJump: '30~33',
    enduranceRun: '6’23”~6’50”',
    shuttleRun: '23~32',
    sprintRun: '10.0~10.6',
    standingJump: '130~142',
    handballThrow: '13~17',
  },
  {
    score: '6',
    grip: '14~16',
    sitUps: '15~17',
    bending: '30~33',
    sideJump: '34~37',
    enduranceRun: '5’56”~6’22”',
    shuttleRun: '33~44',
    sprintRun: '9.4~9.9',
    standingJump: '143~155',
    handballThrow: '18~23',
  },
  {
    score: '7',
    grip: '17~19',
    sitUps: '18~19',
    bending: '34~37',
    sideJump: '38~41',
    enduranceRun: '5’34”~5’55”',
    shuttleRun: '45~56',
    sprintRun: '8.9~9.3',
    standingJump: '156~167',
    handballThrow: '24~29',
  },
  {
    score: '8',
    grip: '20~22',
    sitUps: '20~22',
    bending: '38~42',
    sideJump: '42~45',
    enduranceRun: '5’17”~5’33”',
    shuttleRun: '57~68',
    sprintRun: '8.5~8.8',
    standingJump: '168~179',
    handballThrow: '30~34',
  },
  {
    score: '9',
    grip: '23~25',
    sitUps: '23~25',
    bending: '43~48',
    sideJump: '46~49',
    enduranceRun: '5’00”~5’16”',
    shuttleRun: '69~79',
    sprintRun: '8.1~8.4',
    standingJump: '180~191',
    handballThrow: '35~39',
  },
  {
    score: '10',
    grip: '26kg以上',
    sitUps: '26回以上',
    bending: '49cm以上',
    sideJump: '50点以上',
    enduranceRun: '4’59”以下',
    shuttleRun: '80回以上',
    sprintRun: '8.0秒以下',
    standingJump: '192cm以上',
    handballThrow: '40m以上',
  },
  {
    score: '1',
    grip: '3kg以下',
    sitUps: '2回以下',
    bending: '17cm以下',
    sideJump: '16点以下',
    enduranceRun: '6’58”以上',
    shuttleRun: '7回以下',
    sprintRun: '13.3秒以上',
    standingJump: '84cm以下',
    handballThrow: '3m以下',
  },
  {
    score: '2',
    grip: '4~6',
    sitUps: '3~5',
    bending: '18~20',
    sideJump: '17~20',
    enduranceRun: '6’15”~6’57”',
    shuttleRun: '8~9',
    sprintRun: '12.5~13.2',
    standingJump: '85~97',
    handballThrow: '4',
  },
  {
    score: '3',
    grip: '7~8',
    sitUps: '6~8',
    bending: '21~24',
    sideJump: '21~24',
    enduranceRun: '5’43”~6’14”',
    shuttleRun: '10~13',
    sprintRun: '11.7~12.4',
    standingJump: '98~108',
    handballThrow: '5',
  },
  {
    score: '4',
    grip: '9~10',
    sitUps: '9~11',
    bending: '25~28',
    sideJump: '25~27',
    enduranceRun: '5’19”~5’42”',
    shuttleRun: '14~18',
    sprintRun: '11.0~11.6',
    standingJump: '109~120',
    handballThrow: '6~7',
  },
  {
    score: '5',
    grip: '11~12',
    sitUps: '12~13',
    bending: '29~32',
    sideJump: '28~31',
    enduranceRun: '4’57”~5’18”',
    shuttleRun: '19~25',
    sprintRun: '10.3~10.9',
    standingJump: '121~133',
    handballThrow: '8~10',
  },
  {
    score: '6',
    grip: '13~15',
    sitUps: '14~15',
    bending: '33~36',
    sideJump: '32~35',
    enduranceRun: '4’38”~4’56”',
    shuttleRun: '26~34',
    sprintRun: '9.7~10.2',
    standingJump: '134~146',
    handballThrow: '11~13',
  },
  {
    score: '7',
    grip: '16~18',
    sitUps: '16~17',
    bending: '37~40',
    sideJump: '36~39',
    enduranceRun: '4’20”~4’37”',
    shuttleRun: '35~43',
    sprintRun: '9.2~9.6',
    standingJump: '147~159',
    handballThrow: '14~16',
  },
  {
    score: '8',
    grip: '19~21',
    sitUps: '18~19',
    bending: '41~45',
    sideJump: '40~42',
    enduranceRun: '4’03”~4’19”',
    shuttleRun: '44~53',
    sprintRun: '8.8~9.1',
    standingJump: '160~169',
    handballThrow: '17~20',
  },
  {
    score: '9',
    grip: '22~24',
    sitUps: '20~22',
    bending: '46~51',
    sideJump: '43~46',
    enduranceRun: '3’50”~4’02”',
    shuttleRun: '54~63',
    sprintRun: '8.4~8.7',
    standingJump: '170~180',
    handballThrow: '21~24',
  },
  {
    score: '10',
    grip: '25kg以上',
    sitUps: '23回以上',
    bending: '52cm以上',
    sideJump: '47回以上',
    enduranceRun: '3’49”以下',
    shuttleRun: '64回以上',
    sprintRun: '8.3秒以下',
    standingJump: '181cm以上',
    handballThrow: '25m以上',
  },
]

const elementaryStandard = [
  {
    level: 'A',
    '6': '39以上',
    '7': '47以上',
    '8': '53以上',
    '9': '59回以上',
    '10': '65以上',
    '11': '71以上',
  },
  {
    level: 'B',
    '6': '33~38',
    '7': '41~46',
    '8': '46~52',
    '9': '52~58',
    '10': '58~64',
    '11': '63~70',
  },
  {
    level: 'C',
    '6': '27~32',
    '7': '34~40',
    '8': '39~45',
    '9': '45~51',
    '10': '50~57',
    '11': '55~62',
  },
  {
    level: 'D',
    '6': '22~26',
    '7': '27~33',
    '8': '32~38',
    '9': '38~44',
    '10': '42~49',
    '11': '46~54',
  },
  {
    level: 'E',
    '6': '21以下',
    '7': '26以下',
    '8': '31以下',
    '9': '37以下',
    '10': '41以下',
    '11': '45以下',
  },
]

const secondaryTest = [
  {
    score: '1',
    grip: '17kg以下',
    sitUps: '12回以下',
    bending: '20cm以下',
    sideJump: '29回以下',
    enduranceRun: '9’21”以上',
    shuttleRun: '25回以下',
    sprintRun: '9.8秒以上',
    standingJump: '149cm以下',
    handballThrow: '12m以下',
  },
  {
    score: '2',
    grip: '18~22',
    sitUps: '13~15',
    bending: '21~27',
    sideJump: '30~36',
    enduranceRun: '8’20”~9’20”',
    shuttleRun: '26~36',
    sprintRun: '9.1~9.7',
    standingJump: '150~169',
    handballThrow: '13~15',
  },
  {
    score: '3',
    grip: '23~27',
    sitUps: '16~18',
    bending: '28~32',
    sideJump: '37~40',
    enduranceRun: '7’31”~8’19”',
    shuttleRun: '37~50',
    sprintRun: '8.5~9.0',
    standingJump: '170~187',
    handballThrow: '16~18',
  },
  {
    score: '4',
    grip: '28~32',
    sitUps: '19~21',
    bending: '33~38',
    sideJump: '41~44',
    enduranceRun: '6’51”~7’30”',
    shuttleRun: '51~62',
    sprintRun: '8.0~8.4',
    standingJump: '188~202',
    handballThrow: '19~21',
  },
  {
    score: '5',
    grip: '33~37',
    sitUps: '22~24',
    bending: '39~43',
    sideJump: '45~48',
    enduranceRun: '6’23”~6’50”',
    shuttleRun: '63~75',
    sprintRun: '7.6~7.9',
    standingJump: '203~217',
    handballThrow: '22~24',
  },
  {
    score: '6',
    grip: '38~42',
    sitUps: '25~26',
    bending: '44~48',
    sideJump: '49~52',
    enduranceRun: '5’56”~6’22”',
    shuttleRun: '76~89',
    sprintRun: '7.3~7.5',
    standingJump: '218~229',
    handballThrow: '25~24',
  },
  {
    score: '7',
    grip: '43~46',
    sitUps: '27~29',
    bending: '49~52',
    sideJump: '53~55',
    enduranceRun: '5’34”~5’55”',
    shuttleRun: '90~101',
    sprintRun: '7.1~7.2',
    standingJump: '230~241',
    handballThrow: '28~30',
  },
  {
    score: '8',
    grip: '47~50',
    sitUps: '30~32',
    bending: '53~57',
    sideJump: '56~59',
    enduranceRun: '5’17”~5’33”',
    shuttleRun: '102~112',
    sprintRun: '6.9~7.0',
    standingJump: '242~253',
    handballThrow: '31~33',
  },
  {
    score: '9',
    grip: '51~55',
    sitUps: '33~34',
    bending: '58~63',
    sideJump: '60~62',
    enduranceRun: '5’00”~5’16”',
    shuttleRun: '113~124',
    sprintRun: '6.7~6.8',
    standingJump: '254~264',
    handballThrow: '34~36',
  },
  {
    score: '10',
    grip: '56kg以上',
    sitUps: '35回以上',
    bending: '64cm以上',
    sideJump: '63回以上',
    enduranceRun: '4’59”以下',
    shuttleRun: '125回以上',
    sprintRun: '6.6秒以下',
    standingJump: '265cm以上',
    handballThrow: '37m以上',
  },
  {
    score: '1',
    grip: '13kg以下',
    sitUps: '7回以下',
    bending: '22cm以下',
    sideJump: '26点以下',
    enduranceRun: '6’58”以上',
    shuttleRun: '14回以下',
    sprintRun: '11.3秒以上',
    standingJump: '117cm以下',
    handballThrow: '7m以下',
  },
  {
    score: '2',
    grip: '14~16',
    sitUps: '8~10',
    bending: '23~29',
    sideJump: '27~31',
    enduranceRun: '6’15”~6’57”',
    shuttleRun: '15~20',
    sprintRun: '10.4~11.2',
    standingJump: '118~131',
    handballThrow: '8~9',
  },
  {
    score: '3',
    grip: '17~19',
    sitUps: '11~12',
    bending: '30~34',
    sideJump: '32~35',
    enduranceRun: '5’43”~6’14”',
    shuttleRun: '21~26',
    sprintRun: '9.9~10.3',
    standingJump: '132~144',
    handballThrow: '10',
  },
  {
    score: '4',
    grip: '20~22',
    sitUps: '13~14',
    bending: '35~39',
    sideJump: '36~38',
    enduranceRun: '5’19”~5’42”',
    shuttleRun: '27~34',
    sprintRun: '9.4~9.8',
    standingJump: '145~156',
    handballThrow: '11',
  },
  {
    score: '5',
    grip: '23~24',
    sitUps: '15~17',
    bending: '40~44',
    sideJump: '39~41',
    enduranceRun: '4’57”~5’18”',
    shuttleRun: '35~43',
    sprintRun: '9.0~9.3',
    standingJump: '157~167',
    handballThrow: '12~13',
  },
  {
    score: '6',
    grip: '25~27',
    sitUps: '18~19',
    bending: '45~49',
    sideJump: '42~44',
    enduranceRun: '4’38”~4’56”',
    shuttleRun: '44~53',
    sprintRun: '8.7~8.9',
    standingJump: '168~178',
    handballThrow: '14~15',
  },
  {
    score: '7',
    grip: '28~29',
    sitUps: '20~22',
    bending: '50~53',
    sideJump: '45~47',
    enduranceRun: '4’20”~4’37”',
    shuttleRun: '54~63',
    sprintRun: '8.4~8.6',
    standingJump: '179~189',
    handballThrow: '16~17',
  },
  {
    score: '8',
    grip: '30~32',
    sitUps: '23~25',
    bending: '54~57',
    sideJump: '48~49',
    enduranceRun: '4’03”~4’19”',
    shuttleRun: '64~75',
    sprintRun: '8.1~8.3',
    standingJump: '190~199',
    handballThrow: '18~19',
  },
  {
    score: '9',
    grip: '33~35',
    sitUps: '26~28',
    bending: '58~62',
    sideJump: '50~52',
    enduranceRun: '3’50”~4’02”',
    shuttleRun: '76~87',
    sprintRun: '7.8~8.0',
    standingJump: '200~209',
    handballThrow: '20~22',
  },
  {
    score: '10',
    grip: '36kg以上',
    sitUps: '29回以上',
    bending: '63cm以上',
    sideJump: '53回以上',
    enduranceRun: '3’49”以下',
    shuttleRun: '88回以上',
    sprintRun: '7.7秒以下',
    standingJump: '210cm以上',
    handballThrow: '23m以上',
  },
]

const secondaryStandard = [
  {
    level: 'A',
    '12': '51以上',
    '13': '57以上',
    '14': '60以上',
    '15': '61以上',
    '16': '63以上',
    '17': '65以上',
    '18': '65以上',
    '19': '65以上',
  },
  {
    level: 'B',
    '12': '41~50',
    '13': '47~56',
    '14': '51~59',
    '15': '52~60',
    '16': '53~62',
    '17': '54~64',
    '18': '54~64',
    '19': '54~64',
  },
  {
    level: 'C',
    '12': '32~40',
    '13': '37~46',
    '14': '41~50',
    '15': '41~51',
    '16': '42~52',
    '17': '43~53',
    '18': '43~53',
    '19': '43~53',
  },
  {
    level: 'D',
    '12': '22~31',
    '13': '27~36',
    '14': '31~40',
    '15': '31~40',
    '16': '31~41',
    '17': '31~42',
    '18': '31~42',
    '19': '31~42',
  },
  {
    level: 'E',
    '12': '21以下',
    '13': '26以下',
    '14': '30以下',
    '15': '30以下',
    '16': '30以下',
    '17': '30以下',
    '18': '30以下',
    '19': '30以下',
  },
]

const StandardTablePage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const [school, setSchool] = useState<any>(null)
  const [isElementary, setIsElementary] = useState(false)
  const [testData, setTestData] = useState({
    male: [],
    female: [],
    standard: [],
  })

  const { data: meData } = useSchoolMeApiQuery()

  useEffect(() => {
    if (!meData?.school) {
      return
    }
    const school = meData?.school
    setSchool(school)
  }, [meData])

  useEffect(() => {
    if (school) {
      const isElementary = school?.attributes?.schoolCategoryCode === 'B1'

      if (isElementary) {
        setTestData({
          male: elementaryTest.slice(0, 10).reverse(),
          female: elementaryTest.slice(10).reverse(),
          standard: elementaryStandard,
        })
      } else {
        setTestData({
          male: secondaryTest.slice(0, 10).reverse(),
          female: secondaryTest.slice(10).reverse(),
          standard: secondaryStandard,
        })
      }
      setIsElementary(isElementary)
    }
  }, [school])

  const testMaleColumns: ColumnType<any>[] = [
    {
      title: '得点',
      dataIndex: 'score',
      key: 'score',
      className: 'w-10 text-center-f font-black ant-table-male-test',
    },
    {
      title: '握力',
      dataIndex: 'grip',
      key: 'grip',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: '上体起こし',
      dataIndex: 'sitUps',
      key: 'sitUps',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: '長座体前屈',
      dataIndex: 'bending',
      key: 'bending',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: '反復横跳び',
      dataIndex: 'sideJump',
      key: 'sideJump',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: '持久走',
      dataIndex: 'enduranceRun',
      key: 'enduranceRun',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: '20mシャトルラン',
      dataIndex: 'shuttleRun',
      key: 'shuttleRun',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: '50m走',
      dataIndex: 'sprintRun',
      key: 'sprintRun',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: '立ち幅跳び',
      dataIndex: 'standingJump',
      key: 'standingJump',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
    {
      title: 'ボール投げ',
      dataIndex: 'handballThrow',
      key: 'handballThrow',
      className: 'w-24 text-center-f font-black ant-table-male-test',
    },
  ]
  const testFemaleColumns: ColumnType<any>[] = [
    {
      title: '得点',
      dataIndex: 'score',
      key: 'score',
      className: 'w-10 text-center-f font-black ant-table-female-test',
    },
    {
      title: '握力',
      dataIndex: 'grip',
      key: 'grip',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: '上体起こし',
      dataIndex: 'sitUps',
      key: 'sitUps',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: '長座体前屈',
      dataIndex: 'bending',
      key: 'bending',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: '反復横跳び',
      dataIndex: 'sideJump',
      key: 'sideJump',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: '持久走',
      dataIndex: 'enduranceRun',
      key: 'enduranceRun',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: '20mシャトルラン',
      dataIndex: 'shuttleRun',
      key: 'shuttleRun',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: '50m走',
      dataIndex: 'sprintRun',
      key: 'sprintRun',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: '立ち幅跳び',
      dataIndex: 'standingJump',
      key: 'standingJump',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
    {
      title: 'ボール投げ',
      dataIndex: 'handballThrow',
      key: 'handballThrow',
      className: 'w-24 text-center-f font-black ant-table-female-test',
    },
  ]

  const secondaryStandardColumns: ColumnType<any>[] = [
    {
      title: '段階',
      dataIndex: 'level',
      key: 'level',
      className: 'w-10 text-center-f font-black ant-table-standards',
    },
    {
      title: '12歳',
      dataIndex: '12',
      key: '12',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１３歳',
      dataIndex: '13',
      key: '13',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１４歳',
      dataIndex: '14',
      key: '14',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１５歳',
      dataIndex: '15',
      key: '15',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１６歳',
      dataIndex: '16',
      key: '16',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１７歳',
      dataIndex: '17',
      key: '17',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '18歳',
      dataIndex: '18',
      key: '18',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１９歳',
      dataIndex: '19',
      key: '19',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
  ]
  const elementaryStandardColumns: ColumnType<any>[] = [
    {
      title: '段階',
      dataIndex: 'level',
      key: 'level',
      className: 'w-10 text-center-f font-black ant-table-standards',
    },
    {
      title: '６歳',
      dataIndex: '6',
      key: '6',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '７歳',
      dataIndex: '7',
      key: '7',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '８歳',
      dataIndex: '8',
      key: '8',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '９歳',
      dataIndex: '9',
      key: '9',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１０歳',
      dataIndex: '10',
      key: '10',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
    {
      title: '１１歳',
      dataIndex: '11',
      key: '11',
      className: 'w-24 text-center-f font-black ant-table-standards',
    },
  ]

  // const studentName = isElementary ? '小学生' : '中学生'
  return (
    <>
      <Dashboard
        selectedMenu={graphItem.tabIndex}
        navbar={graphItem.title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className="flex justify-center">
          <div className="flex flex-col items-start">
            <div className="flex flex-col mt-6">
              <span className="text-xs text-black font-bold mb-2">男子</span>
              <Table
                columns={testMaleColumns}
                dataSource={testData.male}
                style={{ minWidth: 828 }}
                size="small"
                rowClassName={(_, idx) => {
                  return `text-10px text-black font-bold ${
                    idx % 2 !== 0 ? 'ant-table-row-light' : 'ant-table-row-dark'
                  }`
                }}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                }}
              />
            </div>

            <div className="flex flex-col mt-6">
              <span className="text-xs text-black font-bold mb-2">女子</span>
              <Table
                columns={testFemaleColumns}
                dataSource={testData.female}
                style={{ minWidth: 828 }}
                size="small"
                rowClassName={(_, idx) => {
                  return `text-10px text-black font-bold ${
                    idx % 2 !== 0 ? 'ant-table-row-light' : 'ant-table-row-dark'
                  }`
                }}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                }}
              />
            </div>

            <div className="flex flex-col mt-6">
              <span className="text-xs text-black font-bold mb-2">
                総合評価基準表
              </span>
              <Table
                columns={
                  isElementary
                    ? elementaryStandardColumns
                    : secondaryStandardColumns
                }
                dataSource={testData.standard}
                style={{ minWidth: 564 }}
                size="small"
                rowClassName={(_, idx) => {
                  return `text-10px text-black font-bold ${
                    idx % 2 !== 0 ? 'ant-table-row-light' : 'ant-table-row-dark'
                  }`
                }}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                }}
              />
            </div>
            <div className="flex w-full justify-end mt-6">
              <span className="text-black">
                引用：文部科学省新体力テスト実施要項
              </span>
            </div>
          </div>
        </div>
      </Dashboard>
    </>
  )
}

export default StandardTablePage
