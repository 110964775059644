import { useMemo } from 'react'
import { Dashboard } from '../layout/Dashboard'
import { Select, Button } from 'antd'
import { Card } from '@alpha/components'
import StudentTestResult from '../shared/StudentTestResult'

const { Option } = Select

const TestResultsForPrintPage = ({
  testYear,
  grades,
  schoolGrade,
  data,
  school,
}: {
  testYear: number
  grades: any[]
  schoolGrade: number
  schoolClass: number
  data: any[]
  school: any
}) => {
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  let school_IsEnduranceRunInputActive =
    school?.attributes?.isEnduranceRunInputActive
  let grade_IsEnduranceRunInputActive =
    grades?.find((i) => i.schoolGrade === schoolGrade)
      ?.isEnduranceRunInputActive ?? false

  const isEnduranceRunInputActive = isElementarySchool
    ? false
    : school_IsEnduranceRunInputActive
    ? true
    : grade_IsEnduranceRunInputActive

  const studentListMemo = useMemo(() => {
    return (data || []).map((s, index) => {
      return (
        <StudentTestResult
          school={school}
          testYear={testYear}
          student={s}
          isEnduranceRunInputActive={isEnduranceRunInputActive}
          key={index}
        />
      )
    })
  }, [testYear, data, isEnduranceRunInputActive, school])
  const studentListForPrintingMemo = useMemo(() => {
    return (data || []).map((s, index) => {
      return (
        <StudentTestResult
          school={school}
          testYear={testYear}
          student={s}
          isEnduranceRunInputActive={isEnduranceRunInputActive}
          key={index}
          forPrinting
        />
      )
    })
  }, [testYear, data, isEnduranceRunInputActive, school])

  return <>{studentListForPrintingMemo}</>
}

export default TestResultsForPrintPage
