import React, { useEffect, useState } from 'react'

import {
  Button,
  Checkbox,
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Table,
} from 'antd'
import {
  useGradesLazyQuery,
  useStudentsLazyQuery,
  fetchSchoolClasses,
} from '@alpha/core'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import { ColumnType } from 'antd/es/table'
import { CloseOutlined } from '@ant-design/icons'
import _ from 'lodash'
import {
  reCalcTestResult,
  schoolHasEnduranceTest,
  convertJapNumbers,
  getShowRanking,
  ageFromSchool,
} from '~/utils'
import { useAlphaStore } from '~/context'
import Api from '~/utils/api'
import { pageSize as defaultPageSize } from '~/utils/constants'
import { useSchoolMeApiQuery } from '~/utils/hooks'

const { Option } = Select
const { useBreakpoint } = Grid

const globalInputKeys = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'shuttleRun',
  'enduranceRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
]
const inputList = [
  {
    key: 'grip',
    tab: '握力',
    measurable: true,
    memoable: true,
    items: [
      {
        label: '右(1回目)',
        name: 'gripRight1',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
      {
        label: '右(2回目)',
        name: 'gripRight2',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
      {
        label: '左(1回目)',
        name: 'gripLeft1',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
      {
        label: '左(2回目)',
        name: 'gripLeft2',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'sitUps',
    tab: '上体起こし',
    tab_width: 'w-16',
    measurable: true,
    memoable: true,
    items: [
      {
        name: 'sitUps',
        suffix: '回',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'bending',
    tab: '長座体前屈',
    tab_width: 'w-16',
    measurable: true,
    memoable: true,
    items: [
      {
        label: '(１回目）',
        name: 'bending1',
        suffix: 'cm',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
      {
        label: '（2回目）',
        name: 'bending2',
        suffix: 'cm',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'sideJump',
    tab: '反復横とび',
    tab_width: 'w-16',
    measurable: true,
    memoable: true,
    items: [
      {
        label: '（１回目）',
        name: 'sideJump1',
        suffix: '回',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
      {
        label: '（2回目）',
        name: 'sideJump2',
        suffix: '回',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'shuttleRun',
    tab: '20mシャトルラン',
    tab_width: 'w-24',
    measurable: true,
    memoable: true,
    items: [
      {
        name: 'shuttleRunCount',
        suffix: '回',
        min: 0,
        max: 200,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 200,
            message: '0から200の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'enduranceRun',
    tab: '持久走',
    tab_width: 'w-16',
    measurable: true,
    memoable: true,
    items: [
      {
        name: 'runningTime',
        suffix: '分',
        rules: [
          {
            type: 'number',
            min: 0,
            max: 59,
            message: '0から59の数字を入力してください',
          },
        ],
      },
      {
        name: 'runningTimeSeconds',
        suffix: '秒',
        rules: [
          {
            type: 'number',
            min: 0,
            max: 59,
            message: '0から59の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'sprintRun',
    tab: '50m走',
    tab_width: 'w-16',
    measurable: true,
    memoable: true,
    items: [
      {
        name: 'sprintRunSeconds',
        suffix: '秒',
        min: 0,
        max: 50,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 50,
            message: '0から50の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'standingJump',
    tab: '立ち幅跳び',
    tab_width: 'w-16',
    measurable: true,
    memoable: true,
    items: [
      {
        label: '(１回目）',
        name: 'standingJump1',
        suffix: 'cm',
        min: 0,
        max: 300,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 300,
            message: '0から300の数字を入力してください',
          },
        ],
      },
      {
        label: '（2回目）',
        name: 'standingJump2',
        suffix: 'cm',
        min: 0,
        max: 300,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 300,
            message: '0から300の数字を入力してください',
          },
        ],
      },
    ],
  },
  {
    key: 'handballThrow',
    tab: 'ボール投げ',
    tab_width: 'w-16',
    measurable: true,
    memoable: true,
    items: [
      {
        label: '(１回目）',
        name: 'handballThrow1',
        suffix: 'm',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
      {
        label: '（2回目）',
        name: 'handballThrow2',
        suffix: 'm',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: '0から99の数字を入力してください',
          },
        ],
      },
    ],
  },
]

const TestInputPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()
  const { data: teacherData } = useSchoolMeApiQuery()
  const testYear = teacherData?.testYear
  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade
  const teacherSchoolClass = teacherData?.schoolClass

  const [isOnline, setIsOnline] = useState(true)
  const [schoolGrade, setSchoolGrade] = useState<any>(
    teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
      ? teacherSchoolGrade
      : 0,
  )
  const [schoolClasses, setSchoolClasses] = useState([
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1,
  ])
  const [schoolClass, setSchoolClass] = useState<any>(
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0,
  )
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad && teacherRole) {
      setFirstLoad(false)
      setSchoolGrade(
        teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
          ? teacherSchoolGrade
          : 0,
      )
      setSchoolClasses([teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1])
      setSchoolClass(teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0)
    }
  }, [firstLoad, teacherRole])

  const [data, setData] = useState<any[]>()
  const [total, setTotal] = useState(0)
  const [isNotMeasurable, setIsNotMeasurable] = useState({})

  const [filterGender, setFilterGender] = useState('')
  const [selectedStudent, setSelectedStudent] = useState<any>(null)
  const [showDetailModal, setShowDetailModal] = useState<any>(false)
  const [reloading, setReloading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const [form] = Form.useForm()
  const [getGrades, { data: gradesData }] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [getAllStudents, { data: allStudentData }] = useStudentsLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [getStudents, { data: studentData }] = useStudentsLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    window.addEventListener(
      'offline',
      () => {
        setIsOnline(false)
      },
      { passive: true },
    )
    window.addEventListener(
      'online',
      () => {
        setIsOnline(true)
      },
      { passive: true },
    )
  }, [])

  useEffect(() => {
    if (!school) return

    fetchSchoolClasses(school._id, testYear).then((res) => {
      const schoolClasses = res['schoolClasses']
      if (schoolClasses.length) {
        schoolClasses.sort()
        setSchoolClasses(schoolClasses)
      }
    })
  }, [testYear, school])

  // getGrades
  useEffect(() => {
    if (!school) return

    const schoolId = school._id
    getGrades({
      variables: {
        input: {
          schoolId,
          testYear,
        },
      },
    })
  }, [school, testYear, getGrades])

  // getAllStudents
  useEffect(() => {
    if (!school) return

    let schoolId = school._id
    if (!schoolId) return

    getAllStudents({
      variables: {
        input: {
          schoolId: schoolId,
          testYear,
        },
      },
    })
  }, [school, testYear, getAllStudents])

  // getStudents
  useEffect(() => {
    if (!school) return

    let schoolId = school._id
    if (!schoolId) return
    if (!teacherData) return

    setReloading(true)

    getStudents({
      variables: {
        input: {
          schoolId: schoolId,
          testYear,
          schoolClass,
          schoolGrade,
          page: currentPage,
          pageSize,
        },
      },
    })
  }, [
    school,
    testYear,
    schoolClass,
    schoolGrade,
    teacherData,
    getStudents,
    currentPage,
    pageSize,
  ])

  // setData
  useEffect(() => {
    let _data

    if (isOnline) {
      if (!studentData?.students?.data) return

      let students = studentData.students.data
      setTotal(studentData.students.count)

      if (filterGender !== '') {
        students = students.filter((s) => s.attributes.gender === filterGender)
      }
      _data = students
    } else {
      if (!allStudentData?.students?.data) {
        setReloading(false)
        setData([])
        return
      }

      let students = allStudentData.students.data
      _data = students.filter(
        (s) =>
          s.attributes.schoolClass === schoolClass &&
          s.attributes.schoolGrade === schoolGrade,
      )
      if (filterGender !== '') {
        _data = _data.filter((s) => s.attributes.gender === filterGender)
      }
    }

    _data.forEach((student) => {
      let trIdx =
        student?.attributes?.testResults &&
        student.attributes.testResults.findIndex((d) => d.testYear === testYear)

      if (trIdx >= 0) {
        const age = ageFromSchool(
          school?.attributes?.schoolCategoryCode,
          student.attributes.schoolGrade,
        )
        let gender = student?.attributes?.gender
        if (gender) {
          student.attributes.testResults[trIdx] = reCalcTestResult(
            student.attributes.testResults[trIdx],
            age,
            gender,
          )
        }
      }
    })
    setData(_data)
    setReloading(false)
  }, [
    isOnline,
    allStudentData,
    teacherData,
    studentData,
    schoolClass,
    schoolGrade,
    filterGender,
    testYear,
    school?.attributes?.schoolCategoryCode,
  ])

  const hasEnduranceTest = schoolHasEnduranceTest(school?.attributes)
  const inputKeys = globalInputKeys.filter(
    (key) =>
      (!hasEnduranceTest && key !== 'enduranceRun') ||
      (hasEnduranceTest && key !== 'shuttleRun'),
  )

  const getInputList = () => {
    return inputList.filter(
      (v) => v.key !== (!hasEnduranceTest ? 'enduranceRun' : 'shuttleRun'),
    )
  }

  const incorrectValue = <div className="bg-gray-160 -m-2">&nbsp;</div>

  const renderTest = (v, test) => {
    const defVal = incorrectValue
    if (!v || v.length === 0) return defVal
    const tr = v.find((d) => d.testYear === testYear)
    if (!tr || tr.length === 0) return defVal

    switch (test) {
      case 'grip':
      case 'bending':
      case 'sideJump':
      case 'standingJump':
      case 'handballThrow':
      case 'sitUps':
      case 'sprintRun':
      case 'shuttleRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        const value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (
          value === null ||
          value === undefined
        )
          return defVal
        return <div>{value}</div>
      }
      // this is wrong (originally at least). runningTime is not a number. is the timeLabel (mm:ss)
      // runningTimeSeconds is the sum of minutes and seconds.
      case 'enduranceRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        if (tr.enduranceRun?.isNotMeasurable === true) return '無'
        const value =
          tr[test].runningTime && tr[test].runningTimeSeconds
            ? `${tr.enduranceRun?.runningTime}'${tr.enduranceRun?.runningTimeSeconds}''`
            : defVal
        if (!value) return defVal
        return <div>{value}</div>
      }
      case 'height':
      case 'weight': {
        const value = tr.sizeTest?.[test]
        if (!value || value.length === 0) return defVal
        return <div>{value}</div>
      }
    }
  }

  const getTestRecordStatus = (v) => {
    let status = false
    if (!v || v.length === 0) return status
    const tr = v.find((d) => d.testYear === testYear)
    if (tr) {
      status = inputKeys.every((tk) => {
        if (tk === 'sitUps') {
          return (
            tr[tk]?.isNotMeasurable === true ||
            (tr[tk]?.value !== undefined && tr[tk]?.value !== null)
          )
        }
        return tr[tk]?.isNotMeasurable === true || !!tr[tk]?.value
      })
    }
    status = status && !!tr?.sizeTest?.height && !!tr?.sizeTest?.weight

    return status
  }

  const getTestResults = (student) => {
    return student.attributes.testResults?.find((d) => d.testYear === testYear)
  }

  const sortTestResults = (a, b, key) => {
    let element1
    let element2

    switch (key) {
      case 'grip':
      case 'sitUps':
      case 'bending':
      case 'sprintRun':
      case 'sideJump':
      case 'standingJump':
      case 'shuttleRun':
      case 'handballThrow':
      case 'enduranceRun': {
        let a_tr = getTestResults(a)
        let b_tr = getTestResults(b)

        element1 = a_tr?.[key]?.value
        element2 = b_tr?.[key]?.value
        break
      }
      case 'rank':
      case 'points': {
        let a_tr = getTestResults(a)
        let b_tr = getTestResults(b)

        element1 = a_tr?.[key]
        element2 = b_tr?.[key]
        break
      }
      case 'height':
      case 'weight': {
        let a_tr = getTestResults(a)
        let b_tr = getTestResults(b)

        element1 = a_tr?.sizeTest?.[key]
        element2 = b_tr?.sizeTest?.[key]
        break
      }
      case 'name': {
        element1 = `${a?.familyName ?? ''}${a?.givenName ?? ''}`
        element2 = `${b?.familyName ?? ''}${b?.givenName ?? ''}`
        break
      }
      default: {
        element1 = a?.[key]
        element2 = b?.[key]
      }
    }

    if (element1 == null && element2 == null) return 1
    if (element1 == null) return -1
    if (element2 == null) return 1
    return typeof element1 === 'number'
      ? element1 - element2
      : element1.localeCompare(element2)
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: '登録状況',
      dataIndex: ['attributes', 'testResults'],
      key: 'status',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        let status1 = getTestRecordStatus(a?.attributes?.testResults)
        let status2 = getTestRecordStatus(b?.attributes?.testResults)

        return status1 === status2 ? 0 : !status1 ? -1 : 1
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        let status = getTestRecordStatus(v)

        return (
          <div
            className="flex justify-center items-center"
            style={{ height: 22 }}
          >
            <div
              className="flex justify-center items-center h-4 w-4 rounded-3px text-xxs font-bold text-white"
              style={{ backgroundColor: status ? '#00944D' : '#CA4141' }}
            >
              {status ? '済' : '未'}
            </div>
          </div>
        )
      },
    },
    {
      title: '学年',
      dataIndex: ['attributes', 'schoolGrade'],
      key: 'schoolGrade',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: '組',
      dataIndex: ['attributes', 'schoolClass'],
      key: 'schoolClass',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: '出席番号',
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      key: 'schoolAttendanceNumber',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div>{v}</div>
      },
    },
    {
      title: '性別',
      dataIndex: ['attributes', 'gender'],
      key: 'gender',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        let gender1 = a?.attributes?.gender
        let gender2 = b?.attributes?.gender

        if (!gender1 && !gender2) return 1
        if (!gender1) return -1
        if (!gender2) return 1
        return gender1.localeCompare(gender2)
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => <div>{v ? (v === 'MALE' ? '男' : '女') : '-'}</div>,
    },
    {
      title: 'ID',
      dataIndex: ['username'],
      key: 'username',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return a.username.localeCompare(b.username)
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        return <div className="whitespace-nowrap">{v}</div>
      },
    },
    {
      title: '総得点',
      dataIndex: ['attributes', 'testResults'],
      key: 'points',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'points')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        let tr = v.find((d) => d.testYear === testYear)

        return <div>{tr ? tr?.points : null}</div>
      },
    },
    {
      title: '評価',
      dataIndex: ['attributes', 'testResults'],
      key: 'rank',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'rank')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        let tr = v.find((d) => d.testYear === testYear)
        const showRanking = getShowRanking(tr, hasEnduranceTest)

        return <div>{showRanking ? tr?.rank : incorrectValue}</div>
      },
    },
    {
      title: '身長',
      dataIndex: ['attributes', 'testResults'],
      key: 'height',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'height')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'height')
      },
    },
    {
      title: '体重',
      dataIndex: ['attributes', 'testResults'],
      key: 'weight',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'weight')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'weight')
      },
    },
    {
      title: '握力',
      dataIndex: ['attributes', 'testResults'],
      key: 'grip',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'grip')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'grip')
      },
    },
    {
      title: '上体起こし',
      dataIndex: ['attributes', 'testResults'],
      key: 'sitUps',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sitUps')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sitUps')
      },
    },
    {
      title: '長座体前屈',
      dataIndex: ['attributes', 'testResults'],
      key: 'bending',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bending')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'bending')
      },
    },
    {
      title: '反復横跳び',
      dataIndex: ['attributes', 'testResults'],
      key: 'sideJump',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sideJump')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sideJump')
      },
    },
    hasEnduranceTest
      ? {
          title: '持久走',
          dataIndex: ['attributes', 'testResults'],
          key: 'enduranceRun',
          className: 'text-center-f whitespace-nowrap',
          sorter: (a, b) => {
            return sortTestResults(a, b, 'enduranceRun')
          },
          sortDirections: ['descend', 'ascend'],
          render: (v) => {
            return renderTest(v, 'enduranceRun')
          },
        }
      : {
          title: 'シャトルラン',
          dataIndex: ['attributes', 'testResults'],
          key: 'shuttleRun',
          className: 'text-center-f whitespace-nowrap',
          sorter: (a, b) => {
            return sortTestResults(a, b, 'shuttleRun')
          },
          sortDirections: ['descend', 'ascend'],
          render: (v) => {
            return renderTest(v, 'shuttleRun')
          },
        },
    {
      title: '50m走',
      dataIndex: ['attributes', 'testResults'],
      key: 'sprintRun',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sprintRun')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sprintRun')
      },
    },
    {
      title: '立ち幅跳び',
      dataIndex: ['attributes', 'testResults'],
      key: 'standingJump',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'standingJump')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'standingJump')
      },
    },
    {
      title: 'ボール投げ',
      dataIndex: ['attributes', 'testResults'],
      key: 'handballThrow',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'handballThrow')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'handballThrow')
      },
    },
    {
      title: '',
      dataIndex: ['attributes'],
      key: 'actions',
      render: (v, r) => {
        return (
          <div
            className={
              !v.testResults || v.testResults.length === 0
                ? 'bg-gray-160 -m-2 px-2'
                : ''
            }
          >
            <Button
              type="primary"
              className="ant-btn-xs text-10px font-black"
              onClick={(e) => {
                e.preventDefault()
                // if (!v.familyName) return
                setSelectedStudent(r)
                const tr = r.attributes.testResults.find(
                  (d) => d.testYear === testYear,
                )
                const isNotMeasurable = {}
                inputList.forEach((input) => {
                  isNotMeasurable[input.key] = tr?.[input.key]?.isNotMeasurable
                })
                setIsNotMeasurable(isNotMeasurable)
                setShowDetailModal(true)
                return false
              }}
              disabled={testYear !== testYear}
            >
              詳細
            </Button>
          </div>
        )
      },
    },
  ]

  const screens = useBreakpoint()

  const modalProps = {
    visible: showDetailModal,
    width: screens.xxl ? '70vw' : screens.lg ? '85vw' : '90vw',
    onCancel: () => {
      setShowDetailModal(false)
      form.resetFields()
    },
    closeIcon: (
      <CloseOutlined className="text-2xl" style={{ color: '#036EB8' }} />
    ),
    footer: null,
  }

  const tr = selectedStudent?.attributes?.testResults.find(
    (d) => d.testYear === testYear,
  )

  const renderInput = (input) => {
    const testResult = selectedStudent?.attributes?.testResults.find(
      (d) => d.testYear === testYear,
    )

    const _testKey = testResult?.[input?.key]
    return (
      <>
        <Row>
          <div
            className={`h-5 ${
              input.tab_width ? input.tab_width : 'w-12'
            } bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black`}
          >
            {input.tab}
          </div>
        </Row>
        <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
          {input.items.map((item, index) => (
            <Col key={`grip-${selectedStudent._id}-${index + 1}`} span={6}>
              <div className="flex relative">
                <Form.Item
                  className="mb-0-f "
                  name={item.name}
                  label={
                    item.label ? (
                      <span className="text-10px">{item.label}</span>
                    ) : null
                  }
                  initialValue={
                    input.key === 'enduranceRun'
                      ? _testKey?.[item.name] &&
                        Number.parseInt(_testKey?.[item.name])
                      : _testKey?.[item.name]
                  }
                  rules={
                    input.measurable && isNotMeasurable[input.key] === true
                      ? []
                      : item.rules
                  }
                  validateTrigger={
                    input.measurable && isNotMeasurable[input.key] === true
                      ? []
                      : ['onSubmit', 'onChange']
                  }
                >
                  <InputNumber
                    size="small"
                    disabled={isNotMeasurable[input.key]}
                    parser={(displayValue) => {
                      return convertJapNumbers(displayValue)
                    }}
                    step="any"
                  />
                </Form.Item>
                {item.suffix && (
                  <div
                    className={`flex items-end absolute left-24 ${
                      item.label ? 'top-10' : 'top-4'
                    }`}
                  >
                    <span className="text-10px">{item.suffix}</span>
                  </div>
                )}
              </div>
            </Col>
          ))}
          {input.measurable && (
            <Col span={input.items.length >= 4 ? 24 : 6}>
              <Form.Item
                className="mb-0-f"
                style={
                  input.items.find((item) => !item.label) ||
                  input.items.length === 4
                    ? {}
                    : { position: 'relative', top: '50%' }
                }
                name={`${input.key}NotMeasurable`}
                valuePropName="checked"
                initialValue={_testKey?.isNotMeasurable}
              >
                <Checkbox
                  className="text-10px font-normal"
                  onChange={(event) => {
                    const newValue = {
                      ...isNotMeasurable,
                    }
                    newValue[input.key] = event.target.checked
                    setIsNotMeasurable(newValue)
                    form.validateFields()
                  }}
                >
                  今年度測定なし
                </Checkbox>
              </Form.Item>
            </Col>
          )}
          {input.memoable && (
            <Col span={24}>
              <label className="text-10px">メモ</label>
              <Form.Item
                className="mb-0-f "
                name={`${input.key}Memo`}
                initialValue={_testKey?.memo}
              >
                <Input size="small" key={`grip-${selectedStudent._id}-memo`} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </>
    )
  }

  const onFinish = async (values) => {
    let data = [
      {
        testYear,
        studentId: selectedStudent._id,
        gripTest: {
          grip: {
            gripRight1: values.gripRight1,
            gripRight2: values.gripRight2,
            gripLeft1: values.gripLeft1,
            gripLeft2: values.gripLeft2,
            isNotMeasurable: isNotMeasurable['grip'],
            memo: values.gripMemo,
          },
        },
        sitUpsTest: {
          sitUps: {
            sitUps: values.sitUps,
            isNotMeasurable: isNotMeasurable['sitUps'],
            memo: values.sitUpsMemo,
          },
        },
        bendingTest: {
          bending: {
            bending1: values.bending1,
            bending2: values.bending2,
            isNotMeasurable: isNotMeasurable['bending'],
            memo: values.bendingMemo,
          },
        },
        sideJumpTest: {
          sideJump: {
            sideJump1: values.sideJump1,
            sideJump2: values.sideJump2,
            isNotMeasurable: isNotMeasurable['sideJump'],
            memo: values.sideJumpMemo,
          },
        },
        enduranceRunTest: {
          enduranceRun: {
            runningTime: values.runningTime?.toString(),
            runningTimeSeconds: values.runningTimeSeconds,
            isNotMeasurable: isNotMeasurable['enduranceRun'],
            memo: values.enduranceRunMemo,
          },
        },
        shuttleRunTest: {
          shuttleRun: {
            shuttleRunCount: values.shuttleRunCount,
            //shuttleRunGainOxygen?
            isNotMeasurable: isNotMeasurable['shuttleRun'],
            memo: values.shuttleRunMemo,
          },
        },
        sprintRunTest: {
          sprintRun: {
            sprintRunSeconds: values.sprintRunSeconds,
            isNotMeasurable: isNotMeasurable['sprintRun'],
            memo: values.sprintRunMemo,
          },
        },
        standingJumpTest: {
          standingJump: {
            standingJump1: values.standingJump1,
            standingJump2: values.standingJump2,
            isNotMeasurable: isNotMeasurable['standingJump'],
            memo: values.standingJumpMemo,
          },
        },
        handballThrowTest: {
          handballThrow: {
            handballThrow1: values.handballThrow1,
            handballThrow2: values.handballThrow2,
            isNotMeasurable: isNotMeasurable['handballThrow'],
            memo: values.handballThrowMemo,
          },
        },
        sizeTest: {
          weight: values.weight,
          height: values.height,
        },
      },
    ]

    try {
      setReloading(true)
      setLoading(true)
      const res = await Api.post('/alpha/student-result', data)
      setShowDetailModal(false)
      form.resetFields()
      setLoading(false)
      if (res.status === 500) {
        message.error(`送信エラーが発生しました [${res.data.error}]`)
        return
      }
      message.success('登録完了')

      getStudents({
        variables: {
          input: {
            schoolId: school._id,
            testYear,
            schoolClass,
            schoolGrade,
            page: currentPage,
            pageSize,
          },
        },
      })
      setReloading(false)
    } catch (err) {
      message.error(`エラーが発生しました [${err}]`)
      setReloading(false)
    }
  }

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {
        <Row className="w-full justify-center">
          <Col className="mt-16" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="space-x-2 pb-2 print:hidden">
              <Select
                className="w-30 rounded-5px"
                value={schoolGrade}
                onChange={setSchoolGrade}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                {teacherRole !== 'CLASS_ADMIN' &&
                  teacherRole !== 'GRADE_ADMIN' && (
                    <Option key={`grade-${0}`} value={0}>
                      全学年
                    </Option>
                  )}
                <>
                  {(teacherRole === 'SUPER_ADMIN' ||
                    teacherRole === 'SCHOOL_ADMIN') &&
                  gradesData?.grades?.data?.length > 0 ? (
                    gradesData?.grades?.data?.map((v) => (
                      <Option value={v.schoolGrade} key={v.schoolGrade}>
                        {`${v.schoolGrade}年生`}
                      </Option>
                    ))
                  ) : (teacherRole === 'SUPER_ADMIN' ||
                      teacherRole === 'SCHOOL_ADMIN') &&
                    gradesData?.grades?.data?.length === 0 ? (
                    <Option value={1} key={999}>
                      1年生
                    </Option>
                  ) : (
                    <Option value={teacherSchoolGrade} key={teacherSchoolGrade}>
                      {`${teacherSchoolGrade}年生`}
                    </Option>
                  )}
                </>
              </Select>

              <Select
                className="w-30 rounded-5px"
                value={schoolClass}
                onChange={setSchoolClass}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                {teacherRole !== 'CLASS_ADMIN' && (
                  <Option key={`class-${0}`} value={0}>
                    全組
                  </Option>
                )}
                <>
                  {teacherRole !== 'CLASS_ADMIN' ? (
                    schoolClasses
                      .sort((a, b) => a - b)
                      .map((_class) => (
                        <Option key={`class-${_class}`} value={_class}>
                          {_class}組
                        </Option>
                      ))
                  ) : (
                    <Option value={teacherSchoolClass} key={teacherSchoolClass}>
                      {`${teacherSchoolClass}組`}
                    </Option>
                  )}
                </>
              </Select>
              <Select
                className="w-30 rounded-5px"
                value={filterGender}
                onChange={(g) => setFilterGender(g)}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                <Option key="gender-0" value="">
                  全性別
                </Option>
                <Option key="gender-male" value="MALE">
                  男性
                </Option>
                <Option key="gender-female" value="FEMALE">
                  女性
                </Option>
              </Select>
            </div>
            <div className="text-10px font-black">
              登録人数{' '}
              {
                data?.filter(
                  (_da) =>
                    _da?.attributes?.testResults &&
                    _da?.attributes?.testResults.length,
                ).length
              }
            </div>
            <Table
              columns={tableColumns}
              dataSource={data}
              loading={reloading}
              rowKey="_id"
              size="small"
              style={{ minWidth: 940 }}
              pagination={{
                pageSize: pageSize,
                defaultPageSize: pageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                total,
                current: currentPage,
                onChange: (page: number, size: number) => {
                  setCurrentPage(pageSize !== size ? 1 : page)
                  setPageSize(size)
                },
              }}
              bordered={true}
            />
          </Col>
        </Row>
      }

      {selectedStudent && showDetailModal && (
        <Modal key={`q-${selectedStudent?._id ?? ''}`} {...modalProps}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              gripRight1: tr?.gripRight1?.value,
            }}
            hideRequiredMark={true}
          >
            <Row>
              <Col span={12} className="pr-2">
                {getInputList()
                  .slice(0, 4)
                  .map((input) => renderInput(input))}
              </Col>
              <Col span={12} className="pl-2">
                <Row className="space-x-2">
                  <Col span={6}>
                    <Row>
                      <div
                        className={`h-5 w-12 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black`}
                      >
                        身長
                      </div>
                    </Row>
                    <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                      <Col key={`height-${selectedStudent._id}`} span={6}>
                        <div className="flex relative">
                          <Form.Item
                            className="mb-0-f "
                            name="height"
                            rules={[
                              {
                                type: 'number',
                                min: 0,
                                max: 200,
                                message: '0から200の数字を入力してください',
                              },
                            ]}
                            initialValue={
                              selectedStudent?.attributes?.testResults.find(
                                (d) => d.testYear === testYear,
                              )?.sizeTest?.height
                            }
                            validateTrigger={['onSubmit', 'onChange']}
                          >
                            <InputNumber
                              size="small"
                              type="number"
                              inputMode="decimal"
                              step="any"
                              parser={(displayValue) => {
                                return convertJapNumbers(displayValue)
                              }}
                            />
                          </Form.Item>
                          <div className="flex items-end absolute left-24 top-4">
                            <span className="text-10px">cm</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <div
                        className={`h-5 w-12 bg-primary rounded-t-5px flex justify-center items-center text-white text-10px font-black`}
                      >
                        体重
                      </div>
                    </Row>
                    <Row className="rounded-5px rounded-5px-without-tl bg-gray-150 p-1.5 mb-3">
                      <Col key={`weight-${selectedStudent._id}`} span={6}>
                        <div className="flex relative">
                          <Form.Item
                            className="mb-0-f "
                            name="weight"
                            rules={[
                              {
                                type: 'number',
                                min: 1,
                                max: 200,
                                message: '1から200の数字を入力してください',
                              },
                            ]}
                            initialValue={
                              selectedStudent?.attributes?.testResults.find(
                                (d) => d.testYear === testYear,
                              )?.sizeTest?.weight
                            }
                            validateTrigger={['onSubmit', 'onChange']}
                          >
                            <InputNumber
                              size="small"
                              type="number"
                              inputMode="decimal"
                              step="any"
                              parser={(displayValue) => {
                                return convertJapNumbers(displayValue)
                              }}
                            />
                          </Form.Item>
                          <div className="flex items-end absolute left-24 top-4">
                            <span className="text-10px">kg</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {getInputList()
                  .slice(4)
                  .map((input) => renderInput(input))}
              </Col>
            </Row>
            <Row justify="center">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-60"
                  size="large"
                  loading={loading}
                >
                  登録
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      )}
    </Dashboard>
  )
}

export default TestInputPage
