import { Modal, Row, Button } from 'antd'

import { CloseOutlined } from '@ant-design/icons'
import { useReactToPrint } from 'react-to-print'
import { useRef } from 'react'
import { ageFromSchool, reCalcTestResult } from '../../utils'
import StudentTestResult from '../shared/StudentTestResult'

type Props = {
  testYear: number
  student: any
  isEnduranceRunInputActive: boolean
  isShow: boolean
  setIsShow: (b: boolean) => void
  school: any
}

const StudentTestResultModal = ({
  testYear,
  student,
  isEnduranceRunInputActive,
  isShow,
  setIsShow,
  school,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>()
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
  })

  let testResult =
    student?.attributes?.testResults &&
    student.attributes.testResults.find((d) => d.testYear === testYear)
  let age =
    student &&
    ageFromSchool(
      school.attributes.schoolCategoryCode,
      student.attributes.schoolGrade,
    )
  let gender = student?.attributes?.gender

  if (testResult && gender) {
    testResult = reCalcTestResult(testResult, age, gender)
  }

  const handleCancel = () => {
    setIsShow(false)
  }

  const modalProps = {
    visible: isShow,
    width: 700,
    onCancel: handleCancel,
    closeIcon: (
      <CloseOutlined className="text-2xl" style={{ color: '#036EB8' }} />
    ),
    footer: null,
    className: 'float-right',
    style: { top: 0 },
  }

  return (
    <Modal {...modalProps}>
      <div ref={modalRef}>
        <StudentTestResult
          school={school}
          testYear={testYear}
          student={student}
          isEnduranceRunInputActive={isEnduranceRunInputActive}
        />
      </div>
      <div className="w-full flex justify-center">
        <Button className="w-60 rounded" type="primary" onClick={handlePrint}>
          印刷
        </Button>
      </div>
    </Modal>
  )
}

export default StudentTestResultModal
