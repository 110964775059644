import { OnboardingLayout, Card } from '@alpha/components'

import { Button, Col, Form, Input, message, Row } from 'antd'
import { AuthContext, useTeacherLinkSchoolMutation } from '@alpha/core'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

const RegisterCodePage = () => {
  const auth = useContext(AuthContext)
  const [register, { loading }] = useTeacherLinkSchoolMutation()
  const history = useHistory()
  const [form] = Form.useForm()

  if (!auth.currentUser) {
    return null
  }

  const onFinish = async (values) => {
    const variables = {
      input: {
        invitationCode: values.invitationCode,
      },
    }
    try {
      const res = await register({ variables })
      if (res.errors) {
        message.error(`エラーが発生しました [${res.errors.join(',')}]`)
        return
      }
      const { teacherLinkSchool } = res.data!
      if (!teacherLinkSchool?.success) {
        message.error(`エラーが発生しました [${teacherLinkSchool?.error}]`)
        return
      }
      message.success('学校情報を登録しました')
      // history.push('/school_setup')
      history.push('/home')
    } catch (err) {
      message.error(err)
    }
  }

  return (
    <OnboardingLayout>
      <Card>
        <h1 className="theme-nav-title">学校の招待コードを入力</h1>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="invitationCode"
                label={<label className="dark:text-white">招待コード</label>}
                rules={[{ required: true, message: '入力して下さい' }]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  送信
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </OnboardingLayout>
  )
}

export default RegisterCodePage
