export const pageSize = 50

export const currentYear = 2024

/**
 * App version for school only.
 */
export const APP_VERSION = `v1.0.7.${currentYear}`

export const getGradeSchool = (role: string, schoolGrade: number) => {
  switch (role) {
    case 'SCHOOL_ADMIN':
      return '全学年'
    case 'GRADE_ADMIN':
    case 'CLASS_ADMIN':
      return `${schoolGrade}年`
    default:
      break
  }
}

export const getClassSchool = (role: string, schoolClass: number) => {
  switch (role) {
    case 'SCHOOL_ADMIN':
    case 'GRADE_ADMIN':
      return '全クラス'
    case 'CLASS_ADMIN':
      return `${schoolClass}組`
    default:
      break
  }
}

export const getRoleSchool = (role: string) => {
  switch (role) {
    case 'SCHOOL_ADMIN':
      return '管理者'
    case 'GRADE_ADMIN':
      return '学年管理者'
    case 'CLASS_ADMIN':
      return 'クラス管理者'
    default:
      return role
  }
}

export const getGenderSchool = (gender: string) => {
  switch (gender) {
    case 'MALE':
      return '男'
    default:
      return '女'
  }
}

export const statusKey = {
  ACTIVE: 0,
  DEACTIVE: 1,
}

export const dataType = {
  STRING: 'string',
  NUMBER: 'number',
}
export const displayNumbers = [
  { text: '20　件/ページ', value: 20 },
  { text: '50　件/ページ', value: 50 },
  { text: '100　件/ページ', value: 100 },
]
