import React from 'react'
import { Button } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

export default function CompletionLayout({
  message = '登録完了',
  prevButton = undefined,
  button = undefined,
  styleButton = false,
  onClickButton = () => {},
}) {
  return (
    <div className="flex items-center flex-col justify-center h-screen">
      <div className="flex items-center justify-center w-40 h-40 rounded-full ring-8 ring-gray-200 ring-offset-8 ring-offset-gray-200 check-outlined-wrapper">
        <CheckOutlined className="check-outlined-icon" />
      </div>
      <div className="mt-8 text-base font-bold">{message}</div>
      {button && <div className="mt-7">{button}</div>}
      {prevButton && (
        <Button type="primary" className="mt-7" onClick={onClickButton}>
          {prevButton}
        </Button>
      )}
    </div>
  )
}
