import React from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { HomeIcon, PlayIcon } from '../icons'
import { useHistory } from 'react-router-dom'
import { Row } from 'antd'
import { useAlphaStore } from '~/context'
import { parseTeacherType } from '~/utils'

export const DashboardLayout = ({
  navbar,
  sidebar,
  loading = false,
  open = true,
  setIsOpen = (open: boolean) => {
    console.log('open', open)
  },
  onSettingsClick,
  children,
  account,
  isKid = false,
  isManagementPage = false,
}) => {
  const { kobe, teacherRole, teacher } = useAlphaStore()

  const history = useHistory()

  const nbHeight = isKid ? 'h-20' : 'h-12'

  return (
    <>
      <div className="flex bg-gray-50 h-screen">
        <div className="flex-auto flex flex-col bg-white dark:bg-gray-700 md:shadow-lg overflow-x-scroll">
          <div
            className={`flex justify-center text-white ${
              isManagementPage ? 'management-bg' : 'bg-primary'
            } items-center fixed w-full ${nbHeight} z-50 print:hidden`}
          >
            <div className="absolute left-4">{sidebar}</div>
            <div className="flex items-center text-base font-black">
              {navbar}
            </div>
            <div
              className={`absolute right-4 flex flex-col ${
                !isKid ? 'cursor-help' : 'cursor-pointer'
              }`}
            >
              {isKid ? (
                <Row className="space-x-6">
                  <div
                    className="flex flex-col justify-center items-center"
                    onClick={() => history.push('/home')}
                  >
                    {HomeIcon}
                    <span className="font-black">
                      ホームに
                      <ruby>
                        戻<rt className="text-tiny font-black">もど</rt>
                      </ruby>
                      る
                    </span>
                  </div>
                  <div
                    className="flex flex-col justify-center items-center"
                    onClick={() => history.push('/advise')}
                  >
                    {PlayIcon}
                    <span className="font-black">
                      <ruby>
                        動画<rt className="text-tiny font-black">どうが</rt>
                      </ruby>
                      をみる
                    </span>
                  </div>
                </Row>
              ) : (
                <div className="flex items-center space-x-8 font-bold text-lg">
                  {/* {kobe && (
                    <>
                      <div>{teacherRole && parseTeacherType(teacherRole)}</div>
                      <div>
                        {teacher &&
                          `${teacher.schoolGrade ?? '-'}学年`}
                      </div>
                      <div>
                        {teacher &&
                          `${teacher.schoolClass ?? '-'}組`}
                      </div>
                    </>
                  )} */}
                  <div
                    className="flex flex-col"
                    onClick={() =>
                      window.open(
                        '/ALPHAページ内利用ガイド.pdf',
                        '_blank',
                      )
                    }
                  >
                    <QuestionCircleOutlined style={{ fontSize: '28px' }} />
                    <span className="text-xxs">使い方を見る</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={isKid ? 'mt-20 h-full' : 'mt-12'}>{children}</div>
        </div>
      </div>
      <div className="hidden print:block">{children}</div>
    </>
  )
}
