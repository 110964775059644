import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** DateTime value */
  DateTime: any
  /** JSON value */
  JSON: any
}

/** # Area */
export type Area = {
  __typename?: 'Area'
  _id: Scalars['ID']
  name: Scalars['String']
  name_en: Scalars['String']
  name_hiragana: Scalars['String']
}

/** # AreasInput */
export type AreasInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  names?: InputMaybe<Array<Scalars['String']>>
}

export type AreasResponse = {
  __typename?: 'AreasResponse'
  count: Scalars['Int']
  data: Array<Area>
  error?: Maybe<Scalars['String']>
}

export type AuthResponse = {
  __typename?: 'AuthResponse'
  error?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

/** # BendingInput */
export type BendingInput = {
  bending1?: InputMaybe<Scalars['Float']>
  bending2?: InputMaybe<Scalars['Float']>
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
}

export type BendingResult = {
  __typename?: 'BendingResult'
  bending1?: Maybe<Scalars['Float']>
  bending2?: Maybe<Scalars['Float']>
  comment?: Maybe<Scalars['String']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Float']>
}

/** # CitiesInput */
export type CitiesInput = {
  area?: InputMaybe<Scalars['ID']>
  codes?: InputMaybe<Array<Scalars['Int']>>
  from?: InputMaybe<Scalars['Int']>
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  names?: InputMaybe<Array<Scalars['String']>>
  prefecture?: InputMaybe<Scalars['ID']>
}

export type CitiesResponse = {
  __typename?: 'CitiesResponse'
  count: Scalars['Int']
  data: Array<City>
  error?: Maybe<Scalars['String']>
}

/** # City */
export type City = {
  __typename?: 'City'
  _id: Scalars['ID']
  area: Scalars['ID']
  code: Scalars['Int']
  name: Scalars['String']
  name_hiragana: Scalars['String']
  prefecture: Scalars['ID']
}

export enum Client {
  AdminClient = 'ADMIN_CLIENT',
  SchoolClient = 'SCHOOL_CLIENT',
  StudentClient = 'STUDENT_CLIENT',
}

export enum ClubActivity {
  Art = 'ART',
  Badminton = 'BADMINTON',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  Biology = 'BIOLOGY',
  BrassBand = 'BRASS_BAND',
  Chemistry = 'CHEMISTRY',
  Creative = 'CREATIVE',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  EarthScience = 'EARTH_SCIENCE',
  EnglishSpeakingSociety = 'ENGLISH_SPEAKING_SOCIETY',
  Fencing = 'FENCING',
  Fishing = 'FISHING',
  History = 'HISTORY',
  IceHockey = 'ICE_HOCKEY',
  Igo = 'IGO',
  Judo = 'JUDO',
  Kendo = 'KENDO',
  Kyudo = 'KYUDO',
  Literature = 'LITERATURE',
  Magic = 'MAGIC',
  Mathematics = 'MATHEMATICS',
  ModelBuilding = 'MODEL_BUILDING',
  MountainClimbing = 'MOUNTAIN_CLIMBING',
  Music = 'MUSIC',
  MusicBand = 'MUSIC_BAND',
  NotSet = 'NOT_SET',
  Origami = 'ORIGAMI',
  Other = 'OTHER',
  Physics = 'PHYSICS',
  Programming = 'PROGRAMMING',
  Quiz = 'QUIZ',
  Railroad = 'RAILROAD',
  Science = 'SCIENCE',
  Shogi = 'SHOGI',
  Ski = 'SKI',
  Soccer = 'SOCCER',
  SoftballBaseball = 'SOFTBALL_BASEBALL',
  SoftTennis = 'SOFT_TENNIS',
  Swimming = 'SWIMMING',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  TrackAndField = 'TRACK_AND_FIELD',
  Volleyball = 'VOLLEYBALL',
}

/** # CreateSchoolInput */
export type CreateSchoolInput = {
  attributes: SchoolAttributesInput
  japanSchoolId: Scalars['ID']
}

/** # EnduranceRunInput */
export type EnduranceRunInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  runningTime?: InputMaybe<Scalars['String']>
  runningTimeSeconds?: InputMaybe<Scalars['Float']>
}

export type EnduranceRunResult = {
  __typename?: 'EnduranceRunResult'
  comment?: Maybe<Scalars['String']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  runningTime?: Maybe<Scalars['String']>
  runningTimeSeconds?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

export enum Era {
  Heisei = 'HEISEI',
  Reiwa = 'REIWA',
  Showa = 'SHOWA',
}

export type GripResult = {
  __typename?: 'GripResult'
  comment?: Maybe<Scalars['String']>
  gripLeft1?: Maybe<Scalars['Float']>
  gripLeft2?: Maybe<Scalars['Float']>
  gripRight1?: Maybe<Scalars['Float']>
  gripRight2?: Maybe<Scalars['Float']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Float']>
}

/** # GripTestInput */
export type GripTestInput = {
  gripLeft1?: InputMaybe<Scalars['Float']>
  gripLeft2?: InputMaybe<Scalars['Float']>
  gripRight1?: InputMaybe<Scalars['Float']>
  gripRight2?: InputMaybe<Scalars['Float']>
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
}

/** # HandballThrowInput */
export type HandballThrowInput = {
  handballThrow1?: InputMaybe<Scalars['Float']>
  handballThrow2?: InputMaybe<Scalars['Float']>
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
}

export type HandballThrowResult = {
  __typename?: 'HandballThrowResult'
  comment?: Maybe<Scalars['String']>
  handballThrow1?: Maybe<Scalars['Float']>
  handballThrow2?: Maybe<Scalars['Float']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['Float']>
}

/** # Hint */
export type Hint = {
  __typename?: 'Hint'
  score: Scalars['Float']
  term: Scalars['String']
}

/** # JapanSchool */
export type JapanSchool = {
  __typename?: 'JapanSchool'
  _id: Scalars['ID']
  prefectureCode: Scalars['Int']
  registered?: Maybe<Scalars['Boolean']>
  school?: Maybe<School>
  schoolAddress?: Maybe<Scalars['String']>
  schoolCategoryCode: Scalars['String']
  schoolCode: Scalars['String']
  schoolName: Scalars['String']
  schoolStatusCode?: Maybe<Scalars['Int']>
  schoolTypeCode: Scalars['Int']
}

export type JapanSchoolCategoriesResponse = {
  __typename?: 'JapanSchoolCategoriesResponse'
  count: Scalars['Int']
  data?: Maybe<Array<JapanSchoolCategory>>
  error?: Maybe<Scalars['String']>
}

/** # JapanSchoolCategory */
export type JapanSchoolCategory = {
  __typename?: 'JapanSchoolCategory'
  code: Scalars['String']
  name: Scalars['String']
}

/** # JapanSchoolType */
export type JapanSchoolType = {
  __typename?: 'JapanSchoolType'
  code: Scalars['Int']
  name: Scalars['String']
}

export type JapanSchoolTypesResponse = {
  __typename?: 'JapanSchoolTypesResponse'
  count: Scalars['Int']
  data?: Maybe<Array<JapanSchoolType>>
  error?: Maybe<Scalars['String']>
}

/** # JapanSchoolsInput */
export type JapanSchoolsInput = {
  cityName?: InputMaybe<Scalars['String']>
  districtName?: InputMaybe<Scalars['String']>
  from?: InputMaybe<Scalars['Int']>
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  prefectureCode?: InputMaybe<Scalars['Int']>
  registered?: InputMaybe<Scalars['Boolean']>
  schoolCategoryCode?: InputMaybe<Scalars['String']>
  schoolCodes?: InputMaybe<Array<Scalars['String']>>
  schoolNames?: InputMaybe<Array<Scalars['String']>>
  schoolTypeCode?: InputMaybe<Scalars['Int']>
}

export type JapanSchoolsResponse = {
  __typename?: 'JapanSchoolsResponse'
  count: Scalars['Int']
  data: Array<JapanSchool>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

export type LocalGovernment = {
  __typename?: 'LocalGovernment'
  _id: Scalars['ID']
  attributes: LocalGovernmentAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type LocalGovernmentAttributes = {
  __typename?: 'LocalGovernmentAttributes'
  name: Scalars['String']
  nameHiragana: Scalars['String']
}

export type MeResponse = {
  __typename?: 'MeResponse'
  data?: Maybe<User>
  error?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

/** MetaStudent */
export type MetaStudent = {
  __typename?: 'MetaStudent'
  attendanceNumber: Scalars['Int']
  username: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** # Analytics */
  analyticsEvent: Scalars['Boolean']
  analyticsEvents: Scalars['Boolean']
  /**
   * # General
   *  done
   */
  auth?: Maybe<AuthResponse>
  /**
   * # Admin
   *  done
   */
  createSchool?: Maybe<OperationResponse>
  /**
   * # DEV
   *  done
   */
  deleteUser?: Maybe<OperationResponse>
  /** done */
  linkSchoolTestYear?: Maybe<OperationResponse>
  /** done */
  registerGrade?: Maybe<OperationResponse>
  /** done */
  registerUser?: Maybe<MeResponse>
  schoolAddStudent?: Maybe<OperationResponse>
  schoolEditStudent?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentBendingTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentEnduranceRunTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentGripTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentHandballThrowTest?: Maybe<OperationResponse>
  schoolSubmitStudentQuestionnaireV2?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentQuestionnarie?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentShuttleRunTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentSideJumpTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentSitUpsTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentSprintRunTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentStandingJumpTest?: Maybe<OperationResponse>
  /** done */
  schoolSubmitStudentTest?: Maybe<OperationResponse>
  /** todo */
  schoolUnlinkStudent?: Maybe<OperationResponse>
  /**
   * # SchoolAdmin
   *  todo
   */
  schoolUnlinkTeacher?: Maybe<OperationResponse>
  /**
   * # Student
   *  done
   */
  studentLinkSchool?: Maybe<StudentLinkSchoolResponse>
  /** done */
  studentSubmitQuestionnaireV2?: Maybe<OperationResponse>
  /** done */
  studentSubmitQuestionnarie?: Maybe<OperationResponse>
  /**
   * # SchoolAdmin, Teacher
   *  done
   */
  teacherLinkSchool?: Maybe<OperationResponse>
  /** done */
  updateSchool?: Maybe<OperationResponse>
  /** # SchoolAdmin */
  updateTeacherRole?: Maybe<OperationResponse>
  /**
   * # Me
   *  done
   */
  updateUser?: Maybe<MeResponse>
}

export type MutationAnalyticsEventArgs = {
  input: Scalars['JSON']
}

export type MutationAnalyticsEventsArgs = {
  input: Array<Scalars['JSON']>
}

export type MutationAuthArgs = {
  uid: Scalars['String']
}

export type MutationCreateSchoolArgs = {
  input: CreateSchoolInput
}

export type MutationLinkSchoolTestYearArgs = {
  input: SchoolLinkTestYearInput
}

export type MutationRegisterGradeArgs = {
  input: RegisterSchoolGradeInput
}

export type MutationRegisterUserArgs = {
  input: RegisterUserInput
}

export type MutationSchoolAddStudentArgs = {
  input: SchoolAddStudentInput
}

export type MutationSchoolEditStudentArgs = {
  input: SchoolEditStudentInput
}

export type MutationSchoolSubmitStudentBendingTestArgs = {
  input: SchoolSubmitStudentBendingTestInput
}

export type MutationSchoolSubmitStudentEnduranceRunTestArgs = {
  input: SchoolSubmitStudentEnduranceRunTestInput
}

export type MutationSchoolSubmitStudentGripTestArgs = {
  input: SchoolSubmitStudentGripTestInput
}

export type MutationSchoolSubmitStudentHandballThrowTestArgs = {
  input: SchoolSubmitStudentHandballThrowTestInput
}

export type MutationSchoolSubmitStudentQuestionnaireV2Args = {
  input: SchoolSubmitStudentQuestionnaireV2Input
}

export type MutationSchoolSubmitStudentQuestionnarieArgs = {
  input: SchoolSubmitStudentQuestionnarieInput
}

export type MutationSchoolSubmitStudentShuttleRunTestArgs = {
  input: SchoolSubmitStudentShuttleRunTestInput
}

export type MutationSchoolSubmitStudentSideJumpTestArgs = {
  input: SchoolSubmitStudentSideJumpTestInput
}

export type MutationSchoolSubmitStudentSitUpsTestArgs = {
  input: SchoolSubmitStudentSitUpsTestInput
}

export type MutationSchoolSubmitStudentSprintRunTestArgs = {
  input: SchoolSubmitStudentSprintRunTestInput
}

export type MutationSchoolSubmitStudentStandingJumpTestArgs = {
  input: SchoolSubmitStudentStandingJumpTestInput
}

export type MutationSchoolSubmitStudentTestArgs = {
  input: SchoolSubmitStudentTestInput
}

export type MutationSchoolUnlinkStudentArgs = {
  input: SchoolUnlinkStudentInput
}

export type MutationSchoolUnlinkTeacherArgs = {
  input: SchoolUnlinkTeacherInput
}

export type MutationStudentLinkSchoolArgs = {
  input: StudentLinkSchoolInput
}

export type MutationStudentSubmitQuestionnaireV2Args = {
  input: StudentQuestionnaireV2Input
}

export type MutationStudentSubmitQuestionnarieArgs = {
  input: StudentQuestionnarieInput
}

export type MutationTeacherLinkSchoolArgs = {
  input: TeacherLinkSchoolInput
}

export type MutationUpdateSchoolArgs = {
  input: UpdateSchoolInput
}

export type MutationUpdateTeacherRoleArgs = {
  input: UpdateTeacherRoleInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

/** # OperationResponse */
export type OperationResponse = {
  __typename?: 'OperationResponse'
  error?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

/** # PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor: Scalars['String']
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor: Scalars['String']
}

/** # PostalCode */
export type PostalCode = {
  __typename?: 'PostalCode'
  _id: Scalars['ID']
  cityName: Scalars['String']
  districtName: Scalars['String']
  groupCode: Scalars['Int']
  oldPostalCode: Scalars['Int']
  postalCode: Scalars['Int']
  prefectureName: Scalars['String']
}

/** # PostalCodesInput */
export type PostalCodesInput = {
  cityName?: InputMaybe<Scalars['String']>
  districtName?: InputMaybe<Scalars['String']>
  from?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  postalCode?: InputMaybe<Scalars['Int']>
  prefectureName?: InputMaybe<Scalars['String']>
}

export type PostalCodesResponse = {
  __typename?: 'PostalCodesResponse'
  count: Scalars['Int']
  data: Array<PostalCode>
  error?: Maybe<Scalars['String']>
}

/** # Prefecture */
export type Prefecture = {
  __typename?: 'Prefecture'
  _id: Scalars['ID']
  area: Scalars['JSON']
  code: Scalars['Int']
  name: Scalars['String']
  name_en: Scalars['String']
  name_hiragana: Scalars['String']
}

/** # PrefecturesInput */
export type PrefecturesInput = {
  areaId?: InputMaybe<Scalars['String']>
  codes?: InputMaybe<Array<Scalars['Int']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  names?: InputMaybe<Array<Scalars['String']>>
}

export type PrefecturesResponse = {
  __typename?: 'PrefecturesResponse'
  count: Scalars['Int']
  data: Array<Prefecture>
  error?: Maybe<Scalars['String']>
}

export type QrCodeStudentInput = {
  qrCode: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  /**
   * # General
   *  done
   */
  areas?: Maybe<AreasResponse>
  /** done */
  cities?: Maybe<CitiesResponse>
  /**
   * SchoolAdmin, Teacher
   * done
   */
  grades?: Maybe<SchoolGradesResponse>
  /** done */
  japanSchoolCategories?: Maybe<JapanSchoolCategoriesResponse>
  /** done */
  japanSchoolTypes?: Maybe<JapanSchoolTypesResponse>
  /** done */
  japanSchools?: Maybe<JapanSchoolsResponse>
  /**
   * # Me
   *  done
   */
  me?: Maybe<MeResponse>
  meCurrentSchool?: Maybe<SchoolsResponse>
  meSchools?: Maybe<SchoolsResponse>
  /** done */
  postalCodes?: Maybe<PostalCodesResponse>
  /** done */
  prefectures?: Maybe<PrefecturesResponse>
  /**
   * # kid
   *  done
   */
  qrCodeStudent?: Maybe<UserResponse>
  /** done */
  reportAverageScoreGraphSummary?: Maybe<ReportAverageScoreGraphSummaryResponse>
  /** done */
  reportBodySummary?: Maybe<ReportBodySummaryResponse>
  /**
   * # Reports
   *  done
   */
  reportCountSummary?: Maybe<ReportCountSummaryResponse>
  /** done */
  reportCrossAnalysisSummary?: Maybe<ReportCrossAnalysisSummaryResponse>
  /** done */
  reportQuestionnaireSummary?: Maybe<ReportQuestionnaireSummaryResponse>
  /** done */
  reportSchoolPerformanceSummary?: Maybe<ReportSchoolPerformanceSummaryResponse>
  /** done */
  reportSchoolProgressSummary?: Maybe<ReportSchoolProgressSummaryResponse>
  /** done */
  reportTestComparisonSummary?: Maybe<ReportTestComparisonSummaryResponse>
  /** done */
  reportTestRankSummary?: Maybe<ReportTestRankSummaryResponse>
  /** done */
  reportTestRankTargetSummary?: Maybe<ReportTestRankTargetSummaryResponse>
  /** done */
  reportTestScoreChartSummary?: Maybe<ReportTestScoreChartSummaryResponse>
  /** done */
  reportTestSummary?: Maybe<ReportTestSummaryResponse>
  /** done */
  reportUploadList?: Maybe<ReportUploadListResponse>
  /** done */
  schoolReportTestScoreChartSummary?: Maybe<SchoolReportTestScoreChartSummaryResponse>
  /** done */
  schoolReportTestSummary?: Maybe<SchoolReportTestSummaryResponse>
  /** done */
  schools?: Maybe<SchoolsResponse>
  /** done */
  searchAreasHints?: Maybe<SearchHintsResponse>
  /** done */
  searchCitiesHints?: Maybe<SearchHintsResponse>
  /**
   * Admin
   * done
   */
  searchJapanSchoolsHints?: Maybe<SearchHintsResponse>
  /** done */
  searchPrefecturesHints?: Maybe<SearchHintsResponse>
  searchStudent?: Maybe<UserResponse>
  searchTeacher?: Maybe<UserResponse>
  sport?: Maybe<SportsResponse>
  sports?: Maybe<SportsResponse>
  /** done */
  studentInvitationCodes?: Maybe<StudentInvitationCodesResponse>
  /** done */
  students?: Maybe<UsersResponse>
  /** done */
  teachers?: Maybe<UsersResponse>
}

export type QueryAreasArgs = {
  input?: InputMaybe<AreasInput>
}

export type QueryCitiesArgs = {
  input: CitiesInput
}

export type QueryGradesArgs = {
  input: SchoolGradesInput
}

export type QueryJapanSchoolsArgs = {
  input: JapanSchoolsInput
}

export type QueryPostalCodesArgs = {
  input: PostalCodesInput
}

export type QueryPrefecturesArgs = {
  input?: InputMaybe<PrefecturesInput>
}

export type QueryQrCodeStudentArgs = {
  input: QrCodeStudentInput
}

export type QueryReportAverageScoreGraphSummaryArgs = {
  input: ReportAverageScoreGraphSummaryInput
}

export type QueryReportBodySummaryArgs = {
  input: ReportBodySummaryInput
}

export type QueryReportCountSummaryArgs = {
  input: ReportCountSummaryInput
}

export type QueryReportCrossAnalysisSummaryArgs = {
  input: ReportCrossAnalysisSummaryInput
}

export type QueryReportQuestionnaireSummaryArgs = {
  input: ReportQuestionnaireSummaryInput
}

export type QueryReportSchoolPerformanceSummaryArgs = {
  input: ReportSchoolPerformanceSummaryInput
}

export type QueryReportSchoolProgressSummaryArgs = {
  input: ReportSchoolProgressSummaryInput
}

export type QueryReportTestComparisonSummaryArgs = {
  input: ReportTestComparisonSummaryInput
}

export type QueryReportTestRankSummaryArgs = {
  input: ReportTestRankSummaryInput
}

export type QueryReportTestRankTargetSummaryArgs = {
  input: ReportTestRankTargetSummaryInput
}

export type QueryReportTestScoreChartSummaryArgs = {
  input: ReportTestScoreChartSummaryInput
}

export type QueryReportTestSummaryArgs = {
  input: ReportTestSummaryInput
}

export type QueryReportUploadListArgs = {
  input: ReportUploadListInput
}

export type QuerySchoolReportTestScoreChartSummaryArgs = {
  input: SchoolReportTestScoreChartSummaryInput
}

export type QuerySchoolReportTestSummaryArgs = {
  input: SchoolReportTestSummaryInput
}

export type QuerySchoolsArgs = {
  input: SchoolsInput
}

export type QuerySearchAreasHintsArgs = {
  input: SearchHintsInput
}

export type QuerySearchCitiesHintsArgs = {
  input: SearchCitiesHintsInput
}

export type QuerySearchJapanSchoolsHintsArgs = {
  input: SearchJapanSchoolsHintsInput
}

export type QuerySearchPrefecturesHintsArgs = {
  input: SearchPrefecturesHintsInput
}

export type QuerySportArgs = {
  input: SportInput
}

export type QuerySportsArgs = {
  input: SportsInput
}

export type QueryStudentInvitationCodesArgs = {
  input: StudentInvitationCodesInput
}

export type QueryStudentsArgs = {
  input: StudentsInput
}

export type QueryTeachersArgs = {
  input: TeachersInput
}

export type QuestionnaireResponse = {
  __typename?: 'QuestionnaireResponse'
  k: Scalars['Int']
  v: Scalars['Int']
}

export type QuestionnaireResult = {
  __typename?: 'QuestionnaireResult'
  belongsToSportsClub?: Maybe<Scalars['Int']>
  breakfast?: Maybe<Scalars['Int']>
  dailyExcerciseTime?: Maybe<Scalars['Int']>
  excerciseActivity?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Float']>
  sittingHeight?: Maybe<Scalars['Float']>
  sleepTime?: Maybe<Scalars['Int']>
  tvTime?: Maybe<Scalars['Int']>
  weight?: Maybe<Scalars['Float']>
}

export type QuestionnaireV2Question = {
  __typename?: 'QuestionnaireV2Question'
  options: Array<Scalars['String']>
  question: Scalars['String']
}

/** # QuestionnaireV2QuestionInput */
export type QuestionnaireV2QuestionInput = {
  options: Array<Scalars['String']>
  question: Scalars['String']
}

export type QuestionnaireV2Result = {
  __typename?: 'QuestionnaireV2Result'
  answers?: Maybe<Array<Scalars['Int']>>
  height?: Maybe<Scalars['Float']>
  sittingHeight?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

export type QuestionnaireV3Result = {
  __typename?: 'QuestionnaireV3Result'
  q1?: Maybe<Scalars['Int']>
  q2?: Maybe<Scalars['Int']>
  q3?: Maybe<Scalars['Int']>
  q4?: Maybe<Scalars['String']>
  q5?: Maybe<Scalars['String']>
  q6?: Maybe<Scalars['Int']>
  q7?: Maybe<Scalars['Int']>
  q8?: Maybe<Scalars['Int']>
  q9?: Maybe<Scalars['Int']>
  q10?: Maybe<Scalars['Int']>
  q11?: Maybe<Scalars['Int']>
  q12?: Maybe<Scalars['Int']>
  q13?: Maybe<Scalars['Int']>
  q14?: Maybe<Scalars['Int']>
  q15?: Maybe<Scalars['Int']>
  q16?: Maybe<Scalars['Int']>
  q17?: Maybe<Scalars['Int']>
  q18?: Maybe<Scalars['Int']>
  q19?: Maybe<Scalars['Int']>
  q20?: Maybe<Scalars['String']>
}

/** # RegisterSchoolGradeInput */
export type RegisterSchoolGradeInput = {
  isEnduranceRunInputActive?: InputMaybe<Scalars['Boolean']>
  numberOfStudentsPerClass: Array<Scalars['Int']>
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # RegisterUserInput */
export type RegisterUserInput = {
  attributes: UserAttributesInput
  uid: Scalars['String']
}

export type ReportAverageScoreGraph = {
  __typename?: 'ReportAverageScoreGraph'
  bending?: Maybe<Scalars['Float']>
  grip?: Maybe<Scalars['Float']>
  handballThrow?: Maybe<Scalars['Float']>
  shuttleRun?: Maybe<Scalars['Float']>
  sideJump?: Maybe<Scalars['Float']>
  sitUps?: Maybe<Scalars['Float']>
  sprintRun?: Maybe<Scalars['Float']>
  standingJump?: Maybe<Scalars['Float']>
  totalScore?: Maybe<Scalars['Float']>
}

export type ReportAverageScoreGraphByCategory = {
  __typename?: 'ReportAverageScoreGraphByCategory'
  reports?: Maybe<Array<ReportAverageScoreGraphByYear>>
}

export type ReportAverageScoreGraphByGrade = {
  __typename?: 'ReportAverageScoreGraphByGrade'
  grade?: Maybe<Scalars['Int']>
  score?: Maybe<ReportAverageScoreGraph>
}

export type ReportAverageScoreGraphByYear = {
  __typename?: 'ReportAverageScoreGraphByYear'
  grades?: Maybe<Array<ReportAverageScoreGraphByGrade>>
  year?: Maybe<Scalars['Int']>
}

export type ReportAverageScoreGraphSummary = {
  __typename?: 'ReportAverageScoreGraphSummary'
  elementarySchoolReport?: Maybe<ReportAverageScoreGraphByCategory>
  highSchoolReport?: Maybe<ReportAverageScoreGraphByCategory>
  juniorHighSchoolReport?: Maybe<ReportAverageScoreGraphByCategory>
}

export type ReportAverageScoreGraphSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
}

/** # ReportAverageScoreGraphSummary */
export type ReportAverageScoreGraphSummaryResponse = {
  __typename?: 'ReportAverageScoreGraphSummaryResponse'
  data?: Maybe<ReportAverageScoreGraphSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportBody = {
  __typename?: 'ReportBody'
  grade?: Maybe<Scalars['Int']>
  heightAvg?: Maybe<Scalars['Float']>
  heightDev?: Maybe<Scalars['Float']>
  sampleCount?: Maybe<Scalars['Int']>
  weightAvg?: Maybe<Scalars['Float']>
  weightDev?: Maybe<Scalars['Float']>
}

export type ReportBodyByCategory = {
  __typename?: 'ReportBodyByCategory'
  reports?: Maybe<Array<ReportBody>>
}

export type ReportBodySummary = {
  __typename?: 'ReportBodySummary'
  allDayHighSchoolReport?: Maybe<ReportBodyByCategory>
  elementarySchoolReport?: Maybe<ReportBodyByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportBodyByCategory>
  juniorHighSchoolReport?: Maybe<ReportBodyByCategory>
}

export type ReportBodySummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  cityName?: InputMaybe<Scalars['String']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportBodySummary */
export type ReportBodySummaryResponse = {
  __typename?: 'ReportBodySummaryResponse'
  data?: Maybe<ReportBodySummary>
  error?: Maybe<Scalars['String']>
}

export type ReportCount = {
  __typename?: 'ReportCount'
  samplingCount?: Maybe<Scalars['Int']>
  totalCount?: Maybe<Scalars['Int']>
}

export type ReportCountByCategory = {
  __typename?: 'ReportCountByCategory'
  reports?: Maybe<Array<ReportCountByGrade>>
  schoolCount?: Maybe<Scalars['Int']>
}

export type ReportCountByGrade = {
  __typename?: 'ReportCountByGrade'
  female?: Maybe<ReportCount>
  grade?: Maybe<Scalars['Int']>
  male?: Maybe<ReportCount>
}

export type ReportCountSummary = {
  __typename?: 'ReportCountSummary'
  allDayHighSchoolReport?: Maybe<ReportCountByCategory>
  elementarySchoolReport?: Maybe<ReportCountByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportCountByCategory>
  juniorHighSchoolReport?: Maybe<ReportCountByCategory>
}

export type ReportCountSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  cityName?: InputMaybe<Scalars['String']>
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportCountSummary */
export type ReportCountSummaryResponse = {
  __typename?: 'ReportCountSummaryResponse'
  data?: Maybe<ReportCountSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportCrossAnalysisByAnswer = {
  __typename?: 'ReportCrossAnalysisByAnswer'
  answer?: Maybe<Scalars['Int']>
  averages?: Maybe<Array<ReportScore>>
  deviations?: Maybe<Array<ReportScore>>
  totalCount?: Maybe<Scalars['Int']>
}

export type ReportCrossAnalysisByGrade = {
  __typename?: 'ReportCrossAnalysisByGrade'
  answers?: Maybe<Array<ReportCrossAnalysisByAnswer>>
  grade?: Maybe<Scalars['Int']>
  schoolCategory?: Maybe<Scalars['String']>
}

export type ReportCrossAnalysisByQuestionnaire = {
  __typename?: 'ReportCrossAnalysisByQuestionnaire'
  dataKey?: Maybe<Scalars['String']>
  grades?: Maybe<Array<ReportCrossAnalysisByGrade>>
}

export type ReportCrossAnalysisSummary = {
  __typename?: 'ReportCrossAnalysisSummary'
  reports?: Maybe<Array<ReportCrossAnalysisByQuestionnaire>>
}

export type ReportCrossAnalysisSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportCrossAnalysisSummary */
export type ReportCrossAnalysisSummaryResponse = {
  __typename?: 'ReportCrossAnalysisSummaryResponse'
  data?: Maybe<ReportCrossAnalysisSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportQuestionnaire = {
  __typename?: 'ReportQuestionnaire'
  q1?: Maybe<Array<QuestionnaireResponse>>
  q2?: Maybe<Array<QuestionnaireResponse>>
  q3?: Maybe<Array<QuestionnaireResponse>>
  q4?: Maybe<Array<QuestionnaireResponse>>
  q5?: Maybe<Array<QuestionnaireResponse>>
  q6?: Maybe<Array<QuestionnaireResponse>>
}

export type ReportQuestionnaireByCategory = {
  __typename?: 'ReportQuestionnaireByCategory'
  reports?: Maybe<Array<ReportQuestionnaireByGrade>>
}

export type ReportQuestionnaireByGrade = {
  __typename?: 'ReportQuestionnaireByGrade'
  countByAnswer?: Maybe<ReportQuestionnaire>
  grade?: Maybe<Scalars['Int']>
}

export type ReportQuestionnaireSummary = {
  __typename?: 'ReportQuestionnaireSummary'
  elementarySchoolReport?: Maybe<ReportQuestionnaireByCategory>
  highSchoolReport?: Maybe<ReportQuestionnaireByCategory>
  juniorHighSchoolReport?: Maybe<ReportQuestionnaireByCategory>
}

export type ReportQuestionnaireSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportQuestionnaireSummary */
export type ReportQuestionnaireSummaryResponse = {
  __typename?: 'ReportQuestionnaireSummaryResponse'
  data?: Maybe<ReportQuestionnaireSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportSchoolEntry = {
  __typename?: 'ReportSchoolEntry'
  cityName?: Maybe<Scalars['String']>
  organizationName?: Maybe<Scalars['String']>
  prefectureSchoolCode?: Maybe<Scalars['String']>
  schoolName?: Maybe<Scalars['String']>
  studentCount?: Maybe<Scalars['Int']>
  uploadedAt?: Maybe<Scalars['DateTime']>
}

export type ReportSchoolPerformance = {
  __typename?: 'ReportSchoolPerformance'
  prefectureSchoolCode?: Maybe<Scalars['Int']>
  scale?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  studentCount?: Maybe<Scalars['Int']>
}

export type ReportSchoolPerformanceSummary = {
  __typename?: 'ReportSchoolPerformanceSummary'
  elementarySchool?: Maybe<Array<ReportSchoolPerformance>>
  highSchool?: Maybe<Array<ReportSchoolPerformance>>
  juniorHighSchool?: Maybe<Array<ReportSchoolPerformance>>
}

export type ReportSchoolPerformanceSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
  year: Scalars['Int']
}

/** # ReportSchoolPerformanceSummary */
export type ReportSchoolPerformanceSummaryResponse = {
  __typename?: 'ReportSchoolPerformanceSummaryResponse'
  data?: Maybe<ReportSchoolPerformanceSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportSchoolProgress = {
  __typename?: 'ReportSchoolProgress'
  prefectureSchoolCode?: Maybe<Scalars['Int']>
  scale?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  studentCount?: Maybe<Scalars['Int']>
}

export type ReportSchoolProgressSummary = {
  __typename?: 'ReportSchoolProgressSummary'
  elementarySchool?: Maybe<Array<ReportSchoolProgress>>
  highSchool?: Maybe<Array<ReportSchoolProgress>>
  juniorHighSchool?: Maybe<Array<ReportSchoolProgress>>
}

export type ReportSchoolProgressSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
  year: Scalars['Int']
}

/** # ReportSchoolProgressSummary */
export type ReportSchoolProgressSummaryResponse = {
  __typename?: 'ReportSchoolProgressSummaryResponse'
  data?: Maybe<ReportSchoolProgressSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportScore = {
  __typename?: 'ReportScore'
  score?: Maybe<Scalars['Float']>
  scoreType?: Maybe<Scalars['String']>
}

export type ReportTest = {
  __typename?: 'ReportTest'
  bending?: Maybe<Scalars['Float']>
  grip?: Maybe<Scalars['Float']>
  handballThrow?: Maybe<Scalars['Float']>
  shuttleRun?: Maybe<Scalars['Float']>
  sideJump?: Maybe<Scalars['Float']>
  sitUps?: Maybe<Scalars['Float']>
  sprintRun?: Maybe<Scalars['Float']>
  standingJump?: Maybe<Scalars['Float']>
  totalScore?: Maybe<Scalars['Float']>
}

export type ReportTestByCategory = {
  __typename?: 'ReportTestByCategory'
  reports?: Maybe<Array<ReportTestByGrade>>
}

export type ReportTestByGrade = {
  __typename?: 'ReportTestByGrade'
  average?: Maybe<ReportTest>
  deviation?: Maybe<ReportTest>
  grade?: Maybe<Scalars['Int']>
}

export type ReportTestComparison = {
  __typename?: 'ReportTestComparison'
  bending?: Maybe<Scalars['Boolean']>
  grip?: Maybe<Scalars['Boolean']>
  handballThrow?: Maybe<Scalars['Boolean']>
  shuttleRun?: Maybe<Scalars['Boolean']>
  sideJump?: Maybe<Scalars['Boolean']>
  sitUps?: Maybe<Scalars['Boolean']>
  sprintRun?: Maybe<Scalars['Boolean']>
  standingJump?: Maybe<Scalars['Boolean']>
  totalScore?: Maybe<Scalars['Boolean']>
}

export type ReportTestComparisonByCategory = {
  __typename?: 'ReportTestComparisonByCategory'
  reports?: Maybe<Array<ReportTestComparisonByGrade>>
}

export type ReportTestComparisonByGrade = {
  __typename?: 'ReportTestComparisonByGrade'
  grade?: Maybe<Scalars['Int']>
  national?: Maybe<ReportTestComparison>
  prefecture?: Maybe<ReportTestComparison>
}

export type ReportTestComparisonSummary = {
  __typename?: 'ReportTestComparisonSummary'
  elementarySchoolReport?: Maybe<ReportTestComparisonByCategory>
  highSchoolReport?: Maybe<ReportTestComparisonByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestComparisonByCategory>
}

export type ReportTestComparisonSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestComparisonSummary */
export type ReportTestComparisonSummaryResponse = {
  __typename?: 'ReportTestComparisonSummaryResponse'
  data?: Maybe<ReportTestComparisonSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestRank = {
  __typename?: 'ReportTestRank'
  rankA?: Maybe<Scalars['Float']>
  rankB?: Maybe<Scalars['Float']>
  rankC?: Maybe<Scalars['Float']>
  rankD?: Maybe<Scalars['Float']>
  rankE?: Maybe<Scalars['Float']>
}

export type ReportTestRankByCategory = {
  __typename?: 'ReportTestRankByCategory'
  reports?: Maybe<Array<ReportTestRankByGrade>>
}

export type ReportTestRankByGrade = {
  __typename?: 'ReportTestRankByGrade'
  countByRank?: Maybe<ReportTestRank>
  grade?: Maybe<Scalars['Int']>
  rateByRank?: Maybe<ReportTestRank>
  totalCount?: Maybe<Scalars['Int']>
}

export type ReportTestRankSummary = {
  __typename?: 'ReportTestRankSummary'
  allDayHighSchoolReport?: Maybe<ReportTestRankByCategory>
  elementarySchoolReport?: Maybe<ReportTestRankByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportTestRankByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestRankByCategory>
}

export type ReportTestRankSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestRankSummary */
export type ReportTestRankSummaryResponse = {
  __typename?: 'ReportTestRankSummaryResponse'
  data?: Maybe<ReportTestRankSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestRankTargetByYear = {
  __typename?: 'ReportTestRankTargetByYear'
  elementarySchoolRates?: Maybe<Array<Scalars['Float']>>
  highSchoolRates?: Maybe<Array<Scalars['Float']>>
  juniorHighSchoolRates?: Maybe<Array<Scalars['Float']>>
  totalRates?: Maybe<Array<Scalars['Float']>>
  year?: Maybe<Scalars['Int']>
}

export type ReportTestRankTargetSummary = {
  __typename?: 'ReportTestRankTargetSummary'
  national?: Maybe<Array<ReportTestRankTargetByYear>>
  reports?: Maybe<Array<ReportTestRankTargetByYear>>
}

export type ReportTestRankTargetSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
}

/** # ReportTestRankTargetSummary */
export type ReportTestRankTargetSummaryResponse = {
  __typename?: 'ReportTestRankTargetSummaryResponse'
  data?: Maybe<ReportTestRankTargetSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestScoreChart = {
  __typename?: 'ReportTestScoreChart'
  bending?: Maybe<Scalars['Float']>
  grip?: Maybe<Scalars['Float']>
  handballThrow?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
  shuttleRun?: Maybe<Scalars['Float']>
  sideJump?: Maybe<Scalars['Float']>
  sitUps?: Maybe<Scalars['Float']>
  sprintRun?: Maybe<Scalars['Float']>
  standingJump?: Maybe<Scalars['Float']>
  totalScore?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

export type ReportTestScoreChartByCategory = {
  __typename?: 'ReportTestScoreChartByCategory'
  reports?: Maybe<Array<ReportTestScoreChartByGrade>>
}

export type ReportTestScoreChartByGrade = {
  __typename?: 'ReportTestScoreChartByGrade'
  grade?: Maybe<Scalars['Int']>
  prefecture?: Maybe<ReportTestScoreChart>
}

export type ReportTestScoreChartSummary = {
  __typename?: 'ReportTestScoreChartSummary'
  elementarySchoolReport?: Maybe<ReportTestScoreChartByCategory>
  highSchoolReport?: Maybe<ReportTestScoreChartByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestScoreChartByCategory>
}

export type ReportTestScoreChartSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestScoreChartSummary */
export type ReportTestScoreChartSummaryResponse = {
  __typename?: 'ReportTestScoreChartSummaryResponse'
  data?: Maybe<ReportTestScoreChartSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportTestSummary = {
  __typename?: 'ReportTestSummary'
  allDayHighSchoolReport?: Maybe<ReportTestByCategory>
  elementarySchoolReport?: Maybe<ReportTestByCategory>
  fixTimeHighSchoolReport?: Maybe<ReportTestByCategory>
  juniorHighSchoolReport?: Maybe<ReportTestByCategory>
}

export type ReportTestSummaryInput = {
  cityCode?: InputMaybe<Scalars['Int']>
  cityName?: InputMaybe<Scalars['String']>
  gender: Scalars['Int']
  prefectureCode: Scalars['Int']
  schoolCode?: InputMaybe<Scalars['String']>
  year: Scalars['Int']
}

/** # ReportTestSummary */
export type ReportTestSummaryResponse = {
  __typename?: 'ReportTestSummaryResponse'
  data?: Maybe<ReportTestSummary>
  error?: Maybe<Scalars['String']>
}

export type ReportUploadListInput = {
  from?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  prefectureCode: Scalars['Int']
  year: Scalars['Int']
}

/** # ReportUploadList */
export type ReportUploadListResponse = {
  __typename?: 'ReportUploadListResponse'
  count: Scalars['Int']
  data?: Maybe<Array<ReportSchoolEntry>>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

/** # School */
export type School = {
  __typename?: 'School'
  _id: Scalars['ID']
  attributes: SchoolAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: SchoolRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SchoolAddStudentInput = {
  attributes: UserAttributesInput
  schoolAttendanceNumber: Scalars['Int']
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolAttributes */
export type SchoolAttributes = {
  __typename?: 'SchoolAttributes'
  cityName?: Maybe<Scalars['String']>
  districtName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  gradeAdminInvitationCode?: Maybe<Scalars['String']>
  isEnduranceRunInputActive?: Maybe<Scalars['Boolean']>
  isStudentInputActive?: Maybe<Scalars['Boolean']>
  onboardComplete?: Maybe<Scalars['Boolean']>
  otherSchoolCode?: Maybe<Scalars['String']>
  prefectureCode: Scalars['Int']
  questions?: Maybe<Array<QuestionnaireV2Question>>
  schoolAddress?: Maybe<Scalars['String']>
  schoolAdminEmail?: Maybe<Scalars['String']>
  schoolAdminInvitationCode?: Maybe<Scalars['String']>
  schoolAdminName?: Maybe<Scalars['String']>
  schoolCategoryCode: Scalars['String']
  schoolCode: Scalars['String']
  schoolName: Scalars['String']
  schoolTypeCode: Scalars['Int']
  teacherInvitationCode?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  testYears?: Maybe<Array<Scalars['Int']>>
  website?: Maybe<Scalars['String']>
}

/** # SchoolAttributesInput */
export type SchoolAttributesInput = {
  email?: InputMaybe<Scalars['String']>
  fax?: InputMaybe<Scalars['String']>
  isEnduranceRunInputActive?: InputMaybe<Scalars['Boolean']>
  isStudentInputActive?: InputMaybe<Scalars['Boolean']>
  otherSchoolCode?: InputMaybe<Scalars['String']>
  questions?: InputMaybe<Array<QuestionnaireV2QuestionInput>>
  schoolAdminEmail?: InputMaybe<Scalars['String']>
  schoolAdminName?: InputMaybe<Scalars['String']>
  telephone?: InputMaybe<Scalars['String']>
  website?: InputMaybe<Scalars['String']>
}

export enum SchoolCategory {
  ElementarySchool = 'ELEMENTARY_SCHOOL',
  HighSchool = 'HIGH_SCHOOL',
  JuniorHighSchool = 'JUNIOR_HIGH_SCHOOL',
  Other = 'OTHER',
}

export type SchoolClass = {
  __typename?: 'SchoolClass'
  _id: Scalars['ID']
  attributes: SchoolClassAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: SchoolClassRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SchoolClassAttributes = {
  __typename?: 'SchoolClassAttributes'
  prefecture: Scalars['String']
  schoolName: Scalars['String']
}

export type SchoolClassConnection = {
  __typename?: 'SchoolClassConnection'
  edges: Array<SchoolClassEdge>
  pageInfo: PageInfo
}

export type SchoolClassEdge = {
  __typename?: 'SchoolClassEdge'
  cursor: Scalars['String']
  node: SchoolClass
}

export type SchoolClassRelationships = {
  __typename?: 'SchoolClassRelationships'
  foo?: Maybe<Scalars['String']>
}

export type SchoolEditStudentInput = {
  attributes: UserAttributesInput
  schoolAttendanceNumber: Scalars['Int']
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolGrade */
export type SchoolGrade = {
  __typename?: 'SchoolGrade'
  isEnduranceRunInputActive?: Maybe<Scalars['Boolean']>
  numberOfClasses: Scalars['Int']
  numberOfStudentsPerClass: Array<Scalars['Int']>
  schoolGrade: Scalars['Int']
  testYear: Scalars['Int']
}

/** # SchoolGradesInput */
export type SchoolGradesInput = {
  schoolGrade?: InputMaybe<Scalars['Int']>
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolGradesResponse */
export type SchoolGradesResponse = {
  __typename?: 'SchoolGradesResponse'
  count: Scalars['Int']
  data: Array<SchoolGrade>
  error?: Maybe<Scalars['String']>
}

/** # SchoolInvitationCode */
export type SchoolInvitationCode = {
  __typename?: 'SchoolInvitationCode'
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  students?: Maybe<Array<Maybe<MetaStudent>>>
  testYear: Scalars['Int']
}

export enum SchoolInvitationCodeType {
  GradeAdmin = 'GRADE_ADMIN',
  SchoolAdmin = 'SCHOOL_ADMIN',
  Teacher = 'TEACHER',
}

/** # SchoolLinkTestYearInput */
export type SchoolLinkTestYearInput = {
  schoolId: Scalars['String']
  testYear: Scalars['Int']
}

/** # SchoolRelationships */
export type SchoolRelationships = {
  __typename?: 'SchoolRelationships'
  japanSchool?: Maybe<JapanSchool>
  students?: Maybe<Array<User>>
  teachers?: Maybe<Array<User>>
}

export type SchoolReportTestByGrade = {
  __typename?: 'SchoolReportTestByGrade'
  bending_avg?: Maybe<Scalars['Float']>
  bending_std?: Maybe<Scalars['Float']>
  count?: Maybe<Scalars['Int']>
  grade?: Maybe<Scalars['Int']>
  grip_avg?: Maybe<Scalars['Float']>
  grip_std?: Maybe<Scalars['Float']>
  handballThrow_avg?: Maybe<Scalars['Float']>
  handballThrow_std?: Maybe<Scalars['Float']>
  height_avg?: Maybe<Scalars['Float']>
  height_std?: Maybe<Scalars['Float']>
  points_avg?: Maybe<Scalars['Float']>
  points_std?: Maybe<Scalars['Float']>
  rank_a?: Maybe<Scalars['Int']>
  rank_b?: Maybe<Scalars['Int']>
  rank_c?: Maybe<Scalars['Int']>
  rank_d?: Maybe<Scalars['Int']>
  rank_e?: Maybe<Scalars['Int']>
  shuttleRun_avg?: Maybe<Scalars['Float']>
  shuttleRun_std?: Maybe<Scalars['Float']>
  sideJump_avg?: Maybe<Scalars['Float']>
  sideJump_std?: Maybe<Scalars['Float']>
  sitUps_avg?: Maybe<Scalars['Float']>
  sitUps_std?: Maybe<Scalars['Float']>
  sprintRun_avg?: Maybe<Scalars['Float']>
  sprintRun_std?: Maybe<Scalars['Float']>
  standingJump_avg?: Maybe<Scalars['Float']>
  standingJump_std?: Maybe<Scalars['Float']>
  testYear?: Maybe<Scalars['Int']>
  weight_avg?: Maybe<Scalars['Float']>
  weight_std?: Maybe<Scalars['Float']>
}

export type SchoolReportTestScoreChartByGrade = {
  __typename?: 'SchoolReportTestScoreChartByGrade'
  chart?: Maybe<ReportTestScoreChart>
  grade?: Maybe<Scalars['Int']>
  testYear?: Maybe<Scalars['Int']>
}

export type SchoolReportTestScoreChartSummary = {
  __typename?: 'SchoolReportTestScoreChartSummary'
  reports?: Maybe<Array<SchoolReportTestScoreChartByGrade>>
}

export type SchoolReportTestScoreChartSummaryInput = {
  gender: Scalars['String']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolReportTestScoreChartSummary */
export type SchoolReportTestScoreChartSummaryResponse = {
  __typename?: 'SchoolReportTestScoreChartSummaryResponse'
  data?: Maybe<SchoolReportTestScoreChartSummary>
  error?: Maybe<Scalars['String']>
}

export type SchoolReportTestSummary = {
  __typename?: 'SchoolReportTestSummary'
  reports?: Maybe<Array<SchoolReportTestByGrade>>
}

export type SchoolReportTestSummaryInput = {
  gender: Scalars['String']
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolReportTestSummary */
export type SchoolReportTestSummaryResponse = {
  __typename?: 'SchoolReportTestSummaryResponse'
  data?: Maybe<SchoolReportTestSummary>
  error?: Maybe<Scalars['String']>
}

/** # SchoolResponse */
export type SchoolResponse = {
  __typename?: 'SchoolResponse'
  data?: Maybe<School>
  error?: Maybe<Scalars['String']>
}

/** # SchoolSubmitStudentBendingTestInput */
export type SchoolSubmitStudentBendingTestInput = {
  bending: BendingInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentEnduranceRunTestInput */
export type SchoolSubmitStudentEnduranceRunTestInput = {
  enduranceRun: EnduranceRunInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentGripTestInput */
export type SchoolSubmitStudentGripTestInput = {
  grip: GripTestInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentHandballThrowTestInput */
export type SchoolSubmitStudentHandballThrowTestInput = {
  handballThrow: HandballThrowInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentQuestionnaireV2Input */
export type SchoolSubmitStudentQuestionnaireV2Input = {
  questionnaire: StudentQuestionnaireV2Input
  studentId: Scalars['ID']
}

/** # SchoolSubmitStudentQuestionnarieInput */
export type SchoolSubmitStudentQuestionnarieInput = {
  questionnaire: StudentQuestionnarieInput
  studentId: Scalars['ID']
}

/** # SchoolSubmitStudentShuttleRunTestInput */
export type SchoolSubmitStudentShuttleRunTestInput = {
  shuttleRun: ShuttleRunInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentSideJumpTestInput */
export type SchoolSubmitStudentSideJumpTestInput = {
  sideJump: SideJumpInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentSitUpsTestInput */
export type SchoolSubmitStudentSitUpsTestInput = {
  sitUps: SitUpsInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentGripTestInput */
export type SchoolSubmitStudentSizeTestInput = {
  height: Scalars['Float']
  studentId: Scalars['ID']
  testYear: Scalars['Int']
  weight: Scalars['Float']
}

/** # SchoolSubmitStudentSprintRunTestInput */
export type SchoolSubmitStudentSprintRunTestInput = {
  sprintRun: SprintRunInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentStandingJumpTestInput */
export type SchoolSubmitStudentStandingJumpTestInput = {
  standingJump: StandingJumpInput
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolSubmitStudentTestInput */
export type SchoolSubmitStudentTestInput = {
  bendingTest: SchoolSubmitStudentBendingTestInput
  enduranceRunTest: SchoolSubmitStudentEnduranceRunTestInput
  gripTest: SchoolSubmitStudentGripTestInput
  handballThrowTest: SchoolSubmitStudentHandballThrowTestInput
  shuttleRunTest: SchoolSubmitStudentShuttleRunTestInput
  sideJumpTest: SchoolSubmitStudentSideJumpTestInput
  sitUpsTest: SchoolSubmitStudentSitUpsTestInput
  sizeTest: SchoolSubmitStudentSizeTestInput
  sprintRunTest: SchoolSubmitStudentSprintRunTestInput
  standingJumpTest: SchoolSubmitStudentStandingJumpTestInput
}

export enum SchoolType {
  Other = 'OTHER',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type SchoolUnlinkStudentInput = {
  schoolAttendanceNumber: Scalars['Int']
  schoolClass: Scalars['Int']
  schoolGrade: Scalars['Int']
  schoolId: Scalars['ID']
  studentId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # SchoolUnlinkTeacherInput */
export type SchoolUnlinkTeacherInput = {
  schoolId: Scalars['ID']
  teacherId: Scalars['ID']
}

/** # SchoolsInput */
export type SchoolsInput = {
  cityName?: InputMaybe<Scalars['String']>
  districtName?: InputMaybe<Scalars['String']>
  from?: InputMaybe<Scalars['Int']>
  ids?: InputMaybe<Array<Scalars['ID']>>
  limit?: InputMaybe<Scalars['Int']>
  prefectureCode?: InputMaybe<Scalars['Int']>
  schoolCategoryCode?: InputMaybe<Scalars['String']>
  schoolCodes?: InputMaybe<Array<Scalars['String']>>
  schoolTypeCode?: InputMaybe<Scalars['Int']>
}

/** # SchoolsResponse */
export type SchoolsResponse = {
  __typename?: 'SchoolsResponse'
  count: Scalars['Int']
  data: Array<School>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

/** # SearchCitiesHintsInput */
export type SearchCitiesHintsInput = {
  areaId?: InputMaybe<Scalars['ID']>
  from?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  prefectureId?: InputMaybe<Scalars['ID']>
  term: Scalars['String']
}

/** # SearchHintsInput */
export type SearchHintsInput = {
  limit?: InputMaybe<Scalars['Int']>
  term: Scalars['String']
}

export type SearchHintsResponse = {
  __typename?: 'SearchHintsResponse'
  count: Scalars['Int']
  data?: Maybe<Array<Hint>>
  error?: Maybe<Scalars['String']>
}

/** # SearchJapanSchoolsHintsInput */
export type SearchJapanSchoolsHintsInput = {
  address?: InputMaybe<Scalars['String']>
  from?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  prefectureCode?: InputMaybe<Scalars['Int']>
  schoolCategoryCode?: InputMaybe<Scalars['String']>
  schoolTypeCode?: InputMaybe<Scalars['Int']>
  term: Scalars['String']
}

/** # SearchPrefecturesHintsInput */
export type SearchPrefecturesHintsInput = {
  areaId?: InputMaybe<Scalars['ID']>
  limit?: InputMaybe<Scalars['Int']>
  term: Scalars['String']
}

/** # ShuttleRunInput */
export type ShuttleRunInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  shuttleRunCount?: InputMaybe<Scalars['Float']>
  shuttleRunGainOxygen?: InputMaybe<Scalars['Float']>
}

export type ShuttleRunResult = {
  __typename?: 'ShuttleRunResult'
  comment?: Maybe<Scalars['String']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  shuttleRunCount?: Maybe<Scalars['Float']>
  shuttleRunGainOxygen?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # SideJumpInput */
export type SideJumpInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  sideJump1?: InputMaybe<Scalars['Float']>
  sideJump2?: InputMaybe<Scalars['Float']>
}

export type SideJumpResult = {
  __typename?: 'SideJumpResult'
  comment?: Maybe<Scalars['String']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  sideJump1?: Maybe<Scalars['Float']>
  sideJump2?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # SitUpsInput */
export type SitUpsInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  sitUps?: InputMaybe<Scalars['Float']>
}

export type SitUpsResult = {
  __typename?: 'SitUpsResult'
  comment?: Maybe<Scalars['String']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  sitUps?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

export type SizeResult = {
  __typename?: 'SizeResult'
  height?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

export type Sport = {
  __typename?: 'Sport'
  _id: Scalars['ID']
  attributes: SportAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SportAttributes = {
  __typename?: 'SportAttributes'
  key: Scalars['String']
  maxValue: Scalars['Int']
  minValue: Scalars['Int']
  name: Scalars['String']
  sortOrder: SortOrder
  unit: Scalars['String']
}

export type SportConnection = {
  __typename?: 'SportConnection'
  edges: Array<SportEdge>
  pageInfo: PageInfo
}

export type SportEdge = {
  __typename?: 'SportEdge'
  cursor: Scalars['String']
  node: Sport
}

export type SportInput = {
  _id: Scalars['ID']
}

export type SportsInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  school?: InputMaybe<Scalars['ID']>
}

export type SportsResponse = {
  __typename?: 'SportsResponse'
  count: Scalars['Int']
  data: Array<Sport>
  error?: Maybe<Scalars['String']>
}

/** # SprintRunInput */
export type SprintRunInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  sprintRunSeconds?: InputMaybe<Scalars['Float']>
}

export type SprintRunResult = {
  __typename?: 'SprintRunResult'
  comment?: Maybe<Scalars['String']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  sprintRunSeconds?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # StandingJumpInput */
export type StandingJumpInput = {
  isNotMeasurable?: InputMaybe<Scalars['Boolean']>
  memo?: InputMaybe<Scalars['String']>
  standingJump1?: InputMaybe<Scalars['Float']>
  standingJump2?: InputMaybe<Scalars['Float']>
}

export type StandingJumpResult = {
  __typename?: 'StandingJumpResult'
  comment?: Maybe<Scalars['String']>
  isNotMeasurable?: Maybe<Scalars['Boolean']>
  memo?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  rating?: Maybe<Scalars['Int']>
  standingJump1?: Maybe<Scalars['Float']>
  standingJump2?: Maybe<Scalars['Float']>
  value?: Maybe<Scalars['Float']>
}

/** # StudentInvitationCodeGradesInput */
export type StudentInvitationCodeGradesInput = {
  classes: Scalars['Int']
  grade: Scalars['Int']
}

/** # StudentInvitationCodesInput */
export type StudentInvitationCodesInput = {
  schoolGrades: Array<StudentInvitationCodeGradesInput>
  schoolId: Scalars['ID']
  testYear: Scalars['Int']
}

/** # StudentInvitationCodesResponse */
export type StudentInvitationCodesResponse = {
  __typename?: 'StudentInvitationCodesResponse'
  data?: Maybe<Array<SchoolInvitationCode>>
  error?: Maybe<Scalars['String']>
}

/** # StudentLinkSchoolInput */
export type StudentLinkSchoolInput = {
  firebaseId: Scalars['String']
  invitationCode: Scalars['String']
}

export type StudentLinkSchoolResponse = {
  __typename?: 'StudentLinkSchoolResponse'
  error?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

/** # StudentQuestionnaireV2Input */
export type StudentQuestionnaireV2Input = {
  answers?: InputMaybe<Array<Scalars['Int']>>
  height: Scalars['Float']
  sittingHeight?: InputMaybe<Scalars['Float']>
  testYear: Scalars['Int']
  weight: Scalars['Float']
}

/** # StudentQuestionnarieInput */
export type StudentQuestionnarieInput = {
  belongsToSportsClub: Scalars['Int']
  breakfast: Scalars['Int']
  dailyExcerciseTime: Scalars['Int']
  excerciseActivity: Scalars['Int']
  height: Scalars['Float']
  sittingHeight?: InputMaybe<Scalars['Float']>
  sleepTime: Scalars['Int']
  testYear: Scalars['Int']
  tvTime: Scalars['Int']
  weight: Scalars['Float']
}

/** StudentsInput */
export type StudentsInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  page?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  schoolClass?: InputMaybe<Scalars['Int']>
  schoolGrade?: InputMaybe<Scalars['Int']>
  schoolId: Scalars['ID']
  testYear?: InputMaybe<Scalars['Int']>
}

/** # TeacherLinkSchoolInput */
export type TeacherLinkSchoolInput = {
  invitationCode: Scalars['String']
}

/** TeachersInput */
export type TeachersInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  role?: InputMaybe<UserRole>
  schoolId: Scalars['ID']
  testYear?: InputMaybe<Scalars['Int']>
}

export type TestResult = {
  __typename?: 'TestResult'
  age?: Maybe<Scalars['Int']>
  bending?: Maybe<BendingResult>
  enduranceRun?: Maybe<EnduranceRunResult>
  gender?: Maybe<Scalars['String']>
  grip?: Maybe<GripResult>
  handballThrow?: Maybe<HandballThrowResult>
  points?: Maybe<Scalars['Int']>
  questionnaire?: Maybe<QuestionnaireResult>
  questionnaireV2?: Maybe<QuestionnaireV2Result>
  questionnaireV3?: Maybe<QuestionnaireV3Result>
  rank?: Maybe<Scalars['String']>
  shuttleRun?: Maybe<ShuttleRunResult>
  sideJump?: Maybe<SideJumpResult>
  sitUps?: Maybe<SitUpsResult>
  sizeTest?: Maybe<SizeResult>
  sprintRun?: Maybe<SprintRunResult>
  standingJump?: Maybe<StandingJumpResult>
  testYear?: Maybe<Scalars['Int']>
}

export type TestYear = {
  __typename?: 'TestYear'
  _id: Scalars['ID']
  attributes: TestYearAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type TestYearAttributes = {
  __typename?: 'TestYearAttributes'
  era: Era
  year: Scalars['Int']
}

export type TestYearConnection = {
  __typename?: 'TestYearConnection'
  edges: Array<TestYearEdge>
  pageInfo: PageInfo
}

export type TestYearEdge = {
  __typename?: 'TestYearEdge'
  cursor: Scalars['String']
  node: TestYear
}

/** # UpdateSchoolInput */
export type UpdateSchoolInput = {
  attributes: SchoolAttributesInput
  schoolId: Scalars['ID']
}

export type UpdateTeacherRoleInput = {
  role: UserRole
  schoolClass?: InputMaybe<Scalars['Int']>
  schoolGrade?: InputMaybe<Scalars['Int']>
  schoolId: Scalars['String']
  teacherEmail: Scalars['String']
}

/** # UpdateUserInput */
export type UpdateUserInput = {
  attributes: UserAttributesInput
}

/** # User */
export type User = {
  __typename?: 'User'
  _id: Scalars['ID']
  attributes: UserAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: UserRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
  username: Scalars['String']
}

/** # UserAttributes */
export type UserAttributes = {
  __typename?: 'UserAttributes'
  birthday?: Maybe<Scalars['DateTime']>
  email?: Maybe<Scalars['String']>
  familyName?: Maybe<Scalars['String']>
  familyNameHiragana?: Maybe<Scalars['String']>
  firebaseId: Scalars['String']
  gender?: Maybe<Scalars['String']>
  givenName?: Maybe<Scalars['String']>
  givenNameHiragana?: Maybe<Scalars['String']>
  prefectureCode?: Maybe<Scalars['Int']>
  role?: Maybe<UserRole>
  schoolAttendanceNumber?: Maybe<Scalars['Int']>
  schoolCategoryCode?: Maybe<Scalars['String']>
  schoolClass?: Maybe<Scalars['Int']>
  schoolGrade?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  studentNumber?: Maybe<Scalars['String']>
  testResults?: Maybe<Array<TestResult>>
}

/** # UserAttributesInput */
export type UserAttributesInput = {
  attendanceNumber?: InputMaybe<Scalars['Int']>
  birthday?: InputMaybe<Scalars['String']>
  clubActivity?: InputMaybe<ClubActivity>
  email?: InputMaybe<Scalars['String']>
  familyName?: InputMaybe<Scalars['String']>
  familyNameHiragana?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  givenName?: InputMaybe<Scalars['String']>
  givenNameHiragana?: InputMaybe<Scalars['String']>
  otherClubActivity?: InputMaybe<Scalars['String']>
  prefectureCode?: InputMaybe<Scalars['Int']>
  studentNumber?: InputMaybe<Scalars['String']>
}

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSet = 'NOT_SET',
}

export type UserLogAttributes = {
  __typename?: 'UserLogAttributes'
  eventType: Scalars['String']
  eventValue_date?: Maybe<Scalars['DateTime']>
  eventValue_enum?: Maybe<Scalars['String']>
  eventValue_int?: Maybe<Scalars['Int']>
  eventValue_meta?: Maybe<Scalars['JSON']>
  eventValue_string?: Maybe<Scalars['String']>
}

export type UserLogRelationships = {
  __typename?: 'UserLogRelationships'
  user: User
}

export type UserLogs = {
  __typename?: 'UserLogs'
  _id: Scalars['ID']
  attributes: UserLogAttributes
  createdAt?: Maybe<Scalars['DateTime']>
  relationships: UserLogRelationships
  updatedAt?: Maybe<Scalars['DateTime']>
  username: Scalars['String']
}

/** # UserRelationships */
export type UserRelationships = {
  __typename?: 'UserRelationships'
  schools?: Maybe<Array<School>>
}

/** # UserResponse */
export type UserResponse = {
  __typename?: 'UserResponse'
  data?: Maybe<User>
  error?: Maybe<Scalars['String']>
}

export enum UserRole {
  Admin = 'ADMIN',
  GradeAdmin = 'GRADE_ADMIN',
  Guest = 'GUEST',
  LocalGovernment = 'LOCAL_GOVERNMENT',
  SchoolAdmin = 'SCHOOL_ADMIN',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export type UserSchoolCode = {
  __typename?: 'UserSchoolCode'
  code: Scalars['String']
}

export type UserSchoolYear = {
  __typename?: 'UserSchoolYear'
  attendanceNumber?: Maybe<Scalars['Int']>
  clubActivity?: Maybe<ClubActivity>
  isCurrent?: Maybe<Scalars['Boolean']>
  otherClubActivity?: Maybe<Scalars['String']>
  schoolCategoryCode?: Maybe<Scalars['String']>
  schoolClass?: Maybe<Scalars['Int']>
  schoolCode?: Maybe<Scalars['String']>
  schoolGrade?: Maybe<Scalars['Int']>
  schoolName?: Maybe<Scalars['String']>
  schoolTypeCode?: Maybe<Scalars['Int']>
  year?: Maybe<Scalars['Int']>
}

export type UserSchoolYearResponse = {
  __typename?: 'UserSchoolYearResponse'
  data?: Maybe<UserSchoolYear>
  error?: Maybe<Scalars['String']>
}

/** # UsersResponse */
export type UsersResponse = {
  __typename?: 'UsersResponse'
  count: Scalars['Int']
  data: Array<User>
  error?: Maybe<Scalars['String']>
  totalCount: Scalars['Int']
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type UpdateTeacherRoleMutationVariables = Exact<{
  input: UpdateTeacherRoleInput
}>

export type UpdateTeacherRoleMutation = {
  __typename?: 'Mutation'
  updateTeacherRole?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type AuthorizeUserMutationVariables = Exact<{
  uid: Scalars['String']
}>

export type AuthorizeUserMutation = {
  __typename?: 'Mutation'
  auth?: {
    __typename?: 'AuthResponse'
    token?: string | null
    error?: string | null
  } | null
}

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput
}>

export type RegisterUserMutation = {
  __typename?: 'Mutation'
  registerUser?: {
    __typename?: 'MeResponse'
    token?: string | null
    error?: string | null
    data?: { __typename?: 'User'; _id: string } | null
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser?: {
    __typename?: 'MeResponse'
    token?: string | null
    error?: string | null
    data?: { __typename?: 'User'; _id: string } | null
  } | null
}

export type CreateSchoolMutationVariables = Exact<{
  input: CreateSchoolInput
}>

export type CreateSchoolMutation = {
  __typename?: 'Mutation'
  createSchool?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type UpdateSchoolMutationVariables = Exact<{
  input: UpdateSchoolInput
}>

export type UpdateSchoolMutation = {
  __typename?: 'Mutation'
  updateSchool?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type LinkSchoolTestYearMutationVariables = Exact<{
  input: SchoolLinkTestYearInput
}>

export type LinkSchoolTestYearMutation = {
  __typename?: 'Mutation'
  linkSchoolTestYear?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolUnlinkTeacherMutationVariables = Exact<{
  input: SchoolUnlinkTeacherInput
}>

export type SchoolUnlinkTeacherMutation = {
  __typename?: 'Mutation'
  schoolUnlinkTeacher?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolUnlinkStudentMutationVariables = Exact<{
  input: SchoolUnlinkStudentInput
}>

export type SchoolUnlinkStudentMutation = {
  __typename?: 'Mutation'
  schoolUnlinkStudent?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type RegisterGradeMutationVariables = Exact<{
  input: RegisterSchoolGradeInput
}>

export type RegisterGradeMutation = {
  __typename?: 'Mutation'
  registerGrade?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type TeacherLinkSchoolMutationVariables = Exact<{
  input: TeacherLinkSchoolInput
}>

export type TeacherLinkSchoolMutation = {
  __typename?: 'Mutation'
  teacherLinkSchool?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolAddStudentMutationVariables = Exact<{
  input: SchoolAddStudentInput
}>

export type SchoolAddStudentMutation = {
  __typename?: 'Mutation'
  schoolAddStudent?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolEditStudentMutationVariables = Exact<{
  input: SchoolEditStudentInput
}>

export type SchoolEditStudentMutation = {
  __typename?: 'Mutation'
  schoolEditStudent?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentQuestionnarieMutationVariables = Exact<{
  input: SchoolSubmitStudentQuestionnarieInput
}>

export type SchoolSubmitStudentQuestionnarieMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentQuestionnarie?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentTestMutationVariables = Exact<{
  input: SchoolSubmitStudentTestInput
}>

export type SchoolSubmitStudentTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentGripTestMutationVariables = Exact<{
  input: SchoolSubmitStudentGripTestInput
}>

export type SchoolSubmitStudentGripTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentGripTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentSitUpsTestMutationVariables = Exact<{
  input: SchoolSubmitStudentSitUpsTestInput
}>

export type SchoolSubmitStudentSitUpsTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentSitUpsTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentBendingTestMutationVariables = Exact<{
  input: SchoolSubmitStudentBendingTestInput
}>

export type SchoolSubmitStudentBendingTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentBendingTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentSideJumpTestMutationVariables = Exact<{
  input: SchoolSubmitStudentSideJumpTestInput
}>

export type SchoolSubmitStudentSideJumpTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentSideJumpTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentEnduranceRunTestMutationVariables = Exact<{
  input: SchoolSubmitStudentEnduranceRunTestInput
}>

export type SchoolSubmitStudentEnduranceRunTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentEnduranceRunTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentShuttleRunTestMutationVariables = Exact<{
  input: SchoolSubmitStudentShuttleRunTestInput
}>

export type SchoolSubmitStudentShuttleRunTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentShuttleRunTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentSprintRunTestMutationVariables = Exact<{
  input: SchoolSubmitStudentSprintRunTestInput
}>

export type SchoolSubmitStudentSprintRunTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentSprintRunTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentStandingJumpTestMutationVariables = Exact<{
  input: SchoolSubmitStudentStandingJumpTestInput
}>

export type SchoolSubmitStudentStandingJumpTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentStandingJumpTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentHandballThrowTestMutationVariables = Exact<{
  input: SchoolSubmitStudentHandballThrowTestInput
}>

export type SchoolSubmitStudentHandballThrowTestMutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentHandballThrowTest?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type StudentLinkSchoolMutationVariables = Exact<{
  input: StudentLinkSchoolInput
}>

export type StudentLinkSchoolMutation = {
  __typename?: 'Mutation'
  studentLinkSchool?: {
    __typename?: 'StudentLinkSchoolResponse'
    token?: string | null
    error?: string | null
  } | null
}

export type StudentSubmitQuestionnarieMutationVariables = Exact<{
  input: StudentQuestionnarieInput
}>

export type StudentSubmitQuestionnarieMutation = {
  __typename?: 'Mutation'
  studentSubmitQuestionnarie?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type SchoolSubmitStudentQuestionnaireV2MutationVariables = Exact<{
  input: SchoolSubmitStudentQuestionnaireV2Input
}>

export type SchoolSubmitStudentQuestionnaireV2Mutation = {
  __typename?: 'Mutation'
  schoolSubmitStudentQuestionnaireV2?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type StudentSubmitQuestionnaireV2MutationVariables = Exact<{
  input: StudentQuestionnaireV2Input
}>

export type StudentSubmitQuestionnaireV2Mutation = {
  __typename?: 'Mutation'
  studentSubmitQuestionnaireV2?: {
    __typename?: 'OperationResponse'
    success: boolean
    error?: string | null
  } | null
}

export type AnalyticsEventMutationVariables = Exact<{
  input: Scalars['JSON']
}>

export type AnalyticsEventMutation = {
  __typename?: 'Mutation'
  analyticsEvent: boolean
}

export type AnalyticsEventsMutationVariables = Exact<{
  input: Array<Scalars['JSON']> | Scalars['JSON']
}>

export type AnalyticsEventsMutation = {
  __typename?: 'Mutation'
  analyticsEvents: boolean
}

export type AreasQueryVariables = Exact<{
  input?: InputMaybe<AreasInput>
}>

export type AreasQuery = {
  __typename?: 'Query'
  areas?: {
    __typename?: 'AreasResponse'
    data: Array<{ __typename?: 'Area'; _id: string; name: string }>
  } | null
}

export type PrefecturesQueryVariables = Exact<{
  input?: InputMaybe<PrefecturesInput>
}>

export type PrefecturesQuery = {
  __typename?: 'Query'
  prefectures?: {
    __typename?: 'PrefecturesResponse'
    data: Array<{
      __typename?: 'Prefecture'
      _id: string
      name: string
      code: number
      area: any
    }>
  } | null
}

export type CitiesQueryVariables = Exact<{
  input: CitiesInput
}>

export type CitiesQuery = {
  __typename?: 'Query'
  cities?: {
    __typename?: 'CitiesResponse'
    data: Array<{ __typename?: 'City'; _id: string; name: string }>
  } | null
}

export type JapanSchoolsQueryVariables = Exact<{
  input: JapanSchoolsInput
}>

export type JapanSchoolsQuery = {
  __typename?: 'Query'
  japanSchools?: {
    __typename?: 'JapanSchoolsResponse'
    totalCount: number
    data: Array<{
      __typename?: 'JapanSchool'
      _id: string
      schoolName: string
      schoolCode: string
      schoolAddress?: string | null
      registered?: boolean | null
      school?: {
        __typename?: 'School'
        _id: string
        createdAt?: any | null
        updatedAt?: any | null
        attributes: {
          __typename?: 'SchoolAttributes'
          email?: string | null
          website?: string | null
          telephone?: string | null
          fax?: string | null
          schoolAdminName?: string | null
          schoolAdminEmail?: string | null
          onboardComplete?: boolean | null
          testYears?: Array<number> | null
          schoolAdminInvitationCode?: string | null
          teacherInvitationCode?: string | null
          otherSchoolCode?: string | null
        }
      } | null
    }>
  } | null
}

export type JapanSchoolCategoriesQueryVariables = Exact<{
  [key: string]: never
}>

export type JapanSchoolCategoriesQuery = {
  __typename?: 'Query'
  japanSchoolCategories?: {
    __typename?: 'JapanSchoolCategoriesResponse'
    data?: Array<{
      __typename?: 'JapanSchoolCategory'
      code: string
      name: string
    }> | null
  } | null
}

export type JapanSchoolTypesQueryVariables = Exact<{ [key: string]: never }>

export type JapanSchoolTypesQuery = {
  __typename?: 'Query'
  japanSchoolTypes?: {
    __typename?: 'JapanSchoolTypesResponse'
    data?: Array<{
      __typename?: 'JapanSchoolType'
      code: number
      name: string
    }> | null
  } | null
}

export type SearchAreasHintsQueryVariables = Exact<{
  input: SearchHintsInput
}>

export type SearchAreasHintsQuery = {
  __typename?: 'Query'
  searchAreasHints?: {
    __typename?: 'SearchHintsResponse'
    data?: Array<{ __typename?: 'Hint'; term: string; score: number }> | null
  } | null
}

export type SearchPrefecturesHintsQueryVariables = Exact<{
  input: SearchPrefecturesHintsInput
}>

export type SearchPrefecturesHintsQuery = {
  __typename?: 'Query'
  searchPrefecturesHints?: {
    __typename?: 'SearchHintsResponse'
    data?: Array<{ __typename?: 'Hint'; term: string; score: number }> | null
  } | null
}

export type SearchCitiesHintsQueryVariables = Exact<{
  input: SearchCitiesHintsInput
}>

export type SearchCitiesHintsQuery = {
  __typename?: 'Query'
  searchCitiesHints?: {
    __typename?: 'SearchHintsResponse'
    data?: Array<{ __typename?: 'Hint'; term: string; score: number }> | null
  } | null
}

export type SearchJapanSchoolsHintsQueryVariables = Exact<{
  input: SearchJapanSchoolsHintsInput
}>

export type SearchJapanSchoolsHintsQuery = {
  __typename?: 'Query'
  searchJapanSchoolsHints?: {
    __typename?: 'SearchHintsResponse'
    data?: Array<{ __typename?: 'Hint'; term: string; score: number }> | null
  } | null
}

export type SchoolsQueryVariables = Exact<{
  input: SchoolsInput
}>

export type SchoolsQuery = {
  __typename?: 'Query'
  schools?: {
    __typename?: 'SchoolsResponse'
    count: number
    totalCount: number
    error?: string | null
    data: Array<{ __typename?: 'School'; _id: string }>
  } | null
}

export type GradesQueryVariables = Exact<{
  input: SchoolGradesInput
}>

export type GradesQuery = {
  __typename?: 'Query'
  grades?: {
    __typename?: 'SchoolGradesResponse'
    count: number
    error?: string | null
    data: Array<{
      __typename?: 'SchoolGrade'
      testYear: number
      schoolGrade: number
      numberOfClasses: number
      numberOfStudentsPerClass: Array<number>
      isEnduranceRunInputActive?: boolean | null
    }>
  } | null
}

export type TeachersQueryVariables = Exact<{
  input: TeachersInput
}>

export type TeachersQuery = {
  __typename?: 'Query'
  teachers?: {
    __typename?: 'UsersResponse'
    count: number
    totalCount: number
    error?: string | null
    data: Array<{
      __typename?: 'User'
      _id: string
      attributes: {
        __typename?: 'UserAttributes'
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        email?: string | null
        role?: UserRole | null
        schoolGrade?: number | null
        schoolClass?: number | null
      }
    }>
  } | null
}

export type StudentsQueryVariables = Exact<{
  input: StudentsInput
}>

export type StudentsQuery = {
  __typename?: 'Query'
  students?: {
    __typename?: 'UsersResponse'
    count: number
    totalCount: number
    error?: string | null
    data: Array<{
      __typename?: 'User'
      _id: string
      username: string
      attributes: {
        __typename?: 'UserAttributes'
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        birthday?: any | null
        gender?: string | null
        schoolName?: string | null
        schoolGrade?: number | null
        schoolClass?: number | null
        schoolAttendanceNumber?: number | null
        studentNumber?: string | null
        email?: string | null
        testResults?: Array<{
          __typename?: 'TestResult'
          testYear?: number | null
          points?: number | null
          rank?: string | null
          age?: number | null
          grip?: {
            __typename?: 'GripResult'
            gripLeft1?: number | null
            gripLeft2?: number | null
            gripRight1?: number | null
            gripRight2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          sitUps?: {
            __typename?: 'SitUpsResult'
            sitUps?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          bending?: {
            __typename?: 'BendingResult'
            bending1?: number | null
            bending2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          sideJump?: {
            __typename?: 'SideJumpResult'
            sideJump1?: number | null
            sideJump2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          shuttleRun?: {
            __typename?: 'ShuttleRunResult'
            shuttleRunCount?: number | null
            shuttleRunGainOxygen?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          sprintRun?: {
            __typename?: 'SprintRunResult'
            sprintRunSeconds?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          standingJump?: {
            __typename?: 'StandingJumpResult'
            standingJump1?: number | null
            standingJump2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          handballThrow?: {
            __typename?: 'HandballThrowResult'
            handballThrow1?: number | null
            handballThrow2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          enduranceRun?: {
            __typename?: 'EnduranceRunResult'
            runningTime?: string | null
            runningTimeSeconds?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          questionnaire?: {
            __typename?: 'QuestionnaireResult'
            belongsToSportsClub?: number | null
            excerciseActivity?: number | null
            dailyExcerciseTime?: number | null
            breakfast?: number | null
            sleepTime?: number | null
            tvTime?: number | null
            height?: number | null
            weight?: number | null
            sittingHeight?: number | null
          } | null
          questionnaireV2?: {
            __typename?: 'QuestionnaireV2Result'
            height?: number | null
            weight?: number | null
            sittingHeight?: number | null
            answers?: Array<number> | null
          } | null
          sizeTest?: {
            __typename?: 'SizeResult'
            height?: number | null
            weight?: number | null
          } | null
          questionnaireV3?: {
            __typename?: 'QuestionnaireV3Result'
            q1?: number | null
            q2?: number | null
            q3?: number | null
            q4?: string | null
            q5?: string | null
            q6?: number | null
            q7?: number | null
            q8?: number | null
            q9?: number | null
          } | null
        }> | null
      }
    }>
  } | null
}

export type RenewTokenQueryVariables = Exact<{ [key: string]: never }>

export type RenewTokenQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'MeResponse'
    token?: string | null
    error?: string | null
  } | null
}

export type TeacherQueryVariables = Exact<{ [key: string]: never }>

export type TeacherQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'MeResponse'
    token?: string | null
    error?: string | null
    data?: {
      __typename?: 'User'
      _id: string
      attributes: {
        __typename?: 'UserAttributes'
        role?: UserRole | null
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        schoolName?: string | null
        schoolCategoryCode?: string | null
        email?: string | null
        schoolGrade?: number | null
        schoolClass?: number | null
      }
      relationships: {
        __typename?: 'UserRelationships'
        schools?: Array<{
          __typename?: 'School'
          _id: string
          attributes: {
            __typename?: 'SchoolAttributes'
            email?: string | null
            fax?: string | null
            telephone?: string | null
            website?: string | null
            schoolName: string
            schoolCategoryCode: string
            schoolAdminName?: string | null
            schoolAddress?: string | null
            onboardComplete?: boolean | null
            testYears?: Array<number> | null
            isStudentInputActive?: boolean | null
            isEnduranceRunInputActive?: boolean | null
            questions?: Array<{
              __typename?: 'QuestionnaireV2Question'
              question: string
              options: Array<string>
            }> | null
          }
        }> | null
      }
    } | null
  } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'MeResponse'
    token?: string | null
    error?: string | null
    data?: {
      __typename?: 'User'
      _id: string
      attributes: {
        __typename?: 'UserAttributes'
        role?: UserRole | null
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        gender?: string | null
        birthday?: any | null
        schoolName?: string | null
        schoolCategoryCode?: string | null
        schoolGrade?: number | null
        schoolClass?: number | null
        schoolAttendanceNumber?: number | null
        studentNumber?: string | null
        email?: string | null
        testResults?: Array<{
          __typename?: 'TestResult'
          testYear?: number | null
          points?: number | null
          rank?: string | null
          age?: number | null
          grip?: {
            __typename?: 'GripResult'
            gripLeft1?: number | null
            gripLeft2?: number | null
            gripRight1?: number | null
            gripRight2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          sitUps?: {
            __typename?: 'SitUpsResult'
            sitUps?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          bending?: {
            __typename?: 'BendingResult'
            bending1?: number | null
            bending2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          sideJump?: {
            __typename?: 'SideJumpResult'
            sideJump1?: number | null
            sideJump2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          shuttleRun?: {
            __typename?: 'ShuttleRunResult'
            shuttleRunCount?: number | null
            shuttleRunGainOxygen?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          sprintRun?: {
            __typename?: 'SprintRunResult'
            sprintRunSeconds?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          standingJump?: {
            __typename?: 'StandingJumpResult'
            standingJump1?: number | null
            standingJump2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          handballThrow?: {
            __typename?: 'HandballThrowResult'
            handballThrow1?: number | null
            handballThrow2?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          enduranceRun?: {
            __typename?: 'EnduranceRunResult'
            runningTime?: string | null
            runningTimeSeconds?: number | null
            isNotMeasurable?: boolean | null
            value?: number | null
            points?: number | null
            memo?: string | null
            rating?: number | null
            comment?: string | null
          } | null
          sizeTest?: {
            __typename?: 'SizeResult'
            height?: number | null
            weight?: number | null
          } | null
          questionnaire?: {
            __typename?: 'QuestionnaireResult'
            belongsToSportsClub?: number | null
            excerciseActivity?: number | null
            dailyExcerciseTime?: number | null
            breakfast?: number | null
            sleepTime?: number | null
            tvTime?: number | null
            height?: number | null
            weight?: number | null
            sittingHeight?: number | null
          } | null
          questionnaireV2?: {
            __typename?: 'QuestionnaireV2Result'
            height?: number | null
            weight?: number | null
            sittingHeight?: number | null
            answers?: Array<number> | null
          } | null
          questionnaireV3?: {
            __typename?: 'QuestionnaireV3Result'
            q1?: number | null
            q2?: number | null
            q3?: number | null
            q4?: string | null
            q5?: string | null
            q6?: number | null
            q7?: number | null
            q8?: number | null
            q9?: number | null
          } | null
        }> | null
      }
      relationships: {
        __typename?: 'UserRelationships'
        schools?: Array<{
          __typename?: 'School'
          _id: string
          attributes: {
            __typename?: 'SchoolAttributes'
            email?: string | null
            fax?: string | null
            telephone?: string | null
            website?: string | null
            schoolName: string
            schoolCategoryCode: string
            schoolAdminName?: string | null
            schoolAddress?: string | null
            onboardComplete?: boolean | null
            testYears?: Array<number> | null
            isStudentInputActive?: boolean | null
            isEnduranceRunInputActive?: boolean | null
            questions?: Array<{
              __typename?: 'QuestionnaireV2Question'
              question: string
              options: Array<string>
            }> | null
          }
        }> | null
      }
    } | null
  } | null
}

export type ReportCountSummaryQueryVariables = Exact<{
  input: ReportCountSummaryInput
}>

export type ReportCountSummaryQuery = {
  __typename?: 'Query'
  reportCountSummary?: {
    __typename?: 'ReportCountSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportCountSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
      allDayHighSchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
      fixTimeHighSchoolReport?: {
        __typename?: 'ReportCountByCategory'
        schoolCount?: number | null
        reports?: Array<{
          __typename?: 'ReportCountByGrade'
          grade?: number | null
          male?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
          female?: {
            __typename?: 'ReportCount'
            totalCount?: number | null
            samplingCount?: number | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportBodySummaryQueryVariables = Exact<{
  input: ReportBodySummaryInput
}>

export type ReportBodySummaryQuery = {
  __typename?: 'Query'
  reportBodySummary?: {
    __typename?: 'ReportBodySummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportBodySummary'
      elementarySchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
      allDayHighSchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
      fixTimeHighSchoolReport?: {
        __typename?: 'ReportBodyByCategory'
        reports?: Array<{
          __typename?: 'ReportBody'
          grade?: number | null
          sampleCount?: number | null
          heightAvg?: number | null
          weightAvg?: number | null
          heightDev?: number | null
          weightDev?: number | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestSummaryQueryVariables = Exact<{
  input: ReportTestSummaryInput
}>

export type ReportTestSummaryQuery = {
  __typename?: 'Query'
  reportTestSummary?: {
    __typename?: 'ReportTestSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
      allDayHighSchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
      fixTimeHighSchoolReport?: {
        __typename?: 'ReportTestByCategory'
        reports?: Array<{
          __typename?: 'ReportTestByGrade'
          grade?: number | null
          average?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
          deviation?: {
            __typename?: 'ReportTest'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestComparisonSummaryQueryVariables = Exact<{
  input: ReportTestComparisonSummaryInput
}>

export type ReportTestComparisonSummaryQuery = {
  __typename?: 'Query'
  reportTestComparisonSummary?: {
    __typename?: 'ReportTestComparisonSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestComparisonSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportTestComparisonByCategory'
        reports?: Array<{
          __typename?: 'ReportTestComparisonByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
          national?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportTestComparisonByCategory'
        reports?: Array<{
          __typename?: 'ReportTestComparisonByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
          national?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
        }> | null
      } | null
      highSchoolReport?: {
        __typename?: 'ReportTestComparisonByCategory'
        reports?: Array<{
          __typename?: 'ReportTestComparisonByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
          national?: {
            __typename?: 'ReportTestComparison'
            grip?: boolean | null
            sitUps?: boolean | null
            bending?: boolean | null
            sideJump?: boolean | null
            shuttleRun?: boolean | null
            sprintRun?: boolean | null
            standingJump?: boolean | null
            handballThrow?: boolean | null
            totalScore?: boolean | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportAverageScoreGraphSummaryQueryVariables = Exact<{
  input: ReportAverageScoreGraphSummaryInput
}>

export type ReportAverageScoreGraphSummaryQuery = {
  __typename?: 'Query'
  reportAverageScoreGraphSummary?: {
    __typename?: 'ReportAverageScoreGraphSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportAverageScoreGraphSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportAverageScoreGraphByCategory'
        reports?: Array<{
          __typename?: 'ReportAverageScoreGraphByYear'
          year?: number | null
          grades?: Array<{
            __typename?: 'ReportAverageScoreGraphByGrade'
            grade?: number | null
            score?: {
              __typename?: 'ReportAverageScoreGraph'
              grip?: number | null
              sitUps?: number | null
              bending?: number | null
              sideJump?: number | null
              shuttleRun?: number | null
              sprintRun?: number | null
              standingJump?: number | null
              handballThrow?: number | null
              totalScore?: number | null
            } | null
          }> | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportAverageScoreGraphByCategory'
        reports?: Array<{
          __typename?: 'ReportAverageScoreGraphByYear'
          year?: number | null
          grades?: Array<{
            __typename?: 'ReportAverageScoreGraphByGrade'
            grade?: number | null
            score?: {
              __typename?: 'ReportAverageScoreGraph'
              grip?: number | null
              sitUps?: number | null
              bending?: number | null
              sideJump?: number | null
              shuttleRun?: number | null
              sprintRun?: number | null
              standingJump?: number | null
              handballThrow?: number | null
              totalScore?: number | null
            } | null
          }> | null
        }> | null
      } | null
      highSchoolReport?: {
        __typename?: 'ReportAverageScoreGraphByCategory'
        reports?: Array<{
          __typename?: 'ReportAverageScoreGraphByYear'
          year?: number | null
          grades?: Array<{
            __typename?: 'ReportAverageScoreGraphByGrade'
            grade?: number | null
            score?: {
              __typename?: 'ReportAverageScoreGraph'
              grip?: number | null
              sitUps?: number | null
              bending?: number | null
              sideJump?: number | null
              shuttleRun?: number | null
              sprintRun?: number | null
              standingJump?: number | null
              handballThrow?: number | null
              totalScore?: number | null
            } | null
          }> | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestRankSummaryQueryVariables = Exact<{
  input: ReportTestRankSummaryInput
}>

export type ReportTestRankSummaryQuery = {
  __typename?: 'Query'
  reportTestRankSummary?: {
    __typename?: 'ReportTestRankSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestRankSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportTestRankByCategory'
        reports?: Array<{
          __typename?: 'ReportTestRankByGrade'
          grade?: number | null
          totalCount?: number | null
          countByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
          rateByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportTestRankByCategory'
        reports?: Array<{
          __typename?: 'ReportTestRankByGrade'
          grade?: number | null
          totalCount?: number | null
          countByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
          rateByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
        }> | null
      } | null
      allDayHighSchoolReport?: {
        __typename?: 'ReportTestRankByCategory'
        reports?: Array<{
          __typename?: 'ReportTestRankByGrade'
          grade?: number | null
          totalCount?: number | null
          countByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
          rateByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
        }> | null
      } | null
      fixTimeHighSchoolReport?: {
        __typename?: 'ReportTestRankByCategory'
        reports?: Array<{
          __typename?: 'ReportTestRankByGrade'
          grade?: number | null
          totalCount?: number | null
          countByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
          rateByRank?: {
            __typename?: 'ReportTestRank'
            rankA?: number | null
            rankB?: number | null
            rankC?: number | null
            rankD?: number | null
            rankE?: number | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportQuestionnaireSummaryQueryVariables = Exact<{
  input: ReportQuestionnaireSummaryInput
}>

export type ReportQuestionnaireSummaryQuery = {
  __typename?: 'Query'
  reportQuestionnaireSummary?: {
    __typename?: 'ReportQuestionnaireSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportQuestionnaireSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportQuestionnaireByCategory'
        reports?: Array<{
          __typename?: 'ReportQuestionnaireByGrade'
          grade?: number | null
          countByAnswer?: {
            __typename?: 'ReportQuestionnaire'
            q1?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q2?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q3?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q4?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q5?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q6?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportQuestionnaireByCategory'
        reports?: Array<{
          __typename?: 'ReportQuestionnaireByGrade'
          grade?: number | null
          countByAnswer?: {
            __typename?: 'ReportQuestionnaire'
            q1?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q2?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q3?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q4?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q5?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q6?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
          } | null
        }> | null
      } | null
      highSchoolReport?: {
        __typename?: 'ReportQuestionnaireByCategory'
        reports?: Array<{
          __typename?: 'ReportQuestionnaireByGrade'
          grade?: number | null
          countByAnswer?: {
            __typename?: 'ReportQuestionnaire'
            q1?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q2?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q3?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q4?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q5?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
            q6?: Array<{
              __typename?: 'QuestionnaireResponse'
              k: number
              v: number
            }> | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestScoreChartSummaryQueryVariables = Exact<{
  input: ReportTestScoreChartSummaryInput
}>

export type ReportTestScoreChartSummaryQuery = {
  __typename?: 'Query'
  reportTestScoreChartSummary?: {
    __typename?: 'ReportTestScoreChartSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestScoreChartSummary'
      elementarySchoolReport?: {
        __typename?: 'ReportTestScoreChartByCategory'
        reports?: Array<{
          __typename?: 'ReportTestScoreChartByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestScoreChart'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
            height?: number | null
            weight?: number | null
          } | null
        }> | null
      } | null
      juniorHighSchoolReport?: {
        __typename?: 'ReportTestScoreChartByCategory'
        reports?: Array<{
          __typename?: 'ReportTestScoreChartByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestScoreChart'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
            height?: number | null
            weight?: number | null
          } | null
        }> | null
      } | null
      highSchoolReport?: {
        __typename?: 'ReportTestScoreChartByCategory'
        reports?: Array<{
          __typename?: 'ReportTestScoreChartByGrade'
          grade?: number | null
          prefecture?: {
            __typename?: 'ReportTestScoreChart'
            grip?: number | null
            sitUps?: number | null
            bending?: number | null
            sideJump?: number | null
            shuttleRun?: number | null
            sprintRun?: number | null
            standingJump?: number | null
            handballThrow?: number | null
            totalScore?: number | null
            height?: number | null
            weight?: number | null
          } | null
        }> | null
      } | null
    } | null
  } | null
}

export type ReportTestRankTargetSummaryQueryVariables = Exact<{
  input: ReportTestRankTargetSummaryInput
}>

export type ReportTestRankTargetSummaryQuery = {
  __typename?: 'Query'
  reportTestRankTargetSummary?: {
    __typename?: 'ReportTestRankTargetSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportTestRankTargetSummary'
      reports?: Array<{
        __typename?: 'ReportTestRankTargetByYear'
        year?: number | null
        totalRates?: Array<number> | null
        elementarySchoolRates?: Array<number> | null
        juniorHighSchoolRates?: Array<number> | null
        highSchoolRates?: Array<number> | null
      }> | null
      national?: Array<{
        __typename?: 'ReportTestRankTargetByYear'
        year?: number | null
        totalRates?: Array<number> | null
        elementarySchoolRates?: Array<number> | null
        juniorHighSchoolRates?: Array<number> | null
        highSchoolRates?: Array<number> | null
      }> | null
    } | null
  } | null
}

export type ReportCrossAnalysisSummaryQueryVariables = Exact<{
  input: ReportCrossAnalysisSummaryInput
}>

export type ReportCrossAnalysisSummaryQuery = {
  __typename?: 'Query'
  reportCrossAnalysisSummary?: {
    __typename?: 'ReportCrossAnalysisSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportCrossAnalysisSummary'
      reports?: Array<{
        __typename?: 'ReportCrossAnalysisByQuestionnaire'
        dataKey?: string | null
        grades?: Array<{
          __typename?: 'ReportCrossAnalysisByGrade'
          schoolCategory?: string | null
          grade?: number | null
          answers?: Array<{
            __typename?: 'ReportCrossAnalysisByAnswer'
            answer?: number | null
            totalCount?: number | null
            averages?: Array<{
              __typename?: 'ReportScore'
              score?: number | null
              scoreType?: string | null
            }> | null
            deviations?: Array<{
              __typename?: 'ReportScore'
              score?: number | null
              scoreType?: string | null
            }> | null
          }> | null
        }> | null
      }> | null
    } | null
  } | null
}

export type ReportSchoolPerformanceSummaryQueryVariables = Exact<{
  input: ReportSchoolPerformanceSummaryInput
}>

export type ReportSchoolPerformanceSummaryQuery = {
  __typename?: 'Query'
  reportSchoolPerformanceSummary?: {
    __typename?: 'ReportSchoolPerformanceSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportSchoolPerformanceSummary'
      elementarySchool?: Array<{
        __typename?: 'ReportSchoolPerformance'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      juniorHighSchool?: Array<{
        __typename?: 'ReportSchoolPerformance'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      highSchool?: Array<{
        __typename?: 'ReportSchoolPerformance'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
    } | null
  } | null
}

export type ReportSchoolProgressSummaryQueryVariables = Exact<{
  input: ReportSchoolProgressSummaryInput
}>

export type ReportSchoolProgressSummaryQuery = {
  __typename?: 'Query'
  reportSchoolProgressSummary?: {
    __typename?: 'ReportSchoolProgressSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'ReportSchoolProgressSummary'
      elementarySchool?: Array<{
        __typename?: 'ReportSchoolProgress'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      juniorHighSchool?: Array<{
        __typename?: 'ReportSchoolProgress'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
      highSchool?: Array<{
        __typename?: 'ReportSchoolProgress'
        prefectureSchoolCode?: number | null
        schoolName?: string | null
        scale?: number | null
        score?: number | null
        studentCount?: number | null
      }> | null
    } | null
  } | null
}

export type ReportUploadListQueryVariables = Exact<{
  input: ReportUploadListInput
}>

export type ReportUploadListQuery = {
  __typename?: 'Query'
  reportUploadList?: {
    __typename?: 'ReportUploadListResponse'
    count: number
    totalCount: number
    error?: string | null
    data?: Array<{
      __typename?: 'ReportSchoolEntry'
      prefectureSchoolCode?: string | null
      schoolName?: string | null
      studentCount?: number | null
      cityName?: string | null
      organizationName?: string | null
      uploadedAt?: any | null
    }> | null
  } | null
}

export type StudentInvitationCodesQueryVariables = Exact<{
  input: StudentInvitationCodesInput
}>

export type StudentInvitationCodesQuery = {
  __typename?: 'Query'
  studentInvitationCodes?: {
    __typename?: 'StudentInvitationCodesResponse'
    error?: string | null
    data?: Array<{
      __typename?: 'SchoolInvitationCode'
      schoolGrade: number
      schoolClass: number
      students?: Array<{
        __typename?: 'MetaStudent'
        attendanceNumber: number
        username: string
      } | null> | null
    }> | null
  } | null
}

export type SchoolReportTestSummaryQueryVariables = Exact<{
  input: SchoolReportTestSummaryInput
}>

export type SchoolReportTestSummaryQuery = {
  __typename?: 'Query'
  schoolReportTestSummary?: {
    __typename?: 'SchoolReportTestSummaryResponse'
    error?: string | null
    data?: {
      __typename?: 'SchoolReportTestSummary'
      reports?: Array<{
        __typename?: 'SchoolReportTestByGrade'
        testYear?: number | null
        grade?: number | null
        count?: number | null
        height_avg?: number | null
        weight_avg?: number | null
        grip_avg?: number | null
        sitUps_avg?: number | null
        bending_avg?: number | null
        sideJump_avg?: number | null
        shuttleRun_avg?: number | null
        sprintRun_avg?: number | null
        standingJump_avg?: number | null
        handballThrow_avg?: number | null
        points_avg?: number | null
        height_std?: number | null
        weight_std?: number | null
        grip_std?: number | null
        sitUps_std?: number | null
        bending_std?: number | null
        sideJump_std?: number | null
        shuttleRun_std?: number | null
        sprintRun_std?: number | null
        standingJump_std?: number | null
        handballThrow_std?: number | null
        points_std?: number | null
        rank_a?: number | null
        rank_b?: number | null
        rank_c?: number | null
        rank_d?: number | null
        rank_e?: number | null
      }> | null
    } | null
  } | null
}

export type SchoolReportTestScoreChartSummaryQueryVariables = Exact<{
  input: SchoolReportTestScoreChartSummaryInput
}>

export type SchoolReportTestScoreChartSummaryQuery = {
  __typename?: 'Query'
  schoolReportTestScoreChartSummary?: {
    __typename?: 'SchoolReportTestScoreChartSummaryResponse'
    data?: {
      __typename?: 'SchoolReportTestScoreChartSummary'
      reports?: Array<{
        __typename?: 'SchoolReportTestScoreChartByGrade'
        testYear?: number | null
        grade?: number | null
        chart?: {
          __typename?: 'ReportTestScoreChart'
          grip?: number | null
          sitUps?: number | null
          bending?: number | null
          sideJump?: number | null
          shuttleRun?: number | null
          sprintRun?: number | null
          standingJump?: number | null
          handballThrow?: number | null
          totalScore?: number | null
          height?: number | null
          weight?: number | null
        } | null
      }> | null
    } | null
  } | null
}

export type QrCodeStudentQueryVariables = Exact<{
  input: QrCodeStudentInput
}>

export type QrCodeStudentQuery = {
  __typename?: 'Query'
  qrCodeStudent?: {
    __typename?: 'UserResponse'
    error?: string | null
    data?: {
      __typename?: 'User'
      _id: string
      attributes: {
        __typename?: 'UserAttributes'
        givenName?: string | null
        givenNameHiragana?: string | null
        familyName?: string | null
        familyNameHiragana?: string | null
        schoolName?: string | null
        schoolGrade?: number | null
        schoolClass?: number | null
        schoolAttendanceNumber?: number | null
      }
    } | null
  } | null
}

export const UpdateTeacherRoleDocument = gql`
  mutation UpdateTeacherRole($input: UpdateTeacherRoleInput!) {
    updateTeacherRole(input: $input) {
      success
      error
    }
  }
`
export type UpdateTeacherRoleMutationFn = Apollo.MutationFunction<
  UpdateTeacherRoleMutation,
  UpdateTeacherRoleMutationVariables
>

/**
 * __useUpdateTeacherRoleMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherRoleMutation, { data, loading, error }] = useUpdateTeacherRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeacherRoleMutation,
    UpdateTeacherRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTeacherRoleMutation,
    UpdateTeacherRoleMutationVariables
  >(UpdateTeacherRoleDocument, options)
}
export type UpdateTeacherRoleMutationHookResult = ReturnType<
  typeof useUpdateTeacherRoleMutation
>
export type UpdateTeacherRoleMutationResult =
  Apollo.MutationResult<UpdateTeacherRoleMutation>
export type UpdateTeacherRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeacherRoleMutation,
  UpdateTeacherRoleMutationVariables
>
export const AuthorizeUserDocument = gql`
  mutation AuthorizeUser($uid: String!) {
    auth(uid: $uid) {
      token
      error
    }
  }
`
export type AuthorizeUserMutationFn = Apollo.MutationFunction<
  AuthorizeUserMutation,
  AuthorizeUserMutationVariables
>

/**
 * __useAuthorizeUserMutation__
 *
 * To run a mutation, you first call `useAuthorizeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeUserMutation, { data, loading, error }] = useAuthorizeUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useAuthorizeUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeUserMutation,
    AuthorizeUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthorizeUserMutation,
    AuthorizeUserMutationVariables
  >(AuthorizeUserDocument, options)
}
export type AuthorizeUserMutationHookResult = ReturnType<
  typeof useAuthorizeUserMutation
>
export type AuthorizeUserMutationResult =
  Apollo.MutationResult<AuthorizeUserMutation>
export type AuthorizeUserMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeUserMutation,
  AuthorizeUserMutationVariables
>
export const RegisterUserDocument = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      data {
        _id
      }
      token
      error
    }
  }
`
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options)
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        _id
      }
      token
      error
    }
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const CreateSchoolDocument = gql`
  mutation CreateSchool($input: CreateSchoolInput!) {
    createSchool(input: $input) {
      success
      error
    }
  }
`
export type CreateSchoolMutationFn = Apollo.MutationFunction<
  CreateSchoolMutation,
  CreateSchoolMutationVariables
>

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSchoolMutation,
    CreateSchoolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSchoolMutation,
    CreateSchoolMutationVariables
  >(CreateSchoolDocument, options)
}
export type CreateSchoolMutationHookResult = ReturnType<
  typeof useCreateSchoolMutation
>
export type CreateSchoolMutationResult =
  Apollo.MutationResult<CreateSchoolMutation>
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<
  CreateSchoolMutation,
  CreateSchoolMutationVariables
>
export const UpdateSchoolDocument = gql`
  mutation UpdateSchool($input: UpdateSchoolInput!) {
    updateSchool(input: $input) {
      success
      error
    }
  }
`
export type UpdateSchoolMutationFn = Apollo.MutationFunction<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >(UpdateSchoolDocument, options)
}
export type UpdateSchoolMutationHookResult = ReturnType<
  typeof useUpdateSchoolMutation
>
export type UpdateSchoolMutationResult =
  Apollo.MutationResult<UpdateSchoolMutation>
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>
export const LinkSchoolTestYearDocument = gql`
  mutation LinkSchoolTestYear($input: SchoolLinkTestYearInput!) {
    linkSchoolTestYear(input: $input) {
      success
      error
    }
  }
`
export type LinkSchoolTestYearMutationFn = Apollo.MutationFunction<
  LinkSchoolTestYearMutation,
  LinkSchoolTestYearMutationVariables
>

/**
 * __useLinkSchoolTestYearMutation__
 *
 * To run a mutation, you first call `useLinkSchoolTestYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkSchoolTestYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkSchoolTestYearMutation, { data, loading, error }] = useLinkSchoolTestYearMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkSchoolTestYearMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkSchoolTestYearMutation,
    LinkSchoolTestYearMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LinkSchoolTestYearMutation,
    LinkSchoolTestYearMutationVariables
  >(LinkSchoolTestYearDocument, options)
}
export type LinkSchoolTestYearMutationHookResult = ReturnType<
  typeof useLinkSchoolTestYearMutation
>
export type LinkSchoolTestYearMutationResult =
  Apollo.MutationResult<LinkSchoolTestYearMutation>
export type LinkSchoolTestYearMutationOptions = Apollo.BaseMutationOptions<
  LinkSchoolTestYearMutation,
  LinkSchoolTestYearMutationVariables
>
export const SchoolUnlinkTeacherDocument = gql`
  mutation SchoolUnlinkTeacher($input: SchoolUnlinkTeacherInput!) {
    schoolUnlinkTeacher(input: $input) {
      success
      error
    }
  }
`
export type SchoolUnlinkTeacherMutationFn = Apollo.MutationFunction<
  SchoolUnlinkTeacherMutation,
  SchoolUnlinkTeacherMutationVariables
>

/**
 * __useSchoolUnlinkTeacherMutation__
 *
 * To run a mutation, you first call `useSchoolUnlinkTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolUnlinkTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolUnlinkTeacherMutation, { data, loading, error }] = useSchoolUnlinkTeacherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolUnlinkTeacherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolUnlinkTeacherMutation,
    SchoolUnlinkTeacherMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolUnlinkTeacherMutation,
    SchoolUnlinkTeacherMutationVariables
  >(SchoolUnlinkTeacherDocument, options)
}
export type SchoolUnlinkTeacherMutationHookResult = ReturnType<
  typeof useSchoolUnlinkTeacherMutation
>
export type SchoolUnlinkTeacherMutationResult =
  Apollo.MutationResult<SchoolUnlinkTeacherMutation>
export type SchoolUnlinkTeacherMutationOptions = Apollo.BaseMutationOptions<
  SchoolUnlinkTeacherMutation,
  SchoolUnlinkTeacherMutationVariables
>
export const SchoolUnlinkStudentDocument = gql`
  mutation SchoolUnlinkStudent($input: SchoolUnlinkStudentInput!) {
    schoolUnlinkStudent(input: $input) {
      success
      error
    }
  }
`
export type SchoolUnlinkStudentMutationFn = Apollo.MutationFunction<
  SchoolUnlinkStudentMutation,
  SchoolUnlinkStudentMutationVariables
>

/**
 * __useSchoolUnlinkStudentMutation__
 *
 * To run a mutation, you first call `useSchoolUnlinkStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolUnlinkStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolUnlinkStudentMutation, { data, loading, error }] = useSchoolUnlinkStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolUnlinkStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolUnlinkStudentMutation,
    SchoolUnlinkStudentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolUnlinkStudentMutation,
    SchoolUnlinkStudentMutationVariables
  >(SchoolUnlinkStudentDocument, options)
}
export type SchoolUnlinkStudentMutationHookResult = ReturnType<
  typeof useSchoolUnlinkStudentMutation
>
export type SchoolUnlinkStudentMutationResult =
  Apollo.MutationResult<SchoolUnlinkStudentMutation>
export type SchoolUnlinkStudentMutationOptions = Apollo.BaseMutationOptions<
  SchoolUnlinkStudentMutation,
  SchoolUnlinkStudentMutationVariables
>
export const RegisterGradeDocument = gql`
  mutation RegisterGrade($input: RegisterSchoolGradeInput!) {
    registerGrade(input: $input) {
      success
      error
    }
  }
`
export type RegisterGradeMutationFn = Apollo.MutationFunction<
  RegisterGradeMutation,
  RegisterGradeMutationVariables
>

/**
 * __useRegisterGradeMutation__
 *
 * To run a mutation, you first call `useRegisterGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerGradeMutation, { data, loading, error }] = useRegisterGradeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterGradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterGradeMutation,
    RegisterGradeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegisterGradeMutation,
    RegisterGradeMutationVariables
  >(RegisterGradeDocument, options)
}
export type RegisterGradeMutationHookResult = ReturnType<
  typeof useRegisterGradeMutation
>
export type RegisterGradeMutationResult =
  Apollo.MutationResult<RegisterGradeMutation>
export type RegisterGradeMutationOptions = Apollo.BaseMutationOptions<
  RegisterGradeMutation,
  RegisterGradeMutationVariables
>
export const TeacherLinkSchoolDocument = gql`
  mutation TeacherLinkSchool($input: TeacherLinkSchoolInput!) {
    teacherLinkSchool(input: $input) {
      success
      error
    }
  }
`
export type TeacherLinkSchoolMutationFn = Apollo.MutationFunction<
  TeacherLinkSchoolMutation,
  TeacherLinkSchoolMutationVariables
>

/**
 * __useTeacherLinkSchoolMutation__
 *
 * To run a mutation, you first call `useTeacherLinkSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherLinkSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherLinkSchoolMutation, { data, loading, error }] = useTeacherLinkSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherLinkSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TeacherLinkSchoolMutation,
    TeacherLinkSchoolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TeacherLinkSchoolMutation,
    TeacherLinkSchoolMutationVariables
  >(TeacherLinkSchoolDocument, options)
}
export type TeacherLinkSchoolMutationHookResult = ReturnType<
  typeof useTeacherLinkSchoolMutation
>
export type TeacherLinkSchoolMutationResult =
  Apollo.MutationResult<TeacherLinkSchoolMutation>
export type TeacherLinkSchoolMutationOptions = Apollo.BaseMutationOptions<
  TeacherLinkSchoolMutation,
  TeacherLinkSchoolMutationVariables
>
export const SchoolAddStudentDocument = gql`
  mutation SchoolAddStudent($input: SchoolAddStudentInput!) {
    schoolAddStudent(input: $input) {
      success
      error
    }
  }
`
export type SchoolAddStudentMutationFn = Apollo.MutationFunction<
  SchoolAddStudentMutation,
  SchoolAddStudentMutationVariables
>

/**
 * __useSchoolAddStudentMutation__
 *
 * To run a mutation, you first call `useSchoolAddStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolAddStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolAddStudentMutation, { data, loading, error }] = useSchoolAddStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolAddStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolAddStudentMutation,
    SchoolAddStudentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolAddStudentMutation,
    SchoolAddStudentMutationVariables
  >(SchoolAddStudentDocument, options)
}
export type SchoolAddStudentMutationHookResult = ReturnType<
  typeof useSchoolAddStudentMutation
>
export type SchoolAddStudentMutationResult =
  Apollo.MutationResult<SchoolAddStudentMutation>
export type SchoolAddStudentMutationOptions = Apollo.BaseMutationOptions<
  SchoolAddStudentMutation,
  SchoolAddStudentMutationVariables
>
export const SchoolEditStudentDocument = gql`
  mutation SchoolEditStudent($input: SchoolEditStudentInput!) {
    schoolEditStudent(input: $input) {
      success
      error
    }
  }
`
export type SchoolEditStudentMutationFn = Apollo.MutationFunction<
  SchoolEditStudentMutation,
  SchoolEditStudentMutationVariables
>

/**
 * __useSchoolEditStudentMutation__
 *
 * To run a mutation, you first call `useSchoolEditStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolEditStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolEditStudentMutation, { data, loading, error }] = useSchoolEditStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolEditStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolEditStudentMutation,
    SchoolEditStudentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolEditStudentMutation,
    SchoolEditStudentMutationVariables
  >(SchoolEditStudentDocument, options)
}
export type SchoolEditStudentMutationHookResult = ReturnType<
  typeof useSchoolEditStudentMutation
>
export type SchoolEditStudentMutationResult =
  Apollo.MutationResult<SchoolEditStudentMutation>
export type SchoolEditStudentMutationOptions = Apollo.BaseMutationOptions<
  SchoolEditStudentMutation,
  SchoolEditStudentMutationVariables
>
export const SchoolSubmitStudentQuestionnarieDocument = gql`
  mutation SchoolSubmitStudentQuestionnarie(
    $input: SchoolSubmitStudentQuestionnarieInput!
  ) {
    schoolSubmitStudentQuestionnarie(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentQuestionnarieMutationFn =
  Apollo.MutationFunction<
    SchoolSubmitStudentQuestionnarieMutation,
    SchoolSubmitStudentQuestionnarieMutationVariables
  >

/**
 * __useSchoolSubmitStudentQuestionnarieMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentQuestionnarieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentQuestionnarieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentQuestionnarieMutation, { data, loading, error }] = useSchoolSubmitStudentQuestionnarieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentQuestionnarieMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentQuestionnarieMutation,
    SchoolSubmitStudentQuestionnarieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentQuestionnarieMutation,
    SchoolSubmitStudentQuestionnarieMutationVariables
  >(SchoolSubmitStudentQuestionnarieDocument, options)
}
export type SchoolSubmitStudentQuestionnarieMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentQuestionnarieMutation
>
export type SchoolSubmitStudentQuestionnarieMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentQuestionnarieMutation>
export type SchoolSubmitStudentQuestionnarieMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentQuestionnarieMutation,
    SchoolSubmitStudentQuestionnarieMutationVariables
  >
export const SchoolSubmitStudentTestDocument = gql`
  mutation SchoolSubmitStudentTest($input: SchoolSubmitStudentTestInput!) {
    schoolSubmitStudentTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentTestMutationFn = Apollo.MutationFunction<
  SchoolSubmitStudentTestMutation,
  SchoolSubmitStudentTestMutationVariables
>

/**
 * __useSchoolSubmitStudentTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentTestMutation, { data, loading, error }] = useSchoolSubmitStudentTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentTestMutation,
    SchoolSubmitStudentTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentTestMutation,
    SchoolSubmitStudentTestMutationVariables
  >(SchoolSubmitStudentTestDocument, options)
}
export type SchoolSubmitStudentTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentTestMutation
>
export type SchoolSubmitStudentTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentTestMutation>
export type SchoolSubmitStudentTestMutationOptions = Apollo.BaseMutationOptions<
  SchoolSubmitStudentTestMutation,
  SchoolSubmitStudentTestMutationVariables
>
export const SchoolSubmitStudentGripTestDocument = gql`
  mutation SchoolSubmitStudentGripTest(
    $input: SchoolSubmitStudentGripTestInput!
  ) {
    schoolSubmitStudentGripTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentGripTestMutationFn = Apollo.MutationFunction<
  SchoolSubmitStudentGripTestMutation,
  SchoolSubmitStudentGripTestMutationVariables
>

/**
 * __useSchoolSubmitStudentGripTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentGripTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentGripTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentGripTestMutation, { data, loading, error }] = useSchoolSubmitStudentGripTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentGripTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentGripTestMutation,
    SchoolSubmitStudentGripTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentGripTestMutation,
    SchoolSubmitStudentGripTestMutationVariables
  >(SchoolSubmitStudentGripTestDocument, options)
}
export type SchoolSubmitStudentGripTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentGripTestMutation
>
export type SchoolSubmitStudentGripTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentGripTestMutation>
export type SchoolSubmitStudentGripTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentGripTestMutation,
    SchoolSubmitStudentGripTestMutationVariables
  >
export const SchoolSubmitStudentSitUpsTestDocument = gql`
  mutation SchoolSubmitStudentSitUpsTest(
    $input: SchoolSubmitStudentSitUpsTestInput!
  ) {
    schoolSubmitStudentSitUpsTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentSitUpsTestMutationFn = Apollo.MutationFunction<
  SchoolSubmitStudentSitUpsTestMutation,
  SchoolSubmitStudentSitUpsTestMutationVariables
>

/**
 * __useSchoolSubmitStudentSitUpsTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentSitUpsTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentSitUpsTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentSitUpsTestMutation, { data, loading, error }] = useSchoolSubmitStudentSitUpsTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentSitUpsTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentSitUpsTestMutation,
    SchoolSubmitStudentSitUpsTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentSitUpsTestMutation,
    SchoolSubmitStudentSitUpsTestMutationVariables
  >(SchoolSubmitStudentSitUpsTestDocument, options)
}
export type SchoolSubmitStudentSitUpsTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentSitUpsTestMutation
>
export type SchoolSubmitStudentSitUpsTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentSitUpsTestMutation>
export type SchoolSubmitStudentSitUpsTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentSitUpsTestMutation,
    SchoolSubmitStudentSitUpsTestMutationVariables
  >
export const SchoolSubmitStudentBendingTestDocument = gql`
  mutation SchoolSubmitStudentBendingTest(
    $input: SchoolSubmitStudentBendingTestInput!
  ) {
    schoolSubmitStudentBendingTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentBendingTestMutationFn = Apollo.MutationFunction<
  SchoolSubmitStudentBendingTestMutation,
  SchoolSubmitStudentBendingTestMutationVariables
>

/**
 * __useSchoolSubmitStudentBendingTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentBendingTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentBendingTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentBendingTestMutation, { data, loading, error }] = useSchoolSubmitStudentBendingTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentBendingTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentBendingTestMutation,
    SchoolSubmitStudentBendingTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentBendingTestMutation,
    SchoolSubmitStudentBendingTestMutationVariables
  >(SchoolSubmitStudentBendingTestDocument, options)
}
export type SchoolSubmitStudentBendingTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentBendingTestMutation
>
export type SchoolSubmitStudentBendingTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentBendingTestMutation>
export type SchoolSubmitStudentBendingTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentBendingTestMutation,
    SchoolSubmitStudentBendingTestMutationVariables
  >
export const SchoolSubmitStudentSideJumpTestDocument = gql`
  mutation SchoolSubmitStudentSideJumpTest(
    $input: SchoolSubmitStudentSideJumpTestInput!
  ) {
    schoolSubmitStudentSideJumpTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentSideJumpTestMutationFn = Apollo.MutationFunction<
  SchoolSubmitStudentSideJumpTestMutation,
  SchoolSubmitStudentSideJumpTestMutationVariables
>

/**
 * __useSchoolSubmitStudentSideJumpTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentSideJumpTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentSideJumpTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentSideJumpTestMutation, { data, loading, error }] = useSchoolSubmitStudentSideJumpTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentSideJumpTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentSideJumpTestMutation,
    SchoolSubmitStudentSideJumpTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentSideJumpTestMutation,
    SchoolSubmitStudentSideJumpTestMutationVariables
  >(SchoolSubmitStudentSideJumpTestDocument, options)
}
export type SchoolSubmitStudentSideJumpTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentSideJumpTestMutation
>
export type SchoolSubmitStudentSideJumpTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentSideJumpTestMutation>
export type SchoolSubmitStudentSideJumpTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentSideJumpTestMutation,
    SchoolSubmitStudentSideJumpTestMutationVariables
  >
export const SchoolSubmitStudentEnduranceRunTestDocument = gql`
  mutation SchoolSubmitStudentEnduranceRunTest(
    $input: SchoolSubmitStudentEnduranceRunTestInput!
  ) {
    schoolSubmitStudentEnduranceRunTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentEnduranceRunTestMutationFn =
  Apollo.MutationFunction<
    SchoolSubmitStudentEnduranceRunTestMutation,
    SchoolSubmitStudentEnduranceRunTestMutationVariables
  >

/**
 * __useSchoolSubmitStudentEnduranceRunTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentEnduranceRunTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentEnduranceRunTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentEnduranceRunTestMutation, { data, loading, error }] = useSchoolSubmitStudentEnduranceRunTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentEnduranceRunTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentEnduranceRunTestMutation,
    SchoolSubmitStudentEnduranceRunTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentEnduranceRunTestMutation,
    SchoolSubmitStudentEnduranceRunTestMutationVariables
  >(SchoolSubmitStudentEnduranceRunTestDocument, options)
}
export type SchoolSubmitStudentEnduranceRunTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentEnduranceRunTestMutation
>
export type SchoolSubmitStudentEnduranceRunTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentEnduranceRunTestMutation>
export type SchoolSubmitStudentEnduranceRunTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentEnduranceRunTestMutation,
    SchoolSubmitStudentEnduranceRunTestMutationVariables
  >
export const SchoolSubmitStudentShuttleRunTestDocument = gql`
  mutation SchoolSubmitStudentShuttleRunTest(
    $input: SchoolSubmitStudentShuttleRunTestInput!
  ) {
    schoolSubmitStudentShuttleRunTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentShuttleRunTestMutationFn =
  Apollo.MutationFunction<
    SchoolSubmitStudentShuttleRunTestMutation,
    SchoolSubmitStudentShuttleRunTestMutationVariables
  >

/**
 * __useSchoolSubmitStudentShuttleRunTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentShuttleRunTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentShuttleRunTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentShuttleRunTestMutation, { data, loading, error }] = useSchoolSubmitStudentShuttleRunTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentShuttleRunTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentShuttleRunTestMutation,
    SchoolSubmitStudentShuttleRunTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentShuttleRunTestMutation,
    SchoolSubmitStudentShuttleRunTestMutationVariables
  >(SchoolSubmitStudentShuttleRunTestDocument, options)
}
export type SchoolSubmitStudentShuttleRunTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentShuttleRunTestMutation
>
export type SchoolSubmitStudentShuttleRunTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentShuttleRunTestMutation>
export type SchoolSubmitStudentShuttleRunTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentShuttleRunTestMutation,
    SchoolSubmitStudentShuttleRunTestMutationVariables
  >
export const SchoolSubmitStudentSprintRunTestDocument = gql`
  mutation SchoolSubmitStudentSprintRunTest(
    $input: SchoolSubmitStudentSprintRunTestInput!
  ) {
    schoolSubmitStudentSprintRunTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentSprintRunTestMutationFn =
  Apollo.MutationFunction<
    SchoolSubmitStudentSprintRunTestMutation,
    SchoolSubmitStudentSprintRunTestMutationVariables
  >

/**
 * __useSchoolSubmitStudentSprintRunTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentSprintRunTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentSprintRunTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentSprintRunTestMutation, { data, loading, error }] = useSchoolSubmitStudentSprintRunTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentSprintRunTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentSprintRunTestMutation,
    SchoolSubmitStudentSprintRunTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentSprintRunTestMutation,
    SchoolSubmitStudentSprintRunTestMutationVariables
  >(SchoolSubmitStudentSprintRunTestDocument, options)
}
export type SchoolSubmitStudentSprintRunTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentSprintRunTestMutation
>
export type SchoolSubmitStudentSprintRunTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentSprintRunTestMutation>
export type SchoolSubmitStudentSprintRunTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentSprintRunTestMutation,
    SchoolSubmitStudentSprintRunTestMutationVariables
  >
export const SchoolSubmitStudentStandingJumpTestDocument = gql`
  mutation SchoolSubmitStudentStandingJumpTest(
    $input: SchoolSubmitStudentStandingJumpTestInput!
  ) {
    schoolSubmitStudentStandingJumpTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentStandingJumpTestMutationFn =
  Apollo.MutationFunction<
    SchoolSubmitStudentStandingJumpTestMutation,
    SchoolSubmitStudentStandingJumpTestMutationVariables
  >

/**
 * __useSchoolSubmitStudentStandingJumpTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentStandingJumpTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentStandingJumpTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentStandingJumpTestMutation, { data, loading, error }] = useSchoolSubmitStudentStandingJumpTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentStandingJumpTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentStandingJumpTestMutation,
    SchoolSubmitStudentStandingJumpTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentStandingJumpTestMutation,
    SchoolSubmitStudentStandingJumpTestMutationVariables
  >(SchoolSubmitStudentStandingJumpTestDocument, options)
}
export type SchoolSubmitStudentStandingJumpTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentStandingJumpTestMutation
>
export type SchoolSubmitStudentStandingJumpTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentStandingJumpTestMutation>
export type SchoolSubmitStudentStandingJumpTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentStandingJumpTestMutation,
    SchoolSubmitStudentStandingJumpTestMutationVariables
  >
export const SchoolSubmitStudentHandballThrowTestDocument = gql`
  mutation SchoolSubmitStudentHandballThrowTest(
    $input: SchoolSubmitStudentHandballThrowTestInput!
  ) {
    schoolSubmitStudentHandballThrowTest(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentHandballThrowTestMutationFn =
  Apollo.MutationFunction<
    SchoolSubmitStudentHandballThrowTestMutation,
    SchoolSubmitStudentHandballThrowTestMutationVariables
  >

/**
 * __useSchoolSubmitStudentHandballThrowTestMutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentHandballThrowTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentHandballThrowTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentHandballThrowTestMutation, { data, loading, error }] = useSchoolSubmitStudentHandballThrowTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentHandballThrowTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentHandballThrowTestMutation,
    SchoolSubmitStudentHandballThrowTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentHandballThrowTestMutation,
    SchoolSubmitStudentHandballThrowTestMutationVariables
  >(SchoolSubmitStudentHandballThrowTestDocument, options)
}
export type SchoolSubmitStudentHandballThrowTestMutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentHandballThrowTestMutation
>
export type SchoolSubmitStudentHandballThrowTestMutationResult =
  Apollo.MutationResult<SchoolSubmitStudentHandballThrowTestMutation>
export type SchoolSubmitStudentHandballThrowTestMutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentHandballThrowTestMutation,
    SchoolSubmitStudentHandballThrowTestMutationVariables
  >
export const StudentLinkSchoolDocument = gql`
  mutation StudentLinkSchool($input: StudentLinkSchoolInput!) {
    studentLinkSchool(input: $input) {
      token
      error
    }
  }
`
export type StudentLinkSchoolMutationFn = Apollo.MutationFunction<
  StudentLinkSchoolMutation,
  StudentLinkSchoolMutationVariables
>

/**
 * __useStudentLinkSchoolMutation__
 *
 * To run a mutation, you first call `useStudentLinkSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentLinkSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentLinkSchoolMutation, { data, loading, error }] = useStudentLinkSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentLinkSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StudentLinkSchoolMutation,
    StudentLinkSchoolMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StudentLinkSchoolMutation,
    StudentLinkSchoolMutationVariables
  >(StudentLinkSchoolDocument, options)
}
export type StudentLinkSchoolMutationHookResult = ReturnType<
  typeof useStudentLinkSchoolMutation
>
export type StudentLinkSchoolMutationResult =
  Apollo.MutationResult<StudentLinkSchoolMutation>
export type StudentLinkSchoolMutationOptions = Apollo.BaseMutationOptions<
  StudentLinkSchoolMutation,
  StudentLinkSchoolMutationVariables
>
export const StudentSubmitQuestionnarieDocument = gql`
  mutation StudentSubmitQuestionnarie($input: StudentQuestionnarieInput!) {
    studentSubmitQuestionnarie(input: $input) {
      success
      error
    }
  }
`
export type StudentSubmitQuestionnarieMutationFn = Apollo.MutationFunction<
  StudentSubmitQuestionnarieMutation,
  StudentSubmitQuestionnarieMutationVariables
>

/**
 * __useStudentSubmitQuestionnarieMutation__
 *
 * To run a mutation, you first call `useStudentSubmitQuestionnarieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentSubmitQuestionnarieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentSubmitQuestionnarieMutation, { data, loading, error }] = useStudentSubmitQuestionnarieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentSubmitQuestionnarieMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StudentSubmitQuestionnarieMutation,
    StudentSubmitQuestionnarieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StudentSubmitQuestionnarieMutation,
    StudentSubmitQuestionnarieMutationVariables
  >(StudentSubmitQuestionnarieDocument, options)
}
export type StudentSubmitQuestionnarieMutationHookResult = ReturnType<
  typeof useStudentSubmitQuestionnarieMutation
>
export type StudentSubmitQuestionnarieMutationResult =
  Apollo.MutationResult<StudentSubmitQuestionnarieMutation>
export type StudentSubmitQuestionnarieMutationOptions =
  Apollo.BaseMutationOptions<
    StudentSubmitQuestionnarieMutation,
    StudentSubmitQuestionnarieMutationVariables
  >
export const SchoolSubmitStudentQuestionnaireV2Document = gql`
  mutation SchoolSubmitStudentQuestionnaireV2(
    $input: SchoolSubmitStudentQuestionnaireV2Input!
  ) {
    schoolSubmitStudentQuestionnaireV2(input: $input) {
      success
      error
    }
  }
`
export type SchoolSubmitStudentQuestionnaireV2MutationFn =
  Apollo.MutationFunction<
    SchoolSubmitStudentQuestionnaireV2Mutation,
    SchoolSubmitStudentQuestionnaireV2MutationVariables
  >

/**
 * __useSchoolSubmitStudentQuestionnaireV2Mutation__
 *
 * To run a mutation, you first call `useSchoolSubmitStudentQuestionnaireV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolSubmitStudentQuestionnaireV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolSubmitStudentQuestionnaireV2Mutation, { data, loading, error }] = useSchoolSubmitStudentQuestionnaireV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolSubmitStudentQuestionnaireV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchoolSubmitStudentQuestionnaireV2Mutation,
    SchoolSubmitStudentQuestionnaireV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchoolSubmitStudentQuestionnaireV2Mutation,
    SchoolSubmitStudentQuestionnaireV2MutationVariables
  >(SchoolSubmitStudentQuestionnaireV2Document, options)
}
export type SchoolSubmitStudentQuestionnaireV2MutationHookResult = ReturnType<
  typeof useSchoolSubmitStudentQuestionnaireV2Mutation
>
export type SchoolSubmitStudentQuestionnaireV2MutationResult =
  Apollo.MutationResult<SchoolSubmitStudentQuestionnaireV2Mutation>
export type SchoolSubmitStudentQuestionnaireV2MutationOptions =
  Apollo.BaseMutationOptions<
    SchoolSubmitStudentQuestionnaireV2Mutation,
    SchoolSubmitStudentQuestionnaireV2MutationVariables
  >
export const StudentSubmitQuestionnaireV2Document = gql`
  mutation StudentSubmitQuestionnaireV2($input: StudentQuestionnaireV2Input!) {
    studentSubmitQuestionnaireV2(input: $input) {
      success
      error
    }
  }
`
export type StudentSubmitQuestionnaireV2MutationFn = Apollo.MutationFunction<
  StudentSubmitQuestionnaireV2Mutation,
  StudentSubmitQuestionnaireV2MutationVariables
>

/**
 * __useStudentSubmitQuestionnaireV2Mutation__
 *
 * To run a mutation, you first call `useStudentSubmitQuestionnaireV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentSubmitQuestionnaireV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentSubmitQuestionnaireV2Mutation, { data, loading, error }] = useStudentSubmitQuestionnaireV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentSubmitQuestionnaireV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    StudentSubmitQuestionnaireV2Mutation,
    StudentSubmitQuestionnaireV2MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StudentSubmitQuestionnaireV2Mutation,
    StudentSubmitQuestionnaireV2MutationVariables
  >(StudentSubmitQuestionnaireV2Document, options)
}
export type StudentSubmitQuestionnaireV2MutationHookResult = ReturnType<
  typeof useStudentSubmitQuestionnaireV2Mutation
>
export type StudentSubmitQuestionnaireV2MutationResult =
  Apollo.MutationResult<StudentSubmitQuestionnaireV2Mutation>
export type StudentSubmitQuestionnaireV2MutationOptions =
  Apollo.BaseMutationOptions<
    StudentSubmitQuestionnaireV2Mutation,
    StudentSubmitQuestionnaireV2MutationVariables
  >
export const AnalyticsEventDocument = gql`
  mutation AnalyticsEvent($input: JSON!) {
    analyticsEvent(input: $input)
  }
`
export type AnalyticsEventMutationFn = Apollo.MutationFunction<
  AnalyticsEventMutation,
  AnalyticsEventMutationVariables
>

/**
 * __useAnalyticsEventMutation__
 *
 * To run a mutation, you first call `useAnalyticsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyticsEventMutation, { data, loading, error }] = useAnalyticsEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnalyticsEventMutation,
    AnalyticsEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AnalyticsEventMutation,
    AnalyticsEventMutationVariables
  >(AnalyticsEventDocument, options)
}
export type AnalyticsEventMutationHookResult = ReturnType<
  typeof useAnalyticsEventMutation
>
export type AnalyticsEventMutationResult =
  Apollo.MutationResult<AnalyticsEventMutation>
export type AnalyticsEventMutationOptions = Apollo.BaseMutationOptions<
  AnalyticsEventMutation,
  AnalyticsEventMutationVariables
>
export const AnalyticsEventsDocument = gql`
  mutation AnalyticsEvents($input: [JSON!]!) {
    analyticsEvents(input: $input)
  }
`
export type AnalyticsEventsMutationFn = Apollo.MutationFunction<
  AnalyticsEventsMutation,
  AnalyticsEventsMutationVariables
>

/**
 * __useAnalyticsEventsMutation__
 *
 * To run a mutation, you first call `useAnalyticsEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyticsEventsMutation, { data, loading, error }] = useAnalyticsEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnalyticsEventsMutation,
    AnalyticsEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AnalyticsEventsMutation,
    AnalyticsEventsMutationVariables
  >(AnalyticsEventsDocument, options)
}
export type AnalyticsEventsMutationHookResult = ReturnType<
  typeof useAnalyticsEventsMutation
>
export type AnalyticsEventsMutationResult =
  Apollo.MutationResult<AnalyticsEventsMutation>
export type AnalyticsEventsMutationOptions = Apollo.BaseMutationOptions<
  AnalyticsEventsMutation,
  AnalyticsEventsMutationVariables
>
export const AreasDocument = gql`
  query Areas($input: AreasInput) {
    areas(input: $input) {
      data {
        _id
        name
      }
    }
  }
`

/**
 * __useAreasQuery__
 *
 * To run a query within a React component, call `useAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreasQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<AreasQuery, AreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AreasQuery, AreasQueryVariables>(
    AreasDocument,
    options,
  )
}
export function useAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreasQuery, AreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AreasQuery, AreasQueryVariables>(
    AreasDocument,
    options,
  )
}
export type AreasQueryHookResult = ReturnType<typeof useAreasQuery>
export type AreasLazyQueryHookResult = ReturnType<typeof useAreasLazyQuery>
export type AreasQueryResult = Apollo.QueryResult<
  AreasQuery,
  AreasQueryVariables
>
export const PrefecturesDocument = gql`
  query Prefectures($input: PrefecturesInput) {
    prefectures(input: $input) {
      data {
        _id
        name
        code
        area
      }
    }
  }
`

/**
 * __usePrefecturesQuery__
 *
 * To run a query within a React component, call `usePrefecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrefecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrefecturesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrefecturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrefecturesQuery,
    PrefecturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PrefecturesQuery, PrefecturesQueryVariables>(
    PrefecturesDocument,
    options,
  )
}
export function usePrefecturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrefecturesQuery,
    PrefecturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PrefecturesQuery, PrefecturesQueryVariables>(
    PrefecturesDocument,
    options,
  )
}
export type PrefecturesQueryHookResult = ReturnType<typeof usePrefecturesQuery>
export type PrefecturesLazyQueryHookResult = ReturnType<
  typeof usePrefecturesLazyQuery
>
export type PrefecturesQueryResult = Apollo.QueryResult<
  PrefecturesQuery,
  PrefecturesQueryVariables
>
export const CitiesDocument = gql`
  query Cities($input: CitiesInput!) {
    cities(input: $input) {
      data {
        _id
        name
      }
    }
  }
`

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCitiesQuery(
  baseOptions: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  )
}
export function useCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  )
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>
export type CitiesQueryResult = Apollo.QueryResult<
  CitiesQuery,
  CitiesQueryVariables
>
export const JapanSchoolsDocument = gql`
  query JapanSchools($input: JapanSchoolsInput!) {
    japanSchools(input: $input) {
      totalCount
      data {
        _id
        schoolName
        schoolCode
        schoolAddress
        registered
        school {
          _id
          attributes {
            email
            website
            telephone
            fax
            schoolAdminName
            schoolAdminEmail
            onboardComplete
            testYears
            schoolAdminInvitationCode
            teacherInvitationCode
            otherSchoolCode
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

/**
 * __useJapanSchoolsQuery__
 *
 * To run a query within a React component, call `useJapanSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJapanSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJapanSchoolsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJapanSchoolsQuery(
  baseOptions: Apollo.QueryHookOptions<
    JapanSchoolsQuery,
    JapanSchoolsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JapanSchoolsQuery, JapanSchoolsQueryVariables>(
    JapanSchoolsDocument,
    options,
  )
}
export function useJapanSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JapanSchoolsQuery,
    JapanSchoolsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JapanSchoolsQuery, JapanSchoolsQueryVariables>(
    JapanSchoolsDocument,
    options,
  )
}
export type JapanSchoolsQueryHookResult = ReturnType<
  typeof useJapanSchoolsQuery
>
export type JapanSchoolsLazyQueryHookResult = ReturnType<
  typeof useJapanSchoolsLazyQuery
>
export type JapanSchoolsQueryResult = Apollo.QueryResult<
  JapanSchoolsQuery,
  JapanSchoolsQueryVariables
>
export const JapanSchoolCategoriesDocument = gql`
  query JapanSchoolCategories {
    japanSchoolCategories {
      data {
        code
        name
      }
    }
  }
`

/**
 * __useJapanSchoolCategoriesQuery__
 *
 * To run a query within a React component, call `useJapanSchoolCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJapanSchoolCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJapanSchoolCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJapanSchoolCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >(JapanSchoolCategoriesDocument, options)
}
export function useJapanSchoolCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    JapanSchoolCategoriesQuery,
    JapanSchoolCategoriesQueryVariables
  >(JapanSchoolCategoriesDocument, options)
}
export type JapanSchoolCategoriesQueryHookResult = ReturnType<
  typeof useJapanSchoolCategoriesQuery
>
export type JapanSchoolCategoriesLazyQueryHookResult = ReturnType<
  typeof useJapanSchoolCategoriesLazyQuery
>
export type JapanSchoolCategoriesQueryResult = Apollo.QueryResult<
  JapanSchoolCategoriesQuery,
  JapanSchoolCategoriesQueryVariables
>
export const JapanSchoolTypesDocument = gql`
  query JapanSchoolTypes {
    japanSchoolTypes {
      data {
        code
        name
      }
    }
  }
`

/**
 * __useJapanSchoolTypesQuery__
 *
 * To run a query within a React component, call `useJapanSchoolTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJapanSchoolTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJapanSchoolTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useJapanSchoolTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    JapanSchoolTypesQuery,
    JapanSchoolTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JapanSchoolTypesQuery, JapanSchoolTypesQueryVariables>(
    JapanSchoolTypesDocument,
    options,
  )
}
export function useJapanSchoolTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JapanSchoolTypesQuery,
    JapanSchoolTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    JapanSchoolTypesQuery,
    JapanSchoolTypesQueryVariables
  >(JapanSchoolTypesDocument, options)
}
export type JapanSchoolTypesQueryHookResult = ReturnType<
  typeof useJapanSchoolTypesQuery
>
export type JapanSchoolTypesLazyQueryHookResult = ReturnType<
  typeof useJapanSchoolTypesLazyQuery
>
export type JapanSchoolTypesQueryResult = Apollo.QueryResult<
  JapanSchoolTypesQuery,
  JapanSchoolTypesQueryVariables
>
export const SearchAreasHintsDocument = gql`
  query SearchAreasHints($input: SearchHintsInput!) {
    searchAreasHints(input: $input) {
      data {
        term
        score
      }
    }
  }
`

/**
 * __useSearchAreasHintsQuery__
 *
 * To run a query within a React component, call `useSearchAreasHintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAreasHintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAreasHintsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchAreasHintsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAreasHintsQuery,
    SearchAreasHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchAreasHintsQuery, SearchAreasHintsQueryVariables>(
    SearchAreasHintsDocument,
    options,
  )
}
export function useSearchAreasHintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAreasHintsQuery,
    SearchAreasHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchAreasHintsQuery,
    SearchAreasHintsQueryVariables
  >(SearchAreasHintsDocument, options)
}
export type SearchAreasHintsQueryHookResult = ReturnType<
  typeof useSearchAreasHintsQuery
>
export type SearchAreasHintsLazyQueryHookResult = ReturnType<
  typeof useSearchAreasHintsLazyQuery
>
export type SearchAreasHintsQueryResult = Apollo.QueryResult<
  SearchAreasHintsQuery,
  SearchAreasHintsQueryVariables
>
export const SearchPrefecturesHintsDocument = gql`
  query SearchPrefecturesHints($input: SearchPrefecturesHintsInput!) {
    searchPrefecturesHints(input: $input) {
      data {
        term
        score
      }
    }
  }
`

/**
 * __useSearchPrefecturesHintsQuery__
 *
 * To run a query within a React component, call `useSearchPrefecturesHintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPrefecturesHintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPrefecturesHintsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchPrefecturesHintsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPrefecturesHintsQuery,
    SearchPrefecturesHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchPrefecturesHintsQuery,
    SearchPrefecturesHintsQueryVariables
  >(SearchPrefecturesHintsDocument, options)
}
export function useSearchPrefecturesHintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPrefecturesHintsQuery,
    SearchPrefecturesHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchPrefecturesHintsQuery,
    SearchPrefecturesHintsQueryVariables
  >(SearchPrefecturesHintsDocument, options)
}
export type SearchPrefecturesHintsQueryHookResult = ReturnType<
  typeof useSearchPrefecturesHintsQuery
>
export type SearchPrefecturesHintsLazyQueryHookResult = ReturnType<
  typeof useSearchPrefecturesHintsLazyQuery
>
export type SearchPrefecturesHintsQueryResult = Apollo.QueryResult<
  SearchPrefecturesHintsQuery,
  SearchPrefecturesHintsQueryVariables
>
export const SearchCitiesHintsDocument = gql`
  query SearchCitiesHints($input: SearchCitiesHintsInput!) {
    searchCitiesHints(input: $input) {
      data {
        term
        score
      }
    }
  }
`

/**
 * __useSearchCitiesHintsQuery__
 *
 * To run a query within a React component, call `useSearchCitiesHintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCitiesHintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCitiesHintsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchCitiesHintsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchCitiesHintsQuery,
    SearchCitiesHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchCitiesHintsQuery,
    SearchCitiesHintsQueryVariables
  >(SearchCitiesHintsDocument, options)
}
export function useSearchCitiesHintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCitiesHintsQuery,
    SearchCitiesHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchCitiesHintsQuery,
    SearchCitiesHintsQueryVariables
  >(SearchCitiesHintsDocument, options)
}
export type SearchCitiesHintsQueryHookResult = ReturnType<
  typeof useSearchCitiesHintsQuery
>
export type SearchCitiesHintsLazyQueryHookResult = ReturnType<
  typeof useSearchCitiesHintsLazyQuery
>
export type SearchCitiesHintsQueryResult = Apollo.QueryResult<
  SearchCitiesHintsQuery,
  SearchCitiesHintsQueryVariables
>
export const SearchJapanSchoolsHintsDocument = gql`
  query SearchJapanSchoolsHints($input: SearchJapanSchoolsHintsInput!) {
    searchJapanSchoolsHints(input: $input) {
      data {
        term
        score
      }
    }
  }
`

/**
 * __useSearchJapanSchoolsHintsQuery__
 *
 * To run a query within a React component, call `useSearchJapanSchoolsHintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchJapanSchoolsHintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchJapanSchoolsHintsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchJapanSchoolsHintsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchJapanSchoolsHintsQuery,
    SearchJapanSchoolsHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchJapanSchoolsHintsQuery,
    SearchJapanSchoolsHintsQueryVariables
  >(SearchJapanSchoolsHintsDocument, options)
}
export function useSearchJapanSchoolsHintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchJapanSchoolsHintsQuery,
    SearchJapanSchoolsHintsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchJapanSchoolsHintsQuery,
    SearchJapanSchoolsHintsQueryVariables
  >(SearchJapanSchoolsHintsDocument, options)
}
export type SearchJapanSchoolsHintsQueryHookResult = ReturnType<
  typeof useSearchJapanSchoolsHintsQuery
>
export type SearchJapanSchoolsHintsLazyQueryHookResult = ReturnType<
  typeof useSearchJapanSchoolsHintsLazyQuery
>
export type SearchJapanSchoolsHintsQueryResult = Apollo.QueryResult<
  SearchJapanSchoolsHintsQuery,
  SearchJapanSchoolsHintsQueryVariables
>
export const SchoolsDocument = gql`
  query Schools($input: SchoolsInput!) {
    schools(input: $input) {
      data {
        _id
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolsQuery(
  baseOptions: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(
    SchoolsDocument,
    options,
  )
}
export function useSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolsQuery,
    SchoolsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(
    SchoolsDocument,
    options,
  )
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>
export type SchoolsQueryResult = Apollo.QueryResult<
  SchoolsQuery,
  SchoolsQueryVariables
>
export const GradesDocument = gql`
  query Grades($input: SchoolGradesInput!) {
    grades(input: $input) {
      data {
        testYear
        schoolGrade
        numberOfClasses
        numberOfStudentsPerClass
        isEnduranceRunInputActive
      }
      count
      error
    }
  }
`

/**
 * __useGradesQuery__
 *
 * To run a query within a React component, call `useGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGradesQuery(
  baseOptions: Apollo.QueryHookOptions<GradesQuery, GradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GradesQuery, GradesQueryVariables>(
    GradesDocument,
    options,
  )
}
export function useGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GradesQuery, GradesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GradesQuery, GradesQueryVariables>(
    GradesDocument,
    options,
  )
}
export type GradesQueryHookResult = ReturnType<typeof useGradesQuery>
export type GradesLazyQueryHookResult = ReturnType<typeof useGradesLazyQuery>
export type GradesQueryResult = Apollo.QueryResult<
  GradesQuery,
  GradesQueryVariables
>
export const TeachersDocument = gql`
  query Teachers($input: TeachersInput!) {
    teachers(input: $input) {
      data {
        _id
        attributes {
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          email
          role
          schoolGrade
          schoolClass
        }
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useTeachersQuery__
 *
 * To run a query within a React component, call `useTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeachersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeachersQuery(
  baseOptions: Apollo.QueryHookOptions<TeachersQuery, TeachersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeachersQuery, TeachersQueryVariables>(
    TeachersDocument,
    options,
  )
}
export function useTeachersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeachersQuery,
    TeachersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeachersQuery, TeachersQueryVariables>(
    TeachersDocument,
    options,
  )
}
export type TeachersQueryHookResult = ReturnType<typeof useTeachersQuery>
export type TeachersLazyQueryHookResult = ReturnType<
  typeof useTeachersLazyQuery
>
export type TeachersQueryResult = Apollo.QueryResult<
  TeachersQuery,
  TeachersQueryVariables
>
export const StudentsDocument = gql`
  query Students($input: StudentsInput!) {
    students(input: $input) {
      data {
        _id
        username
        attributes {
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          birthday
          gender
          schoolName
          schoolGrade
          schoolClass
          schoolAttendanceNumber
          studentNumber
          email
          testResults {
            testYear
            points
            rank
            age
            grip {
              gripLeft1
              gripLeft2
              gripRight1
              gripRight2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            sitUps {
              sitUps
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            bending {
              bending1
              bending2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            sideJump {
              sideJump1
              sideJump2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            shuttleRun {
              shuttleRunCount
              shuttleRunGainOxygen
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            sprintRun {
              sprintRunSeconds
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            standingJump {
              standingJump1
              standingJump2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            handballThrow {
              handballThrow1
              handballThrow2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            enduranceRun {
              runningTime
              runningTimeSeconds
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            questionnaire {
              belongsToSportsClub
              excerciseActivity
              dailyExcerciseTime
              breakfast
              sleepTime
              tvTime
              height
              weight
              sittingHeight
            }
            questionnaireV2 {
              height
              weight
              sittingHeight
              answers
            }
            sizeTest {
              height
              weight
            }
            questionnaireV3 {
              q1
              q2
              q3
              q4
              q5
              q6
              q7
              q8
              q9
            }
          }
        }
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentsQuery(
  baseOptions: Apollo.QueryHookOptions<StudentsQuery, StudentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StudentsQuery, StudentsQueryVariables>(
    StudentsDocument,
    options,
  )
}
export function useStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentsQuery,
    StudentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StudentsQuery, StudentsQueryVariables>(
    StudentsDocument,
    options,
  )
}
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>
export type StudentsLazyQueryHookResult = ReturnType<
  typeof useStudentsLazyQuery
>
export type StudentsQueryResult = Apollo.QueryResult<
  StudentsQuery,
  StudentsQueryVariables
>
export const RenewTokenDocument = gql`
  query RenewToken {
    me {
      token
      error
    }
  }
`

/**
 * __useRenewTokenQuery__
 *
 * To run a query within a React component, call `useRenewTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenewTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenewTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useRenewTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RenewTokenQuery,
    RenewTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RenewTokenQuery, RenewTokenQueryVariables>(
    RenewTokenDocument,
    options,
  )
}
export function useRenewTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RenewTokenQuery,
    RenewTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RenewTokenQuery, RenewTokenQueryVariables>(
    RenewTokenDocument,
    options,
  )
}
export type RenewTokenQueryHookResult = ReturnType<typeof useRenewTokenQuery>
export type RenewTokenLazyQueryHookResult = ReturnType<
  typeof useRenewTokenLazyQuery
>
export type RenewTokenQueryResult = Apollo.QueryResult<
  RenewTokenQuery,
  RenewTokenQueryVariables
>
export const TeacherDocument = gql`
  query Teacher {
    me {
      token
      error
      data {
        _id
        attributes {
          role
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          schoolName
          schoolCategoryCode
          email
          schoolGrade
          schoolClass
        }
        relationships {
          schools {
            _id
            attributes {
              email
              fax
              telephone
              website
              schoolName
              schoolCategoryCode
              schoolAdminName
              schoolAddress
              onboardComplete
              testYears
              isStudentInputActive
              isEnduranceRunInputActive
              questions {
                question
                options
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useTeacherQuery__
 *
 * To run a query within a React component, call `useTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherQuery(
  baseOptions?: Apollo.QueryHookOptions<TeacherQuery, TeacherQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeacherQuery, TeacherQueryVariables>(
    TeacherDocument,
    options,
  )
}
export function useTeacherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeacherQuery,
    TeacherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeacherQuery, TeacherQueryVariables>(
    TeacherDocument,
    options,
  )
}
export type TeacherQueryHookResult = ReturnType<typeof useTeacherQuery>
export type TeacherLazyQueryHookResult = ReturnType<typeof useTeacherLazyQuery>
export type TeacherQueryResult = Apollo.QueryResult<
  TeacherQuery,
  TeacherQueryVariables
>
export const MeDocument = gql`
  query Me {
    me {
      token
      error
      data {
        _id
        attributes {
          role
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          gender
          birthday
          schoolName
          schoolCategoryCode
          schoolGrade
          schoolClass
          schoolAttendanceNumber
          studentNumber
          email
          testResults {
            testYear
            points
            rank
            age
            grip {
              gripLeft1
              gripLeft2
              gripRight1
              gripRight2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            sitUps {
              sitUps
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            bending {
              bending1
              bending2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            sideJump {
              sideJump1
              sideJump2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            shuttleRun {
              shuttleRunCount
              shuttleRunGainOxygen
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            sprintRun {
              sprintRunSeconds
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            standingJump {
              standingJump1
              standingJump2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            handballThrow {
              handballThrow1
              handballThrow2
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            enduranceRun {
              runningTime
              runningTimeSeconds
              isNotMeasurable
              value
              points
              memo
              rating
              comment
            }
            sizeTest {
              height
              weight
            }
            questionnaire {
              belongsToSportsClub
              excerciseActivity
              dailyExcerciseTime
              breakfast
              sleepTime
              tvTime
              height
              weight
              sittingHeight
            }
            questionnaireV2 {
              height
              weight
              sittingHeight
              answers
            }
            questionnaireV3 {
              q1
              q2
              q3
              q4
              q5
              q6
              q7
              q8
              q9
            }
          }
        }
        relationships {
          schools {
            _id
            attributes {
              email
              fax
              telephone
              website
              schoolName
              schoolCategoryCode
              schoolAdminName
              schoolAddress
              onboardComplete
              testYears
              isStudentInputActive
              isEnduranceRunInputActive
              questions {
                question
                options
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const ReportCountSummaryDocument = gql`
  query ReportCountSummary($input: ReportCountSummaryInput!) {
    reportCountSummary(input: $input) {
      data {
        elementarySchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
        juniorHighSchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
        allDayHighSchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
        fixTimeHighSchoolReport {
          schoolCount
          reports {
            grade
            male {
              totalCount
              samplingCount
            }
            female {
              totalCount
              samplingCount
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportCountSummaryQuery__
 *
 * To run a query within a React component, call `useReportCountSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCountSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCountSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCountSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >(ReportCountSummaryDocument, options)
}
export function useReportCountSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportCountSummaryQuery,
    ReportCountSummaryQueryVariables
  >(ReportCountSummaryDocument, options)
}
export type ReportCountSummaryQueryHookResult = ReturnType<
  typeof useReportCountSummaryQuery
>
export type ReportCountSummaryLazyQueryHookResult = ReturnType<
  typeof useReportCountSummaryLazyQuery
>
export type ReportCountSummaryQueryResult = Apollo.QueryResult<
  ReportCountSummaryQuery,
  ReportCountSummaryQueryVariables
>
export const ReportBodySummaryDocument = gql`
  query ReportBodySummary($input: ReportBodySummaryInput!) {
    reportBodySummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
        allDayHighSchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
        fixTimeHighSchoolReport {
          reports {
            grade
            sampleCount
            heightAvg
            weightAvg
            heightDev
            weightDev
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportBodySummaryQuery__
 *
 * To run a query within a React component, call `useReportBodySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBodySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBodySummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportBodySummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >(ReportBodySummaryDocument, options)
}
export function useReportBodySummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportBodySummaryQuery,
    ReportBodySummaryQueryVariables
  >(ReportBodySummaryDocument, options)
}
export type ReportBodySummaryQueryHookResult = ReturnType<
  typeof useReportBodySummaryQuery
>
export type ReportBodySummaryLazyQueryHookResult = ReturnType<
  typeof useReportBodySummaryLazyQuery
>
export type ReportBodySummaryQueryResult = Apollo.QueryResult<
  ReportBodySummaryQuery,
  ReportBodySummaryQueryVariables
>
export const ReportTestSummaryDocument = gql`
  query ReportTestSummary($input: ReportTestSummaryInput!) {
    reportTestSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        allDayHighSchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        fixTimeHighSchoolReport {
          reports {
            grade
            average {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            deviation {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportTestSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >(ReportTestSummaryDocument, options)
}
export function useReportTestSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestSummaryQuery,
    ReportTestSummaryQueryVariables
  >(ReportTestSummaryDocument, options)
}
export type ReportTestSummaryQueryHookResult = ReturnType<
  typeof useReportTestSummaryQuery
>
export type ReportTestSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestSummaryLazyQuery
>
export type ReportTestSummaryQueryResult = Apollo.QueryResult<
  ReportTestSummaryQuery,
  ReportTestSummaryQueryVariables
>
export const ReportTestComparisonSummaryDocument = gql`
  query ReportTestComparisonSummary($input: ReportTestComparisonSummaryInput!) {
    reportTestComparisonSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            national {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            national {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
        highSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
            national {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportTestComparisonSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestComparisonSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestComparisonSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestComparisonSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestComparisonSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >(ReportTestComparisonSummaryDocument, options)
}
export function useReportTestComparisonSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestComparisonSummaryQuery,
    ReportTestComparisonSummaryQueryVariables
  >(ReportTestComparisonSummaryDocument, options)
}
export type ReportTestComparisonSummaryQueryHookResult = ReturnType<
  typeof useReportTestComparisonSummaryQuery
>
export type ReportTestComparisonSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestComparisonSummaryLazyQuery
>
export type ReportTestComparisonSummaryQueryResult = Apollo.QueryResult<
  ReportTestComparisonSummaryQuery,
  ReportTestComparisonSummaryQueryVariables
>
export const ReportAverageScoreGraphSummaryDocument = gql`
  query ReportAverageScoreGraphSummary(
    $input: ReportAverageScoreGraphSummaryInput!
  ) {
    reportAverageScoreGraphSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            year
            grades {
              grade
              score {
                grip
                sitUps
                bending
                sideJump
                shuttleRun
                sprintRun
                standingJump
                handballThrow
                totalScore
              }
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            year
            grades {
              grade
              score {
                grip
                sitUps
                bending
                sideJump
                shuttleRun
                sprintRun
                standingJump
                handballThrow
                totalScore
              }
            }
          }
        }
        highSchoolReport {
          reports {
            year
            grades {
              grade
              score {
                grip
                sitUps
                bending
                sideJump
                shuttleRun
                sprintRun
                standingJump
                handballThrow
                totalScore
              }
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportAverageScoreGraphSummaryQuery__
 *
 * To run a query within a React component, call `useReportAverageScoreGraphSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportAverageScoreGraphSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportAverageScoreGraphSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportAverageScoreGraphSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >(ReportAverageScoreGraphSummaryDocument, options)
}
export function useReportAverageScoreGraphSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportAverageScoreGraphSummaryQuery,
    ReportAverageScoreGraphSummaryQueryVariables
  >(ReportAverageScoreGraphSummaryDocument, options)
}
export type ReportAverageScoreGraphSummaryQueryHookResult = ReturnType<
  typeof useReportAverageScoreGraphSummaryQuery
>
export type ReportAverageScoreGraphSummaryLazyQueryHookResult = ReturnType<
  typeof useReportAverageScoreGraphSummaryLazyQuery
>
export type ReportAverageScoreGraphSummaryQueryResult = Apollo.QueryResult<
  ReportAverageScoreGraphSummaryQuery,
  ReportAverageScoreGraphSummaryQueryVariables
>
export const ReportTestRankSummaryDocument = gql`
  query ReportTestRankSummary($input: ReportTestRankSummaryInput!) {
    reportTestRankSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            totalCount
            countByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
            rateByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            totalCount
            countByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
            rateByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
          }
        }
        allDayHighSchoolReport {
          reports {
            grade
            totalCount
            countByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
            rateByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
          }
        }
        fixTimeHighSchoolReport {
          reports {
            grade
            totalCount
            countByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
            rateByRank {
              rankA
              rankB
              rankC
              rankD
              rankE
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportTestRankSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestRankSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestRankSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestRankSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestRankSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestRankSummaryQuery,
    ReportTestRankSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestRankSummaryQuery,
    ReportTestRankSummaryQueryVariables
  >(ReportTestRankSummaryDocument, options)
}
export function useReportTestRankSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestRankSummaryQuery,
    ReportTestRankSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestRankSummaryQuery,
    ReportTestRankSummaryQueryVariables
  >(ReportTestRankSummaryDocument, options)
}
export type ReportTestRankSummaryQueryHookResult = ReturnType<
  typeof useReportTestRankSummaryQuery
>
export type ReportTestRankSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestRankSummaryLazyQuery
>
export type ReportTestRankSummaryQueryResult = Apollo.QueryResult<
  ReportTestRankSummaryQuery,
  ReportTestRankSummaryQueryVariables
>
export const ReportQuestionnaireSummaryDocument = gql`
  query ReportQuestionnaireSummary($input: ReportQuestionnaireSummaryInput!) {
    reportQuestionnaireSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            countByAnswer {
              q1 {
                k
                v
              }
              q2 {
                k
                v
              }
              q3 {
                k
                v
              }
              q4 {
                k
                v
              }
              q5 {
                k
                v
              }
              q6 {
                k
                v
              }
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            countByAnswer {
              q1 {
                k
                v
              }
              q2 {
                k
                v
              }
              q3 {
                k
                v
              }
              q4 {
                k
                v
              }
              q5 {
                k
                v
              }
              q6 {
                k
                v
              }
            }
          }
        }
        highSchoolReport {
          reports {
            grade
            countByAnswer {
              q1 {
                k
                v
              }
              q2 {
                k
                v
              }
              q3 {
                k
                v
              }
              q4 {
                k
                v
              }
              q5 {
                k
                v
              }
              q6 {
                k
                v
              }
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportQuestionnaireSummaryQuery__
 *
 * To run a query within a React component, call `useReportQuestionnaireSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuestionnaireSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuestionnaireSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportQuestionnaireSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportQuestionnaireSummaryQuery,
    ReportQuestionnaireSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportQuestionnaireSummaryQuery,
    ReportQuestionnaireSummaryQueryVariables
  >(ReportQuestionnaireSummaryDocument, options)
}
export function useReportQuestionnaireSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportQuestionnaireSummaryQuery,
    ReportQuestionnaireSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportQuestionnaireSummaryQuery,
    ReportQuestionnaireSummaryQueryVariables
  >(ReportQuestionnaireSummaryDocument, options)
}
export type ReportQuestionnaireSummaryQueryHookResult = ReturnType<
  typeof useReportQuestionnaireSummaryQuery
>
export type ReportQuestionnaireSummaryLazyQueryHookResult = ReturnType<
  typeof useReportQuestionnaireSummaryLazyQuery
>
export type ReportQuestionnaireSummaryQueryResult = Apollo.QueryResult<
  ReportQuestionnaireSummaryQuery,
  ReportQuestionnaireSummaryQueryVariables
>
export const ReportTestScoreChartSummaryDocument = gql`
  query ReportTestScoreChartSummary($input: ReportTestScoreChartSummaryInput!) {
    reportTestScoreChartSummary(input: $input) {
      data {
        elementarySchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
              height
              weight
            }
          }
        }
        juniorHighSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
              height
              weight
            }
          }
        }
        highSchoolReport {
          reports {
            grade
            prefecture {
              grip
              sitUps
              bending
              sideJump
              shuttleRun
              sprintRun
              standingJump
              handballThrow
              totalScore
              height
              weight
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportTestScoreChartSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestScoreChartSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestScoreChartSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestScoreChartSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestScoreChartSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >(ReportTestScoreChartSummaryDocument, options)
}
export function useReportTestScoreChartSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestScoreChartSummaryQuery,
    ReportTestScoreChartSummaryQueryVariables
  >(ReportTestScoreChartSummaryDocument, options)
}
export type ReportTestScoreChartSummaryQueryHookResult = ReturnType<
  typeof useReportTestScoreChartSummaryQuery
>
export type ReportTestScoreChartSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestScoreChartSummaryLazyQuery
>
export type ReportTestScoreChartSummaryQueryResult = Apollo.QueryResult<
  ReportTestScoreChartSummaryQuery,
  ReportTestScoreChartSummaryQueryVariables
>
export const ReportTestRankTargetSummaryDocument = gql`
  query ReportTestRankTargetSummary($input: ReportTestRankTargetSummaryInput!) {
    reportTestRankTargetSummary(input: $input) {
      data {
        reports {
          year
          totalRates
          elementarySchoolRates
          juniorHighSchoolRates
          highSchoolRates
        }
        national {
          year
          totalRates
          elementarySchoolRates
          juniorHighSchoolRates
          highSchoolRates
        }
      }
      error
    }
  }
`

/**
 * __useReportTestRankTargetSummaryQuery__
 *
 * To run a query within a React component, call `useReportTestRankTargetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTestRankTargetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTestRankTargetSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTestRankTargetSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >(ReportTestRankTargetSummaryDocument, options)
}
export function useReportTestRankTargetSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportTestRankTargetSummaryQuery,
    ReportTestRankTargetSummaryQueryVariables
  >(ReportTestRankTargetSummaryDocument, options)
}
export type ReportTestRankTargetSummaryQueryHookResult = ReturnType<
  typeof useReportTestRankTargetSummaryQuery
>
export type ReportTestRankTargetSummaryLazyQueryHookResult = ReturnType<
  typeof useReportTestRankTargetSummaryLazyQuery
>
export type ReportTestRankTargetSummaryQueryResult = Apollo.QueryResult<
  ReportTestRankTargetSummaryQuery,
  ReportTestRankTargetSummaryQueryVariables
>
export const ReportCrossAnalysisSummaryDocument = gql`
  query ReportCrossAnalysisSummary($input: ReportCrossAnalysisSummaryInput!) {
    reportCrossAnalysisSummary(input: $input) {
      data {
        reports {
          dataKey
          grades {
            schoolCategory
            grade
            answers {
              answer
              totalCount
              averages {
                score
                scoreType
              }
              deviations {
                score
                scoreType
              }
            }
          }
        }
      }
      error
    }
  }
`

/**
 * __useReportCrossAnalysisSummaryQuery__
 *
 * To run a query within a React component, call `useReportCrossAnalysisSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCrossAnalysisSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCrossAnalysisSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCrossAnalysisSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >(ReportCrossAnalysisSummaryDocument, options)
}
export function useReportCrossAnalysisSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportCrossAnalysisSummaryQuery,
    ReportCrossAnalysisSummaryQueryVariables
  >(ReportCrossAnalysisSummaryDocument, options)
}
export type ReportCrossAnalysisSummaryQueryHookResult = ReturnType<
  typeof useReportCrossAnalysisSummaryQuery
>
export type ReportCrossAnalysisSummaryLazyQueryHookResult = ReturnType<
  typeof useReportCrossAnalysisSummaryLazyQuery
>
export type ReportCrossAnalysisSummaryQueryResult = Apollo.QueryResult<
  ReportCrossAnalysisSummaryQuery,
  ReportCrossAnalysisSummaryQueryVariables
>
export const ReportSchoolPerformanceSummaryDocument = gql`
  query ReportSchoolPerformanceSummary(
    $input: ReportSchoolPerformanceSummaryInput!
  ) {
    reportSchoolPerformanceSummary(input: $input) {
      data {
        elementarySchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        juniorHighSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        highSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
      }
      error
    }
  }
`

/**
 * __useReportSchoolPerformanceSummaryQuery__
 *
 * To run a query within a React component, call `useReportSchoolPerformanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSchoolPerformanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSchoolPerformanceSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSchoolPerformanceSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >(ReportSchoolPerformanceSummaryDocument, options)
}
export function useReportSchoolPerformanceSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportSchoolPerformanceSummaryQuery,
    ReportSchoolPerformanceSummaryQueryVariables
  >(ReportSchoolPerformanceSummaryDocument, options)
}
export type ReportSchoolPerformanceSummaryQueryHookResult = ReturnType<
  typeof useReportSchoolPerformanceSummaryQuery
>
export type ReportSchoolPerformanceSummaryLazyQueryHookResult = ReturnType<
  typeof useReportSchoolPerformanceSummaryLazyQuery
>
export type ReportSchoolPerformanceSummaryQueryResult = Apollo.QueryResult<
  ReportSchoolPerformanceSummaryQuery,
  ReportSchoolPerformanceSummaryQueryVariables
>
export const ReportSchoolProgressSummaryDocument = gql`
  query ReportSchoolProgressSummary($input: ReportSchoolProgressSummaryInput!) {
    reportSchoolProgressSummary(input: $input) {
      data {
        elementarySchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        juniorHighSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
        highSchool {
          prefectureSchoolCode
          schoolName
          scale
          score
          studentCount
        }
      }
      error
    }
  }
`

/**
 * __useReportSchoolProgressSummaryQuery__
 *
 * To run a query within a React component, call `useReportSchoolProgressSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSchoolProgressSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSchoolProgressSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSchoolProgressSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >(ReportSchoolProgressSummaryDocument, options)
}
export function useReportSchoolProgressSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportSchoolProgressSummaryQuery,
    ReportSchoolProgressSummaryQueryVariables
  >(ReportSchoolProgressSummaryDocument, options)
}
export type ReportSchoolProgressSummaryQueryHookResult = ReturnType<
  typeof useReportSchoolProgressSummaryQuery
>
export type ReportSchoolProgressSummaryLazyQueryHookResult = ReturnType<
  typeof useReportSchoolProgressSummaryLazyQuery
>
export type ReportSchoolProgressSummaryQueryResult = Apollo.QueryResult<
  ReportSchoolProgressSummaryQuery,
  ReportSchoolProgressSummaryQueryVariables
>
export const ReportUploadListDocument = gql`
  query ReportUploadList($input: ReportUploadListInput!) {
    reportUploadList(input: $input) {
      data {
        prefectureSchoolCode
        schoolName
        studentCount
        cityName
        organizationName
        uploadedAt
      }
      count
      totalCount
      error
    }
  }
`

/**
 * __useReportUploadListQuery__
 *
 * To run a query within a React component, call `useReportUploadListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportUploadListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportUploadListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportUploadListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportUploadListQuery,
    ReportUploadListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportUploadListQuery, ReportUploadListQueryVariables>(
    ReportUploadListDocument,
    options,
  )
}
export function useReportUploadListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportUploadListQuery,
    ReportUploadListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReportUploadListQuery,
    ReportUploadListQueryVariables
  >(ReportUploadListDocument, options)
}
export type ReportUploadListQueryHookResult = ReturnType<
  typeof useReportUploadListQuery
>
export type ReportUploadListLazyQueryHookResult = ReturnType<
  typeof useReportUploadListLazyQuery
>
export type ReportUploadListQueryResult = Apollo.QueryResult<
  ReportUploadListQuery,
  ReportUploadListQueryVariables
>
export const StudentInvitationCodesDocument = gql`
  query StudentInvitationCodes($input: StudentInvitationCodesInput!) {
    studentInvitationCodes(input: $input) {
      data {
        schoolGrade
        schoolClass
        students {
          attendanceNumber
          username
        }
      }
      error
    }
  }
`

/**
 * __useStudentInvitationCodesQuery__
 *
 * To run a query within a React component, call `useStudentInvitationCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentInvitationCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentInvitationCodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentInvitationCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >(StudentInvitationCodesDocument, options)
}
export function useStudentInvitationCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StudentInvitationCodesQuery,
    StudentInvitationCodesQueryVariables
  >(StudentInvitationCodesDocument, options)
}
export type StudentInvitationCodesQueryHookResult = ReturnType<
  typeof useStudentInvitationCodesQuery
>
export type StudentInvitationCodesLazyQueryHookResult = ReturnType<
  typeof useStudentInvitationCodesLazyQuery
>
export type StudentInvitationCodesQueryResult = Apollo.QueryResult<
  StudentInvitationCodesQuery,
  StudentInvitationCodesQueryVariables
>
export const SchoolReportTestSummaryDocument = gql`
  query SchoolReportTestSummary($input: SchoolReportTestSummaryInput!) {
    schoolReportTestSummary(input: $input) {
      data {
        reports {
          testYear
          grade
          count
          height_avg
          weight_avg
          grip_avg
          sitUps_avg
          bending_avg
          sideJump_avg
          shuttleRun_avg
          sprintRun_avg
          standingJump_avg
          handballThrow_avg
          points_avg
          height_std
          weight_std
          grip_std
          sitUps_std
          bending_std
          sideJump_std
          shuttleRun_std
          sprintRun_std
          standingJump_std
          handballThrow_std
          points_std
          rank_a
          rank_b
          rank_c
          rank_d
          rank_e
        }
      }
      error
    }
  }
`

/**
 * __useSchoolReportTestSummaryQuery__
 *
 * To run a query within a React component, call `useSchoolReportTestSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolReportTestSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolReportTestSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolReportTestSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >(SchoolReportTestSummaryDocument, options)
}
export function useSchoolReportTestSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchoolReportTestSummaryQuery,
    SchoolReportTestSummaryQueryVariables
  >(SchoolReportTestSummaryDocument, options)
}
export type SchoolReportTestSummaryQueryHookResult = ReturnType<
  typeof useSchoolReportTestSummaryQuery
>
export type SchoolReportTestSummaryLazyQueryHookResult = ReturnType<
  typeof useSchoolReportTestSummaryLazyQuery
>
export type SchoolReportTestSummaryQueryResult = Apollo.QueryResult<
  SchoolReportTestSummaryQuery,
  SchoolReportTestSummaryQueryVariables
>
export const SchoolReportTestScoreChartSummaryDocument = gql`
  query SchoolReportTestScoreChartSummary(
    $input: SchoolReportTestScoreChartSummaryInput!
  ) {
    schoolReportTestScoreChartSummary(input: $input) {
      data {
        reports {
          testYear
          grade
          chart {
            grip
            sitUps
            bending
            sideJump
            shuttleRun
            sprintRun
            standingJump
            handballThrow
            totalScore
            height
            weight
          }
        }
      }
    }
  }
`

/**
 * __useSchoolReportTestScoreChartSummaryQuery__
 *
 * To run a query within a React component, call `useSchoolReportTestScoreChartSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolReportTestScoreChartSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolReportTestScoreChartSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolReportTestScoreChartSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >(SchoolReportTestScoreChartSummaryDocument, options)
}
export function useSchoolReportTestScoreChartSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchoolReportTestScoreChartSummaryQuery,
    SchoolReportTestScoreChartSummaryQueryVariables
  >(SchoolReportTestScoreChartSummaryDocument, options)
}
export type SchoolReportTestScoreChartSummaryQueryHookResult = ReturnType<
  typeof useSchoolReportTestScoreChartSummaryQuery
>
export type SchoolReportTestScoreChartSummaryLazyQueryHookResult = ReturnType<
  typeof useSchoolReportTestScoreChartSummaryLazyQuery
>
export type SchoolReportTestScoreChartSummaryQueryResult = Apollo.QueryResult<
  SchoolReportTestScoreChartSummaryQuery,
  SchoolReportTestScoreChartSummaryQueryVariables
>
export const QrCodeStudentDocument = gql`
  query QRCodeStudent($input: QRCodeStudentInput!) {
    qrCodeStudent(input: $input) {
      data {
        _id
        attributes {
          givenName
          givenNameHiragana
          familyName
          familyNameHiragana
          schoolName
          schoolGrade
          schoolClass
          schoolAttendanceNumber
        }
      }
      error
    }
  }
`

/**
 * __useQrCodeStudentQuery__
 *
 * To run a query within a React component, call `useQrCodeStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodeStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodeStudentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQrCodeStudentQuery(
  baseOptions: Apollo.QueryHookOptions<
    QrCodeStudentQuery,
    QrCodeStudentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<QrCodeStudentQuery, QrCodeStudentQueryVariables>(
    QrCodeStudentDocument,
    options,
  )
}
export function useQrCodeStudentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QrCodeStudentQuery,
    QrCodeStudentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<QrCodeStudentQuery, QrCodeStudentQueryVariables>(
    QrCodeStudentDocument,
    options,
  )
}
export type QrCodeStudentQueryHookResult = ReturnType<
  typeof useQrCodeStudentQuery
>
export type QrCodeStudentLazyQueryHookResult = ReturnType<
  typeof useQrCodeStudentLazyQuery
>
export type QrCodeStudentQueryResult = Apollo.QueryResult<
  QrCodeStudentQuery,
  QrCodeStudentQueryVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
}
export default result
