import React from 'react'
import ReactDOM from 'react-dom'

// WARNING: This order is important! index.css -> antd.css
import './index.css'
// WARNING: This order is important!
import './antd.css'

import { ConfigProvider } from 'antd'
import jaJP from 'antd/lib/locale/ja_JP'

import App from './App'
import reportWebVitals from './reportWebVitals'

import moment from 'moment'
import 'moment/locale/ja'

moment.locale('ja')

ReactDOM.render(
  <React.StrictMode>
    <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    <ConfigProvider locale={jaJP} autoInsertSpaceInButton={false}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
