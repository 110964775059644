import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import AnimationData from './nodata.json'

export const NoData = ({ centered = false }) => {
  const container = useRef<any>(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: AnimationData,
    })
  }, [])

  return (
    <div className={`${centered ? 'flex flex-col items-center' : ''}`}>
      <div className="pb-4">データがありませんでした。</div>
      <div
        className="container"
        ref={container}
        style={{ width: 250, height: 250 }}
      />
    </div>
  )
}
