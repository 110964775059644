import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Select, Table, Tag, Button, Grid, Modal, message } from 'antd'
import { ColumnType } from 'antd/es/table'

import {
  TeachersQuery,
  UpdateTeacherRoleInput,
  useTeachersLazyQuery,
  useUpdateTeacherRoleMutation,
} from '@alpha/core'
import { parseTeacherType } from '~/utils'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import { useAlphaStore } from '~/context'
import { CloseOutlined } from '@ant-design/icons'
import { allListTeachers } from '~/components/admin/api'
import {
  getGradeSchool,
  getClassSchool,
  getRoleSchool,
} from '~/utils/constants'

const { Option } = Select
const { useBreakpoint } = Grid

function colorizeTeacherType(v: string) {
  if (v === 'SCHOOL_ADMIN') {
    return 'green'
  } else if (v === 'GRADE_ADMIN') {
    return 'blue'
  } else {
    return 'magenta'
  }
}

const TeacherListPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const {
    teacher,
    testYear,
    testYears,
    school,
    schoolCategoryCode,
    setTestYear,
    kobe,
  } = useAlphaStore()

  const [data, setData] = useState<TeachersQuery['teachers']['data']>()

  const [selectedTeacher, setSelectedTeacher] =
    useState<TeachersQuery['teachers']['data'][0]>()
  const [listTeachers, setListTeachers] = useState<any>([])
  const [selectedRole, setSelectedRole] = useState('SCHOOL_ADMIN')
  const [selectedGrade, setSelectedGrade] = useState<number>(1)
  const [selectedClass, setSelectedClass] = useState<number>(1)
  const [pageSizeList, setPageSizeList] = useState<number>(50)
  const [currentList, setCurrentList] = useState<number>(1)

  const [getTeachers, { data: teacherData, loading, refetch }] =
    useTeachersLazyQuery({
      fetchPolicy: 'network-only',
    })

  const [updateTeacherRole] = useUpdateTeacherRoleMutation()

  const [showEditModal, setShowEditModal] = useState(false)

  const handleEditTeacher = useCallback(
    (_teacher) => {
      if (teacher?.email === _teacher.email) return
      setSelectedTeacher(_teacher)
      setSelectedRole(_teacher.attributes.role)
      setShowEditModal(true)
    },
    [teacher],
  )

  useEffect(() => {
    const fetchListTeachers = async () => {
      const { data } = await allListTeachers()
      if (!data) return
      setListTeachers(data.data)
    }
    fetchListTeachers()
  }, [])

  // async function handleEditTeacherSubmit() {
  //   const input: UpdateTeacherRoleInput = {
  //     role: selectedRole as any,
  //     schoolId: school._id,
  //     teacherEmail: selectedTeacher.attributes.email,
  //     schoolGrade: ['GRADE_ADMIN', 'TEACHER'].includes(selectedRole)
  //       ? selectedGrade
  //       : undefined,
  //     schoolClass: ['TEACHER'].includes(selectedRole)
  //       ? selectedClass
  //       : undefined,
  //   }
  //   await updateTeacherRole({ variables: { input } })
  //   message.success('更新しました')
  //   refetch()
  //   setShowEditModal(false)
  //   setSelectedTeacher(undefined)
  //   setSelectedRole('SCHOOL_ADMIN')
  // }

  // getTeachers
  useEffect(() => {
    if (!school) return

    getTeachers({
      variables: {
        input: {
          schoolId: school._id,
          testYear,
        },
      },
    })
  }, [school, testYear, getTeachers])

  // setData
  useEffect(() => {
    if (!teacherData?.teachers?.data) {
      setData([])
      return
    }

    setData(teacherData.teachers.data)
  }, [teacherData])

  const tableList = listTeachers
    ?.filter((inf) => +inf.status !== 1 && inf.status !== null)
    .map((info, idx) => {
      return {
        key: idx,
        fullName: info.fullName,
        fullNameHiragana: info.fullNameHiragana,
        username: info.username,
        role: getRoleSchool(info.role),
        password: info.password,
        schoolGrade: getGradeSchool(info.role, info.schoolGrade),
        schoolClass: getClassSchool(info.role, info.schoolClass),
      }
    })

  const useTableColumns: ColumnType<any>[] = useMemo(() => {
    let defaultColumns: any[] = [
      {
        title: '氏名',
        dataIndex: 'fullName',
        key: 'fullName',
      },
      {
        title: 'しめい',
        dataIndex: 'fullNameHiragana',
        key: 'nameHiragana',
      },
      {
        title: 'ID',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: '権限',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: '学年',
        dataIndex: 'schoolGrade',
        key: 'schoolGrade',
      },
      {
        title: 'クラス',
        dataIndex: 'schoolClass',
        key: 'schoolClass',
      },
    ]
    // if (kobe) {
    //   // role column
    //   defaultColumns.push({
    //     title: '権限',
    //     dataIndex: ['attributes', 'role'],
    //     render: (v) => (
    //       <Tag color={colorizeTeacherType(v)}>{parseTeacherType(v)}</Tag>
    //     ),
    //   })

    //   // grade column
    //   defaultColumns.push({
    //     title: '学年',
    //     dataIndex: ['attributes'],
    //     key: 'schoolGrade',
    //     render: (v) => (
    //       <div>
    //         {['GRADE_ADMIN', 'CLASS_ADMIN'].includes(v.role)
    //           ? v.schoolGrade
    //             ? `${v.schoolGrade}学年`
    //             : '-'
    //           : '-'}
    //       </div>
    //     ),
    //   })

    //   // class column
    //   defaultColumns.push({
    //     title: '組',
    //     dataIndex: ['attributes'],
    //     key: 'schoolClass',
    //     render: (v) => (
    //       <div>
    //         {['CLASS_ADMIN'].includes(v.role)
    //           ? v.schoolClass
    //             ? `${v.schoolClass}組`
    //             : '-'
    //           : '-'}
    //       </div>
    //     ),
    //   })

    //   if (teacher?.role === 'SCHOOL_ADMIN') {
    //     // edit role column
    //     defaultColumns.push({
    //       title: '編集',
    //       key: 'edit',
    //       render: (u) => (
    //         <Button
    //           type={
    //             u.attributes.role === 'SCHOOL_ADMIN' ? 'default' : 'primary'
    //           }
    //           className="ant-btn-xs"
    //           disabled={
    //             u.attributes.role === 'SCHOOL_ADMIN' &&
    //             u.attributes.email === teacher?.email
    //           }
    //           onClick={() => handleEditTeacher(u)}
    //         >
    //           編集
    //         </Button>
    //       ),
    //     })
    //   }
    // }

    return defaultColumns
  }, [kobe, handleEditTeacher, teacher])

  let title = <div className="theme-nav-title">{graphItem.title}</div>

  const screens = useBreakpoint()

  const modalProps = {
    visible: showEditModal,
    width: screens.xxl ? '70vw' : screens.lg ? '85vw' : '90vw',
    onCancel: () => {
      setShowEditModal(false)
    },
    closeIcon: (
      <CloseOutlined className="text-2xl" style={{ color: '#036EB8' }} />
    ),
    footer: null,
  }

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="flex justify-center">
        <div className="inline-block mt-14">
          <div className="">登録人数　{tableList?.length}</div>
          <Table
            columns={useTableColumns}
            dataSource={tableList}
            size="small"
            style={{ minWidth: 850 }}
            rowKey="_id"
            bordered={true}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: pageSizeList,
              pageSize: pageSizeList,
              position: ['bottomCenter'],
              current: currentList,
              onChange(current, size) {
                setCurrentList(pageSizeList !== size ? 1 : current)
                setPageSizeList(size)
              },
            }}
          />
        </div>
      </div>
      {selectedTeacher && (
        <Modal key={`q^${selectedTeacher._id}`} {...modalProps}>
          <div className="my-8">
            <Table
              columns={[
                {
                  title: '名前',
                  dataIndex: ['attributes', 'familyName'],
                },
                {
                  title: '権限',
                  key: 'role',
                  render: (v) => (
                    <div className="flex flex-row space-x-4">
                      <Select
                        className="w-64"
                        defaultValue={v.attributes.role}
                        onChange={setSelectedRole}
                      >
                        <Option value="SCHOOL_ADMIN">全体管理者</Option>
                        <Option value="GRADE_ADMIN">学年管理者</Option>
                        <Option value="CLASS_ADMIN">担任</Option>
                      </Select>

                      {['GRADE_ADMIN', 'CLASS_ADMIN'].includes(
                        selectedRole,
                      ) && (
                        <Select
                          className="w-30"
                          value={selectedGrade}
                          onChange={setSelectedGrade}
                          // dropdownAlign={{
                          //   offset: [0, -2], // align offset
                          // }}
                        >
                          <>
                            {schoolCategoryCode === 'B1'
                              ? [1, 2, 3, 4, 5, 6].map((grade) => (
                                  <Option value={grade} key={grade}>
                                    {grade}学年
                                  </Option>
                                ))
                              : [1, 2, 3].map((grade) => (
                                  <Option value={grade} key={grade}>
                                    {grade}学年
                                  </Option>
                                ))}
                          </>
                        </Select>
                      )}
                      {['TEACHER'].includes(selectedRole) && (
                        <Select
                          className="w-30"
                          value={selectedClass}
                          onChange={setSelectedClass}
                          // dropdownAlign={{
                          //   offset: [0, -2], // align offset
                          // }}
                        >
                          <>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_class) => (
                              <Option value={_class} key={_class}>
                                {_class}組
                              </Option>
                            ))}
                          </>
                        </Select>
                      )}
                    </div>
                  ),
                },
              ]}
              dataSource={[selectedTeacher]}
              size="small"
              rowKey="_id"
              bordered={true}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
          <div className="flex justify-center">
            <Button
              type="primary"
              className="w-64"
              // onClick={() => handleEditTeacherSubmit()}
            >
              変更
            </Button>
          </div>
        </Modal>
      )}
    </Dashboard>
  )
}

export default TeacherListPage
