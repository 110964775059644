import { Button, Modal } from 'antd'
import QRCode from 'qrcode.react'
import { CloseOutlined } from '@ant-design/icons'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import _ from 'lodash'

export const QRCodeModal = ({ data, showModal, setShowModal }) => {
  const handleCancel = () => {
    setShowModal(false)
  }

  // QR_modal
  const QRmodalProps = {
    bodyStyle: { background: '#E9ECF6', borderRadius: '10px' },
    width: '1200px',
    visible: showModal,
    onCancel: handleCancel,
    closable: false,
    closeIcon: (
      <CloseOutlined className="text-base" style={{ color: '#036EB8' }} />
    ),
    footer: null,
  }

  const printPage = (students, printing = false) => {
    return (
      <div className={printing ? 'p-4 pt-16' : ''}>
        <div className="flex justify-center text-base font-black">
          {data?.code.schoolGrade}年 {data?.code.schoolClass}組
        </div>
        <div className="absolute right-4 top-4 print:hidden">
          <CloseOutlined
            className="text-base"
            style={{ color: '#036EB8' }}
            onClick={() => handleCancel()}
          />
        </div>
        <div className={printing ? 'mx-5' : 'mx-5 my-5'}>
          <div className={printing ? '' : 'my-4'}>
            <div className="grid grid-cols-3 gap-5 py-2">
              {students.map(({ attendanceNumber, name }) => (
                <div
                  className="p-2 border grid justify-center bg-white"
                  key={`qr-code-${data.code.schoolGrade}-${data.code.schoolClass}-${attendanceNumber}`}
                >
                  <div className="flex flex-col space-y-2 items-center">
                    <QRCode value={`${data.code.code}${attendanceNumber}`} />
                    <div className="text-sm">{`${data.code.code}${attendanceNumber}`}</div>
                    <div className="text-lg">
                      {data.code.schoolGrade}年生 {data.code.schoolClass}組{' '}
                      {attendanceNumber}番
                    </div>
                    <div>名前：{name || '_____________'}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalRef = useRef<HTMLDivElement>()
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
  })

  return (
    <div>
      {data && (
        <Modal {...QRmodalProps}>
          {printPage(data.code.students || [])}
          <div className="w-full flex justify-center">
            <Button
              className="w-60 rounded"
              type="primary"
              onClick={handlePrint}
            >
              印刷
            </Button>
          </div>
          <div ref={modalRef} className="print-container m-0 p-0">
            {_.chunk(data.code.students || [], 9).map((chunk) => (
              <>
                {printPage(chunk, true)}
                <div className="page-break" />
              </>
            ))}
          </div>
        </Modal>
      )}
    </div>
  )
}
