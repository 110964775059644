import { useContext, useEffect, useState } from 'react'

import {
  AuthContext,
  SchoolSubmitStudentQuestionnarieInput,
  useMeQuery,
  useSchoolSubmitStudentQuestionnaireV2Mutation,
  useSchoolSubmitStudentQuestionnarieMutation,
} from '@alpha/core'

import { Button, message, Modal, Form, InputNumber, Radio, Row } from 'antd'

type Props = {
  student: any
  isShow: boolean
  setIsShow: (b: boolean) => void
  testYear: number
  refetch: () => void
}

const InputQuestionnaireModal = ({
  student,
  isShow,
  setIsShow,
  testYear,
  refetch,
}: Props) => {
  const auth = useContext(AuthContext)
  const [form] = Form.useForm()
  const [formV2] = Form.useForm()
  const [school, setSchool] = useState<any>(null)

  const { data: meData } = useMeQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const [submitQuestionnaire, { loading }] =
    useSchoolSubmitStudentQuestionnarieMutation()
  const [submitQuestionnaireV2, { loading: loadingV2 }] =
    useSchoolSubmitStudentQuestionnaireV2Mutation()

  const { currentUser } = auth

  // testYears
  useEffect(() => {
    if (!currentUser) {
      return
    }
    if (!meData?.me?.data?.relationships.schools) {
      return
    }
    const school = meData.me.data.relationships.schools[0]
    setSchool(school)
  }, [currentUser, meData])

  const onFinish = async (values) => {
    const variables: { input: SchoolSubmitStudentQuestionnarieInput } = {
      input: {
        studentId: student._id,
        questionnaire: {
          testYear,
          belongsToSportsClub: values.belongsToSportsClub,
          excerciseActivity: values.excerciseActivity,
          dailyExcerciseTime: values.dailyExcerciseTime,
          breakfast: values.breakfast,
          sleepTime: values.sleepTime,
          tvTime: values.tvTime,
          height: values.height,
          weight: values.weight,
          sittingHeight: values.sittingHeight,
        },
      },
    }
    try {
      const res = await submitQuestionnaire({ variables })
      // @ts-ignore
      if (res.dataPresent) {
        message.success('アンケートの保存が完了しました')
        return
      }
      if (!res.data) {
        message.error('エラーが発生しました')
        return
      }
      message.success('アンケートの送信が完了しました')
    } catch (err) {
      message.error(`エラーが発生しました [${err}]`)
    }
    setIsShow(false)
    refetch()
  }

  const onQuestionnaireV2Finish = async (values) => {
    let height = values.height
    let weight = values.weight
    let sittingHeight = values.sittingHeight
    delete values.height
    delete values.weight
    delete values.sittingHeight

    let answers: any = []
    for (const [_, value] of Object.entries(values)) {
      answers.push(value)
    }

    try {
      const res = await submitQuestionnaireV2({
        variables: {
          input: {
            studentId: student._id,
            questionnaire: {
              testYear,
              height,
              weight,
              sittingHeight,
              answers,
            },
          },
        },
      })

      // @ts-ignore
      if (res.dataPresent) {
        message.success('アンケートの保存が完了しました')
        return
      }
      if (!res.data) {
        message.error('エラーが発生しました')
        return
      }
      message.success('アンケートの送信が完了しました')
    } catch (err) {
      message.error(`エラーが発生しました [${err}]`)
    }
    setIsShow(false)
    refetch()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsShow(false)
  }

  const StandardQuestionnaire = () => (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={
        student && student.attributes.testResults
          ? student.attributes.testResults[0]?.questionnaire
          : {}
      }
    >
      <Form.Item
        label={<label>運動部やスポーツクラブへの所属状況</label>}
        name="belongsToSportsClub"
        rules={[{ required: true, message: '選択して下さい' }]}
        style={{ color: 'white' }}
      >
        <Radio.Group disabled={loading}>
          <Radio value={1}>所属している</Radio>
          <Radio value={2}>所属していない</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<label>運動・スポーツの実施状況（体育の授業を除く）</label>}
        name="excerciseActivity"
        rules={[{ required: true, message: '選択して下さい' }]}
        style={{ color: 'white' }}
      >
        <Radio.Group disabled={loading}>
          <Radio value={1}>ほとんど毎日（週3日以上）</Radio>
          <Radio value={2}>ときどき（週1〜2日程度）</Radio>
          <Radio value={3}>ときたま（月1〜3日程度）</Radio>
          <Radio value={4}>しない</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<label>1日の運動・スポーツ実施時間（体育の授業を除く）</label>}
        name="dailyExcerciseTime"
        rules={[{ required: true, message: '選択して下さい' }]}
        style={{ color: 'white' }}
      >
        <Radio.Group disabled={loading}>
          <Radio value={1}>30分未満</Radio>
          <Radio value={2}>30分以上1時間未満</Radio>
          <Radio value={3}>1時間以上2時間未満</Radio>
          <Radio value={4}>2時間以上</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<label>朝食の有無</label>}
        name="breakfast"
        rules={[{ required: true, message: '選択して下さい' }]}
        style={{ color: 'white' }}
      >
        <Radio.Group disabled={loading}>
          <Radio value={1}>毎日食べる</Radio>
          <Radio value={2}>時々欠かす</Radio>
          <Radio value={3}>まったく食べない</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<label>1日の睡眠時間</label>}
        name="sleepTime"
        rules={[{ required: true, message: '選択して下さい' }]}
        style={{ color: 'white' }}
      >
        <Radio.Group disabled={loading}>
          <Radio value={1}>6時間未満</Radio>
          <Radio value={2}>6時間以上8時間未満</Radio>
          <Radio value={3}>8時間以上</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={<label>1日のテレビ（テレビゲームを含む）の視聴時間</label>}
        name="tvTime"
        rules={[{ required: true, message: '選択して下さい' }]}
        style={{ color: 'white' }}
      >
        <Radio.Group disabled={loading}>
          <Radio value={1}>1時間未満</Radio>
          <Radio value={2}>1時間以上2時間未満</Radio>
          <Radio value={3}>2時間以上3時間未満</Radio>
          <Radio value={4}>3時間以上</Radio>
        </Radio.Group>
      </Form.Item>
      <Row gutter={10}>
        <div className="flex space-x-4">
          <Form.Item
            label={<label>身長 (cm)</label>}
            name="height"
            rules={[
              {
                required: true,
                message: '入力して下さい',
              },
            ]}
          >
            <InputNumber
              size="large"
              min={0}
              max={240}
              disabled={loading}
              step="any"
            />
          </Form.Item>
          <Form.Item
            label={<label>体重 (kg)</label>}
            name="weight"
            rules={[
              {
                required: true,
                message: '入力して下さい',
              },
            ]}
          >
            <InputNumber
              size="large"
              min={0}
              max={200}
              disabled={loading}
              step="any"
            />
          </Form.Item>
          <Form.Item
            label={<label>座高 (cm)(希望学校のみ)</label>}
            name="sittingHeight"
          >
            <InputNumber
              size="large"
              min={0}
              max={150}
              disabled={loading}
              step="any"
            />
          </Form.Item>
        </div>
      </Row>
    </Form>
  )

  const QuestionnaireV2 = () => {
    let questions = school?.attributes.questions

    if (!questions) return null

    // let results =
    //   student &&
    //   student.attributes.testResults &&
    //   student.attributes.testResults[0]?.questionnaireV2

    // todo: format initialValues

    return (
      <Form
        form={formV2}
        layout="vertical"
        onFinish={onQuestionnaireV2Finish}
        initialValues={{}}
      >
        {questions.map((question, index) => (
          <Form.Item
            label={<label>{question.question}</label>}
            name={`${index}`}
            rules={[{ required: true, message: '選択して下さい' }]}
            style={{ color: 'white' }}
          >
            <Radio.Group disabled={loading}>
              {question.options.map((answer, index) => (
                <Radio value={index + 1}>{answer}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        ))}
        <Row gutter={10}>
          <div className="flex space-x-4">
            <Form.Item
              label={<label>身長 (cm)</label>}
              name="height"
              rules={[
                {
                  required: true,
                  message: '入力して下さい',
                },
              ]}
            >
              <InputNumber
                size="large"
                min={0}
                max={240}
                disabled={loading}
                step="any"
              />
            </Form.Item>
            <Form.Item
              label={<label>体重 (kg)</label>}
              name="weight"
              rules={[
                {
                  required: true,
                  message: '入力して下さい',
                },
              ]}
            >
              <InputNumber
                size="large"
                min={0}
                max={200}
                disabled={loading}
                step="any"
              />
            </Form.Item>
            <Form.Item label={<label>座高 (cm)</label>} name="sittingHeight">
              <InputNumber
                size="large"
                min={0}
                max={150}
                disabled={loading}
                step="any"
              />
            </Form.Item>
          </div>
        </Row>
      </Form>
    )
  }

  const modalProps = {
    title: 'アンケート入力',
    visible: isShow,
    width: '80vw',
    onCancel: handleCancel,
    footer: [
      <Button key="cancel" onClick={handleCancel}>
        キャンセル
      </Button>,
      <>
        {school?.attributes.questions ? (
          <Button
            key="submit"
            type="primary"
            loading={loadingV2}
            onClick={formV2.submit}
          >
            提出
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={form.submit}
          >
            提出
          </Button>
        )}
      </>,
    ],
  }

  return (
    <Modal {...modalProps}>
      {school?.attributes.questions ? (
        <QuestionnaireV2 />
      ) : (
        <StandardQuestionnaire />
      )}
    </Modal>
  )
}

export default InputQuestionnaireModal
