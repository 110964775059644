import React, { useEffect, useState } from 'react'
import { writeFile, utils, set_cptable } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'
import { Button, Col, Row, Select, Table } from 'antd'

import { fetchSchoolClasses } from '@alpha/core'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import { useAlphaStore } from '~/context'
import {
  useSchoolGradesApiQuery,
  useSchoolSurveysApiQuery,
} from '~/utils/hooks'
import { pageSize as defaultPageSize } from '../../utils/constants'
import querystring from 'querystring'
import Api from '~/utils/api'
import { getQuestionnairesArr } from '~/utils'
import { useSchoolMeApiQuery } from '~/utils/hooks'

set_cptable(cp_table)

const { Option } = Select

const incorrectValue = <div className="bg-gray-160 -m-2">&nbsp;</div>

const getQ8Value = (q8) => {
  if (typeof q8 !== 'object') return q8
  let total = 0
  for (const val of q8) total += (val || 0)
  return total
}

const sortTestResults = (a, b, key: string) => {
  let element1
  let element2
  if (key.startsWith('q')) {
    const a_qV3 = a.attributes?.testResults?.[0]?.questionnaireV3
    const b_qV3 = b.attributes?.testResults?.[0]?.questionnaireV3
    element1 = a_qV3?.[key]
    element2 = b_qV3?.[key]
  } else if (key === 'name') {
    element1 = `${a?.attributes?.familyName ?? ''}${
      a?.attributes?.givenName ?? ''
    }`
    element2 = `${b?.attributes?.familyName ?? ''}${
      b?.attributes?.givenName ?? ''
    }`
  } else {
    element1 = a?.[key]
    element2 = b?.[key]
  }

  if (element1 == null && element2 == null) return 1
  if (element1 == null) return -1
  if (element2 == null) return 1
  return typeof element1 === 'number'
    ? element1 - element2
    : element1.localeCompare(element2)
}

const getTestRecordStatus = (totalQuestionnaires, v) => {
  let status = false
  if (!v || v.length === 0) return status
  let questions = v[0]?.questionnaireV3
  if (questions) {
    status = totalQuestionnaires.every((_, q) => {
      const qk = `q${q + 1}`
      return !!questions[qk]
    })
  }

  return status
}

const columns = (totalQuestionnaires: (number | string)[]): any[] => [
  {
    title: '提出状況',
    dataIndex: ['attributes', 'testResults'],
    className: 'text-center-f whitespace-nowrap',
    key: 'status',
    sorter: (a, b) => {
      let status1 = getTestRecordStatus(
        totalQuestionnaires,
        a?.attributes?.testResults,
      )
      let status2 = getTestRecordStatus(
        totalQuestionnaires,
        b?.attributes?.testResults,
      )

      return status1 === status2 ? 0 : !status1 ? -1 : 1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      let status = getTestRecordStatus(totalQuestionnaires, v)

      return (
        <div
          className="flex justify-center items-center"
          style={{ height: 22, width: 60 }}
        >
          <div
            className="flex justify-center items-center h-4 w-4 rounded-3px text-xxs font-bold text-white"
            style={{ backgroundColor: status ? '#00944D' : '#CA4141' }}
          >
            {status ? '済' : '未'}
          </div>
        </div>
      )
    },
  },
  {
    title: '学年',
    key: 'schoolGrade',
    dataIndex: ['attributes', 'schoolGrade'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolGrade')
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'クラス',
    key: 'schoolClass',
    dataIndex: ['attributes', 'schoolClass'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolClass')
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '出席番号',
    key: 'schoolAttendanceNumber',
    dataIndex: ['attributes', 'schoolAttendanceNumber'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolAttendanceNumber')
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'ID',
    key: 'username',
    dataIndex: ['username'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'username')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v, _) => <div>{v}</div>,
  },
]

const QuestionnaireResultPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()
  const { data: teacherData } = useSchoolMeApiQuery()
  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade
  const teacherSchoolClass = teacherData?.schoolClass
  const testYear = teacherData?.testYear
  const [schoolGrade, setSchoolGrade] = useState<any>(
    teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
      ? teacherSchoolGrade
      : 0,
  )
  const [schoolClasses, setSchoolClasses] = useState([
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1,
  ])
  const [schoolClass, setSchoolClass] = useState<any>(
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0,
  )
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad && teacherRole) {
      setFirstLoad(false)
      setSchoolGrade(
        teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
          ? teacherSchoolGrade
          : 0,
      )
      setSchoolClasses([teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1])
      setSchoolClass(teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0)
    }
  }, [firstLoad, teacherRole])

  const [xlsxData, setXlsxData] = useState<any>()

  const { data: grades, loading: gradesLoading } = useSchoolGradesApiQuery({
    schoolId: school?._id,
    testYear: testYear,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const { data: surveys, loading: surveysLoading } = useSchoolSurveysApiQuery(
    {
      schoolId: school?._id,
      testYear: testYear,
      schoolClass,
      schoolGrade,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
    },
    firstLoad,
  )

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const totalQuestionnaires = getQuestionnairesArr(school, isElementarySchool)
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, testYear).then((res) => {
        const schoolClasses = res['schoolClasses']
        if (schoolClasses.length) {
          schoolClasses.sort()
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [testYear, school])

  useEffect(() => {
    if (!surveys?.data) {
      return
    }

    let xlsxData = surveys.data.map((student) => {
      let attributes = student.attributes
      let testResult = attributes.testResults && attributes.testResults[0]
      let questionnaireV3 = testResult?.questionnaireV3
      return {
        学年: attributes.schoolGrade,
        組: attributes.schoolClass,
        出席番号: attributes.schoolAttendanceNumber,
        ...Object.fromEntries(
          totalQuestionnaires.map((q) => [`q${q}`, questionnaireV3?.[`q${q}`]]),
        ),
      }
    })

    setXlsxData(xlsxData)
  }, [surveys, isElementarySchool])

  const downloadXlsx = async () => {
    setDownloading(true)
    try {
      const apiUrl = `/alpha/v1/school/survey?${querystring.stringify({
        schoolId: school?._id,
        testYear: testYear,
        schoolGrade,
        schoolClass,
      })}`
      const { data: surveys } = await Api.get(apiUrl)
      const xlsxData = surveys.data.map((student) => {
        const attributes = student.attributes
        const testResult = attributes.testResults && attributes.testResults[0]
        const questionnaireV3 = testResult?.questionnaireV3
        
        return {
          学年: attributes.schoolGrade,
          組: attributes.schoolClass,
          出席番号: attributes.schoolAttendanceNumber,
          ID: student.username,
          ...Object.fromEntries(
            totalQuestionnaires.map((q, index) => [
              `q${q}`,
              index + 1 === 8
                ? getQ8Value(questionnaireV3?.[`q${index + 1}`])
                : questionnaireV3?.[`q${index + 1}`],
            ]),
          ),
        }
      })
      const workbook = utils.book_new()
      const worksheet = utils.json_to_sheet(xlsxData)
      utils.book_append_sheet(workbook, worksheet, '中高用')
      writeFile(workbook, 'questionnaire_result.xlsx', {
        bookType: 'xlsx',
      })
    } finally {
      setDownloading(false)
    }
  }

  const editableColumns = [
    ...columns(totalQuestionnaires),
    ...totalQuestionnaires.map((_q, id) => {
      const qk = `q${id + 1}`
      return {
        title: `Q${_q}`,
        dataIndex: qk,
        key: qk,
        className: 'text-center-f whitespace-nowrap',
        sorter: (a, b) => {
          return sortTestResults(a, b, qk)
        },
        sortDirections: ['descend', 'ascend'],
        render: (v, r) => {
          if (
            !r.attributes?.testResults ||
            r.attributes?.testResults.length === 0
          )
            return incorrectValue
          let q = r.attributes.testResults[0]?.questionnaireV3
          if (qk === 'q8' && typeof q?.[qk] === 'object') {
            q[qk] = getQ8Value(q[qk])
            return <div>{q[qk]}</div>
          }
          if (!q?.[qk]) return incorrectValue
          return <div>{q?.[qk]}</div>
        },
      }
    }),
  ].map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const prefCode = school?.attributes?.prefectureCode
  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="flex justify-center">
        <div style={{ width: 1700 }}>
          <Row className="w-full justify-center">
            <Col className="mt-16" xxl={{ span: 20 }} lg={{ span: 22 }}>
              <div className="pb-8 print:hidden">
                <Row className="w-full space-x-2">
                  <Col>
                    <Select
                      className="w-30 rounded-5px"
                      value={
                        teacherRole === 'CLASS_ADMIN' ||
                        teacherRole === 'GRADE_ADMIN'
                          ? teacherSchoolGrade
                          : schoolGrade
                      }
                      onChange={setSchoolGrade}
                      dropdownAlign={{
                        offset: [0, -2], // align offset
                      }}
                    >
                      {teacherRole !== 'CLASS_ADMIN' &&
                        teacherRole !== 'GRADE_ADMIN' && (
                          <Option key={`grade-${0}`} value={0}>
                            全学年
                          </Option>
                        )}
                      <>
                        {(teacherRole === 'SUPER_ADMIN' ||
                          teacherRole === 'SCHOOL_ADMIN') &&
                        grades?.data?.length > 0 ? (
                          grades?.data?.map((v) => (
                            <Option value={v.schoolGrade} key={v.schoolGrade}>
                              {`${v.schoolGrade}年生`}
                            </Option>
                          ))
                        ) : (teacherRole === 'SUPER_ADMIN' ||
                            teacherRole === 'SCHOOL_ADMIN') &&
                          grades?.data?.length === 0 ? (
                          <Option value={1} key={999}>
                            1年生
                          </Option>
                        ) : (
                          <Option
                            value={teacherSchoolGrade}
                            key={teacherSchoolGrade}
                          >
                            {`${teacherSchoolGrade}年生`}
                          </Option>
                        )}
                      </>
                    </Select>
                  </Col>

                  <Col>
                    <Select
                      className="w-30 rounded-5px"
                      value={
                        teacherRole === 'CLASS_ADMIN'
                          ? teacherSchoolClass
                          : schoolClass
                      }
                      onChange={setSchoolClass}
                      dropdownAlign={{
                        offset: [0, -2], // align offset
                      }}
                    >
                      {teacherRole !== 'CLASS_ADMIN' && (
                        <Option key={`class-${0}`} value={0}>
                          全組
                        </Option>
                      )}
                      <>
                        {teacherRole !== 'CLASS_ADMIN' ? (
                          schoolClasses
                            .sort((a, b) => a - b)
                            .map((_class) => (
                              <Option key={`class-${_class}`} value={_class}>
                                {_class}組
                              </Option>
                            ))
                        ) : (
                          <Option
                            value={teacherSchoolClass}
                            key={teacherSchoolClass}
                          >
                            {`${teacherSchoolClass}組`}
                          </Option>
                        )}
                      </>
                    </Select>
                  </Col>
                  <Col flex="auto">
                    <Button
                      type="primary"
                      className="float-right w-50"
                      onClick={downloadXlsx}
                      disabled={downloading}
                    >
                      {downloading ? 'ダウンロード中...' : '一斉ダウンロード'}
                    </Button>
                  </Col>
                </Row>
              </div>

              <div>登録人数 {surveys?.total}</div>

              <Table
                columns={editableColumns}
                rowKey="schoolAttendanceNumber"
                dataSource={surveys?.data}
                loading={gradesLoading || surveysLoading}
                style={{ width: 1700 }}
                size="small"
                rowClassName="font-bold text-black"
                bordered={true}
                pagination={{
                  pageSize: pageSize,
                  defaultPageSize: pageSize,
                  position: ['bottomCenter'],
                  total: surveys?.total,
                  current: currentPage,
                  showSizeChanger: true,
                  onChange: (page: number, size: number) => {
                    setCurrentPage(pageSize !== size ? 1 : page)
                    setPageSize(size)
                  },
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Dashboard>
  )
}

export default QuestionnaireResultPage
