import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import { useApolloClient } from '@apollo/client'

import { Menu, Button } from 'antd'

import { DashboardLayout, Card } from '@alpha/components'

import { dashboardNavGraphItems } from '../navigation'
import { UpOutlined } from '@ant-design/icons'
import { School, useAlphaStore } from '~/context'
import { useSchoolMeApiQuery } from '~/utils/hooks'

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../../alpha/core/auth/AuthProvider'
import { currentYear } from '~/utils/constants'

const dummyOnClick = (e) => {
  e.preventDefault()
}

const SidebarMenu = ({ selectedMenu = 1, open = true }) => {
  const { kobe } = useAlphaStore()
  const { data: teacherData, loading: teacherLoading } = useSchoolMeApiQuery()

  const { useCustomizeQuestion } = useAlphaStore()

  const [isOnline, setIsOnline] = useState(true)
  const [mouseOver, setMouseOver] = useState(false)

  useEffect(() => {
    window.addEventListener('offline', () => {
      setIsOnline(false)
    })
    window.addEventListener('online', () => {
      setIsOnline(true)
    })
  }, [])

  const history = useHistory()

  const { SubMenu } = Menu
  const hideQuestionMenus = [10, 11, 12]

  const menuItems = dashboardNavGraphItems
    .filter((item) =>
      kobe
        ? item.key !== 'school_year_info'
        : item.key !== 'kobe_school_year_info',
    )
    .filter(
      (item) =>
        item.tabIndex &&
        !(useCustomizeQuestion && hideQuestionMenus.includes(item.tabIndex)),
    )
    .map((item, index) => (
      <Menu.Item
        className="text-center break-words font-bold"
        key={item.tabIndex}
        onClick={() => history.push(`/${item.key}`)}
      >
        <span style={{ whiteSpace: 'pre-line' }}>{item.title}</span>
      </Menu.Item>
    ))

  menuItems.splice(
    1,
    0,
    <Menu.Item
      className="text-center break-words font-bold"
      style={
        teacherData?.username !== 'admin'
          ? { visibility: 'hidden', height: 0 }
          : {}
      }
      key={20}
      onClick={() => history.push('/teacher_management')}
    >
      <div
        className={`flex flex-col items-center justify-center ${
          selectedMenu === 20 ? 'pb-1' : ''
        } `}
      >
        <span style={{ whiteSpace: 'pre-line' }}>先生管理</span>
        <span className="brand-name">※pestalozzi専用</span>
      </div>
    </Menu.Item>,
  )

  menuItems.splice(
    2,
    0,
    <Menu.Item
      className="text-center break-words font-bold"
      style={
        teacherData?.username !== 'admin'
          ? { visibility: 'hidden', height: 0 }
          : {}
      }
      key={21}
      onClick={() => history.push('/student_management')}
    >
      <div
        className={`flex flex-col items-center justify-center ${
          selectedMenu === 21 ? 'pt-1' : ''
        } `}
      >
        <span style={{ whiteSpace: 'pre-line' }}>生徒管理</span>
        <span className="brand-name">※pestalozzi専用</span>
      </div>
    </Menu.Item>,
  )

  menuItems.push(
    <Menu.Item className="text-center bg-white" key="closeMenu">
      <div className="flex items-center justify-center">
        <UpOutlined className="pr-2" />
        {`閉じる`}
      </div>
    </Menu.Item>,
  )

  const MenuIcon = (
    <svg
      width="34"
      height="23"
      viewBox="0 0 34 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        selectedMenu === 20 || selectedMenu === 21
          ? { background: '#C14A30' }
          : { background: '#036EB8' }
      }
    >
      <path d="M2 2H32" stroke="white" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M2 11.375H24.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M2 20.75H17"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[`${selectedMenu}`]}
        triggerSubMenuAction="click"
        onOpenChange={() => setMouseOver(!mouseOver)}
        onSelect={() => setMouseOver(false)}
        defaultOpenKeys={['submenu']}
      >
        <SubMenu
          className="custom-submenu"
          popupClassName="text-center"
          key="submenu"
          icon={MenuIcon}
          popupOffset={[-16, 0]}
          title={<span style={{ display: 'none' }} />}
        >
          {isOnline ? (
            menuItems
          ) : (
            <Menu.Item
              className="text-center"
              key="test"
              onClick={() => history.push(`/test`)}
            >
              {open ? '測定結果を入力' : null}
            </Menu.Item>
          )}
        </SubMenu>
      </Menu>
    </>
  )
}

export const Dashboard = ({
  navbar,
  children,
  isOpen = true,
  setIsOpen,
  selectedMenu = 1,
  loading = false,
  isManagementPage = false,
}) => {
  const history = useHistory()
  const client = useApolloClient()

  const {
    school,
    setTeacher,
    setSchool,
    setUseCustomizeQuestion,
    setTestYears,
  } = useAlphaStore()

  const { data: teacherData, loading: teacherLoading } = useSchoolMeApiQuery()

  const logoutUser = useCallback(() => {
    window.localStorage.clear()
    delete axios.defaults.headers.common[LOCAL_STORAGE_AUTH_TOKEN_KEY]
    client.clearStore()
    setTimeout(() => {
      history.push('/')
      window.location.reload()
    }, 300)
  }, [client, history])

  useEffect(() => {
    if (teacherLoading) return
    const _teacher = teacherData
    const _school = _teacher?.school

    if (!_teacher) {
      return
    }

    if (_teacher && !_school) {
      logoutUser()
      return
    }

    setTeacher(_teacher)
    setSchool(_school as School)

    const _useCustomizeQuestion =
      _school?.attributes?.schoolName === '横浜市立中和田小学校'

    setUseCustomizeQuestion(_useCustomizeQuestion)

    let _testYears = _school?.attributes?.testYears ?? [currentYear]
    setTestYears(_testYears)
  }, [
    teacherLoading,
    teacherData,
    setTeacher,
    setSchool,
    logoutUser,
    setTestYears,
    school,
    setUseCustomizeQuestion,
  ])

  return (
    <DashboardLayout
      sidebar={
        !loading && <SidebarMenu open={isOpen} selectedMenu={selectedMenu} />
      }
      navbar={navbar}
      loading={loading}
      onSettingsClick={dummyOnClick}
      isManagementPage={isManagementPage}
      account={
        <div className="flex space-x-8 items-center">
          <div></div>
        </div>
      }
      open={isOpen}
      setIsOpen={setIsOpen}
    >
      {children}
    </DashboardLayout>
  )
}
