import { NavigationItem } from '@sergeimeza/uikit-react/Elements/Navigation/NavigationItem'

export const navigation: NavigationItem[] = [
  {
    name: '身体力テスト',
    to: '/home',
    current: true,
  },
  {
    name: '設定',
    current: false,
    children: [
      {
        name: '学校情報',
        to: '/settings/school',
      },
      {
        name: '学年情報',
        to: '/settings/grades',
      },
      {
        name: '生徒情報を登録',
        to: '/settings/students',
      },
      {
        name: 'アンケート設定',
        to: '/settings/questionnaire',
      },
    ],
  },
  {
    name: '測定結果',
    current: false,
    children: [
      {
        name: '測定結果を入力',
        to: '/tests/input',
      },
      {
        name: '測定結果を閲覧',
        to: '/tests/result',
      },
      {
        name: '測定結果を印刷',
        to: '/tests/print',
      },
    ],
  },
  {
    name: 'アンケート',
    current: false,
    children: [
      {
        name: 'アンケートを入力・確認',
        to: '/questionnaires/input',
      },
      {
        name: 'アンケート結果を閲覧',
        to: '/questionnaires/result',
      },
    ],
  },
  {
    name: '集計レポート',
    to: '/reports',
    current: false,
  },
  {
    name: 'その他',
    current: false,
    children: [
      {
        name: '先生一覧',
        to: '/other/teachers',
      },
    ],
  },
]

export type DashboardNavGraphItem = {
  key: string
  title: string
  tabIndex: number | undefined
}

export const dashboardNavGraphItems: DashboardNavGraphItem[] = [
  {
    key: 'school_info',
    title: '学校情報',
    tabIndex: 1,
  },
  {
    key: 'teacher_list',
    title: '先生一覧',
    tabIndex: 2,
  },
  {
    key: 'student_list',
    title: '生徒登録', //生徒一覧
    tabIndex: 3,
  },
  {
    key: 'school_year_info',
    title: 'サインインコード表示', //学年情報
    tabIndex: 4,
  },
  {
    key: 'kobe_school_year_info',
    title: 'ID表示', //学年情報
    tabIndex: 4,
  },
  {
    key: 'test',
    title: '測定結果を登録',
    tabIndex: 5,
  },
  {
    key: 'test_result',
    title: '測定結果を閲覧',
    tabIndex: 6,
  },
  {
    key: 'test_result_upload',
    title: '測定結果を\nエクセルでアップロード',
    tabIndex: 7,
  },
  {
    key: 'test_results_for_print',
    title: '測定結果(印刷用)',
    tabIndex: undefined,
  },
  {
    key: 'questionnaire_edit',
    title: 'アンケートを設定',
    tabIndex: undefined,
  },
  {
    key: 'questionnaire_old',
    title: 'アンケート結果を登録\n（2021年度）',
    tabIndex: undefined,
  },
  {
    key: 'questionnaire_result_old',
    title: 'アンケート結果を閲覧\n（2021年度）',
    tabIndex: undefined,
  },
  {
    key: 'questionnaire',
    title: 'アンケート結果を登録', //アンケートを入力・確認
    tabIndex: 10,
  },
  {
    key: 'questionnaire_result',
    title: 'アンケート結果を閲覧',
    tabIndex: 11,
  },
  {
    key: 'questionnaire_upload',
    title: 'アンケート結果を\nエクセルでアップロード',
    tabIndex: 12,
  },
  {
    key: 'test_report',
    title: '集計レポート',
    tabIndex: 13,
  },
  {
    key: 'standard_table',
    title: '基準表',
    tabIndex: 14,
  },
  {
    key: 'exercise_diary',
    title: '運動日記',
    tabIndex: 18,
  },
  {
    key: 'logout',
    title: 'ログアウト',
    tabIndex: 15,
  },
  {
    key: 'school_year_setup',
    title: '学年情報を入力',
    tabIndex: undefined,
  },
  {
    key: 'school_setup',
    title: '学校情報を編集',
    tabIndex: undefined,
  },
  {
    key: 'home',
    title: 'ホーム',
    tabIndex: undefined,
  },
  {
    key: 'code',
    title: 'コード登録',
    tabIndex: undefined,
  },
  {
    key: 'register',
    title: 'ユーザー情報登録',
    tabIndex: undefined,
  },
  {
    key: '',
    title: 'サインイン',
    tabIndex: undefined,
  },
]
