import { Dashboard } from '../layout/Dashboard'
import { UserGender } from '@alpha/core'
import { Button, Col, Row, Select, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Loading } from '../shared/Loading'
import { DashboardNavGraphItem } from '../navigation'

import {
  Bar,
  BarChart,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ColumnType } from 'antd/es/table'
import { useReactToPrint } from 'react-to-print'
import axios from 'axios'
import { schoolHasEnduranceTest } from '~/utils'
import { useAlphaStore } from '~/context'
import { useSchoolMeApiQuery } from '~/utils/hooks'

const { Option } = Select

const showTypes = ['集計一覧', '学年結果', 'クラス結果', '運動部結果']

export type ScoreData = {
  subject: string
  national: number
  chart: number
}

type TableProps = {
  schoolReportTestSummary: any | undefined
  gender: UserGender
  showType: string
  loading: boolean
  hasEnduranceTest: boolean
}

const ReportTestTable = ({
  schoolReportTestSummary,
  gender,
  showType,
  loading,
  hasEnduranceTest,
}: TableProps) => {
  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const suffix = showType === 'average' ? 'avg' : 'std'
  const rowColor = isMale ? 'blue' : 'pink'
  const testColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'grade',
      key: 'grade',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: '人数',
      dataIndex: 'count',
      key: 'count',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: '身長',
      dataIndex: `height_${suffix}`,
      key: `height_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '体重',
      dataIndex: `weight_${suffix}`,
      key: `weight_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '握力',
      dataIndex: `grip_${suffix}`,
      key: `grip_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '上体起こし',
      dataIndex: `sitUps_${suffix}`,
      key: `sitUps_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '長座体前屈',
      dataIndex: `bending_${suffix}`,
      key: `bending_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '反復横跳び',
      dataIndex: `sideJump_${suffix}`,
      key: `sideJump_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    !hasEnduranceTest
      ? {
          title: 'シャトルラン',
          dataIndex: `shuttleRun_${suffix}`,
          key: `shuttleRun_${suffix}`,
          className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
          render: (v, _) => v?.toFixed(2) ?? '0.0',
        }
      : {
          title: '持久走',
          dataIndex: `enduranceRun_${suffix}`,
          key: `enduranceRun_${suffix}`,
          className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
          render: (v, _) => v?.toFixed(2) ?? '0.0',
        },
    {
      title: '50m走',
      dataIndex: `sprintRun_${suffix}`,
      key: `sprintRun_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '立ち幅跳び',
      dataIndex: `standingJump_${suffix}`,
      key: `standingJump_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: 'ボール投げ',
      dataIndex: `handballThrow_${suffix}`,
      key: `handballThrow_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '得点合計',
      dataIndex: `points_${suffix}`,
      key: `points_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
  ]

  return (
    <>
      <Table
        columns={testColumns}
        dataSource={
          isMale
            ? schoolReportTestSummary?.male
            : schoolReportTestSummary?.female
        }
        style={{ width: 1185 }}
        size="small"
        rowKey="title"
        rowClassName={(_, idx) => {
          const _default = 'text-10px text-black font-bold'
          return `${_default} ${
            idx % 2 !== 0
              ? `ant-table-row-${rowColor}-light`
              : `ant-table-row-${rowColor}-dark`
          }`
        }}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: 10,
        }}
        loading={loading}
      />
    </>
  )
}

const ReportScoreGraphs = ({ chartSummary, loading, hasEnduranceTest }) => {
  const [data, setData] = useState(null)

  const computeTestScore = (
    inputs: Array<number>,
    reverse: boolean,
  ): string => {
    const [a, b = a, c = 1] = inputs
    let ret = 50
    if (a) {
      ret = ((reverse ? b - a : a - b) / c) * 10 + 50
    }

    return ret?.toFixed(2)
  }

  useEffect(() => {
    if (loading) return

    let _data = chartSummary?.male
    let _femaleData = chartSummary?.female

    const tests = {
      totalScore: { title: '得点合計' },
      height: { title: '身長' },
      weight: { title: '体重' },
      grip: { title: '握力' },
      sitUps: { title: '上体起こし' },
      bending: { title: '長座体前屈' },
      sideJump: { title: '反復横とび' },
      ...(!hasEnduranceTest
        ? { shuttleRun: { title: 'シャトルラン' } }
        : { enduranceRun: { title: '持久走' } }),
      sprintRun: { title: '50m走' },
      standingJump: { title: '立ち幅とび' },
      handballThrow: { title: 'ボール投げ' },
    }

    let data = _data.map((datum) => {
      let grade = datum.grade
      let fdatum = _femaleData.find((d) => d.grade === grade)
      let maleChart = datum
        ? Object.keys(tests).map((key) => {
            return {
              subject: tests[key].title,
              school: computeTestScore(
                datum.chart?.[key] ?? [],
                key === 'sprintRun' || key === 'enduranceRun',
              ),
              national:
                datum.chart?.[key]?.[1] ?? datum.chart?.[key]?.[0] ?? 50,
            }
          })
        : []

      let femaleChart = Object.keys(tests).map((key) => {
        return {
          subject: tests[key].title,
          school: computeTestScore(
            fdatum?.chart?.[key] ?? [],
            key === 'sprintRun' || key === 'enduranceRun',
          ),
          national:
            fdatum?.chart?.[key]?.[1] ?? fdatum?.chart?.[key]?.[0] ?? 50,
        }
      })

      return {
        grade,
        male: {
          chart: maleChart,
        },
        female: {
          chart: femaleChart,
        },
      }
    })

    setData(data)
  }, [loading, chartSummary])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="py-8 px-4">
      <div className="text-black font-black mb-2">
        Tスコアによる全国平均値による比較
      </div>
      {data?.map((data, index) => {
        let grade = data.grade
        return (
          <div key={`graph-${index}`}>
            <div className="flex">
              <div className="w-1/2">
                <div className="text-black">{grade}年生 (男子)</div>
                <RadarChart data={data.male.chart} height={420} width={520}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis
                    angle={75}
                    tickCount={10}
                    domain={[20, 60]}
                  />
                  <Radar
                    name="学校結果"
                    dataKey="school"
                    stroke="#FF0000"
                    fill="#FF0000"
                    fillOpacity={0.2}
                    isAnimationActive={false}
                  />
                  <Legend />
                  <Tooltip />
                </RadarChart>
              </div>
              <div className="w-1/2">
                <div className="text-black">{grade}年生 (女子)</div>
                <RadarChart data={data.female.chart} height={420} width={520}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <PolarRadiusAxis
                    angle={75}
                    tickCount={10}
                    domain={[20, 60]}
                  />
                  <Radar
                    name="学校結果"
                    dataKey="school"
                    stroke="#FF0000"
                    fill="#FF0000"
                    fillOpacity={0.2}
                    isAnimationActive={false}
                  />
                  <Legend />
                  <Tooltip />
                </RadarChart>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const OverallTable = ({ schoolReportTestSummary, gender, loading }) => {
  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const rowColor = isMale ? 'blue' : 'pink'

  const countColumns: ColumnType<any>[] = [
    {
      title: 'A',
      dataIndex: 'rank_a',
      key: 'rank_a',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
    },
    {
      title: 'B',
      dataIndex: 'rank_b',
      key: 'rank_b',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
    },
    {
      title: 'C',
      dataIndex: 'rank_c',
      key: 'rank_c',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
    },
    {
      title: 'D',
      dataIndex: 'rank_d',
      key: 'rank_d',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
    },
    {
      title: 'E',
      dataIndex: 'rank_e',
      key: 'rank_e',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
    },
  ]
  const ratioColumns: ColumnType<any>[] = [
    {
      title: 'A',
      dataIndex: 'count',
      key: 'rank_a',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
      render: (v, r) => {
        if (!v || !r.rank_a) return '0%'
        return `${((r.rank_a / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'B',
      dataIndex: 'count',
      key: 'rank_b',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
      render: (v, r) => {
        if (!v || !r.rank_b) return '0%'
        return `${((r.rank_b / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'C',
      dataIndex: 'count',
      key: 'rank_c',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
      render: (v, r) => {
        if (!v || !r.rank_c) return '0%'
        return `${((r.rank_c / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'D',
      dataIndex: 'count',
      key: 'rank_d',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
      render: (v, r) => {
        if (!v || !r.rank_d) return '0%'
        return `${((r.rank_d / v) * 100).toFixed(2)}%`
      },
    },
    {
      title: 'E',
      dataIndex: 'count',
      key: 'rank_e',
      className: `w-20 h-6 font-black ant-table-${genderClass}-test`,
      render: (v, r) => {
        if (!v || !r.rank_e) return '0%'
        return `${((r.rank_e / v) * 100).toFixed(2)}%`
      },
    },
  ]
  const overallColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'grade',
      key: 'grade',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: '人数',
      dataIndex: 'ranking_count',
      key: 'ranking_count',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
  ]

  return (
    <Row gutter={4}>
      <Col span={4}>
        <div
          className={`h-6 mb-2 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {isMale ? '総合評価（男子）' : '総合評価（女子）'}
        </div>
        <Table
          columns={overallColumns}
          dataSource={
            isMale
              ? schoolReportTestSummary?.male
              : schoolReportTestSummary?.female
          }
          style={{ width: 200 }}
          size="small"
          rowKey="title"
          rowClassName={(_, idx) => {
            const _default = 'text-10px text-black font-bold'
            return `${_default} ${
              idx % 2 !== 0
                ? `ant-table-row-${rowColor}-light`
                : `ant-table-row-${rowColor}-dark`
            }`
          }}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
          loading={loading}
        />
      </Col>
      <Col span={10}>
        <div
          className={`h-6 mb-2 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          人数
        </div>
        <Table
          columns={countColumns}
          dataSource={
            isMale
              ? schoolReportTestSummary?.male
              : schoolReportTestSummary?.female
          }
          style={{ width: 500 }}
          size="small"
          rowKey="title"
          rowClassName={(_, idx) => {
            const _default = 'text-10px text-black font-bold'
            return `${_default} ${
              idx % 2 !== 0
                ? `ant-table-row-${rowColor}-light`
                : `ant-table-row-${rowColor}-dark`
            }`
          }}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
          loading={loading}
        />
      </Col>
      <Col span={10}>
        <div
          className={`h-6 mb-2 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          割合
        </div>
        <Table
          columns={ratioColumns}
          dataSource={
            isMale
              ? schoolReportTestSummary?.male
              : schoolReportTestSummary?.female
          }
          style={{ width: 500 }}
          size="small"
          rowKey="title"
          rowClassName={(_, idx) => {
            const _default = 'text-10px text-black font-bold'
            return `${_default} ${
              idx % 2 !== 0
                ? `ant-table-row-${rowColor}-light`
                : `ant-table-row-${rowColor}-dark`
            }`
          }}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

const questionnaire2024Headers = [
  'Q1',
  'Q2',
  'Q3',
  'Q3-2',
  'Q3-3',
  'Q4',
  'Q4-2',
  // 'Q5',
  'Q6',
  'Q7',
  'Q8',
  'Q9',
  'Q10',
  'Q11',
  'Q12',
  'Q13',
  'Q14',
  'Q15',
  'Q16',
  'Q17',
]

const QuestionnaireTable = ({
  questionnaireSummary,
  testYear,
  gender,
  loading,
}) => {
  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const rowColor = isMale ? 'blue' : 'pink'

  const questionColumns: ColumnType<any>[] = [
    {
      title: '質問',
      dataIndex: 'question',
      key: 'question',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '1',
      dataIndex: 'a1',
      key: 'a1',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '2',
      dataIndex: 'a2',
      key: 'a2',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '3',
      dataIndex: 'a3',
      key: 'a3',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    ...(testYear === 2024
      ? [
          {
            title: '4',
            dataIndex: 'a4',
            key: 'a4',
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v) => (v ? v : null),
          },
          {
            title: '5',
            dataIndex: 'a5',
            key: 'a5',
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v) => (v ? v : null),
          },
          {
            title: '6',
            dataIndex: 'a6',
            key: 'a6',
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v) => (v ? v : null),
          },
          {
            title: '7',
            dataIndex: 'a7',
            key: 'a7',
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v) => (v ? v : null),
          },
          {
            title: '8',
            dataIndex: 'a8',
            key: 'a8',
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v) => (v ? v : null),
          },
          {
            title: '9',
            dataIndex: 'a9',
            key: 'a9',
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v) => (v ? v : null),
          },
        ]
      : []),
    {
      title: '合計',
      dataIndex: 'total',
      key: 'total',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (_, r) => {
        return (
          (r?.a1 ?? 0) +
          (r?.a2 ?? 0) +
          (r?.a3 ?? 0) +
          (r?.a4 ?? 0) +
          (r?.a5 ?? 0) +
          (r?.a6 ?? 0) +
          (r?.a7 ?? 0) +
          (r?.a8 ?? 0) +
          (r?.a9 ?? 0)
        )
      },
    },
  ]

  let data =
    (isMale ? questionnaireSummary?.male : questionnaireSummary?.female) ?? {}
  data = Object.entries(data)
    .filter((_, index) => index !== 7) // Skip Q5
    .map(([k, v], index) => ({
      question:
        testYear !== 2024 ? k.toUpperCase() : questionnaire2024Headers[index],
      ...(v as Object),
    }))

  return (
    <>
      <div className="space-y-2">
        <div
          className={`h-6 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {isMale ? 'アンケート(男子)' : '　アンケート(女子)'}
        </div>

        <Row>
          <Col span={10}>
            <Table
              columns={questionColumns}
              dataSource={data}
              style={{ width: 500 }}
              size="small"
              rowKey="title"
              rowClassName={(_, idx) => {
                const _default = 'text-10px text-black font-bold'
                return `${_default} ${
                  idx % 2 !== 0
                    ? `ant-table-row-${rowColor}-light`
                    : `ant-table-row-${rowColor}-dark`
                }`
              }}
              pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 9,
              }}
              loading={loading}
            />
          </Col>
          <Col span={13}>
            <BarChart
              width={550}
              height={350}
              layout="vertical"
              data={data}
              stackOffset="sign"
              barCategoryGap={8}
            >
              <XAxis
                type="number"
                tick={false}
                domain={[0, 'dataMax']}
                axisLine={false}
              />
              <YAxis
                dataKey="question"
                type="category"
                interval={0}
                tickLine={false}
                style={{ color: 'red' }}
              />
              <Bar barSize={16} dataKey="a1" stackId="stack" fill="#FF9E9E" />
              <Bar barSize={16} dataKey="a2" stackId="stack" fill="#FFEA9E" />
              <Bar barSize={16} dataKey="a3" stackId="stack" fill="#8DD498" />
              <Bar barSize={16} dataKey="a4" stackId="stack" fill="#A586FF" />
              {testYear === 2024 && (
                <>
                  <Bar
                    barSize={16}
                    dataKey="a5"
                    stackId="stack"
                    fill="#CD5C5C"
                  />
                  <Bar
                    barSize={16}
                    dataKey="a6"
                    stackId="stack"
                    fill="#DFFF00"
                  />
                  <Bar
                    barSize={16}
                    dataKey="a7"
                    stackId="stack"
                    fill="#40E0D0"
                  />
                  <Bar
                    barSize={16}
                    dataKey="a8"
                    stackId="stack"
                    fill="#6495ED"
                  />
                  <Bar
                    barSize={16}
                    dataKey="a9"
                    stackId="stack"
                    fill="#CCCCFF"
                  />
                </>
              )}
              <Legend
                formatter={(value) => value.substring(1, 2)}
                verticalAlign="top"
                align="right"
              />
            </BarChart>
          </Col>
        </Row>
      </div>
    </>
  )
}

const ClassesReportTable = ({
  reportTestByClassSummary,
  gender,
  showType,
  loading,
  hasEnduranceTest,
}) => {
  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const suffix = showType === 'average' ? 'avg' : 'std'
  const rowColor = isMale ? 'blue' : 'pink'

  const testColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'grade',
      key: 'grade',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: 'クラス',
      dataIndex: ['_id', 'schoolClass'],
      key: 'schoolClass',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: '人数',
      dataIndex: 'count',
      key: 'count',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: '身長',
      dataIndex: `height_${suffix}`,
      key: `height_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '体重',
      dataIndex: `weight_${suffix}`,
      key: `weight_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '握力',
      dataIndex: `grip_${suffix}`,
      key: `grip_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '上体起こし',
      dataIndex: `sitUps_${suffix}`,
      key: `sitUps_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '長座体前屈',
      dataIndex: `bending_${suffix}`,
      key: `bending_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '反復横跳び',
      dataIndex: `sideJump_${suffix}`,
      key: `sideJump_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    !hasEnduranceTest
      ? {
          title: 'シャトルラン',
          dataIndex: `shuttleRun_${suffix}`,
          key: `shuttleRun_${suffix}`,
          className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
          render: (v, _) => v?.toFixed(2) ?? '0.0',
        }
      : {
          title: '持久走',
          dataIndex: `enduranceRun_${suffix}`,
          key: `enduranceRun_${suffix}`,
          className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
          render: (v, _) => v?.toFixed(2) ?? '0.0',
        },
    {
      title: '50m走',
      dataIndex: `sprintRun_${suffix}`,
      key: `sprintRun_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '立ち幅跳び',
      dataIndex: `standingJump_${suffix}`,
      key: `standingJump_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: 'ボール投げ',
      dataIndex: `handballThrow_${suffix}`,
      key: `handballThrow_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '得点合計',
      dataIndex: `points_${suffix}`,
      key: `points_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
  ]

  return (
    <>
      <div className="space-y-2">
        <div
          className={`h-6 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {isMale ? 'クラス平均値（男子）' : 'クラス平均値（女子）'}
        </div>
        <Table
          columns={testColumns}
          dataSource={
            isMale
              ? reportTestByClassSummary?.male
              : reportTestByClassSummary?.female
          }
          style={{ width: 1185 }}
          size="small"
          className="print:hidden"
          rowClassName={(_, idx) => {
            const _default = 'text-10px text-black font-bold'
            return `${_default} ${
              idx % 2 !== 0
                ? `ant-table-row-${rowColor}-light`
                : `ant-table-row-${rowColor}-dark`
            }`
          }}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
        />
        <Table
          columns={testColumns}
          dataSource={
            isMale
              ? reportTestByClassSummary?.male
              : reportTestByClassSummary?.female
          }
          style={{ width: 1185 }}
          size="small"
          className="print-component"
          rowClassName={(_, idx) => {
            const _default = 'text-10px text-black font-bold'
            return `${_default} ${
              idx % 2 !== 0
                ? `ant-table-row-${rowColor}-light`
                : `ant-table-row-${rowColor}-dark`
            }`
          }}
          loading={loading}
          pagination={false}
        />
      </div>
    </>
  )
}

const ClubsReportTable = ({
  extraActivitySummary,
  gender,
  loading,
  hasEnduranceTest,
}) => {
  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const rowColor = isMale ? 'blue' : 'pink'

  const testColumns: ColumnType<any>[] = [
    {
      title: '部活名',
      dataIndex: ['_id', 'q9'],
      key: 'club_name',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: '人数',
      dataIndex: 'count',
      key: 'count',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: '身長',
      dataIndex: `height_avg`,
      key: `height_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '体重',
      dataIndex: `weight_avg`,
      key: `weight_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '握力',
      dataIndex: `grip_avg`,
      key: `grip_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '上体起こし',
      dataIndex: `sitUps_avg`,
      key: `sitUps_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '長座体前屈',
      dataIndex: `bending_avg`,
      key: `bending_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '反復横跳び',
      dataIndex: `sideJump_avg`,
      key: `sideJump_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    !hasEnduranceTest
      ? {
          title: 'シャトルラン',
          dataIndex: `shuttleRun_avg`,
          key: `shuttleRun_avg`,
          className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
          render: (v, _) => v?.toFixed(2) ?? '0.0',
        }
      : {
          title: '持久走',
          dataIndex: `enduranceRun_avg`,
          key: `enduranceRun_avg`,
          className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
          render: (v, _) => v?.toFixed(2) ?? '0.0',
        },
    {
      title: '50m走',
      dataIndex: `sprintRun_avg`,
      key: `sprintRun_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '立ち幅跳び',
      dataIndex: `standingJump_avg`,
      key: `standingJump_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: 'ボール投げ',
      dataIndex: `handballThrow_avg`,
      key: `handballThrow_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
    {
      title: '得点合計',
      dataIndex: `points_avg`,
      key: `points_avg`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => v?.toFixed(2) ?? '0.0',
    },
  ]

  return (
    <>
      <div className="space-y-2">
        <div
          className={`h-6 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {isMale ? '部活動（男子）' : '部活動（女子）'}
        </div>
        <Table
          columns={testColumns}
          dataSource={
            isMale ? extraActivitySummary?.male : extraActivitySummary?.female
          }
          style={{ width: 1185 }}
          size="small"
          rowKey="title"
          rowClassName={(_, idx) => {
            const _default = 'text-10px text-black font-bold'
            return `${_default} ${
              idx % 2 !== 0
                ? `ant-table-row-${rowColor}-light`
                : `ant-table-row-${rowColor}-dark`
            }`
          }}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
        />
      </div>
    </>
  )
}

const ReportTestPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()
  const hasEnduranceTest = schoolHasEnduranceTest(school?.attributes)
  const { data: teacherData } = useSchoolMeApiQuery()
  const testYear = teacherData?.testYear

  const [statistics, setStatistics] = useState(null)
  const [loading, setLoading] = useState(true)

  const [showType, setShowType] = useState(0)
  useEffect(() => {
    if (school) {
      setLoading(true)
      axios
        .get(
          `${process.env.REACT_APP_REST_API_URL}/alpha/v1/school/statistic`,
          {
            params: {
              testYear,
            },
          },
        )
        .then((res) => {
          setStatistics(res.data)
        })
        .catch(() => {
          setStatistics({
            schoolReportTestSummary: {
              male: [],
              female: [],
            },
            schoolReportTestByClassSummary: {
              male: [],
              female: [],
            },
            schoolReportTestScoreChartSummary: {
              male: [],
              female: [],
            },
            questionnaireSummary: {
              male: [],
              female: [],
            },
            extraActivitySummary: {
              male: [],
              female: [],
            },
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [school, testYear])

  const reportRef = useRef<HTMLDivElement>()
  // TODO: Fix ResponsiveContainer width not recalculate when printing
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    bodyClass: 'p-4',
  })

  return (
    <Dashboard
      navbar={graphItem.title}
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="flex justify-center">
        <div ref={reportRef} style={{ width: 1185 }} className="mt-3">
          <div className="py-3 print:hidden">
            <Select
              className="w-30"
              size="small"
              value={showType}
              onChange={(y) => setShowType(y)}
              dropdownAlign={{
                offset: [0, -2], // align offset
              }}
            >
              <>
                {showTypes?.map((type, idx) => (
                  <Option value={idx} key={idx}>
                    {type}
                  </Option>
                ))}
              </>
            </Select>
            <Button
              type="primary"
              className="w-30 rounded float-right"
              onClick={handlePrint}
            >
              印刷
            </Button>
          </div>
          <div className="text-black font-black">{showTypes[showType]}</div>

          {(showType === 0 || showType === 1) && (
            <>
              <div className="space-y-8">
                <div className="space-y-2 page-break-auto">
                  <div className="h-6 flex justify-center items-center text-xs text-white font-black table-male-test">
                    平均値（男子）
                  </div>
                  <ReportTestTable
                    schoolReportTestSummary={
                      statistics?.schoolReportTestSummary
                    }
                    gender={UserGender.Male}
                    loading={loading}
                    showType="average"
                    hasEnduranceTest={hasEnduranceTest}
                  />
                </div>
                <div className="space-y-2 page-break-auto">
                  <div className="h-6 flex justify-center items-center text-xs text-white font-black table-female-test">
                    平均値（女子）
                  </div>
                  <ReportTestTable
                    schoolReportTestSummary={
                      statistics?.schoolReportTestSummary
                    }
                    gender={UserGender.Female}
                    loading={loading}
                    showType="average"
                    hasEnduranceTest={hasEnduranceTest}
                  />
                </div>
                <div className="space-y-2 page-break-auto">
                  <div className="h-6 flex justify-center items-center text-xs text-white font-black table-male-test">
                    標準偏差（男子）
                  </div>
                  <ReportTestTable
                    schoolReportTestSummary={
                      statistics?.schoolReportTestSummary
                    }
                    gender={UserGender.Male}
                    loading={loading}
                    showType="deviation"
                    hasEnduranceTest={hasEnduranceTest}
                  />
                </div>
                <div className="space-y-2 page-break-auto">
                  <div className="h-6 flex justify-center items-center text-xs text-white font-black table-female-test">
                    標準偏差（女子）
                  </div>
                  <ReportTestTable
                    schoolReportTestSummary={
                      statistics?.schoolReportTestSummary
                    }
                    gender={UserGender.Female}
                    loading={loading}
                    showType="deviation"
                    hasEnduranceTest={hasEnduranceTest}
                  />
                </div>
              </div>
              <div className="mt-8 space-y-8">
                <div className="space-y-2 page-break-auto">
                  <OverallTable
                    schoolReportTestSummary={
                      statistics?.schoolReportTestSummary
                    }
                    gender={UserGender.Male}
                    loading={loading}
                  />
                </div>
                <div className="space-y-2 page-break-auto">
                  <OverallTable
                    schoolReportTestSummary={
                      statistics?.schoolReportTestSummary
                    }
                    gender={UserGender.Female}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="mt-8 space-y-8">
                <div className="page-break-auto">
                  <QuestionnaireTable
                    testYear={testYear}
                    questionnaireSummary={statistics?.questionnaireSummary}
                    gender={UserGender.Male}
                    loading={loading}
                  />
                </div>
                <div className="page-break-auto">
                  <QuestionnaireTable
                    testYear={testYear}
                    questionnaireSummary={statistics?.questionnaireSummary}
                    gender={UserGender.Female}
                    loading={loading}
                  />
                </div>
              </div>
            </>
          )}

          {(showType === 0 || showType === 2) && (
            <div className="mt-8 space-y-8">
              <div className="page-break-auto">
                <ClassesReportTable
                  reportTestByClassSummary={
                    statistics?.schoolReportTestByClassSummary
                  }
                  gender={UserGender.Male}
                  loading={loading}
                  showType="average"
                  hasEnduranceTest={hasEnduranceTest}
                />
              </div>
              <div className="page-break-auto">
                <ClassesReportTable
                  reportTestByClassSummary={
                    statistics?.schoolReportTestByClassSummary
                  }
                  gender={UserGender.Female}
                  loading={loading}
                  showType="average"
                  hasEnduranceTest={hasEnduranceTest}
                />
              </div>
            </div>
          )}

          {(showType === 0 || showType === 3) && (
            <div className="mt-8 space-y-8">
              <div className="page-break-auto">
                <ClubsReportTable
                  extraActivitySummary={statistics?.extraActivitySummary}
                  gender={UserGender.Male}
                  loading={loading}
                  hasEnduranceTest={hasEnduranceTest}
                />
              </div>
              <div className="page-break-auto">
                <ClubsReportTable
                  extraActivitySummary={statistics?.extraActivitySummary}
                  gender={UserGender.Female}
                  loading={loading}
                  hasEnduranceTest={hasEnduranceTest}
                />
              </div>
            </div>
          )}

          {showType === 0 && (
            <div className="w-full">
              <ReportScoreGraphs
                chartSummary={statistics?.schoolReportTestScoreChartSummary}
                loading={loading}
                hasEnduranceTest={hasEnduranceTest}
              />
            </div>
          )}
        </div>
      </div>
      {/* <div className="hidden print:block">
        <div>
          {`${makeYearName(testYear)}`}
          児童生徒の体力・運動能力調査結果
        </div>
        <div>体力テスト測定項目の平均値と標準偏差</div>
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="text-xl">平均値（男子）</div>
            <ReportTestTable
              schoolId={school?._id}
              testYear={testYear}
              gender={UserGender.Male}
              showType="average"
            />
          </div>
          <div className="space-y-2">
            <div className="text-xl">平均値（女子）</div>
            <ReportTestTable
              schoolId={school?._id}
              testYear={testYear}
              gender={UserGender.Female}
              showType="average"
            />
          </div>
          <div className="space-y-2">
            <div className="text-xl">標準偏差（男子）</div>
            <ReportTestTable
              schoolId={school?._id}
              testYear={testYear}
              gender={UserGender.Male}
              showType="deviation"
            />
          </div>
          <div className="space-y-2">
            <div className="text-xl">標準偏差（女子）</div>
            <ReportTestTable
              schoolId={school?._id}
              testYear={testYear}
              gender={UserGender.Female}
              showType="deviation"
            />
          </div>
        </div>
      </div> */}
    </Dashboard>
  )
}

export default ReportTestPage
