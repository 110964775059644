import axios from './axios'

export const signInNew = async (data: any) => {
  return await axios.post(`/alpha/v1/school/login`, data)
}

export const getListTeachers = async () => {
  return await axios.get(`/alpha/v1/school/admin/getListTeachers`)
}

export const updateListTeachers = async (data: any) => {
  return await axios.post(`/alpha/v1/school/admin/uploadTeachers`, data)
}

export const getListStudents = async () => {
  return await axios.get(`/alpha/v1/school/admin/getListStudents`)
}

export const updateListStudents = async (data: any) => {
  return await axios.post(`/alpha/v1/school/admin/uploadStudents`, data)
}

export const allListTeachers = async () => {
  return await axios.get(`/alpha/v1/school/teacher/getListTeachers`)
}

export const uploadStudentsByTeacher = async (data: any) => {
  return await axios.post(`/alpha/v1/school/teacher/uploadStudents`, data)
}

export const fileUpload = async (file: any) => {
  return await axios.post(`/alpha/file-upload`, file)
}

export const getExerciseDiary = async (page, pageSize) => {
  return await axios.get(
    `alpha/v1/school/teacher/getExerciseDiary?page=${page ?? 1}&pageSize=${
      pageSize ?? 50
    }`,
  )
}

export const getExerciseDiaryFilter = async (
  schoolClass,
  schoolGrade,
  gender,
  page,
  pageSize,
) => {
  return await axios.get(
    `alpha/v1/school/teacher/getExerciseDiaryFilter/?schoolClass=${
      schoolClass ?? ''
    }&schoolGrade=${schoolGrade ?? ''}&gender=${gender ?? ''}&page=${
      page ?? 1
    }&pageSize=${pageSize ?? 50}`,
  )
}

export const getSchoolInformation = async () => {
  return await axios.get(`alpha/v1/school/getSchoolInformation`)
}

export const updateSchoolInformation = async (telephone?: number) => {
  return await axios.put(`alpha/v1/school/updateSchoolInformation`, telephone)
}
