import React, { useEffect, useState } from 'react'
import { read, utils, set_cptable } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import axios from 'axios'

import {
  Table,
  Button,
  Upload,
  message,
  Checkbox,
  Steps,
  Typography,
  Select,
} from 'antd'
import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import CompletionLayout from '@alpha/components/layout/CompletionLayout'
import { useHistory } from 'react-router-dom'
import { useAlphaStore } from '~/context'
import { convertJapNumbers } from '~/utils'
import { useSchoolMeApiQuery } from '~/utils/hooks'
import { PreparingPage } from '../shared/PreparingPage'

const { Option } = Select
const { Step } = Steps
const { Text } = Typography

set_cptable(cp_table)

const TestResultUpload = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()

  const [disabled, setDisabled] = useState(true)
  const [uploadFile, setUploadFile] = useState<any>()
  const [uploading, setUploading] = useState(false)

  const [data, setData] = useState<any[]>()
  const [postData, setPostData] = useState<any[]>()
  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [downloaded, setDownloaded] = useState(false)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)
  const { data: teacherData } = useSchoolMeApiQuery()
  const testYear = teacherData?.testYear
  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade
  const teacherSchoolClass = teacherData?.schoolClass

  const history = useHistory()

  const showPreparingPage = false
  const isDev = window.location.href?.endsWith('?dev')

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setDisabled(!downloaded)
        break
      case 1:
        setDisabled(!confirmed1)
        break
      case 2:
        setDisabled(!confirmed2)
        break
      case 3:
        setDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, downloaded, confirmed1, confirmed2, confirmed3])

  const onFinish = async (values = {}) => {
    setUploading(true)

    // upload backup file
    let schoolId = school._id
    let schoolName = school.attributes.schoolName
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `test_result/${schoolName}_${schoolId}_${fileId}`

    let fileName: string

    const testResultFormData = new FormData()
    testResultFormData.append('file', uploadFile)
    testResultFormData.append('filename', fileId)

    await axios
      .post(
        `${process.env.REACT_APP_REST_API_URL!}/alpha/file-upload`,
        testResultFormData,
      )
      .then((res) => {
        fileName = res.data.data
      })

    // upload test_result data
    await axios({
      method: 'POST',
      url: `${process.env
        .REACT_APP_REST_API_URL!}/alpha/upload-test-result-data`,
      data: {
        schoolId: school._id,
        postData,
        testYear,
      },
    })
      .then((response) => {
        let { status, error } = response.data
        if (status === 200) {
          message.success('アップロードしました')
          // form.resetFields()
          setUploadFile(null)
          setTimeout(() => {
            setUploading(false)
          }, 1000)
        } else {
          if (error) {
            message.error(error)
          } else {
            message.error('エラーが発生しました')
          }
        }
        setUploading(false)
      })
      .catch((response) => {
        console.error(response)
        message.error('エラーが発生しました')
        setUploading(false)
      })
  }

  const register = async () => {
    await onFinish()
    setRegisterComplete(true)
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      message.error('Excelファイルを選んでください')
      return
    }

    setCurrentStepIdx(nextStepIdx)
  }

  const prev = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  const downloadTemplate = () => {
    window.open(
      '/upload_sample/ALPHAアップロード用ファイル-測定結果.xlsx',
      '_blank',
    )
    setDownloaded(true)
  }

  const parseXlsx = async (file) => {
    let _data = []
    const submitData = []
    const data = read(await file.arrayBuffer())

    const firstSheetName = data.SheetNames[0]
    const workBook = data.Sheets

    if (firstSheetName && workBook) {
      let range = utils.decode_range(workBook[firstSheetName]['!ref'])
      range.s.r = 1 // skip row 0
      workBook[firstSheetName]['!ref'] = utils.encode_range(range)

      const rows = utils.sheet_to_json(workBook[firstSheetName])
      for (let row of rows) {
        let _schoolGrade =
          typeof row['学年'] === 'number'
            ? row['学年']
            : parseInt(convertJapNumbers(row['学年']))
        let _schoolClass =
          typeof row['学年'] === 'number'
            ? row['組']
            : parseInt(convertJapNumbers(row['組']))

        if (isNaN(_schoolGrade) || isNaN(_schoolClass)) continue

        const values = {
          schoolGrade: _schoolGrade,
          schoolClass: _schoolClass,
          schoolAttendanceNumber: row['出席番号'],
          height: row['身長'],
          weight: row['体重'],
          gripRight1: row['握力右1'],
          gripRight2: row['握力右2'],
          gripLeft1: row['握力左1'],
          gripLeft2: row['握力左2'],
          sitUps: row['上体起こし'],
          bending1: row['長座体前屈1'],
          bending2: row['長座体前屈2'],
          sideJump1: row['反復横跳び1'],
          sideJump2: row['反復横跳び2'],
          shuttleRun: row['20mシャトルラン'],
          sprintRun: row['50m走'],
          standingJump1: row['立ち幅跳び1'],
          standingJump2: row['立ち幅跳び2'],
          handballThrow1: row['ボール投げ1'],
          handballThrow2: row['ボール投げ2'],
          runningTime: row['持久走（分）'],
          runningTimeSeconds: row['持久走（秒）'],
        }
        _data.push(values)
        submitData.push({
          schoolGrade: values.schoolGrade,
          schoolClass: values.schoolClass,
          schoolAttendanceNumber: values.schoolAttendanceNumber,
          gripTest: {
            testYear,
            grip: {
              gripRight1: values.gripRight1,
              gripRight2: values.gripRight2,
              gripLeft1: values.gripLeft1,
              gripLeft2: values.gripLeft2,
              isNotMeasurable: false,
              memo: '',
            },
          },
          sitUpsTest: {
            testYear,
            sitUps: {
              sitUps: values.sitUps,
              isNotMeasurable: false,
              memo: '',
            },
          },
          bendingTest: {
            testYear,
            bending: {
              bending1: values.bending1,
              bending2: values.bending2,
              isNotMeasurable: false,
              memo: '',
            },
          },
          sideJumpTest: {
            testYear,
            sideJump: {
              sideJump1: values.sideJump1,
              sideJump2: values.sideJump2,
              isNotMeasurable: false,
              memo: '',
            },
          },
          //(!hasEnduranceTest ? 'enduranceRun' : 'shuttleRun'))
          enduranceRunTest: {
            testYear,
            enduranceRun: {
              // TODO: runningTime should be time in mm:ss format
              runningTime: values.runningTime?.toString(),
              // TODO: runningTimeSeconds should be the result of adding runningTime minutes and seconds
              runningTimeSeconds: values.runningTimeSeconds,
              isNotMeasurable: false,
              memo: '',
            },
          },
          shuttleRunTest: {
            testYear,
            shuttleRun: {
              shuttleRunCount: values.shuttleRun,
              isNotMeasurable: false,
              memo: '',
            },
          },
          sprintRunTest: {
            testYear,
            sprintRun: {
              sprintRunSeconds: values.sprintRun,
              isNotMeasurable: false,
              memo: '',
            },
          },
          standingJumpTest: {
            testYear,
            standingJump: {
              standingJump1: values.standingJump1,
              standingJump2: values.standingJump2,
              isNotMeasurable: false,
              memo: '',
            },
          },
          handballThrowTest: {
            testYear,
            handballThrow: {
              handballThrow1: values.handballThrow1,
              handballThrow2: values.handballThrow2,
              isNotMeasurable: false,
              memo: '',
            },
          },
          sizeTest: {
            testYear,
            weight: values.weight,
            height: values.height,
          },
        })
      }
      if (teacherRole === 'SUPER_ADMIN' || teacherRole === 'SCHOOL_ADMIN') {
        setData(_data)
      } else if (teacherRole === 'GRADE_ADMIN') {
        const newData = _data?.filter(
          (inf) => inf.schoolGrade === teacherSchoolGrade,
        )
        setData(newData)
      } else if (teacherRole === 'CLASS_ADMIN') {
        const filterData = _data?.filter(
          (inf) =>
            inf.schoolGrade === teacherSchoolGrade &&
            inf.schoolClass === teacherSchoolClass,
        )
        setData(filterData)
      }
      setPostData(submitData)
    }
  }

  const tableColumns: ColumnType<any>[] = [
    {
      title: '学年',
      dataIndex: 'schoolGrade',
      key: 'schoolGrade',
      className: 'text-center-f',
    },
    {
      title: '組',
      dataIndex: 'schoolClass',
      key: 'schoolClass',
      className: 'text-center-f',
    },
    {
      title: '出席番号',
      dataIndex: 'schoolAttendanceNumber',
      key: 'schoolAttendanceNumber',
      className: 'text-center-f',
    },
    {
      title: '身長',
      dataIndex: 'height',
      key: 'height',
      className: 'text-center-f',
    },
    {
      title: '体重',
      dataIndex: 'weight',
      key: 'weight',
      className: 'text-center-f',
    },
    {
      title: '握力右1',
      dataIndex: 'gripRight1',
      key: 'gripRight1',
      className: 'text-center-f',
    },
    {
      title: '握力右2',
      dataIndex: 'gripRight2',
      key: 'gripRight2',
      className: 'text-center-f',
    },
    {
      title: '握力左1',
      dataIndex: 'gripLeft1',
      key: 'gripLeft1',
      className: 'text-center-f',
    },
    {
      title: '握力左2',
      dataIndex: 'gripLeft2',
      key: 'gripLeft2',
      className: 'text-center-f',
    },
    {
      title: '上体起こし',
      dataIndex: 'sitUps',
      key: 'sitUps',
      className: 'text-center-f',
    },
    {
      title: '長座体前屈1',
      dataIndex: 'bending1',
      key: 'bending1',
      className: 'text-center-f',
    },
    {
      title: '長座体前屈2',
      dataIndex: 'bending2',
      key: 'bending2',
      className: 'text-center-f',
    },
    {
      title: '反復横跳び1',
      dataIndex: 'sideJump1',
      key: 'sideJump1',
      className: 'text-center-f',
    },
    {
      title: '反復横跳び2',
      dataIndex: 'sideJump2',
      key: 'sideJump2',
      className: 'text-center-f',
    },
    {
      title: 'シャトルラン',
      dataIndex: 'shuttleRun',
      key: 'shuttleRun',
      className: 'text-center-f',
    },
    {
      title: '50m走',
      dataIndex: 'sprintRun',
      key: 'sprintRun',
      className: 'text-center-f',
    },
    {
      title: '立ち幅跳び1',
      dataIndex: 'standingJump1',
      key: 'standingJump1',
      className: 'text-center-f',
    },
    {
      title: '立ち幅跳び2',
      dataIndex: 'standingJump2',
      key: 'standingJump2',
      className: 'text-center-f',
    },
    {
      title: 'ボール投げ1',
      dataIndex: 'handballThrow1',
      key: 'handballThrow1',
      className: 'text-center-f',
    },
    {
      title: 'ボール投げ2',
      dataIndex: 'handballThrow2',
      key: 'handballThrow2',
      className: 'text-center-f',
    },
    {
      title: '持久走（分）',
      dataIndex: 'runningTime',
      key: 'runningTime',
      className: 'text-center-f',
    },
    {
      title: '持久走（秒）',
      dataIndex: 'runningTimeSeconds',
      key: 'runningTimeSeconds',
      className: 'text-center-f',
    },
  ]

  const tableProps = {
    columns: tableColumns,
    dataSource: data,
    rowKey: 'attendanceNumber',
  }

  const steps = [
    {
      title: '①登録用エクセルファイルをダウンロード',
      content: (
        <div className="steps-content flex items-center justify-center">
          <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
            <DownloadOutlined
              className="text-2xl"
              onClick={() => downloadTemplate()}
            />
          </div>
          <Button type="primary" onClick={() => downloadTemplate()}>
            登録用エクセルファイルをダウンロード
          </Button>
        </div>
      ),
    },
    {
      title: '②記入例',
      content: (
        <div className="steps-content flex flex-col items-center justify-between pt-5">
          <img className="mb-5" src="/test_result_demo.png" alt="" />
          <Checkbox
            className="font-black"
            checked={confirmed1}
            onChange={(e) => setConfirmed1(e.target.checked)}
          >
            記入例を確認しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: '③測定結果をアップロード',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black">
              ①でダウンロードしたファイルをアップロードしてください。
            </Text>
          </div>
          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file) => {
                parseXlsx(file)
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setUploadFile(null)
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">測定結果をアップロード</Button>
              </div>
            </Upload>
          </div>
          <Text className="mb-36">(XLSXファイル式)</Text>
          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
          >
            測定結果を正しく記入しましたか？
          </Checkbox>
        </div>
      ),
    },
    {
      title: ' ④確認',
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <Table
            {...tableProps}
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 50,
              position: ['bottomCenter'],
            }}
          />
          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
            disabled={data?.length === 0}
          >
            記入したデータは正しいでしょうか？
          </Checkbox>
        </div>
      ),
    },
  ]

  const preparingElement = (
    <>
      <label className="text-base font-black">
        Excelでの入力をご希望の方は
      </label>
      <label className="text-base font-black">
        こちらのメールアドレスまでご連絡をお願いいたします。
      </label>
      <a
        className="text-base font-black"
        style={{ color: 'rgb(255,200,51)' }}
        href="mailto: alpha@pestalozzi-tech.co"
      >
        alpha@pestalozzi-tech.com
      </a>
    </>
  )

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {showPreparingPage && !isDev ? (
        <PreparingPage notice={preparingElement} />
      ) : !registerComplete ? (
        <div className="flex justify-center">
          <div className="mt-16" style={{ minWidth: '900px' }}>
            {/* <div className="space-x-4 mb-12">
                    <Select
                      className="w-30"
                      value={testYear}
                      onChange={(y) => setTestYear(y)}
                      dropdownAlign={{
                        offset: [0, -2], // align offset
                      }}
                      disabled={currentStepIdx > 1}
                    >
                      <>
                        {testYears?.map((year) => (
                          <Option value={year} key={year}>
                            {year}年度
                          </Option>
                        ))}
                      </>
                    </Select>
                  </div> */}

            <Steps
              labelPlacement="vertical"
              size="default"
              current={currentStepIdx}
              onChange={() => {}}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[currentStepIdx].content}</div>
            <div className="steps-action text-center">
              {currentStepIdx > 0 && (
                <Button
                  type="primary"
                  className="h-8 w-24 mx-2"
                  onClick={() => prev()}
                >
                  戻る
                </Button>
              )}
              {currentStepIdx < steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => next()}
                  disabled={disabled}
                >
                  次へ
                </Button>
              )}
              {currentStepIdx === steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => register()}
                  disabled={disabled}
                >
                  登録
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CompletionLayout
          message="登録完了"
          button={
            <Button type="primary" onClick={() => history.push('/test_result')}>
              測定結果を閲覧する
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default TestResultUpload
