import axios from 'axios'

export const fetchSchoolClasses = (schoolId, testYear) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${process.env.REACT_APP_REST_API_URL}/alpha/max-class`, {
        schoolId: schoolId,
        testYear,
      })
      .then((res) => {
        const maxClass = res.data.maxClass
        let classes = []
        if (maxClass) {
          classes = Array.from({ length: maxClass }, (_, i) => i + 1)
        }
        resolve({ schoolClasses: classes })
      })
  })
}
