import { useEffect, useRef, useState } from 'react'
import Api from '../utils/api'
import { message } from 'antd'
import querystring from 'querystring'
import axios from 'axios'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core/auth/AuthProvider'

const useAuthApiGet = (
  apiUrl: string,
  skipError: boolean = false,
  firstLoad?: boolean,
): any => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const fetchedRef = useRef(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (!firstLoad) {
      new Promise(async () => {
        try {
          setLoading(true)
          const { data } = await Api.get(apiUrl)
          setData(data)
          if (
            apiUrl === '/alpha/v1/school/me' &&
            typeof data._id !== 'string'
          ) {
            window.localStorage.clear()
            delete axios.defaults.headers.common[LOCAL_STORAGE_AUTH_TOKEN_KEY]
          }
        } catch (e) {
          console.log(e)
          if (!skipError) message.error('エラーが発生しました')
        } finally {
          fetchedRef.current = false
          setLoading(false)
        }
      })
    }
  }, [apiUrl, skipError, firstLoad])

  return {
    data,
    loading,
    refresh: () => {
      setRefresh(!refresh)
    },
  }
}

// Alpha student apis
export const useStudentMeApiQuery = () => useAuthApiGet('/alpha/v1/student/me')
export const useStudentQuestionnaireResultApiQuery = () =>
  useAuthApiGet('/alpha/v1/student/survey')
export const useStudentTestsApiQuery = () =>
  useAuthApiGet('/alpha/v1/student/exams')
// Alpha school apis
export const useSchoolMeApiQuery = () =>
  useAuthApiGet('/alpha/v1/school/me', true)
export const useSchoolGradesApiQuery = (params: {
  schoolId: string
  testYear: number
  schoolGrade?: number
}) => useAuthApiGet(`/alpha/v1/school/grades?${querystring.stringify(params)}`)
export const useSchoolSurveysApiQuery = (
  params: {
    schoolId: string
    testYear: number
    schoolGrade: number
    schoolClass: number
    offset: number
    limit: number
  },
  firstLoad?: boolean,
) =>
  useAuthApiGet(
    `/alpha/v1/school/survey?${querystring.stringify(params)}`,
    false,
    firstLoad,
  )
export const useSchoolQuestionnaireResultApiQuery = (params: string) =>
  useAuthApiGet(`/alpha/v1/school/survey?${params}`)
