import {
  AuthProvider,
  Client,
  PrivateRoute,
  PublicRoute,
  getApolloClient,
  offlineLink,
} from '@alpha/core'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { dashboardNavGraphItems } from './components/navigation'
import ExerciseDiaryPage from './components/pages/ExerciseDiaryPage'
import KobeSchoolYearInfoPage from './components/pages/KobeSchoolYearInfoPage'
import LogoutPage from './components/pages/LogoutPage'
import QuestionnaireInputOldPage from './components/pages/QuestionnaireInputOldPage'
import QuestionnaireResultOldPage from './components/pages/QuestionnaireResultOldPage'
import QuestionnaireResultPage from './components/pages/QuestionnaireResultPage'
import QuestionnaireUpload from './components/pages/QuestionnaireUpload'
import QuestionnaireEditPage from './components/pages/QuestionnarieEditPage'
import QuestionnaireInputPage from './components/pages/QuestionnarieInputPage'
import RegisterCodePage from './components/pages/RegisterCodePage'
import RegisterSchoolPage from './components/pages/RegisterSchoolPage'
import RegisterUserPage from './components/pages/RegisterUserPage'
import ReportTestPage from './components/pages/ReportTestPage'
import SchoolInfoPage from './components/pages/SchoolInfoPage'
import SchoolYearInfoPage from './components/pages/SchoolYearInfoPage'
import SignInPage from './components/pages/SignInPage'
import StandardTable from './components/pages/StandardTablePage'
import StudentListPage from './components/pages/StudentListPage'
import StudentManagementPage from './components/pages/StudentManagementPage'
import TeacherListPage from './components/pages/TeacherListPage'
import TeacherManagementPage from './components/pages/TeacherManagementPage'
import TestInputPage from './components/pages/TestInputPage'
import TestResultPage from './components/pages/TestResultPage'
import TestResultUpload from './components/pages/TestResultUpload'
import TestResultsForPrintPage from './components/pages/TestResultsForPrintPage'
import { useAlphaStore } from './context'
import { APP_VERSION } from './utils/constants'

// to determine whether new versions is available
console.info(APP_VERSION)

function App() {
  const { kobe } = useAlphaStore()

  const [isOpen, setIsOpen] = useState(true)
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    window.addEventListener('offline', () => {
      message.warning('オフラインになりました。')
    })
    window.addEventListener('online', () => {
      offlineLink
        .sync()
        .then(() => {
          message.success('オンラインになりました。変更を送信しました。')
        })
        .catch((e) => {
          message.error('キューの送信に失敗しました')
          console.error(e)
        })
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      const ac = await getApolloClient(Client.SchoolClient)
      setClient(ac)
    })()
  }, [])

  if (!client) return null

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/home`}
              component={SchoolInfoPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'school_info',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/teacher_management`}
              component={TeacherManagementPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'teacher_management',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/student_management`}
              component={StudentManagementPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'student_management',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/teacher_list`}
              component={TeacherListPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'teacher_list',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/student_list`}
              component={StudentListPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'student_list',
              )}
            />

            {kobe ? (
              <PrivateRoute
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                path={`/kobe_school_year_info`}
                component={KobeSchoolYearInfoPage}
                graphItem={dashboardNavGraphItems.find(
                  (i) => i.key === 'kobe_school_year_info',
                )}
              />
            ) : (
              <PrivateRoute
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                path={`/school_year_info`}
                component={SchoolYearInfoPage}
                graphItem={dashboardNavGraphItems.find(
                  (i) => i.key === 'school_year_info',
                )}
              />
            )}
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/test`}
              component={TestInputPage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'test')}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/test_result`}
              component={TestResultPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'test_result',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/test_result_upload`}
              component={TestResultUpload}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'test_result_upload',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/questionnaire_old`}
              component={QuestionnaireInputOldPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire_old',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/questionnaire_result_old`}
              component={QuestionnaireResultOldPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire_result_old',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/questionnaire`}
              component={QuestionnaireInputPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/questionnaire_edit`}
              component={QuestionnaireEditPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire_edit',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/questionnaire_result`}
              component={QuestionnaireResultPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire_result',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/questionnaire_upload`}
              component={QuestionnaireUpload}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire_upload',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/test_report`}
              component={ReportTestPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'test_report',
              )}
            />

            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/school_setup`}
              component={RegisterSchoolPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'school_setup',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/code`}
              component={RegisterCodePage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'code')}
            />
            <PublicRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path={`/register`}
              component={RegisterUserPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'register',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/logout"
              component={LogoutPage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'logout')}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/test_results_for_print"
              component={TestResultsForPrintPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'test_results_for_print',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/standard_table"
              component={StandardTable}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'standard_table',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/exercise_diary"
              component={ExerciseDiaryPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'exercise_diary',
              )}
            />
            <PublicRoute path="/" component={SignInPage} />
          </Switch>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
