import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { writeFile, utils, set_cptable } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'
import {
  Button,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd'

import {
  useGradesLazyQuery,
  useStudentsLazyQuery,
  fetchSchoolClasses,
} from '@alpha/core'

import StudentTestResultModal from '../modal/StudentTestResultModal'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import { useSchoolMeApiQuery } from '~/utils/hooks'

import _ from 'lodash'
import { useReactToPrint } from 'react-to-print'
import {
  ageFromSchool,
  getShowRanking,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '../../utils'
import { useAlphaStore } from '~/context'
import TestResultsForPrintPage from './TestResultsForPrintPage'
import { pageSize as defaultPageSize } from '~/utils/constants'

set_cptable(cp_table)

const { Option } = Select

const TestResultPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()
  const { data: teacherData } = useSchoolMeApiQuery()
  const testYear = teacherData?.testYear
  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade
  const teacherSchoolClass = teacherData?.schoolClass

  const [schoolGrade, setSchoolGrade] = useState<any>(
    teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
      ? teacherSchoolGrade
      : 0,
  )
  const [schoolClasses, setSchoolClasses] = useState([
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1,
  ])
  const [schoolClass, setSchoolClass] = useState<any>(
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0,
  )
  const [firstLoad, setFirstLoad] = useState(true)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (firstLoad && teacherRole) {
      setFirstLoad(false)
      setSchoolGrade(
        teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
          ? teacherSchoolGrade
          : 0,
      )
      setSchoolClasses([teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1])
      setSchoolClass(teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0)
    }
  }, [firstLoad, teacherRole])

  const [data, setData] = useState<any[]>()
  const [allData, setAllData] = useState<any[]>()
  const [xlsxData, setXlsxData] = useState<any>()
  const [selectedStudent, setSelectedStudent] = useState<any>(null)
  const [showDetailModal, setShowDetailModal] = useState<any>(false)
  const [isEnduranceRunInputActive, setIsEnduranceRunInputActive] =
    useState(false)
  const [filterGender, setFilterGender] = useState('')

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const studentTestForPrintRef = useRef<HTMLDivElement>()

  const EditableContext = createContext(null)

  const [getGrades, { data: gradesData }] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [studentData, setStudentData] = useState({students: {count: 0, data: []}});

  const [getAllStudents, { data: allStudentsData, loading }] = useStudentsLazyQuery({
    fetchPolicy: 'network-only',
  })

  // getGrades
  useEffect(() => {
    if (!school) {
      return
    }
    const schoolId = school._id
    getGrades({
      variables: {
        input: {
          schoolId,
          testYear,
        },
      },
    })
  }, [school, getGrades, testYear])

  

  // getAllStudents
  useEffect(() => {
    if (!school) return

    let schoolId = school._id
    if (!schoolId) return

    getAllStudents({
      variables: {
        input: {
          schoolId: schoolId,
          testYear,
        },
      },
    })
  }, [school, testYear, getAllStudents])

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, testYear).then((res) => {
        const schoolClasses = res['schoolClasses']
        if (schoolClasses.length) {
          schoolClasses.sort()
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [testYear, school])

  useEffect(() => {
    const data = (allStudentsData?.students.data ?? [])
      .filter(d => (d.attributes.schoolClass === schoolClass || schoolClass === 0) && (d.attributes.schoolGrade === schoolGrade || schoolGrade === 0))
    setStudentData({
      students: {
        count: data.length,
        data
      }
    })
    const testData = (allData ?? [])
      .filter(d => (d.attributes.schoolClass === schoolClass || schoolClass === 0) && (d.attributes.schoolGrade === schoolGrade || schoolGrade === 0))
    setData(testData)
  }, [allData, allStudentsData, schoolClass, schoolGrade])

  // setIsEnduranceRunInputActive
  useEffect(() => {
    if (!gradesData?.grades?.data) return
    if (!school) return

    let grade_IsEnduranceRunInputActive =
      gradesData?.grades.data.find((i) => i.schoolGrade === schoolGrade)
        ?.isEnduranceRunInputActive ?? false

    let isEnduranceRunInputActive = schoolHasEnduranceTest(school?.attributes)
      ? true
      : grade_IsEnduranceRunInputActive

    setIsEnduranceRunInputActive(isEnduranceRunInputActive)
  }, [gradesData, school, schoolGrade, testYear])

  const hasEnduranceTest = schoolHasEnduranceTest(school?.attributes)

  // setCSVData
  useEffect(() => {
    if (!allData) {
      return
    }

    let xlsxData = allData.map((student) => {
      let attributes = student.attributes
      let testResult =
        attributes.testResults &&
        attributes.testResults.find((d) => d.testYear === testYear)
      return {
        年度: testYear,
        学年: attributes.schoolGrade,
        組: attributes.schoolClass,
        出席番号: attributes.schoolAttendanceNumber ?? '',
        ID: student.username,
        性別: attributes.gender === 'MALE' ? '男' : '女',
        身長: testResult?.sizeTest?.height,
        体重: testResult?.sizeTest?.weight,
        総得点: testResult?.points,
        評価: testResult?.rank,
        握力: testResult?.grip?.isNotMeasurable
          ? '無'
          : testResult?.grip?.value ?? '',
        上体起こし: testResult?.sitUps?.isNotMeasurable
          ? '無'
          : testResult?.sitUps?.value ?? '',
        長座体前屈: testResult?.bending?.isNotMeasurable
          ? '無'
          : testResult?.bending?.value ?? '',
        反復横とび: testResult?.sideJump?.isNotMeasurable
          ? '無'
          : testResult?.sideJump?.value ?? '',
        ...(!hasEnduranceTest
          ? {
              '20mシャトルラン': testResult?.shuttleRun?.isNotMeasurable
                ? '無'
                : testResult?.shuttleRun?.value ?? '',
            }
          : {}),
        '50m走': testResult?.sprintRun?.isNotMeasurable
          ? '無'
          : testResult?.sprintRun?.value ?? '',
        立ち幅とび: testResult?.standingJump?.isNotMeasurable
          ? '無'
          : testResult?.standingJump?.value ?? '',
        ボール投げ: testResult?.handballThrow?.isNotMeasurable
          ? '無'
          : testResult?.handballThrow?.value ?? '',
        ...(hasEnduranceTest
          ? {
              持久走: testResult?.enduranceRun?.isNotMeasurable
                ? '無'
                : testResult?.enduranceRun?.runningTime ?? '',
              持久走の秒: testResult?.enduranceRun?.isNotMeasurable
                ? '無'
                : testResult?.enduranceRun?.runningTimeSeconds ?? '',
            }
          : {}),
      }
    })

    function compare(a: any, b: any) {
      return a['組'] - b['組']
    }

    xlsxData = xlsxData.sort(compare)

    setXlsxData(xlsxData)
  }, [allData, hasEnduranceTest, testYear])

  // setData
  useEffect(() => {
    if (!allStudentsData?.students?.data) return

    let studentsData = allStudentsData.students.data
    setTotal(allStudentsData.students.count)

    studentsData?.forEach((student) => {
      let trIdx =
        student?.attributes?.testResults &&
        student.attributes.testResults.findIndex((d) => d.testYear === testYear)

      if (trIdx >= 0) {
        const age = ageFromSchool(
          school?.attributes?.schoolCategoryCode,
          student?.attributes?.schoolGrade,
        )
        let gender = student?.attributes?.gender
        if (gender) {
          student.attributes.testResults[trIdx] = reCalcTestResult(
            student.attributes.testResults[trIdx],
            age,
            gender,
          )
        }
      }
    })

    if (filterGender === '') {
      setAllData(studentsData)
    } else {
      studentsData = studentsData.filter(
        (s) => s.attributes.gender === filterGender,
      )
      setAllData(studentsData)
    }
  }, [allStudentsData?.students.count, allStudentsData?.students.data, filterGender, school?.attributes?.schoolCategoryCode, testYear])

  const downloadXlsx = () => {
    let workbook = utils.book_new()
    let worksheet = utils.json_to_sheet(xlsxData)
    utils.book_append_sheet(workbook, worksheet, '測定結果')
    writeFile(workbook, 'test_result.xlsx', {
      bookType: 'xlsx',
    })
  }

  const incorrectValue = <div className="bg-gray-160 -m-2">&nbsp;</div>

  const renderTest = (v, test) => {
    const defVal = incorrectValue
    if (!v || v.length === 0) return defVal
    const tr = v.find((d) => d.testYear === testYear)
    if (!tr || tr.length === 0) return defVal

    let value
    switch (test) {
      case 'grip':
      case 'bending':
      case 'sideJump':
      case 'standingJump':
      case 'handballThrow':
      case 'sitUps':
      case 'sprintRun':
      case 'shuttleRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        value = tr[test].isNotMeasurable === true ? '無' : tr[test].value
        if (
          value === null ||
          value === undefined
        )
          return defVal
        break
      }
      case 'enduranceRun': {
        if (!tr[test] || tr[test].length === 0) return defVal
        if (tr.enduranceRun?.isNotMeasurable === true) return '無'
        value =
          tr[test].runningTime && tr[test].runningTimeSeconds
            ? `${tr.enduranceRun?.runningTime}'${tr.enduranceRun?.runningTimeSeconds}''`
            : defVal
        if (!value) return defVal
        break
      }
      case 'height':
      case 'weight': {
        value = tr.sizeTest?.[test]
        if (!value || value.length === 0) return defVal
        break
      }
      case 'points':
      case 'rank': {
        value = tr[test]
        if (!value || value.length === 0) return defVal
        break
      }
    }
    return (
      <div className={tr[test]?.memo ? 'underline cursor-pointer' : ''}>
        <Tooltip placement="top" title={tr[test]?.memo}>
          <div>{value}</div>
        </Tooltip>
      </div>
    )
  }

  const getTestResults = (student) => {
    return (
      student.attributes.testResults &&
      student.attributes.testResults.find((d) => d.testYear === testYear)
    )
  }

  const sortTestResults = (a, b, key) => {
    let element1
    let element2

    switch (key) {
      case 'grip':
      case 'sitUps':
      case 'bending':
      case 'sprintRun':
      case 'sideJump':
      case 'standingJump':
      case 'shuttleRun':
      case 'handballThrow':
      case 'enduranceRun': {
        let a_tr = getTestResults(a)
        let b_tr = getTestResults(b)

        element1 = a_tr?.[key]?.value
        element2 = b_tr?.[key]?.value
        break
      }
      case 'rank':
      case 'points': {
        let a_tr = getTestResults(a)
        let b_tr = getTestResults(b)

        element1 = a_tr?.[key]
        element2 = b_tr?.[key]
        break
      }
      case 'height':
      case 'weight': {
        let a_tr = getTestResults(a)
        let b_tr = getTestResults(b)

        element1 = a_tr?.sizeTest?.[key]
        element2 = b_tr?.sizeTest?.[key]
        break
      }
      case 'name': {
        element1 = `${a?.familyName ?? ''}${a?.givenName ?? ''}`
        element2 = `${b?.familyName ?? ''}${b?.givenName ?? ''}`
        break
      }
      default: {
        element1 = a?.[key]
        element2 = b?.[key]
      }
    }

    if (element1 == null && element2 == null) return 1
    if (element1 == null) return -1
    if (element2 == null) return 1
    return typeof element1 === 'number'
      ? element1 - element2
      : element1.localeCompare(element2)
  }

  const columns: any[] = [
    {
      title: '学年',
      key: 'schoolGrade',
      dataIndex: ['attributes', 'schoolGrade'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolGrade')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '組',
      dataIndex: ['attributes', 'schoolClass'],
      key: 'schoolClass',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolClass')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '出席番号',
      key: 'schoolAttendanceNumber',
      dataIndex: ['attributes', 'schoolAttendanceNumber'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'schoolAttendanceNumber')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'ID',
      key: 'username',
      dataIndex: ['username'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'username')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => <div className="whitespace-nowrap">{v}</div>,
    },
    {
      title: '性別',
      dataIndex: ['attributes', 'gender'],
      key: 'gender',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        let gender1 = a?.attributes?.gender
        let gender2 = b?.attributes?.gender

        if (!gender1 && !gender2) return 1
        if (!gender1) return -1
        if (!gender2) return 1
        return gender1.localeCompare(gender2)
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => <div>{v ? (v === 'MALE' ? '男' : '女') : '-'}</div>,
    },
    {
      title: '総得点',
      key: 'points',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'points')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        let tr = v.find((d) => d.testYear === testYear)
        return <div>{tr ? tr?.points : incorrectValue}</div>
      },
    },
    {
      title: '評価',
      key: 'rank',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'rank')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        if (!v || v.length === 0) return incorrectValue
        let tr = v.find((d) => d.testYear === testYear)
        const showRanking = getShowRanking(tr, hasEnduranceTest)

        return <div>{showRanking ? tr?.rank : incorrectValue}</div>
      },
    },

    {
      title: '身長',
      dataIndex: ['attributes', 'testResults'],
      key: 'height',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'height')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'height')
      },
    },
    {
      title: '体重',
      dataIndex: ['attributes', 'testResults'],
      key: 'weight',
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'weight')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'weight')
      },
    },
    {
      title: '握力',
      key: 'grip',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'grip')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'grip')
      },
    },
    {
      title: '上体起こし',
      key: 'sitUps',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sitUps')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sitUps')
      },
    },
    {
      title: '長座体前屈',
      key: 'bending',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'bending')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'bending')
      },
    },
    {
      title: '反復横跳び',
      key: 'sideJump',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sideJump')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sideJump')
      },
    },
    ...(!hasEnduranceTest
      ? [
          {
            title: 'シャトルラン',
            key: 'shuttleRun',
            dataIndex: ['attributes', 'testResults'],
            className: 'text-center-f whitespace-nowrap',
            sorter: (a, b) => {
              return sortTestResults(a, b, 'shuttleRun')
            },
            sortDirections: ['descend', 'ascend'],
            render: (v) => {
              return renderTest(v, 'shuttleRun')
            },
          },
        ]
      : []),
    {
      title: '50m走',
      key: 'sprintRun',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'sprintRun')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'sprintRun')
      },
    },
    {
      title: '立ち幅跳び',
      key: 'standingJump',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'standingJump')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'standingJump')
      },
    },
    {
      title: 'ボール投げ',
      key: 'handballThrow',
      dataIndex: ['attributes', 'testResults'],
      className: 'text-center-f whitespace-nowrap',
      sorter: (a, b) => {
        return sortTestResults(a, b, 'handballThrow')
      },
      sortDirections: ['descend', 'ascend'],
      render: (v) => {
        return renderTest(v, 'handballThrow')
      },
    },
    ...(hasEnduranceTest
      ? [
          {
            title: '持久走',
            key: 'enduranceRun',
            dataIndex: ['attributes', 'testResults'],
            className: 'text-center-f whitespace-nowrap',
            sorter: (a, b) => {
              return sortTestResults(a, b, 'enduranceRun')
            },
            sortDirections: ['descend', 'ascend'],
            render: (v) => {
              return renderTest(v, 'enduranceRun')
            },
          },
        ]
      : []),
    {
      title: '',
      dataIndex: ['attributes'],
      className: 'text-center-f',
      render: (v, r) => (
        <div
          className={
            !v.testResults || v.testResults.length === 0
              ? 'bg-gray-160 -m-2'
              : ''
          }
        >
          <Button
            type="primary"
            className="ant-btn-xs"
            onClick={() => {
              setSelectedStudent(r)
              setShowDetailModal(true)
            }}
          >
            詳細
          </Button>
        </div>
      ),
    },
  ]

  const modalProps = {
    testYear,
    student: selectedStudent,
    isEnduranceRunInputActive,
    isShow: showDetailModal,
    setIsShow: setShowDetailModal,
    school,
  }

  const handleSave = (newRecord) => {
    const newData = [...data]
    const index = newData.findIndex((item) => newRecord._id === item._id)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...newRecord })
    setData(newData)
    // TODO: Api save test result
  }

  const handlePrint = useReactToPrint({
    content: () => studentTestForPrintRef.current,
  })

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)
    const tr =
      record?.attributes?.testResults &&
      record.attributes.testResults.find((d) => d.testYear === testYear)

    useEffect(() => {
      if (editing) {
        inputRef.current.focus()
      }
    }, [editing])

    const toggleEdit = () => {
      if (!tr[dataIndex]?.isNotMeasurable) {
        setEditing(!editing)
        form.setFieldsValue({
          [dataIndex]: tr[dataIndex]?.value,
        })
      }
    }

    const save = async () => {
      try {
        const values = await form.validateFields()
        toggleEdit()
        // let newRecord = _.cloneDeep(record)
        const _tr = record.attributes.testResults.find(
          (d) => d.testYear === testYear,
        )
        _tr[dataIndex].value = Number.parseInt(values[dataIndex])
        handleSave(record)
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    }

    let childNode = children

    if (editable) {
      childNode = editing ? (
        <Form.Item
          name={dataIndex}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          <InputNumber
            size="small"
            step="any"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    }
  })

  return (
    <>
      <Dashboard
        selectedMenu={graphItem.tabIndex}
        navbar={graphItem.title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <Row className="w-full justify-center">
          <Col className="mt-16" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="space-x-2 pb-8 print:hidden">
              <Select
                className="w-30 rounded-5px"
                value={schoolGrade}
                onChange={setSchoolGrade}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                {teacherRole !== 'CLASS_ADMIN' &&
                  teacherRole !== 'GRADE_ADMIN' && (
                    <Option key={`grade-${0}`} value={0}>
                      全学年
                    </Option>
                  )}
                <>
                  {(teacherRole === 'SUPER_ADMIN' ||
                    teacherRole === 'SCHOOL_ADMIN') &&
                  gradesData?.grades?.data?.length > 0 ? (
                    gradesData?.grades?.data?.map((v) => (
                      <Option value={v.schoolGrade} key={v.schoolGrade}>
                        {`${v.schoolGrade}年生`}
                      </Option>
                    ))
                  ) : (teacherRole === 'SUPER_ADMIN' ||
                      teacherRole === 'SCHOOL_ADMIN') &&
                    gradesData?.grades?.data?.length === 0 ? (
                    <Option value={1} key={999}>
                      1年生
                    </Option>
                  ) : (
                    <Option value={teacherSchoolGrade} key={teacherSchoolGrade}>
                      {`${teacherSchoolGrade}年生`}
                    </Option>
                  )}
                </>
              </Select>

              <Select
                className="w-30 rounded-5px"
                value={schoolClass}
                onChange={setSchoolClass}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                {teacherRole !== 'CLASS_ADMIN' && (
                  <Option key={`class-${0}`} value={0}>
                    全組
                  </Option>
                )}
                <>
                  {teacherRole !== 'CLASS_ADMIN' ? (
                    schoolClasses
                      .sort((a, b) => a - b)
                      .map((_class) => (
                        <Option key={`class-${_class}`} value={_class}>
                          {_class}組
                        </Option>
                      ))
                  ) : (
                    <Option value={teacherSchoolClass} key={teacherSchoolClass}>
                      {`${teacherSchoolClass}組`}
                    </Option>
                  )}
                </>
              </Select>
              <Select
                className="w-30 rounded-5px"
                value={filterGender}
                onChange={(g) => setFilterGender(g)}
                dropdownAlign={{
                  offset: [0, -2], // align offset
                }}
              >
                <Option key="gender-0" value="">
                  全性別
                </Option>
                <Option key="gender-male" value="MALE">
                  男性
                </Option>
                <Option key="gender-male" value="FEMALE">
                  女性
                </Option>
              </Select>
              <Button
                type="primary"
                className="float-right w-30"
                onClick={handlePrint}
              >
                印刷
              </Button>
              <Button
                type="primary"
                className="float-right w-30"
                onClick={downloadXlsx}
              >
                ダウンロード
              </Button>
            </div>

            <Table
              components={components}
              columns={editableColumns}
              dataSource={data}
              loading={loading}
              rowKey="title"
              size="small"
              className="relative"
              style={{ minWidth: 940 }}
              pagination={{
                pageSize: pageSize,
                defaultPageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100, 1000, 2000],
                position: ['bottomCenter'],
                showSizeChanger: true,
                total,
                current: currentPage,
                onChange: (page: number, pageSize: number) => {
                  setCurrentPage(page)
                  setPageSize(pageSize)
                },
              }}
              bordered={true}
            />
            {selectedStudent && (
              <StudentTestResultModal
                key={`d-${selectedStudent?._id ?? ''}`}
                {...modalProps}
              />
            )}
          </Col>
        </Row>
      </Dashboard>
      <div ref={studentTestForPrintRef} className="print-component">
        <TestResultsForPrintPage
          testYear={testYear}
          grades={gradesData?.grades?.data}
          schoolGrade={schoolGrade}
          schoolClass={schoolClass}
          data={allStudentsData?.students?.data?.filter((s) => {
            const { testResults } = s.attributes
            if (!testResults) {
              return false
            }
            return testResults.findIndex((d) => d.testYear === testYear) !== -1
          })}
          school={school}
        />
      </div>
    </>
  )
}

export default TestResultPage
