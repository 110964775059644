export const PreparingPage = ({ notice }): JSX.Element => {
  return (
    <div
      style={{ height: 'calc(100vh - 100px)' }}
      className="flex justify-center items-center overflow-hidden"
    >
      <div
        style={{ width: 450, height: 160 }}
        className="flex flex-col justify-between items-center relative rounded-10px border-3px border-primary"
      >
        <img
          className="absolute"
          style={{ top: -87 }}
          src="/AlphaWhale.png"
          alt=""
        />
        <div className="h-full w-full flex flex-col justify-center items-center">
          {typeof notice === 'string' ? (
            <label className="text-base font-black">{notice}</label>
          ) : (
            notice
          )}
        </div>
      </div>
    </div>
  )
}
