import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, Col, Form, Input, InputNumber, List, Row, Select, Table } from 'antd'
import {
  DownOutlined,
  EditOutlined,
  SaveOutlined,
  UpOutlined,
} from '@ant-design/icons'

import { fetchSchoolClasses } from '@alpha/core'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import _ from 'lodash'
import axios from 'axios'
import { useAlphaStore } from '~/context'
import {
  useSchoolGradesApiQuery,
  useSchoolSurveysApiQuery,
} from '~/utils/hooks'
import { pageSize as defaultPageSize } from '../../utils/constants'
import { convertJapNumbers, getQuestionnairesArr } from '~/utils'
import { useSchoolMeApiQuery } from '~/utils/hooks'

const { Option } = Select

const incorrectValue = <div className="bg-gray-160 -m-2">&nbsp;</div>

const sortTestResults = (a, b, key: string) => {
  let element1
  let element2
  if (key.startsWith('q')) {
    const a_qV3 = a.attributes?.testResults?.[0]?.questionnaireV3
    const b_qV3 = b.attributes?.testResults?.[0]?.questionnaireV3
    element1 = a_qV3?.[key]
    element2 = b_qV3?.[key]
  } else if (key === 'name') {
    element1 = `${a?.attributes?.familyName ?? ''}${
      a?.attributes?.givenName ?? ''
    }`
    element2 = `${b?.attributes?.familyName ?? ''}${
      b?.attributes?.givenName ?? ''
    }`
  } else {
    element1 = a?.[key]
    element2 = b?.[key]
  }

  if (element1 == null && element2 == null) return 1
  if (element1 == null) return -1
  if (element2 == null) return 1
  return typeof element1 === 'number'
    ? element1 - element2
    : element1.localeCompare(element2)
}

const getTestRecordStatus = (totalQuestionnaires, v) => {
  let status = false
  if (!v || v.length === 0) return status
  let questions = v[0]?.questionnaireV3
  if (questions) {
    status = totalQuestionnaires.every((_, q) => {
      const qk = `q${q + 1}`
      return !!questions[qk]
    })
  }

  return status
}

const columns = (totalQuestionnaires: (string | number)[]): any[] => [
  {
    title: '提出状況',
    dataIndex: ['attributes', 'testResults'],
    key: 'status',
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      const status1 = getTestRecordStatus(
        totalQuestionnaires,
        a?.attributes?.testResults,
      )
      const status2 = getTestRecordStatus(
        totalQuestionnaires,
        b?.attributes?.testResults,
      )

      return status1 === status2 ? 0 : !status1 ? -1 : 1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      const status = getTestRecordStatus(totalQuestionnaires, v)

      return (
        <div
          className="flex justify-center items-center"
          style={{ height: 22, width: 60 }}
        >
          <div
            className="flex justify-center items-center h-4 w-4 rounded-3px text-xxs font-bold text-white"
            style={{ backgroundColor: status ? '#00944D' : '#CA4141' }}
          >
            {status ? '済' : '未'}
          </div>
        </div>
      )
    },
  },
  {
    title: '学年',
    key: 'schoolGrade',
    dataIndex: ['attributes', 'schoolGrade'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolGrade')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => <div style={{ width: 40 }}>{v}</div>,
  },
  {
    title: 'クラス',
    key: 'schoolClass',
    dataIndex: ['attributes', 'schoolClass'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolClass')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => <div style={{ width: 50 }}>{v}</div>,
  },
  {
    title: '出席番号',
    key: 'schoolAttendanceNumber',
    dataIndex: ['attributes', 'schoolAttendanceNumber'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolAttendanceNumber')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => <div style={{ width: 70 }}>{v}</div>,
  },
  {
    title: 'ID',
    key: 'username',
    dataIndex: ['username'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'username')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v, _) => <div style={{ width: 140 }}>{v}</div>,
  },
]

const editPanelColumn = (isEditing: any, edit: any, handleSave: any): any[] => {
  return [
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      classNAme: 'text-center',
      editable: false,
      render: (v, r) => {
        const editable = isEditing(r)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return editable ? (
          <SaveOutlined
            className="cursor-pointer"
            onClick={() => handleSave(r._id)}
          />
        ) : (
          <EditOutlined className="cursor-pointer" onClick={() => edit(r)} />
        )
      },
    },
  ]
}

const QuestionnaireInputPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const totalQuestionnaires = getQuestionnairesArr(school, isElementarySchool)

  const { data: teacherData } = useSchoolMeApiQuery()
  const testYear = teacherData?.testYear
  const teacherRole = teacherData?.role
  const teacherSchoolGrade = teacherData?.schoolGrade
  const teacherSchoolClass = teacherData?.schoolClass
  const [schoolGrade, setSchoolGrade] = useState<any>(
    teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
      ? teacherSchoolGrade
      : 0,
  )
  const [schoolClasses, setSchoolClasses] = useState([
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1,
  ])
  const [schoolClass, setSchoolClass] = useState<any>(
    teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0,
  )
  const [firstLoad, setFirstLoad] = useState(true)
  const [data, setData] = useState<any[]>()

  useEffect(() => {
    if (firstLoad && teacherRole) {
      setFirstLoad(false)
      setSchoolGrade(
        teacherRole === 'GRADE_ADMIN' || teacherRole === 'CLASS_ADMIN'
          ? teacherSchoolGrade
          : 0,
      )
      setSchoolClasses([teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 1])
      setSchoolClass(teacherRole === 'CLASS_ADMIN' ? teacherSchoolClass : 0)
    }
  }, [firstLoad, teacherRole])

  const [questionData, setQuestionData] = useState([])
  const [isCollapsed, setCollapsed] = useState(false)
  const [editingId, setEditingId] = useState('')

  const editRow = (record: any) => {
    setEditingId(record._id)
    form.resetFields()
    form.setFieldsValue({
      ...record.attributes?.testResults[0]?.questionnaireV3,
    })
  }
  const schoolId = school?._id
  const { data: grades, loading: gradesLoading } = useSchoolGradesApiQuery({
    schoolId: school?._id,
    testYear: testYear,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const { data: surveys, loading: surveysLoading } = useSchoolSurveysApiQuery(
    {
      schoolId: school?._id,
      testYear: testYear,
      schoolClass,
      schoolGrade,
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
    },
    firstLoad,
  )
  const [questionsPerPage, setQuestionsPerPage] = useState(4)

  useEffect(() => {
    setData(surveys?.data)
  }, [surveys?.data])

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, testYear).then((res) => {
        const schoolClasses = res['schoolClasses']
        if (schoolClasses.length) {
          schoolClasses.sort()
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [testYear, school])

  // Get questions
  useEffect(() => {
    setQuestionsPerPage(school?.attributes?.prefectureCode === 16 ? 5 : 4)
    const fileName = './questions.json'
    fetch(fileName)
      .then((res) => res.json())
      .then((data) => {
        setQuestionData(
          data.filter(
            (d) => d.type === `${isElementarySchool ? 'elementary' : 'junior'}`,
          ),
        )
      })
  }, [school, isElementarySchool])

  const renderQuestion = (questionData, idx) => {
    let answers: JSX.Element
    if (questionData.options) {
      answers = (
        <Row className="w-full" gutter={8}>
          {Object.keys(questionData.options).map((answer, idx) => {
            const value = questionData.options[answer]
            return (
              <Col key={idx}>
                <span className={isCollapsed ? 'hidden' : 'pl-10 block'}>
                  {value + (answer && `.${answer}`)}
                </span>
              </Col>
            )
          })}
        </Row>
      )
    }

    const showEllipsis = isCollapsed && idx === 0

    return (
      <List.Item
        style={{
          width: school?.attributes?.prefectureCode === 45 ? 860 : 800,
          borderStyle: 'none',
        }}
      >
        <>
          <div
            className={
              showEllipsis
                ? 'font-black collapsed-questions relative w-max '
                : 'font-black whitespace-pre-wrap'
            }
            {...{ ellipsis: '...' }}
          >
            {questionData.question}
          </div>
          <>{answers}</>
        </>
      </List.Item>
    )
  }

  const collapse = () => {
    setCollapsed(!isCollapsed)
  }

  const fetchUpdateTestResult = async (schoolId: string, students: any) => {
    await axios.post(
      `${process.env.REACT_APP_REST_API_URL}/alpha/upload-questionnaire-data`,
      {
        schoolId,
        students: JSON.stringify(students),
        testYear
      },
    )
  }

  const [form] = Form.useForm()
  const handleSave = async (id: string) => {
    let questionnaire
    try {
      questionnaire = await form.validateFields()
    } catch (e) {
      console.log(`Validate failed ${e.message}`)
      return
    }
    const newData = [...data]
    const index = newData.findIndex((item) => id === item._id)
    const item = _.cloneDeep(newData[index])
    if (item.attributes.testResults.length === 0)
      item.attributes.testResults.push({})
    const { testResults } = item.attributes
    testResults[0].questionnaireV3 = questionnaire
    newData.splice(index, 1, {
      ...item,
    })
    setData(newData)
    setEditingId('')

    await fetchUpdateTestResult(schoolId, [
      {
        schoolGrade: item.attributes.schoolGrade,
        schoolClass: item.attributes.schoolClass,
        schoolAttendanceNumber: item.attributes.schoolAttendanceNumber,
        questionnaire,
      },
    ])
  }

  const EditableCell = ({
    editing,
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    let childNode = children
    const question = questionData.find((_, id) => `q${id+1}` === dataIndex)
    if (editable) {
      childNode = editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title}を入力してください`,
            },
          ]}
        >
          {question?.multiple
            ? <Input
              style={{ width: 50, textAlign: 'left' }}
              size="small"
            />
            : <InputNumber
            style={{ width: 50, textAlign: 'left' }}
            size="small"
            min={dataIndex === 'q8' ? '0' : '1'}
            max={
              dataIndex === 'q8' ? '1000' : String(question?.options?.length)
            }
            parser={(displayValue) => {
              return convertJapNumbers(displayValue)
            }}
          />}
        </Form.Item>
      ) : (
        children
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  }

  const isEditing = (record: any) => record._id === editingId
  const editableColumns = [
    ...columns(totalQuestionnaires),
    ...totalQuestionnaires.map((_q, id) => {
      const qk = `q${id + 1}`
      return {
        title: `Q${_q}`,
        dataIndex: qk,
        key: qk,
        className: 'text-center-f whitespace-nowrap',
        editable: true,
        sorter: (a, b) => {
          return sortTestResults(a, b, qk)
        },
        sortDirections: ['descend', 'ascend'],
        render: (v, r) => {
          if (
            !r.attributes?.testResults ||
            r.attributes?.testResults.length === 0
          )
            return incorrectValue
          let q = r.attributes.testResults[0]?.questionnaireV3
          if (qk === 'q8' && typeof q?.[qk] === 'object') {
            let total = 0
            for (const val of q[qk]) total += (val || 0)
            q[qk] = total
            return <div>{q[qk]}</div>
          }
          if (!q?.[qk]) return incorrectValue
          return <div>{q?.[qk]}</div>
        },
      }
    }),
    ...editPanelColumn(isEditing, editRow, handleSave),
  ].map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={graphItem.title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="mt-3 flex flex-col justify-center items-center ml-20">
        <div style={{ minWidth: 930 }}>
          <div
            className={`border border-primary rounded-xl px-10 py-2 ${
              isCollapsed && 'h-16 overflow-hidden'
            }`}
          >
            <List
              className="flex flex-col items-center space-y-1"
              itemLayout="vertical"
              dataSource={questionData}
              renderItem={(question, idx) => renderQuestion(question, idx)}
              bordered={false}
              pagination={{
                size: 'small',
                showTitle: false,
                hideOnSinglePage: true,
                showSizeChanger: false,
                pageSize: questionsPerPage,
                position: 'bottom',
              }}
            />
          </div>
          <div className="flex justify-center items-center mt-2">
            <Button
              type="link"
              icon={
                isCollapsed ? (
                  <DownOutlined style={{ verticalAlign: 'middle' }} />
                ) : (
                  <UpOutlined style={{ verticalAlign: 'middle' }} />
                )
              }
              onClick={collapse}
            >
              {isCollapsed ? '質問をもっと見る' : '質問を閉じる'}
            </Button>
          </div>

          <div className="w-full space-x-2">
            <Select
              className="w-30 rounded-5px"
              value={schoolGrade}
              onChange={setSchoolGrade}
              dropdownAlign={{
                offset: [0, -2], // align offset
              }}
            >
              {teacherRole !== 'CLASS_ADMIN' && teacherRole !== 'GRADE_ADMIN' && (
                <Option key={`grade-${0}`} value={0}>
                  全学年
                </Option>
              )}
              <>
                {(teacherRole === 'SUPER_ADMIN' ||
                  teacherRole === 'SCHOOL_ADMIN') &&
                grades?.data?.length > 0 ? (
                  grades?.data?.map((v) => (
                    <Option value={v.schoolGrade} key={v.schoolGrade}>
                      {`${v.schoolGrade}年生`}
                    </Option>
                  ))
                ) : (teacherRole === 'SUPER_ADMIN' ||
                    teacherRole === 'SCHOOL_ADMIN') &&
                  grades?.data?.length === 0 ? (
                  <Option value={1} key={999}>
                    1年生
                  </Option>
                ) : (
                  <Option value={teacherSchoolGrade} key={teacherSchoolGrade}>
                    {`${teacherSchoolGrade}年生`}
                  </Option>
                )}
              </>
            </Select>

            <Select
              className="w-30 rounded-5px"
              value={schoolClass}
              onChange={setSchoolClass}
              dropdownAlign={{
                offset: [0, -2], // align offset
              }}
            >
              {teacherRole !== 'CLASS_ADMIN' && (
                <Option key={`class-${0}`} value={0}>
                  全組
                </Option>
              )}
              <>
                {teacherRole !== 'CLASS_ADMIN' ? (
                  schoolClasses
                    .sort((a, b) => a - b)
                    .map((_class) => (
                      <Option key={`class-${_class}`} value={_class}>
                        {_class}組
                      </Option>
                    ))
                ) : (
                  <Option value={teacherSchoolClass} key={teacherSchoolClass}>
                    {`${teacherSchoolClass}組`}
                  </Option>
                )}
              </>
            </Select>
          </div>

          <div className="w-full mt-1">登録人数 {surveys?.total}</div>
          <Form form={form} component={false}>
            <Table
              columns={editableColumns}
              components={components}
              dataSource={data}
              loading={gradesLoading || surveysLoading}
              style={{ minWidth: 930 }}
              size="small"
              rowClassName="font-bold text-black"
              bordered={true}
              pagination={{
                pageSize: pageSize,
                defaultPageSize: pageSize,
                position: ['bottomCenter'],
                total: surveys?.total,
                current: currentPage,
                showSizeChanger: true,
                onChange: (page: number, size: number) => {
                  setCurrentPage(pageSize !== size ? 1 : page)
                  setPageSize(size)
                },
              }}
            />
          </Form>
        </div>
      </div>
    </Dashboard>
  )
}

export default QuestionnaireInputPage
