import React, { useState, useContext, useEffect } from 'react'
import { CSVLink } from 'react-csv'

import { Card } from '@alpha/components'
import { Select, Table, Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table'

import {
  AuthContext,
  useGradesLazyQuery,
  useMeQuery,
  useStudentsLazyQuery,
  fetchSchoolClasses,
} from '@alpha/core'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navigation'
import { Link } from 'react-router-dom'

const { Option } = Select

const columns: ColumnType<any>[] = [
  {
    title: '学年',
    key: 'schoolGrade',
    dataIndex: ['attributes', 'schoolGrade'],
    sorter: (a, b) => {
      let schoolGrade1 = a.attributes.schoolGrade
      let schoolGrade2 = b.attributes.schoolGrade

      if (!schoolGrade1 && !schoolGrade2) return 1
      if (!schoolGrade1) return -1
      if (!schoolGrade2) return 1
      return schoolGrade1 > schoolGrade2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '組',
    key: 'schoolClass',
    dataIndex: ['attributes', 'schoolClass'],
    sorter: (a, b) => {
      let schoolClass1 = a.attributes.schoolClass
      let schoolClass2 = b.attributes.schoolClass

      if (!schoolClass1 && !schoolClass2) return 1
      if (!schoolClass1) return -1
      if (!schoolClass2) return 1
      return schoolClass1 > schoolClass2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '出席番号',
    key: 'schoolAttendanceNumber',
    dataIndex: ['attributes', 'schoolAttendanceNumber'],
    sorter: (a, b) => {
      let schoolAttendanceNumber1 = a.attributes.schoolAttendanceNumber
      let schoolAttendanceNumber2 = b.attributes.schoolAttendanceNumber

      if (!schoolAttendanceNumber1 && !schoolAttendanceNumber2) return 1
      if (!schoolAttendanceNumber1) return -1
      if (!schoolAttendanceNumber2) return 1
      return schoolAttendanceNumber1 > schoolAttendanceNumber2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: '姓名',
    dataIndex: ['attributes'],
    render: (v, _) => (
      <div>{`${v.familyName ?? ''}　${v.givenName ?? ''}`}</div>
    ),
  },
  {
    title: '性別',
    dataIndex: ['attributes', 'gender'],
    key: 'gender',
    render: (v) => <div>{v ? (v === 'MALE' ? '男' : '女') : '-'}</div>,
  },
  {
    title: '身長',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.height
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.height

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      return <div>{v ? v[0]?.questionnaire?.height ?? '-' : '-'}</div>
    },
  },
  {
    title: '体重',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.weight
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.weight

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      return <div>{v ? v[0]?.questionnaire?.weight ?? '-' : '-'}</div>
    },
  },
  {
    title: '座高',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.sittingHeight
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.sittingHeight

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      return <div>{v ? v[0]?.questionnaire?.sittingHeight ?? '-' : '-'}</div>
    },
  },
]

let defaultColumns: ColumnType<any>[] = [
  {
    title: '所属状況',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.belongsToSportsClub
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.belongsToSportsClub

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      if (!v || v.length === 0) return <div></div>
      return (
        <div>{v ? v[0]?.questionnaire?.belongsToSportsClub ?? '-' : '-'}</div>
      )
    },
  },
  {
    title: '実施状況',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.excerciseActivity
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.excerciseActivity

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      if (!v || v.length === 0) return <div></div>
      return (
        <div>{v ? v[0]?.questionnaire?.excerciseActivity ?? '-' : '-'}</div>
      )
    },
  },
  {
    title: '実施時間',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.dailyExcerciseTime
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.dailyExcerciseTime

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      if (!v || v.length === 0) return <div></div>
      return (
        <div>{v ? v[0]?.questionnaire?.dailyExcerciseTime ?? '-' : '-'}</div>
      )
    },
  },
  {
    title: '朝食の有無',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.breakfast
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.breakfast

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      if (!v || v.length === 0) return <div></div>
      return <div>{v ? v[0]?.questionnaire?.breakfast ?? '-' : '-'}</div>
    },
  },
  {
    title: '睡眠時間',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.sleepTime
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.sleepTime

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      if (!v || v.length === 0) return <div></div>
      return <div>{v ? v[0]?.questionnaire?.sleepTime ?? '-' : '-'}</div>
    },
  },
  {
    title: '視聴時間',
    dataIndex: ['attributes', 'testResults'],
    sorter: (a, b) => {
      let questionnaire1 =
        a.attributes.testResults &&
        a.attributes.testResults[0]?.questionnaire?.tvTime
      let questionnaire2 =
        b.attributes.testResults &&
        b.attributes.testResults[0]?.questionnaire?.tvTime

      if (!questionnaire1 && !questionnaire2) return 1
      if (!questionnaire1) return -1
      if (!questionnaire2) return 1
      return questionnaire1 > questionnaire2 ? 1 : -1
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => {
      if (!v || v.length === 0) return <div></div>
      return <div>{v ? v[0]?.questionnaire?.tvTime ?? '-' : '-'}</div>
    },
  },
]

const QuestionnaireResultOldPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const auth = useContext(AuthContext)

  const [testYears, setTestYears] = useState([2021])
  const [testYear, setTestYear] = useState(2021)
  const [schoolGrade, setSchoolGrade] = useState<any>(1)
  const [schoolClass, setSchoolClass] = useState<any>(1)
  const [schoolClasses, setSchoolClasses] = useState([1])
  const [school, setSchool] = useState<any>(null)
  const [data, setData] = useState<any[]>()
  const [csvReport, setCSVReport] = useState<any>()
  const [filterGender, setFilterGender] = useState('')

  const { data: meData } = useMeQuery({
    fetchPolicy: 'cache-first',
  })
  const [getGrades, { data: gradesData }] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  const [getStudents, { data: studentData }] = useStudentsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const { currentUser } = auth

  // testYears
  // useEffect(() => {
  //   if (!currentUser) {
  //     return
  //   }
  //   if (!meData?.me?.data?.relationships.schools) {
  //     return
  //   }
  //   const school = meData.me.data.relationships.schools[0]
  //   setSchool(school)

  //   let _testYears = school.attributes.testYears ?? [2022]
  //   setTestYears(_testYears)
  // }, [currentUser, meData])

  // getGrades
  useEffect(() => {
    if (!currentUser) {
      return
    }
    if (!meData?.me?.data?.relationships.schools) {
      return
    }
    const school = meData.me.data.relationships.schools[0]
    setSchool(school)

    const schoolId = meData.me.data.relationships.schools[0]._id
    getGrades({
      variables: {
        input: {
          schoolId,
          testYear,
        },
      },
    })
  }, [currentUser, getGrades, meData, testYear])

  // getStudents
  useEffect(() => {
    if (!gradesData?.grades?.data) return

    let schoolId = school._id
    if (!schoolId) return

    getStudents({
      variables: {
        input: {
          schoolId: schoolId,
          testYear,
          schoolClass,
          schoolGrade,
        },
      },
    })
  }, [gradesData, school, getStudents, schoolGrade, schoolClass, testYear])

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, testYear).then((res) => {
        const schoolClasses = res['schoolClasses']
        if (schoolClasses.length) {
          schoolClasses.sort()
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [testYear, school])

  // setData
  useEffect(() => {
    if (!studentData?.students?.data) return

    let studentsData = studentData.students.data
    if (filterGender === '') {
      setData(studentsData)
    } else {
      studentsData = studentsData.filter(
        (s) => s.attributes.gender === filterGender,
      )
      setData(studentsData)
    }
  }, [studentData, filterGender])

  // setCSVData
  useEffect(() => {
    if (!data) {
      setCSVReport({
        headers: [],
        data: [],
        filename: 'questionnaire_result.csv',
      })
      return
    }

    let isVersion2 = school?.attributes.questions

    let commonHeaders = [
      { label: '年度', key: 'testYear' },
      { label: '学年', key: 'schoolGrade' },
      { label: '組', key: 'schoolClass' },
      { label: '出席番号', key: 'schoolAttendanceNumber' },
      { label: '姓名', key: 'name' },
      { label: '性別', key: 'gender' },
      { label: '身長', key: 'height' },
      { label: '体重', key: 'weight' },
    ]

    let defaultHeaders = [
      { label: '所属状況', key: 'belongsToSportsClub' },
      { label: '実施状況', key: 'excerciseActivity' },
      { label: '実施時間', key: 'dailyExcerciseTime' },
      { label: '朝食の有無', key: 'breakfast' },
      { label: '睡眠時間', key: 'sleepTime' },
      { label: '視聴時間', key: 'tvTime' },
    ]

    let headers: any[] = []
    if (isVersion2) {
      let questionHeaders = school.attributes.questions.map((q, i) => ({
        label: `q${i + 1}`,
        key: `q${i + 1}`,
      }))
      headers = [...commonHeaders, ...questionHeaders]
    } else {
      headers = [...commonHeaders, ...defaultHeaders]
    }

    let CSVData = data.map((student) => {
      let attributes = student.attributes
      let testResult = attributes.testResults && attributes.testResults[0]
      let questionnaire = testResult?.questionnaire
      let questionnaireV2 = testResult?.questionnaireV2

      if (isVersion2) {
        let studentData = {
          testYear,
          schoolGrade: attributes.schoolGrade,
          schoolClass: attributes.schoolClass,
          schoolAttendanceNumber: attributes.schoolAttendanceNumber ?? '',
          name: `${attributes.familyName ?? ''} ${attributes.givenName ?? ''}`,
          gender: attributes.gender === 'MALE' ? '男' : '女',
          height: questionnaireV2?.height ?? '',
          weight: questionnaireV2?.weight ?? '',
        }
        questionnaireV2?.answers.forEach((answer, index) => {
          studentData[`q${index + 1}`] = answer
        })
        return studentData
      } else {
        return {
          testYear,
          schoolGrade: attributes.schoolGrade,
          schoolClass: attributes.schoolClass,
          schoolAttendanceNumber: attributes.schoolAttendanceNumber ?? '',
          name: `${attributes.familyName ?? ''} ${attributes.givenName ?? ''}`,
          gender: attributes.gender === 'MALE' ? '男' : '女',
          height: questionnaire?.height ?? '',
          weight: questionnaire?.weight ?? '',
          belongsToSportsClub: questionnaire?.belongsToSportsClub ?? '',
          excerciseActivity: questionnaire?.excerciseActivity ?? '',
          dailyExcerciseTime: questionnaire?.dailyExcerciseTime ?? '',
          breakfast: questionnaire?.breakfast ?? '',
          sleepTime: questionnaire?.sleepTime ?? '',
          tvTime: questionnaire?.tvTime ?? '',
        }
      }
    })

    let report = {
      data: CSVData,
      headers,
      filename: 'questionnaire_result.csv',
    }

    setCSVReport(report)
  }, [data, schoolGrade, schoolClass, school, testYear])

  if (!school) return null

  let customColumns: ColumnType<any>[] = school.attributes.questions?.map(
    (question, index) => {
      return {
        title: `q${index + 1}`,
        dataIndex: ['attributes', 'testResults'],
        sorter: (a, b) => {
          let questionnaire1 =
            a.attributes.testResults &&
            a.attributes.testResults[0]?.questionnaireV2?.answers[index]
          let questionnaire2 =
            b.attributes.testResults &&
            b.attributes.testResults[0]?.questionnaireV2?.answers[index]

          if (!questionnaire1 && !questionnaire2) return 1
          if (!questionnaire1) return -1
          if (!questionnaire2) return 1
          return questionnaire1 > questionnaire2 ? 1 : -1
        },
        sortDirections: ['descend', 'ascend'],
        render: (v) => {
          return (
            <div>
              <Tooltip placement="top" title={question.question}>
                <div>
                  {v ? v[0]?.questionnaireV2?.answers[index] ?? '-' : '-'}
                </div>
              </Tooltip>
            </div>
          )
        },
      }
    },
  )

  let title = <div className="theme-nav-title">{graphItem.title}</div>

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card>
        <div className="w-full mb-3">
          <Link className="warn-link" to="/questionnaire_result">
            2022年度はこちら&gt;&gt;&gt;
          </Link>
        </div>

        <div className="pb-8">
          <Select
            className="w-36"
            value={testYear}
            onChange={(y) => setTestYear(y)}
          >
            <>
              {testYears?.map((year) => (
                <Option value={year} key={year}>
                  {year}年度
                </Option>
              ))}
            </>
          </Select>

          <Select
            className="w-36"
            value={schoolGrade}
            onChange={setSchoolGrade}
          >
            <Option key={`grade-${0}`} value={0}>
              全学年
            </Option>
            <>
              {gradesData?.grades?.data?.map((v) => (
                <Option value={v.schoolGrade} key={v.schoolGrade}>
                  {`${v.schoolGrade}年生`}
                </Option>
              ))}
            </>
          </Select>

          <Select
            className="w-36 mx-4"
            value={schoolClass}
            onChange={setSchoolClass}
          >
            <Option key={`class-${0}`} value={0}>
              全組
            </Option>
            <>
              {schoolClasses.map((_class) => {
                return (
                  <Option key={`class-${_class}`} value={_class}>
                    {_class}組
                  </Option>
                )
              })}
            </>
          </Select>
          <Select
            className="w-36"
            value={filterGender}
            onChange={(f) => setFilterGender(f)}
          >
            <Option key="gender-0" value="">
              性別
            </Option>
            <Option key="gender-male" value="MALE">
              男性
            </Option>
            <Option key="gender-male" value="FEMALE">
              女性
            </Option>
          </Select>
        </div>

        <Table
          columns={
            school.attributes.questions
              ? [...columns, ...customColumns]
              : [...columns, ...defaultColumns]
          }
          dataSource={data}
          style={{ minWidth: 2000 }}
          pagination={{ pageSize: 100 }}
        />

        <div className="flex justify-end py-4 px-4">
          {csvReport && <CSVLink {...csvReport}>CSVをダウンロード</CSVLink>}
        </div>
      </Card>
    </Dashboard>
  )
}

export default QuestionnaireResultOldPage
